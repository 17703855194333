import { useState } from "react";
import { useAppDispatch } from "../../utils/hook";
import {createInvitedUser} from "../../utils/axios";
import { login } from "../../store/slice/auth";
import { useNavigate } from "react-router-dom";

const LoginInvitedUser = ({wsId, wsTitle, email, sign} : {wsId: string, wsTitle: string, email: string, sign: string}) => {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");

    const [errors, setErrors] = useState<string[]>([]);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const handleSubmit = async () => {
        try {
            setErrors([]);

            let newErrors = [];

            if (firstName === '') newErrors.push('Имя не может быть пустым');
            if (lastName === '') newErrors.push('Фамилия не может быть пустой');
            if (email === '') newErrors.push('Электронная почта не может быть пустой');
            if (password === '') newErrors.push('Пароль не может быть пустым');
            if (password && !passwordConfirm) newErrors.push('Подтвердите пароль');
            if (password && passwordConfirm && password !== passwordConfirm) newErrors.push('Пароли не совпадают');

            if (newErrors.length > 0) {
                setErrors(newErrors);
                return;
            }

            const userData = {
                ws_id: wsId,
                sign: sign,
                user: {
                    first_name: firstName,
                    last_name: lastName,
                    email: email,
                    password: password
                }
            }
            const res = await createInvitedUser(userData);
            if (res.user) {
                dispatch(login(res.user));
                navigate('/home');
            } else if (res.errors) {
                setErrors(res.errors);
            }

        } catch (e) {
            return e
        }
    }

    return (
        <div className="form-container">
            <h3 className="form-title">Mngr.online</h3>
            <div className="form-wrapper">
                <div className="form-type">
                    <button className={"form-type-button-full"}>Регистрация</button>
                </div>

                <form className="form">
                    {errors.length > 0 &&
                        <div className="form-task-error">
                            {errors.map((error: string)=>(
                                <p>{error}</p>
                            ))}
                        </div>}
                    
                    <>
                        <div className='form-group'>
                            <label className='form-label'>Рабочее окружение</label>
                            <input 
                                type="text"
                                className="form-input"
                                value={wsTitle}
                                readOnly
                            />
                        </div>

                        <div className='form-group'>
                            <label className='form-label'>Электронная почта</label>
                            <input 
                                type="email"
                                className="form-input"
                                value={email}
                                readOnly
                            />
                        </div>

                        <div className='form-group'>
                            <label className='form-label'>Имя</label>
                            <input 
                                type="text" 
                                className="form-input" 
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                />
                        </div>
                        
                        <div className='form-group'>
                            <label className='form-label'>Фамилия</label>
                            <input
                                type="text" 
                                className="form-input" 
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)} 
                            />
                        </div>

                        <div className='form-group'>
                            <label className='form-label'>Пароль</label>
                            <input 
                                type="password" 
                                className="form-input"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>

                        <div className='form-group'>
                            <label className='form-label'>Подтверждение пароля</label>
                            <input 
                                type="password" 
                                className="form-input"
                                value={passwordConfirm}
                                onChange={(e) => setPasswordConfirm(e.target.value)}
                            />
                        </div>
                        
                        <div className='form-group'>
                            <button className="form-submit" onClick={handleSubmit} type={'button'}>Продолжить</button>
                        </div>  
                    </>


                </form>

            </div>
      </div>
    )
}

export default LoginInvitedUser