import { FC, useCallback, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import ruLocale from '@fullcalendar/core/locales/ru';
import { ICalendarEvent } from '../../common/types/tasks';

import "./CalendarEvents.css";

interface CalendarEventsProps {
    calendarRef: React.RefObject<FullCalendar>;
    events: ICalendarEvent[];
    initialView: string;
    handleSelect: (selectInfo: any) => void;
    handleEventClick: (clickInfo: any) => void;
    handleEventChange: (changeInfo: any) => void;
    onDatesSet: (dateInfo: any) => void;
}

const CalendarEvents: FC<CalendarEventsProps> = ({
    calendarRef,
    events, 
    initialView,
    handleSelect,
    handleEventClick,
    handleEventChange, 
    onDatesSet
}) => {

    return (
        <FullCalendar
            ref={calendarRef}
            plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
            initialView={initialView}
            headerToolbar={{
                left: 'prev,next today',
                center: 'title',
                right: 'timeGridDay,timeGridWeek,dayGridMonth'
            }}
            locale={ruLocale}
            editable={true}
            selectable={true}
            selectMirror={true}
            dayMaxEvents={true}
            weekends={true}
            events={events}
            select={handleSelect}
            eventClick={handleEventClick}
            eventChange={handleEventChange}
            height="auto"
            aspectRatio={1.8}
            buttonText={{
                today: "Сегодня",
                day: "День",
                week: "Неделя",
                month: "Месяц",
                list: "Список"
            }}
            allDaySlot={false}
            slotMinTime="00:00:00"
            slotMaxTime="24:00:00"
            slotDuration="00:15:00"
            slotLabelInterval="01:00:00"
            nowIndicator={true}
            
            scrollTime={'16:00:00'}

            unselectAuto={false} 
            selectConstraint={{
                startTime: '00:00',
                endTime: '24:00',
                daysOfWeek: [0, 1, 2, 3, 4, 5, 6]
            }}
            datesSet={onDatesSet} 
        />
    );
};

export default CalendarEvents;
