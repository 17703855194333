import {useEffect, useState} from "react";
import {IPaginatedResponse, IProject} from "../../common/types/tasks";
import BoardsTable from "../../components/BoardsTable";
import {CircularProgress} from "@mui/material";
import {fetchBoards} from "../../utils/axios";
import Pagination from "../../components/Pagination";
import { convertFiltersToParam, convertSearchToParam, filterEntries, getInitialFilterParams,getInitialSearchParams, isPresent, searchEntries, setParams } from "../../utils/utils";
import FiltersHead from "../FiltersHead";

const BoardsList = () => {
    const [fetched, setFetched] = useState<boolean>(false);
    const [boardsData, setBoardsData] = useState<IPaginatedResponse>({ objects: [], previous: '', next: '', current_page: 0, count_objects: 0, count_pages: 0 });

    const [searchData, setSearchData] = useState<{ title: string; }>({ title: getInitialSearchParams('title') });
    const [filterData, setFilterData] = useState<{ projects: IProject[] }>({ projects: [] });
    const [paginateData, setPaginateData] = useState<{ page: string; page_size: string; }>({
        page: getInitialSearchParams('page'),
        page_size: getInitialSearchParams('page_size')
    });

    const [selectedFilters, setSelectedFilters] = useState<{ projects: number[] }>({ projects: getInitialFilterParams('project') });
    
    const handleSelectedChange = (filter: string) => (newSelectedOptions: number[]) => {
        setSelectedFilters(prev => ({ ...prev, [filter]: newSelectedOptions }));
    };

    const handleFilterApply = async () => {
        try {
            const params = getBoardsParams();
            setParams(params);
            setFetched(false);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (isPresent(paginateData.page) || isPresent(paginateData.page_size))
        handleFilterApply();
    }, [paginateData]);

    useEffect(() => {
        const getBoards = async () => {
            try {
                let curr_params = getBoardsParams();
                const fetchedBoards = await fetchBoards({params: curr_params});
                setBoardsData(fetchedBoards.boards);
                setFilterData(fetchedBoards.filter_data);
                setFetched(true);
            } catch (error) {
                console.error(error);
            }
        };
        if (!fetched) {
            getBoards();
        }
    }, [fetched]);

    const handlePrevNextBtn = (paginate_params: string) => async () => {
        try {
            const url_params = new URLSearchParams(paginate_params);   
            setPaginateData({
                page: url_params.get('page') || '',
                page_size: url_params.get('page_size') || ''
            });
        } catch (error) {
            console.error(error);
        }
    }

    const handleFilledSearch = (filter: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchData(prev => ({ ...prev, [filter]: event.target.value }));
    }

    const getBoardsParams = () => {
        return(
            Object.entries(searchData).reduce((acc, [filter, value]) => acc + convertSearchToParam(value, searchEntries[filter].param_name), '') +
            Object.entries(selectedFilters).reduce((acc, [filter, value]) => acc + convertFiltersToParam(value, filterEntries[filter].param_name), '') +
            Object.entries(paginateData).reduce((acc, [filter, value]) => acc + convertSearchToParam(value, searchEntries[filter].param_name), '')
        )
    }

    return(
        <> 
            <div className={'head-with-add-btn'}>
                <FiltersHead
                    searchData={searchData}
                    filterData={filterData}
                    selectedFilters={selectedFilters}
                    onSelectedChange={handleSelectedChange}
                    onSearchFilled={handleFilledSearch}
                    onApply={handleFilterApply}
                />
            </div>
            <div className={'main-container task-list'}>
                {fetched ?
                    <>
                        <BoardsTable boards={boardsData.objects} />
                        <Pagination data={boardsData} onPrevNext={handlePrevNextBtn} />
                    </>
                    :
                    <CircularProgress/>
                }
            </div>

        </>
    )
}

export default BoardsList
