import {ITimeEntries} from "../../common/types/tasks";
import React, {useState} from "react";
import {createTimeEntries, editTimeEntries} from "../../utils/axios";
import { useUserId } from "../../utils/hook";
import './TimeEntries.css'

type entryType = 'show' | 'edit';

const TimeEntries = ({time_entries, taskId, setFetched, canEdit} :
                         {time_entries: ITimeEntries[], taskId: number, setFetched: (fetched: boolean) => void, canEdit: boolean}) => {
    
    const current_user_id = useUserId();
    const [timeEntry, setTimeEntry] = useState('');
    const [textEntry, setTextEntry] = useState('');

    const [timeEntryIdEdit, setTimeEntryIdEdit] = useState<number>();

    const [timeEntryEdit, setTimeEntryEdit] = useState('');
    const [textEntryEdit, setTextEntryEdit] = useState('');

    const [timeEntriesType, setTimeEntriesType] = useState<entryType>('show');

    const handleAddTimeEntries = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (timeEntry === '') return
        await createTimeEntries(taskId, {
            time: timeEntry,
            text: textEntry
        });
        setFetched(false);
        setTimeEntry('');
        setTextEntry('');
    }

    const handleInputChange = (setter: React.Dispatch<React.SetStateAction<string>>) => {
        return (e: React.ChangeEvent<HTMLInputElement>) => {
            const re = /^[0-9]*[.,]?[0-9]*$/;
            if (e.target.value === '' || re.test(e.target.value)) {
                setter(e.target.value);
            }
        };
    };

    const handleTimeEntryChange = handleInputChange(setTimeEntry);
    const handleTimeEntryEditChange = handleInputChange(setTimeEntryEdit);

    const handleEditTimeEntries = async (timeEntryId: number) => {
        const editedTimeEntries = await editTimeEntries(taskId, timeEntryId, {text: textEntryEdit, time: timeEntryEdit});
        if (editedTimeEntries) {
            setTimeEntriesType('show');
            setFetched(false);
            setTextEntryEdit('');
            setTimeEntryEdit('');
        }
    }

    return(
        <div className={'comments-container'}>
            <div className={'time-entries-list'}>
                {time_entries && time_entries.map((entry) => (
                    <div key={entry.id} className={'time-entries-item'}>
                        <div className={'time-entries-number'}>#{entry.sequence_number}</div>
                        <div className={'time-entries-text'}>
                            {timeEntriesType === 'edit' && timeEntryIdEdit === entry.id ?
                                (
                                    <>
                                        <div className={'time-entries-author_created_at'}>
                                            <p className={'time-entries-author'}>{entry.author_name}</p>
                                            <p className={'time-entries-created_at'}>{entry.created_at}</p>
                                        </div>

                                        <div className={'comment-text-edit'}>
                                            <div className={'time-entries-inputs'}>
                                                <label className={'form-entries-label'}>Затраченное время</label>
                                                <input className={'form-entries time'} name={'time'} placeholder={'часы'}
                                                       value={timeEntryEdit} onChange={handleTimeEntryEditChange}/>
                                                <span style={{'marginLeft': '0.4rem'}}>ч</span>
                                            </div>

                                            <div className={'time-entries-inputs'}>
                                                <label className={'form-entries-label'}>Комментарий</label>
                                                <input className={'form-entries text'} name={'text'}
                                                       placeholder={'комментарий'}
                                                       value={textEntryEdit}
                                                       onChange={(e) => (setTextEntryEdit(e.target.value))}/>
                                            </div>

                                            <div className={'flex-with-spase comment-btns'}>
                                                <button className={'add-task-btn comment-save'}
                                                        onClick={() => setTimeEntriesType('show')}>
                                                    Отменить
                                                </button>
                                                <button className={'add-task-btn comment-save'}
                                                        onClick={() => handleEditTimeEntries(entry.id)}>
                                                    Сохранить
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className={'time-entries-author_created_at'}>
                                            <p className={'time-entries-author'}>{entry.author_name}</p>
                                            <p className={'time-entries-created_at'}>{entry.created_at}</p>
                                        </div>
                                        <div className={'comment-text-edit show'}>
                                            <div>
                                                <p>
                                                    <strong>Затраченное время: </strong>
                                                    <span className={'time-entries-text-main'}>{entry.time} ч</span>
                                                </p>
                                                {entry.text.length > 0 && 
                                                    <p>
                                                        <strong>Комментарий: </strong>
                                                        <span>{entry.text}</span>
                                                    </p>
                                                }
                                                
                                            </div>

                                            {current_user_id === entry.author && canEdit &&
                                                <img className={'task-board-btn'} src={'/app/edit.svg'}
                                                     onClick={() => {
                                                         setTimeEntriesType('edit');
                                                         setTimeEntryIdEdit(entry.id);
                                                         setTimeEntryEdit(entry.time);
                                                         setTextEntryEdit(entry.text);
                                                     }}
                                                />
                                            }
                                        </div>
                                    </>
                                )
                            }
                        </div>
                    </div>
                ))}
            </div>

            {canEdit && 
                <form className={'time-entries-form'} onSubmit={handleAddTimeEntries}>
                    <div className={'time-entries-inputs'}>
                        <label className={'form-entries-label'}>Затраченное время</label>
                        <input className={'form-entries time'} name={'time'} placeholder={'часы'}
                            value={timeEntry} onChange={handleTimeEntryChange}/>
                        <span style={{'marginLeft': '0.4rem'}}>ч</span>
                    </div>

                    <div className={'time-entries-inputs'}>
                        <label className={'form-entries-label'}>Комментарий</label>
                        <input className={'form-entries text'} name={'text'} placeholder={'комментарий'}
                            value={textEntry} onChange={(e) => (setTextEntry(e.target.value))}/>
                    </div>

                    <button className={'add-task-btn'} type={'submit'}>
                        Сохранить
                    </button>
                </form>
            }
        </div>
    )
}

export default TimeEntries