import UserForm from "../../components/UserForm";
import Layout from "../../components/layout";
import UsersList from "../../components/UsersList";
import { useParams } from "react-router-dom";
import { useActionIsAllowed } from "../../utils/hook";

type SettingsUsersPageType = 'list' | 'new' | 'edit' | 'create' | 'invite';

const SettingsUsers = ({typePage} : {typePage: SettingsUsersPageType}) => {
    const {id} = useParams();

    const showAllowed = useActionIsAllowed('user_show');
    const createAllowed = useActionIsAllowed('user_create');
    const editAllowed = useActionIsAllowed('user_edit');

    return(
        <Layout title={'Настройки. Пользователи'} addButton={typePage === 'list' && createAllowed ? 'addUser' : ''}>
            {typePage === 'list' && showAllowed && <UsersList canEdit={editAllowed} /> }
            
            {typePage === 'new' && createAllowed && <UserForm typePage={'new'} />}
            {typePage === 'create' && createAllowed && <UserForm typePage={'create'} />}
            {typePage === 'invite' && createAllowed && <UserForm typePage={'invite'} />}

            {typePage === 'edit' && editAllowed && id && <UserForm typePage={'edit'} id={parseInt(id)} />}
        </Layout>
    )
}

export default SettingsUsers