// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.paginate-number {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.3rem;
    height: 2.3rem;
    text-align: center;
}

.paginate-number.active {
    border-radius: 100%;
    border: 1px solid #273B64;
}

.paginate-total {
    display: flex;
    margin-left: auto;
    font-weight: normal;
}

.list-button.prev {
    border: 1px solid #273B64;
    margin-right: 0.7rem;
}

.list-button.next {
    border: 1px solid #273B64;
    margin-left: 0.7rem;
}

.list-button.unactive {
    cursor: auto;
}

.page-size {
    margin-right: 0.4rem;
    text-decoration: underline;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/Pagination/Pagination.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;IACb,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;IACnB,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,oBAAoB;AACxB;;AAEA;IACI,yBAAyB;IACzB,mBAAmB;AACvB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,oBAAoB;IACpB,0BAA0B;IAC1B,eAAe;AACnB","sourcesContent":[".paginate-number {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    width: 2.3rem;\n    height: 2.3rem;\n    text-align: center;\n}\n\n.paginate-number.active {\n    border-radius: 100%;\n    border: 1px solid #273B64;\n}\n\n.paginate-total {\n    display: flex;\n    margin-left: auto;\n    font-weight: normal;\n}\n\n.list-button.prev {\n    border: 1px solid #273B64;\n    margin-right: 0.7rem;\n}\n\n.list-button.next {\n    border: 1px solid #273B64;\n    margin-left: 0.7rem;\n}\n\n.list-button.unactive {\n    cursor: auto;\n}\n\n.page-size {\n    margin-right: 0.4rem;\n    text-decoration: underline;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
