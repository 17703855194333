// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.to-conference {
    font-size: 16px;
    font-weight: normal;
    border-radius: 10px;
    border: none;
    background-color: #273B64;
    color: #ffffff;
    padding: 0.5rem 1rem;
    cursor: pointer;
    text-decoration: none;
}

.th-meet-title, .th-meet-start_at, .th-meet-end_at, .th-meet-project, .th-meet-readiness, .th-meet-author {
    font-weight: bold;
    width: 15%;
}

.td-meet-title, .td-meet-start_at, .td-meet-end_at, .td-meet-readiness, .td-meet-project, .td-meet-author {
    width: 15%;
}

.td-meet-title a {
    text-decoration: none;
    color: #273B64;
}

.th-meet-title, .th-meet-start_at, .th-meet-end_at, .th-meet-readiness, .th-meet-project, .th-meet-author,
.td-meet-title, .td-meet-start_at, .td-meet-end_at, .td-meet-readiness, .td-meet-project, .td-meet-author {
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 10px;
  border: 1px solid #EDEDED;
}

.th-id, .th-project, .th-tracker, .th-readiness, .th-title, .th-priority,
.td-meet-id, .td-project, .td-tracker, .td-readiness, .td-title, .td-priority {
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 10px;
  border: 1px solid #EDEDED;
}

.td-meet-id {
  width: 5%;
}

.td-meet-title a {
  text-decoration: none;
  color: #273B64;
}
`, "",{"version":3,"sources":["webpack://./src/components/MeetsTable/MeetsTable.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,mBAAmB;IACnB,mBAAmB;IACnB,YAAY;IACZ,yBAAyB;IACzB,cAAc;IACd,oBAAoB;IACpB,eAAe;IACf,qBAAqB;AACzB;;AAEA;IACI,iBAAiB;IACjB,UAAU;AACd;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,qBAAqB;IACrB,cAAc;AAClB;;AAEA;;EAEE,gBAAgB;EAChB,uBAAuB;EACvB,aAAa;EACb,yBAAyB;AAC3B;;AAEA;;EAEE,gBAAgB;EAChB,uBAAuB;EACvB,aAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,qBAAqB;EACrB,cAAc;AAChB","sourcesContent":[".to-conference {\n    font-size: 16px;\n    font-weight: normal;\n    border-radius: 10px;\n    border: none;\n    background-color: #273B64;\n    color: #ffffff;\n    padding: 0.5rem 1rem;\n    cursor: pointer;\n    text-decoration: none;\n}\n\n.th-meet-title, .th-meet-start_at, .th-meet-end_at, .th-meet-project, .th-meet-readiness, .th-meet-author {\n    font-weight: bold;\n    width: 15%;\n}\n\n.td-meet-title, .td-meet-start_at, .td-meet-end_at, .td-meet-readiness, .td-meet-project, .td-meet-author {\n    width: 15%;\n}\n\n.td-meet-title a {\n    text-decoration: none;\n    color: #273B64;\n}\n\n.th-meet-title, .th-meet-start_at, .th-meet-end_at, .th-meet-readiness, .th-meet-project, .th-meet-author,\n.td-meet-title, .td-meet-start_at, .td-meet-end_at, .td-meet-readiness, .td-meet-project, .td-meet-author {\n  overflow: hidden;\n  text-overflow: ellipsis;\n  padding: 10px;\n  border: 1px solid #EDEDED;\n}\n\n.th-id, .th-project, .th-tracker, .th-readiness, .th-title, .th-priority,\n.td-meet-id, .td-project, .td-tracker, .td-readiness, .td-title, .td-priority {\n  overflow: hidden;\n  text-overflow: ellipsis;\n  padding: 10px;\n  border: 1px solid #EDEDED;\n}\n\n.td-meet-id {\n  width: 5%;\n}\n\n.td-meet-title a {\n  text-decoration: none;\n  color: #273B64;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
