import React, { useEffect, useState } from 'react';
import { fetcheWikiPagesList } from '../../utils/axios';
import { IPaginatedResponse, IProject, IWikiPage } from '../../common/types/tasks';
import { Link } from 'react-router-dom';
import {
    convertFiltersToParam,
    convertSearchToParam,
    filterEntries,
    searchEntries,
    getInitialFilterParams,
    getInitialSearchParams,
    setParams,
    isPresent
} from "../../utils/utils";
import FiltersHead from "../FiltersHead";
import Pagination from '../Pagination';
import {CircularProgress} from "@mui/material";

const WikiPageList = ({canCreate} : {canCreate: boolean}) => {
    const [fetched, setFetched] = useState<boolean>(false);
    const [pagesData, setPagesData] = useState<IPaginatedResponse>({ objects: [], previous: '', next: '', current_page: 0, count_objects: 0, count_pages: 0 });
    
    const [filterData, setFilterData] = useState<{ projects: IProject[] }>({ projects: [] });
    const [searchData, setSearchData] = useState<{ title: string }>({ title: getInitialSearchParams('title') });
    const [paginateData, setPaginateData] = useState<{ page: string; page_size: string; }>({
        page: getInitialSearchParams('page'),
        page_size: getInitialSearchParams('page_size')
    });
    const [selectedFilters, setSelectedFilters] = useState<{ projects: number[] }>({ projects: getInitialFilterParams('project')});

    const handleSelectedChange = (filter: string) => (newSelectedOptions: number[]) => {
        setSelectedFilters(prev => ({ ...prev, [filter]: newSelectedOptions }));
    };

    const handleFilledSearch = (filter: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchData(prev => ({ ...prev, [filter]: event.target.value }));
    }

    const getTasksParams = () => {
        const param_from_filters = Object.entries(selectedFilters).reduce((acc, [filter, values]) => acc + convertFiltersToParam(values, filterEntries[filter].param_name), '');
        const param_from_search = Object.entries(searchData).reduce((acc, [filter, value]) => acc + convertSearchToParam(value, searchEntries[filter].param_name), '')
        return param_from_filters + param_from_search;
    }

    useEffect(() => {
        const getWikiPages = async () => {
            if (fetched) return;
            try {
                const fetchedWikiPages = await fetcheWikiPagesList(getTasksParams());
                if (fetchedWikiPages) {
                    setPagesData(fetchedWikiPages.wiki_pages);
                    setFilterData(fetchedWikiPages.filter_data);
                }
                
            } catch (error) {
                console.error(error);
            }
            setFetched(true);
        }
        getWikiPages();
    }, [fetched]);

    const handleFilterApply = async () => {
        try {
            const params = getWikisParams();
            setParams(params);
            setFetched(false);
        } catch (error) {
            console.error(error);
        }
    };

    const handlePrevNextBtn = (paginate_params: string) => async () => {
        try {
            const url_params = new URLSearchParams(paginate_params);   
            setPaginateData({
                page: url_params.get('page') || '',
                page_size: url_params.get('page_size') || ''
            });
        } catch (error) {
            console.error(error);
        }
    }

    const getWikisParams = () => {
        return(
            Object.entries(searchData).reduce((acc, [filter, value]) => acc + convertSearchToParam(value, searchEntries[filter].param_name), '') +
            Object.entries(selectedFilters).reduce((acc, [filter, values]) => acc + convertFiltersToParam(values, filterEntries[filter].param_name), '') +
            Object.entries(paginateData).reduce((acc, [filter, value]) => acc + convertSearchToParam(value, searchEntries[filter].param_name), '')
        )
    }

    useEffect(() => {
        if (isPresent(paginateData.page) || isPresent(paginateData.page_size))
        handleFilterApply();
    }, [paginateData]);

    return (
        <>
            <div className={'head-with-add-btn'}>
                <FiltersHead
                    searchData={searchData}
                    filterData={filterData}
                    selectedFilters={selectedFilters}
                    onSelectedChange={handleSelectedChange}
                    onSearchFilled={handleFilledSearch}
                    onApply={handleFilterApply}
                />
                <div className={'right-links'}>
                    {canCreate && <Link to={`/wiki/new/`}>Новая страница</Link>}
                    <Link to={`/wiki/`}>Главная страница</Link>
                </div>
            </div>

            <div className={'main-container'}>
                {fetched ?
                    <div className={'wiki-table'}>
                        <div className={'list-table'}>
                            <table className={'table-main'}>
                                <thead className={'table-head'}>
                                    <tr>
                                        <th className={'th-id'}>#</th>
                                        <th className={'th-title'}>Название</th>
                                        <th className={'th-project'}>Проект</th>
                                        <th className={'th-sprint-author'}>Автор</th>
                                        <th className={'th-project'}>Создана</th>
                                    </tr>
                                </thead>
                                <tbody className={'table-body'}>
                                    {pagesData.objects.map((page: IWikiPage) => (
                                        <tr key={page.id} className={'task-row'}>
                                            <td className={'td-id'}>{page.id}</td>
                                            <td className={'td-title'}>
                                                <Link to={'/wiki/pages/' + page.slug}>{page.title}</Link>
                                            </td>
                                            <td className={'td-project'}>
                                                <Link to={'/projects/' + page.project_id}>{page.project}</Link>
                                            </td>
                                            <td className={'td-sprint-author'}>{page.author}</td>
                                            <td className={'td-project'}>{page.created_at_formatted}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div> 
                        <Pagination data={pagesData} onPrevNext={handlePrevNextBtn} />
                    </div>
                    
                    :
                    <CircularProgress/>
                }
            </div>
        </>
    );
};

export default WikiPageList;
