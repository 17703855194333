import './SearchHead.css'

const SearchHead = ({ searchData, onSearchFilled, onApply }: any) => {

    return(
        // <div className={'search-head'}>
            <div className={'search-field-with-btn'}>
                <input className={'search-filter'} placeholder={'Поиск'} value={searchData} onChange={onSearchFilled} />
                <button type={'button'} className={'list-find-btn-with-icon'} onClick={onApply}>
                    <img src={'/search_icon.png'} />
                </button>
            </div>
        // </div>
    )
}

export default SearchHead