import TasksTable from "../../components/TasksTable";
import React, {useEffect, useState} from "react";
import {fetchTaskNew, fetchTasks} from "../../utils/axios";
import {
    IDeveloper,
    IPaginatedResponse,
    IPriority,
    IProject,
    ISprint,
    IStatus,
    ITracker
} from "../../common/types/tasks";
import {CircularProgress} from "@mui/material";
import {
    convertFiltersToParam,
    convertSearchToParam,
    filterEntries,
    searchEntries,
    getInitialFilterParams,
    getInitialSearchParams,
    setParams,
    isPresent
} from "../../utils/utils";
import Pagination from "../../components/Pagination";
import FiltersHead from "../../components/FiltersHead";
import AddTasksButtons from "../AddTasksButtons";
import './TasksListWithFilters.css'
import { useActionIsAllowed } from "../../utils/hook";

const TasksListWithFilters = () => {

    const createTaskAllowed = useActionIsAllowed('task_create');

    const [fetched, setFetched] = useState<boolean>(false);
    const [tasksData, setTasksData] = useState<IPaginatedResponse>({ objects: [], previous: '', next: '', current_page: 0, count_objects: 0, count_pages: 0 });
    
    const [filterData, setFilterData] = useState<{
        projects: IProject[],
        trackers: ITracker[],
        statuses: IStatus[],
        priorities: IPriority[],
        developers: IDeveloper[],
        sprints: ISprint[] }>
    ({ projects: [], trackers: [], statuses: [], priorities: [], developers: [], sprints: [] });

    const [searchData, setSearchData] = useState<{ title: string; start_at: string; end_at: string }>({
        title: getInitialSearchParams('title'),
        start_at: getInitialSearchParams('start_at'),
        end_at: getInitialSearchParams('end_at')
    });

    const [paginateData, setPaginateData] = useState<{ page: string; page_size: string; }>({
        page: getInitialSearchParams('page'),
        page_size: getInitialSearchParams('page_size')
    });

    const [selectedFilters, setSelectedFilters] = useState<{ projects: number[], trackers: number[], statuses: number[], priorities: number[], developers: number[], sprints: number[], boards: number[] }>({
        projects: getInitialFilterParams('project'),
        trackers: getInitialFilterParams('tracker'),
        statuses: getInitialFilterParams('status'),
        priorities: getInitialFilterParams('priority'),
        developers: getInitialFilterParams('developer'),
        sprints: getInitialFilterParams('sprint'),
        boards: getInitialFilterParams('board')
    });

    const handleSelectedChange = (filter: string) => (newSelectedOptions: number[]) => {
        setSelectedFilters(prev => ({ ...prev, [filter]: newSelectedOptions }));
    };

    const handleFilledSearch = (filter: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchData(prev => ({ ...prev, [filter]: event.target.value }));
    }

    const handleFilterApply = async () => {
        try {
            const params = getTasksParams();
            setParams(params);
            setFetched(false);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (isPresent(paginateData.page) || isPresent(paginateData.page_size))
        handleFilterApply();
    }, [paginateData]);

    const handlePrevNextBtn = (paginate_params: string) => async () => {
        try {
            const url_params = new URLSearchParams(paginate_params);   
            setPaginateData({
                page: url_params.get('page') || '',
                page_size: url_params.get('page_size') || ''
            });
        } catch (error) {
            console.error(error);
        }
    }

    const getTasksParams = () => {
        const param_from_filters = Object.entries(selectedFilters).reduce((acc, [filter, values]) => acc + convertFiltersToParam(values, filterEntries[filter].param_name), '');
        const param_from_search = Object.entries(searchData).reduce((acc, [filter, value]) => acc + convertSearchToParam(value, searchEntries[filter].param_name), '');
        const paginate_params = Object.entries(paginateData).reduce((acc, [filter, value]) => acc + convertSearchToParam(value, searchEntries[filter].param_name), '');
        return param_from_filters + param_from_search + paginate_params;
    }

    useEffect(() => {
        const getTaskNew = async () => {
            try {
                const fetchedTaskNew = await fetchTaskNew();
                const { complexities, ...dataToSet } = fetchedTaskNew;
                setFilterData(dataToSet);
            } catch (error) {
                console.error(error);
            }
        };
        getTaskNew();
    }, []);

    useEffect(() => {
        const getTasks = async () => {
            try {
                const fetchedTasks = await fetchTasks({ params: getTasksParams() });
                setTasksData(fetchedTasks.tasks);
            } catch (error) {
                console.error(error);
            }
            setFetched(true);
        };
        getTasks();
    }, [fetched]);

    return(
        <div>
            <div className={'head-with-add-btn'}>
                <FiltersHead
                    searchData={searchData}
                    filterData={filterData}
                    selectedFilters={selectedFilters}
                    onSelectedChange={handleSelectedChange}
                    onSearchFilled={handleFilledSearch}
                    onApply={handleFilterApply}
                />
                {createTaskAllowed &&
                    <AddTasksButtons onSuccessCreatedTask={setFetched} />}
            </div>

            <div className={'main-container task-list'}>
                {fetched ?
                    <>
                        <TasksTable tasks={tasksData.objects} />
                        <Pagination data={tasksData} onPrevNext={handlePrevNextBtn} />
                    </>
                    :
                    <CircularProgress/>
                }
            </div>
        </div>
    )
}

export default TasksListWithFilters
