// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-window {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    border: #EDEDED solid 1px;
    background-color: #fff;
    padding: 20px;
    border-radius: 20px;
    max-width: 500px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modal-content h2 {
    white-space: pre-line;
    margin-bottom: 20px;
}

.confirm-button-save, .cancel-button-save {
    margin-top: 10px;
    margin-left: 10px;
    padding: 10px;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    width: 6rem;
}

.inputs-edit {
    width: 85%;
}

.modal-btns {
    display: flex;
    width: 87%;
    justify-content: right;
}

.confirm-button-save {
    background-color: #273b64;
    border: #273b64 solid 1px;
    color: white;
}

.cancel-button-save {
    background-color: #fff;
    border: #273b64 solid 1px;
    color: #273b64;
}

.confirm-button-save:hover, .cancel-button-save:hover {
    opacity: 0.8;
}`, "",{"version":3,"sources":["webpack://./src/components/ModalWindowEdit/ModalWindowEdit.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,oCAAoC;IACpC,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,sBAAsB;IACtB,aAAa;IACb,mBAAmB;IACnB,gBAAgB;IAChB,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,qBAAqB;IACrB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,aAAa;IACb,YAAY;IACZ,mBAAmB;IACnB,eAAe;IACf,WAAW;AACf;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,aAAa;IACb,UAAU;IACV,sBAAsB;AAC1B;;AAEA;IACI,yBAAyB;IACzB,yBAAyB;IACzB,YAAY;AAChB;;AAEA;IACI,sBAAsB;IACtB,yBAAyB;IACzB,cAAc;AAClB;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".modal-window {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: rgba(0, 0, 0, 0.7);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.modal-content {\n    border: #EDEDED solid 1px;\n    background-color: #fff;\n    padding: 20px;\n    border-radius: 20px;\n    max-width: 500px;\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.modal-content h2 {\n    white-space: pre-line;\n    margin-bottom: 20px;\n}\n\n.confirm-button-save, .cancel-button-save {\n    margin-top: 10px;\n    margin-left: 10px;\n    padding: 10px;\n    border: none;\n    border-radius: 15px;\n    cursor: pointer;\n    width: 6rem;\n}\n\n.inputs-edit {\n    width: 85%;\n}\n\n.modal-btns {\n    display: flex;\n    width: 87%;\n    justify-content: right;\n}\n\n.confirm-button-save {\n    background-color: #273b64;\n    border: #273b64 solid 1px;\n    color: white;\n}\n\n.cancel-button-save {\n    background-color: #fff;\n    border: #273b64 solid 1px;\n    color: #273b64;\n}\n\n.confirm-button-save:hover, .cancel-button-save:hover {\n    opacity: 0.8;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
