import { useState } from 'react';
import {useNavigate} from "react-router-dom";
import {useAppDispatch} from "../../../utils/hook";
import {signIn} from "../../../utils/axios";
import {login} from "../../../store/slice/auth";
import { setPermissionsData } from '../../../store/slice/permissions';

const LoginForm = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch();
    
    const [workspace, setWorkspace] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errors, setErrors] = useState<string[]>([]);

    const handleSubmitLogin = async () => {
        setErrors([]);
        try {
            // Trim whitespace from all input fields
            const trimmedWorkspace = workspace.trim();
            const trimmedEmail = email.trim();
            const trimmedPassword = password.trim();
    
            if (trimmedWorkspace === "" || trimmedEmail === "" || trimmedPassword === "") {
                setErrors(['Все поля должны быть заполнены']);
                return;
            }
    
            const userData = { workspace: trimmedWorkspace, email: trimmedEmail, password: trimmedPassword };
            const res = await signIn(userData);
    
            if (res.user && res.permissions) {
                dispatch(login(res.user));
                dispatch(setPermissionsData({
                    role: res.permissions.role,
                    permissions: res.permissions.permissions,
                    version: res.permissions.version,
                }));
                navigate('/home');
            } else if (res.errors) {
                setErrors(res.errors);
            }

        } catch (e) { 
            setErrors(["Произошла ошибка"]);
        }
    };

    return (
        <form className="form">
            {
                errors.length > 0 &&
                    <div className="form-task-error">
                        {errors.map((error: string)=>(
                            <p>{error}</p>
                        ))}
                    </div>
            }

            <div className='form-group'>
                <label className='form-label'>Название рабочего пространства</label>
                <input
                    name="workspace-title"
                    type="text"
                    className="form-input"
                    value={workspace}
                    onChange={(e) => setWorkspace(e.target.value)}
                />
            </div>
        
            <div className='form-group'>
                <label className='form-label'>Электронная почта</label>
                <input
                    name="workspace-email"
                    type="text"
                    className="form-input"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </div>
        
            <div className='form-group'>
                <label className='form-label'>Пароль</label>
                <input
                    name="workspace-passowrd"
                    type="password"
                    className="form-input"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
            </div>

            <div className='form-group'>
                <button type="button" onClick={handleSubmitLogin} className="form-submit">Войти</button>
            </div>
        </form>
    )
}

export default LoginForm;