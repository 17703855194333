import React from "react";
import {ISprint} from '../../common/types/tasks';
import {Link} from "react-router-dom";
import './SprintsTable.css'

interface SprintsTableProps {
    sprints: ISprint[];
}
const SprintsTable: React.FC<SprintsTableProps> = ({ sprints }) => {
    return (
        <div className={'list-table'}>
            <table className={'table-main'}>
                <thead className={'table-head'}>
                <tr>
                    <th className={'th-sprint-title'}>Название</th>
                    <th className={'th-sprint-start_at'}>Начало</th>
                    <th className={'th-sprint-end_at'}>Завершение</th>
                    <th className={'th-sprint-readiness'}>Прогресс</th>
                    <th className={'th-sprint-project'}>Проект</th>
                    <th className={'th-sprint-author'}>Автор</th>
                </tr>
                </thead>
                <tbody className={'table-body'}>
                {sprints.map((sprint) => (
                    <tr key={sprint.id} className={'task-row'}>
                        <td className={'td-sprint-title'}>
                            <Link to={`/sprints/${sprint.number}/` + (!sprint.closed ? 'board/' : '')}>
                                {sprint.title}
                            </Link>
                        </td>
                        <td className={'td-sprint-start_at'}>{sprint.start_at}</td>
                        <td className={'td-sprint-end_at'}>{sprint.end_at}</td>
                        <td className={'td-sprint-readiness'}>
                            <span className={'progress-block'}>
                                <div className={'progress-bar'}>
                                    <div className={'progress'} style={{'width': `${sprint.readiness}%`}}></div>
                                </div>
                                {sprint.readiness}%
                            </span>
                        </td>
                        <td className={'td-sprint-project'}>
                            <Link to={`/projects/${sprint.project_number}/`}>
                                {sprint.project}
                            </Link>
                        </td>
                        <td className={'td-sprint-author'}>{sprint.author}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default SprintsTable;