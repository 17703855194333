const PaginationDroppable = ({ prev, next, count_objects, current_page, count_pages, onPrevNext }: any) => {
    return(
        <div className={'sprints-task-list-bottom'}>
            {prev && <button className={'list-button prev'} onClick={onPrevNext(prev)} type={'button'}><span>&lt;</span></button>}

            {Array.from({ length: count_pages }, (_, i) => i + 1)
                .map((page_number) => (
                        <span key={page_number} className={'paginate-number ' + (page_number === current_page ? 'active' : '')}>
                    {page_number}
                </span>
                    )
                )}

            {next && <button className={'list-button next'} onClick={onPrevNext(next)} type={'button'}><span>&gt;</span></button>}
        </div>
    )
}


export default PaginationDroppable