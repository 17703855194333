import { useEffect, useState } from "react";
import { IMeet } from "../../common/types/tasks";
import { createTaskMeet } from "../../utils/axios";
import Calendar from "../Calendar";
import './TaskMeets.css'

const TaskMeets = ({task_meets, taskId, projectId, setFetched, canEdit} :
                        {task_meets: IMeet[], taskId: number, projectId: number, setFetched: (fetched: false) => void, canEdit: boolean}) => {
    
    const [title, setTitle] = useState<string>('');
    const [autoRecord, setAutoRecord] = useState<boolean>(false);
    const [startAt, setStartAt] = useState<Date | null>(null);
    const [endAt, setEndAt] = useState<Date | null>(null);
    const [startAtTime, setStartAtTime] = useState<string>('');
    const [endAtTime, setEndAtTime] = useState<string>('');

    const [errors, setErrors] = useState<string[]>([]);

    const [finishedMeets, setFinishedMeets] = useState<IMeet[]>();
    const [presentMeets, setPresentMeets] = useState<IMeet[]>();

    useEffect(() => {
        setFinishedMeets(task_meets.filter((meet) => meet.finished).sort((a, b) => a.sequence_number - b.sequence_number));
        setPresentMeets(task_meets.filter((meet) => !meet.finished).sort((a, b) => a.sequence_number - b.sequence_number));
    }, [task_meets]);

    const handleAddMeet = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const createdMeet = await createTaskMeet(taskId, buildMeetParams());
        if (!createdMeet.errors) {
            setFetched(false);
            setTitle('');
            setStartAt(null);
            setEndAt(null);
            setStartAtTime('');
            setEndAtTime('');
            setAutoRecord(false);
        } else {
            setErrors(createdMeet.errors);
        }
    }  
    
    const buildMeetParams = () => {
        const meetData: { 
            title: string, 
            start_at: string, 
            end_at: string, 
            auto_record: boolean, 
            access_type: string, 
            project_id: number | null 
        } = {
            title: title,
            start_at: '',
            end_at: '',
            auto_record: autoRecord,
            access_type: 'workspace',
            project_id: null,
        }
    
        if (startAt && startAtTime) {
            const [hours, minutes] = startAtTime.split(':');
            startAt.setHours(parseInt(hours));
            startAt.setMinutes(parseInt(minutes));
            meetData.start_at = startAt.toISOString();
        }
    
        if (endAt && endAtTime) {
            const [hours, minutes] = endAtTime.split(':');
            endAt.setHours(parseInt(hours));
            endAt.setMinutes(parseInt(minutes));
            meetData.end_at = endAt.toISOString();
        }
    
        if (projectId) {
            meetData.project_id = projectId;
            meetData.access_type = 'project';
        }
    
        return meetData;
    }    

    return(
        <div className={'task-meets-container'}>
            {finishedMeets && finishedMeets.length > 0 && 
                <>
                    <h4>Завершенные встречи</h4>
                    <div className={'task-meets-list'}>
                        {finishedMeets.map((meet)=>(
                            <div className={'task-meet-item'}>
                                <div className={'task-meet-number'}>#{meet.sequence_number}</div>
                                <div className={'task-meet-row'}>
                                    <span className={'task-meet-title'}>
                                        <a href={`https://${process.env.REACT_APP_API_URL}/app/meets/${meet.number}`} className={'task-meet-title-href'} target={'_blank'}>{meet.title}</a>
                                    </span>
                                    <span className={'task-meet-start_at'}>
                                        {meet.start_at_formatted}
                                    </span>
                                    <span className={'task-meet-end_at'}>
                                        {meet.end_at_formatted}
                                    </span>
                                </div>
                            </div>   
                        ))} 
                    </div>
                </>
            }

            {presentMeets && presentMeets.length > 0 && 
                <>
                    <h4>Предстоящие встречи</h4>
                    <div className={'task-meets-list'}>
                        {presentMeets.map((meet)=>(
                            <div className={'task-meet-item'}>
                                <div className={'task-meet-number'}>#{meet.sequence_number}</div>
                                <div className={'task-meet-row'}>
                                    <span className={'task-meet-title'}>
                                        <a href={`/app/meets/${meet.number}`} className={'task-meet-title-href'} target={'_blank'}>{meet.title}</a>
                                    </span>
                                    <span className={'task-meet-start_at'}>
                                        {meet.start_at_formatted}
                                    </span>
                                    <span className={'task-meet-end_at'}>
                                        {meet.end_at_formatted}
                                    </span>
                                    <span className={'task-meet-link'}>
                                        <a href={`https://${process.env.REACT_APP_API_URL}/meets/conference/${meet.uuid}`} className={'task-meet-link-conf-href'} target={'_blank'}>Открыть встречу</a>
                                    </span>
                                </div>
                            </div>   
                        ))} 
                    </div>
                </>
            }

            {canEdit &&
                <form className={'meets-form'} onSubmit={handleAddMeet}>
                    <h3 style={{marginTop: '2rem'}}>
                        Добавить новую встречу
                    </h3>

                    {errors &&
                        <div className="form-task-error">
                            {errors.map((error) => (
                                <p>{error}</p>
                            ))}
                        </div>
                    }

                    <div className={'form-task-meet-group'}>
                        <label className={'form-task-label'} htmlFor={'title'}>Название</label>
                        <input className={'form-task-input meet'} name={'title'} placeholder={'Название'}
                            value={title} onChange={(e) => setTitle(e.target.value)}/>
                    </div>
                    <div className={'form-task-meet-group'}>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start'}}>
                            <div>
                                <label className={'form-task-label'} htmlFor={'start_at'}>Дата начала</label>
                                <Calendar dateAt={startAt} setDateAt={setStartAt} minDate={new Date()} maxDate={endAt} />
                            </div>                     
                            <span>&emsp;</span>
                            <div>
                                <label className={'form-task-label'} htmlFor={'start_at'}>Время</label>
                                <input type="time"
                                    value={startAtTime}
                                    onChange={(e) => setStartAtTime(e.target.value)}
                                    className={'form-task-input'}
                                    style={{width: 'calc(100% - 0.4rem)'}}
                                    placeholder="время" name="time-input"
                                    required
                                />
                            </div>
                        </div>
                    </div>

                    <div className={'form-task-meet-group'}>                       
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start'}}>
                            <div>
                            <label className={'form-task-label'} htmlFor={'end_at'}>Дата завершения</label>
                                <Calendar dateAt={endAt} setDateAt={setEndAt} minDate={startAt} maxDate={null} />
                            </div>                     
                            <span>&emsp;</span>
                            <div>
                                <label className={'form-task-label'} htmlFor={'end_at'}>Время</label>
                                <input type="time"
                                    value={endAtTime}
                                    onChange={(e) => setEndAtTime(e.target.value)}
                                    className={'form-task-input'}
                                    style={{width: 'calc(100% - 0.4rem)'}}
                                    placeholder="время" name="time-input"
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className={'form-task-meet-group'} style={{'display': 'flex'}}>
                        <input
                            name={'auto_record'}
                            type="checkbox"
                            checked={autoRecord}
                            onChange={() => setAutoRecord(!autoRecord)}
                        />
                        <label htmlFor={'auto_record'}>Вести запись</label>
                    </div>

                    <br></br>
                    <button className={'save-task-btn'} type={'submit'}>Сохранить</button>
                </form>
            }      
        </div>
    )
}

export default TaskMeets
