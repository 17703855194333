import React, {useEffect, useState} from "react";
import {createSprint} from "../../utils/axios";
import {useNavigate} from "react-router-dom";
import Calendar from "../Calendar";
import TasksTables from "../TasksTables";
import {ITask} from "../../common/types/tasks";

const SprintNew = () => {
    const navigate = useNavigate();

    const durationTypes = {
        '1 неделя': 604800000,
        '2 недели': 1209600000,
        '3 недели': 1814400000,
        '4 недели': 2419200000,
        'Другое': 0
    };

    const [tasksToSprint, setTasksToSprint] = useState<ITask[]>([]);
    const [project, setProject] = useState<number | null>(null);

    const [title, setTitle] = useState<string>('');
    const [desc, setDesc] = useState<string>('');
    const [startAt, setStartAt] = useState<Date | null>(new Date(Date.now()));
    const [duration, setDuration] = useState<number>(durationTypes['2 недели'])
    const [endAt, setEndAt] = useState<Date | null>(new Date(Date.now() + 1209600000));

    const [errors, setErrors] = useState<string[]>([]);

    useEffect(() => {
        if (duration !== 0) {
            setEndAt(new Date((startAt ? startAt.getTime() : Date.now()) + duration));
        }
    }, [duration, startAt]);

    const handleCreateSprint = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        (async () => {
            setErrors([]);

            try {
                if (!startAt || !endAt) {
                    setErrors(["Даты начала и завершения не могут быть пустыми"])   
                    return;
                }
                if (!title) {
                    setErrors(["Название не может быть пустыми"])   
                    return;
                }

                const createdSprint = await createSprint({
                    title: title,
                    desc: desc,
                    project_id: project,
                    start_at: startAt.toISOString().slice(0, 10),
                    end_at: endAt.toISOString().slice(0, 10),
                    tasks: tasksToSprint.map((task) => (task.id))
                })
                if (createdSprint.sprint) {
                    navigate(`/sprints/${createdSprint.sprint.number}/board`)
                } else if (createdSprint.errors) {
                    setErrors(createdSprint.errors)
                }
            } catch (e) {
                console.error(e);
            }
        })();
    }

    return (
        <div className={'main-container task-new'}>
            <div className={'form-head'}>
                <p className={'title-form'}>
                    Новый спринт
                </p>
                <button className={'back-button'} onClick={() => navigate(-1)}>
                    <span>&lt;</span>
                </button>
            </div>

            {errors.length > 0 && <ul className="form-task-error">{errors.map((error)=>( <li>{error}</li> ))}</ul>}

            <form className={'form-main'} onSubmit={handleCreateSprint}>
                <div className={'form-task-col-left'}>
                    <div className={'form-task-group'}>
                        <label className={'form-task-label'} htmlFor={'title'}>Название</label>
                        <input className={'form-task-input'} name={'title'} placeholder={'Название'}
                                value={title} onChange={(e) => setTitle(e.target.value)} />
                    </div>
                    <div className={'form-task-group'}>
                        <label className={'form-task-label'} htmlFor={'text'}>Цели спринта</label>
                        <textarea className={'form-sprint-textarea'} name={'text'} placeholder={'Цели спринта'}
                                    value={desc} onChange={(e) => (setDesc(e.target.value))}/>
                    </div>
                </div>

                <div className={'form-task-col-right'}>
                    <div className={'form-task-col-left'}>

                        <div className={'form-task-group'}>
                            <label className={'form-task-label'} htmlFor={'duration'}>Длительность</label>
                            <select className={'form-task-select'}
                                    style={{'width':'253px'}}
                                    name={'duration'}
                                    placeholder={'Длительность'}
                                    value={duration}
                                    onChange={(e) => setDuration(parseInt(e.target.value))}
                            >
                                {Object.entries(durationTypes).map(([key, value]) => (
                                    <option key={key} value={value}>{key}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className={'form-task-col-right'}>
                        <div className={'form-task-group'}>
                            <label className={'form-task-label'} htmlFor={'start_at'}>Дата начала</label>
                            <Calendar dateAt={startAt} setDateAt={setStartAt} minDate={null} maxDate={endAt} />
                        </div>

                        <div className={'form-task-group'}>
                            <label className={'form-task-label'} htmlFor={'end_at'}>Дата завершения</label>
                            <Calendar dateAt={endAt} setDateAt={setEndAt} minDate={startAt} maxDate={null}
                                        disabled={duration !== 0} />
                        </div>
                    </div>
                </div>

                <div className={'form-task-col-center'}>
                    <div className={'form-task-group'}>
                    </div>
                </div>

                <TasksTables tasksToSprint={tasksToSprint} setTasksToSprint={setTasksToSprint}
                                project={project} setProject={setProject} />

                <div className={'form-task-col-center'}>
                    <div className={'form-task-group'}>
                    </div>
                </div>

                <div className={'form-task-col-center'}>
                    <button className={'save-task-btn'} type={'submit'}>
                        Добавить спринт
                    </button>
                </div>
            </form>
        </div>
    )
}

export default SprintNew
