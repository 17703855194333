import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TasksTables from "../TasksTables";
import { ITask } from "../../common/types/tasks";
import { createBoard } from "../../utils/axios";

const BoardNew = () => {
    const navigate = useNavigate();

    const [tasksToBoard, setTasksToBoard] = useState<ITask[]>([]);
    const [project, setProject] = useState<number | null>(null);

    const [title, setTitle] = useState('');
    const [errors, setErrors] = useState<String[]>([]);

    const handleCreateBoard = (event: React.FormEvent<HTMLFormElement>) => {
        setErrors([]);
        event.preventDefault();

        if (title.trim() === "") {
            setErrors(["Название не может быть пустым"])
            return
        }

        (async () => {
            try {
                const createdBoard = await createBoard({
                    title: title,
                    project_id: project,
                    tasks: tasksToBoard.map((task) => (task.id))
                })
                if (createdBoard.board) {
                    navigate(`/boards/${createdBoard.board.number}/`);  
                } else if (createdBoard.errors) {
                    setErrors(createdBoard.errors);
                }
            } catch (e) {
                console.error(e);
            }
        })();
    }

    return(
        <div className={'main-container task-show'}>
            <div className={'form-head'}>
                <p className={'title-form'}>
                    Новая канбан-доска
                </p>
                <button className={'back-button'} onClick={() => navigate(-1)}>
                    <span>&lt;</span>
                </button>
            </div>

            <form className={'form-main'} onSubmit={handleCreateBoard}>
                <div className={'form-task-col-left'}>
                    <div className={'form-task-group'}>
                        <label className={'form-task-label'} htmlFor={'title'}>Название</label>
                        <input className={'form-task-input'} name={'title'} placeholder={'Название'}
                            value={title} onChange={(e) => setTitle(e.target.value)} />
                        {errors &&
                            <div className="form-task-error">
                                {errors.map((error)=>(
                                    <p>{error}</p>
                                ))}
                            </div>
                        }
                    </div>
                </div>
                <div className={'form-task-col-right'}></div>

                <div className={'form-task-col-center'}>
                    <div className={'form-task-group'}>
                        <TasksTables tasksToSprint={tasksToBoard} setTasksToSprint={setTasksToBoard}
                                     project={project} setProject={setProject} />
                    </div>
                </div>

                <div className={'form-task-col-center'}>
                    <br></br>
                    <button className={'save-task-btn'} type={'submit'}>
                        Добавить доску
                    </button>
                </div>
            </form>

        </div>
    )
}

export default BoardNew