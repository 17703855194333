import React, { useState, useEffect, useRef } from "react";
import { fetchTaskForEventInfo } from "../../utils/axios";
import { format } from "date-fns";
import { CircularProgress } from "@mui/material";
import "./EventModal.css";
import { ITaskEvent } from "../../common/types/tasks";

interface EventModalProps {
    modalPosition: { top: number; left: number } | null;
    modalType: "edit" | "new";
    currentView: string;
    eventData: { id?: number, title?: string; start: Date, end: Date, jsEvent?: any, task?: ITaskEvent } | null;
    isOpen: boolean;
    setModalPosition: React.Dispatch<React.SetStateAction<{ top: number; left: number } | null>>;
    onClose: () => void;
    onSave: (event: { title: string; start_at: Date; end_at: Date, task_id?: number }) => void;
    onUpdate: (event: { id: number, title: string; start_at: Date; end_at: Date, task_id?: number }) => void;
    onRemove: (id: number) => void;
}

const EventModal: React.FC<EventModalProps> = ({ currentView, modalPosition, modalType, eventData, isOpen, setModalPosition, onClose, onSave, onUpdate, onRemove }) => {
    const [id, setId] = useState<number>();
    const [title, setTitle] = useState("");
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    
    const [taskNumber, setTaskNumber] = useState("");
    const [taskInfo, setTaskInfo] = useState<{ id: number; number: string, title: string } | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const modalRef = useRef<HTMLDivElement>(null);
    
    useEffect(() => {
        if (!isOpen || !eventData) return;
    
        const modalWidth = 400;  // Предполагаемая ширина модального окна
        const modalHeight = 200; // Предполагаемая высота модального окна
    
        let top, left;
    
        if (currentView === "timeGridDay") {
            top = Math.max(0, (window.innerHeight - modalHeight) / 2) + window.scrollY;
            left = Math.max(0, (window.innerWidth - modalWidth) / 2) + window.scrollX;
        } else if (eventData && eventData.jsEvent) {
            // Если есть информация о событии клика
            const clickX = eventData.jsEvent.clientX;
            const clickY = eventData.jsEvent.clientY;
    
            // Определяем, в какой четверти экрана произошел клик
            const isRightHalf = clickX > window.innerWidth / 2;
            const isBottomHalf = clickY > window.innerHeight / 2;
    
            if (isRightHalf) {
                left = Math.max(0, Math.min(clickX - modalWidth, window.innerWidth - modalWidth)) + window.scrollX;
            } else {
                left = Math.max(0, Math.min(clickX, window.innerWidth - modalWidth)) + window.scrollX;
            }
    
            if (isBottomHalf) {
                top = Math.max(0, Math.min(clickY - modalHeight, window.innerHeight - modalHeight)) + window.scrollY;
            } else {
                top = Math.max(0, Math.min(clickY, window.innerHeight - modalHeight)) + window.scrollY;
            }
        } else {
            // Если нет информации о клике, размещаем окно в центре
            top = Math.max(0, (window.innerHeight - modalHeight) / 2) + window.scrollY;
            left = Math.max(0, (window.innerWidth - modalWidth) / 2) + window.scrollX;
        }
    
    setModalPosition({top, left});

        const formatTime = (date: Date) => 
            `${date.getHours().toString().padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}`;

        if (eventData.id) {
            setId(eventData.id);
        }

        if (eventData.title) {
            setTitle(eventData.title);
        }
        setStartTime(formatTime(eventData.start));
        setEndTime(formatTime(eventData.end));

        if (eventData.task) {
            setTaskNumber(eventData.task.number);
            setTaskInfo(eventData.task);                
        }
    }, [isOpen, eventData]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
                onClose();
            }
        };
        if (isOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };

    }, [isOpen, onClose]);
    
    useEffect(() => {
        const fetchTaskInfo = async () => {
            if (taskNumber.trim() === "") {
                setTaskInfo(null);
                return;
            }

            if (taskInfo !== undefined && taskInfo !== null && taskInfo.number === taskNumber) { return }

            setIsLoading(true);
            try {
                const taskInfo = await fetchTaskForEventInfo(taskNumber);
                if (taskInfo.task) {
                    setTaskInfo(taskInfo.task);
                } else {
                    setTaskInfo(null);
                }
            } catch (error) {
                console.error("Error fetching task info:", error);
                setTaskInfo(null);
            } finally {
                setIsLoading(false);
            }
        };

        const debounceTimer = setTimeout(fetchTaskInfo, 300); // Debounce for 300ms

        return () => clearTimeout(debounceTimer);
    }, [taskNumber, taskInfo]);

    const handleSave = () => {
        if (!eventData) return;

        try {
            const [startHours, startMinutes] = startTime.split(":").map(Number);
            const [endHours, endMinutes] = endTime.split(":").map(Number);

            const start_at = new Date(eventData.start);
            start_at.setHours(startHours, startMinutes);

            const end_at = new Date(eventData.end);
            end_at.setHours(endHours, endMinutes);

            const task_id = taskInfo?.id;

            if (modalType === "new") {
                onSave({ title, start_at, end_at, task_id });
            } else if (modalType === "edit" && id) {
                onUpdate({ id, title, start_at, end_at, task_id });
            }
             
            onClose();
        } catch (e) {
            console.log(e)
        }
    };

    const handleRemove = () => {
        try {
            if (modalType === "edit" && id) {
                onRemove(id);
            }     
            onClose();
        } catch (e) {
            console.log(e)
        }
    }; 
    
    const cancelTaskNumberEdit = () => {
        if (!eventData) return;

        if (eventData.task === undefined || eventData.task === null || eventData.task.number === taskNumber) { 
            return 
        }
        setTaskNumber(eventData.task.number);
    }

    return (
        <>
            { isOpen && modalPosition && eventData &&
                <div 
                    ref={modalRef}
                    className="event-modal"
                    style={{
                        top: `${modalPosition.top}px`,
                        left: `${modalPosition.left}px`,
                    }}
                >
                    <div className={"closeEventModalBlock"}>
                        <button onClick={onClose} className="closeEventModal">&times;</button>
                    </div>
                    
                    {modalType === "new" && <h2>Добавить событие на {format(eventData.start, "dd.MM.yyyy")}</h2>}
                    {modalType === "edit" && <h2>Изменить событие на {format(eventData.start, "dd.MM.yyyy")}</h2>}

                    <input
                        type="text"
                        placeholder="Название события"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        className="event-input"
                    />
                    <div className="time-inputs">
                        <label>
                            <input
                                type="time"
                                value={startTime}
                                onChange={(e) => setStartTime(e.target.value)}
                                className="time-input"
                                step="900" // 15 минут в секундах
                            />
                            &ensp;-&ensp;
                            <input
                                type="time"
                                value={endTime}
                                onChange={(e) => setEndTime(e.target.value)}
                                className="time-input"
                                step="900" // 15 минут в секундах
                            />
                        </label>
                    </div>

                    <br></br>

                    <div className="task-number-inputs">
                        <label className="task-number-label">Связано с задачей #</label>
                        &ensp;
                        <input 
                            type="number"
                            placeholder="Номер задачи"
                            value={taskNumber}
                            onChange={(e) => {
                                const value = e.target.value;
                                if (value === '' || (parseInt(value) > 0 && Number.isInteger(parseFloat(value)))) {
                                    setTaskNumber(value);
                                }
                            }}
                            min="1"
                            step="1"
                            className="task-number-input"
                        />
                        {eventData.task !== undefined && eventData.task !== null && eventData.task.number !== taskNumber && 
                            <span className="task-number-cancel" onClick={() => cancelTaskNumberEdit()}>↶</span>
                        }
                    </div>

                    <div className="task-info">
                        <div className="task-number-label">
                            &emsp;
                            {isLoading && <CircularProgress />}
                            {!isLoading && taskInfo && <span>#{taskInfo.number} - {taskInfo.title}</span> }
                            {!isLoading && taskNumber && !taskInfo && <span>Задача не найдена</span> }
                        </div>
                    </div>

                    <div className="button-group">
                        {modalType === "edit" && 
                            <>
                                <button className="delete-button" onClick={handleRemove}>Удалить</button>
                                &emsp;
                            </>}
                        <button className="save-button" onClick={handleSave}>Сохранить</button>
                    </div>
                </div>
            }
        </>
    );
};

export default EventModal;
