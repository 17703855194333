import React, { useEffect, useState, FC } from "react";
import { fetchHomePage } from "../../utils/axios";
import { CircularProgress } from "@mui/material";
import { IHomePageResponse, HomeWidget } from "../../common/types/tasks";
import HomeTasksList from "../../components/HomeTasksList";
import HomeCalendar from "../../components/HomeCalendar";

type ComponentMap = {
    [key: string]: FC<any>;
};

const componentMap: ComponentMap = {
    "HomeAssignedMe": HomeTasksList,
    "HomeAuthorMe": HomeTasksList,
    "Calendar": HomeCalendar, 
};

const HomePageShow: React.FC = () => {
    const [homePageData, setHomePageData] = useState<IHomePageResponse>();
    const [activeWidgetKey, setActiveWidgetKey] = useState<string>("");

    const renderComponent = (widget: HomeWidget, key: string) => {
        const Component = componentMap[widget.title];
        return <Component key={key} data={widget?.data} endpoint={widget.endpoint} />;
    };

    useEffect(() => {
        const getHomePage = async () => {
            try {
                const fetchedHomePage = await fetchHomePage();
                if (fetchedHomePage) {
                    setHomePageData(fetchedHomePage.page);
                    const firstKey = Object.keys(fetchedHomePage.page)[0];
                    setActiveWidgetKey(firstKey);
                }
            } catch (error) {
                console.error(error);
            }
        };

        getHomePage();
    }, []);

    const handleWidgetChange = (key: string) => {
        setActiveWidgetKey(key);
    };

    return (
        <>
            {homePageData ? (
                <div className={'main-container task-list'}>
                    <div className={'table-type-home'}>
                        {Object.entries(homePageData)
                            .sort(([aKey], [bKey]) => Number(aKey) - Number(bKey))
                            .map(([key, widget]) => (
                                <button
                                    key={key}
                                    className={`table-type-button ${activeWidgetKey === key ? "active" : ""}`}
                                    onClick={() => handleWidgetChange(key)}
                                >
                                    {widget.title_ru}
                                </button>
                            ))
                        }
                    </div>
                    {activeWidgetKey && renderComponent(homePageData[activeWidgetKey], activeWidgetKey)}
                </div>
            ) : (
                <CircularProgress />
            )}
        </>
    );
};

export default HomePageShow;
