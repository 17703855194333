import Layout from "../../components/layout";
import WikiPageList from "../../components/WikiPageList";
import WikiPageShow from "../../components/WikiPageShow";
import WikiPageForm from "../../components/WikiPageForm";
import { useParams } from "react-router-dom";
import './wiki.css'
import { useActionIsAllowed } from "../../utils/hook";

type WikiPageType = 'mainWS' | 'mainProject' | 'list' | 'show' |'edit' | 'new';

const Wiki = ({typePage} : {typePage: WikiPageType}) => {
    const {slug} = useParams();

    const showAllowed = useActionIsAllowed('wiki_show');
    const createAllowed = useActionIsAllowed('wiki_create');
    const editAllowed = useActionIsAllowed('wiki_edit');

    return(
        <Layout title="Wiki">
            {typePage === 'list' && showAllowed && <WikiPageList canCreate={createAllowed} />}
            {typePage === 'mainWS' && showAllowed && <WikiPageShow pageType={'mainWS'} canCreate={createAllowed} canEdit={editAllowed} />}
            {typePage === 'mainProject' && showAllowed && <WikiPageShow pageType={'mainProject'} canCreate={createAllowed} canEdit={editAllowed}  />}
            {typePage === 'show' && showAllowed && slug && <WikiPageShow slug={slug} pageType={'show'} canCreate={createAllowed} canEdit={editAllowed}  />}
            {typePage === 'edit' && editAllowed && slug && <WikiPageForm slug={slug} pageType={'edit'} />}
            {typePage === 'new' && createAllowed && <WikiPageForm pageType={'new'} />}
        </Layout>
    )
}

export default Wiki