import React, {useEffect, useState} from "react";
import {IProject} from "../../common/types/tasks";
import {createTaskColumnNew, createTaskNew, fetchTaskNew} from "../../utils/axios";
import {AxiosError} from "axios";
import {BoardColumn} from "../../common/types/boards";

interface TaskFormSmallProps {
    columnId?: number;
    columnStatus?: string;
    boardId?: number;
    onSuccessCreatedTask?: (fetched: boolean) => void;
    onSuccessCreatedColumnTask?: (column_index: string, task_id: number, column: BoardColumn) => void;
    projectId?: number;
}

const TaskFormSmall: React.FC<TaskFormSmallProps> = (
    {onSuccessCreatedTask, onSuccessCreatedColumnTask, boardId, columnId, columnStatus, projectId}
) => {

    const [projects, setProjects] = useState<IProject[]>([])

    const [project, setProject] = useState<number | null>(null);
    const [title, setTitle] = useState('');
    const [text, setText] = useState('');

    const [titleErrors, setTitleErrors] = useState<string[]>([]);
    const [textErrors, setTextErrors] = useState<string[]>([]);

    useEffect(() => {
        const getTasksProps = async () => {
            try {
                const fetchedTaskNew = await fetchTaskNew();
                setProjects(fetchedTaskNew.projects);
                // if (fetchedTaskNew.projects && fetchedTaskNew.projects.length > 0)
                //     setProject(fetchedTaskNew.projects[0].id);

            } catch (error) {
                console.error(error);
            }
        };
        if (!projectId) {
            getTasksProps();
        }
    }, [])

    const handleCreateTask = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        (async () => {
            try {
                let project_id = null;
                if (columnId) {
                    project_id = projectId || null;
                } else {
                    project_id = project
                }
                if (project_id === null) {
                    project_id = projects[0].id
                }

                const task_data = {
                    title: title,
                    text: text,
                    project_id: project_id,
                    priority_id: null,
                    developer_id: null,
                    tracker_id: null,
                    status_id: null,
                    time_expected: null,
                    developer: null,
                    readiness: 0,
                    start_at: null,
                    end_at: null,
                    complexity_id: null,
                    complexity_units: null,
                    time_expected_by_params: ''
                }

                if (boardId && columnStatus) {
                    const updatedColumn = await createTaskColumnNew(boardId, task_data)
                    if (onSuccessCreatedColumnTask) {
                        onSuccessCreatedColumnTask(columnStatus, updatedColumn.task_id, updatedColumn.source_column);
                    }

                } else {
                    await createTaskNew(task_data)
                    if (onSuccessCreatedTask) {
                        onSuccessCreatedTask(false);
                    }
                }
                setTitle('');
                setTitleErrors(['']);
                setText('');
                setTextErrors(['']);
            } catch (error) {
                if (error instanceof AxiosError) {
                    setTitleErrors(error.response?.data.title);
                    setTextErrors(error.response?.data.text);
                }
            }
        })();
    }

    return(
        <form onSubmit={handleCreateTask}>
            {!columnId && 
                <div className={'form-task-group-small'}>
                    <label className={'form-task-label'} htmlFor={'project'}>Проект</label>
                    <select className={'form-task-select-small'}
                            name={'project'}
                            placeholder={'Проект'}
                            value={project !== null ? project.toString() : ''}
                            onChange={(e) => setProject(parseInt(e.target.value) || null)}
                    >
                        {projects.map((project) => (
                            <option key={project.id} value={project.id}>{project.title}</option>
                        ))}
                    </select>
                </div>
            }

            <div className={'form-task-group-small'}>
                <label className={'form-task-label'} htmlFor={'title'}>Название</label>
                <input className={'form-task-input-small'} name={'title'} placeholder={'Название'}
                       value={title} onChange={(e) => setTitle(e.target.value)} />
                {titleErrors &&
                    <div className="form-task-error">
                        {titleErrors.map((error)=>(
                            <p>{error}</p>
                        ))}
                    </div>
                }
            </div>

            <div className={'form-task-group-small'}>
                <label className={'form-task-label'} htmlFor={'text'}>Описание</label>
                <textarea className={'form-task-textarea-small' + (columnId ? ' large' : '')} name={'text'} placeholder={'Описание'}
                          value={text} onChange={(e) => (setText(e.target.value))}/>
                {textErrors &&
                    <div className="form-task-error">
                        {textErrors.map((error)=>(
                            <p>{error}</p>
                        ))}
                    </div>
                }
            </div>

            <button className={'save-task-btn small-form'} type={'submit'}>
                Добавить задачу
            </button>
        </form>
    )
}

export default TaskFormSmall