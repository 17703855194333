import {ITasksCount} from "../../common/types/tasks";
import {useNavigate} from "react-router-dom";
import './SimpleTable.css'

const SimpleTable = ({task_counts} : {task_counts: ITasksCount}) => {
    const navigate = useNavigate();

    return(
        <div className="tasks-small-table">
            <div className="tasks-small-header">
                <div className="header-item">&emsp;</div>
                <div className="header-item">Открыто</div>
                <div className="header-item">Закрыто</div>
                <div className="header-item">Всего</div>
            </div>
            <div className="tasks-small-body">
                {Object.entries(task_counts).map(([trackerTitle, taskCounts]) => (
                    <div className="table-row" key={trackerTitle}>
                        <div className="body-item">{trackerTitle}</div>
                        <div className="body-item">
                            <span onClick={() => navigate(`/tasks/?tracker=${taskCounts.tracker_id}&status=${taskCounts.opened_id}`)}>
                                {taskCounts.opened_tasks}
                            </span>
                        </div>
                        <div className="body-item">
                            <span onClick={() => navigate(`/tasks/?tracker=${taskCounts.tracker_id}&status=${taskCounts.closed_id}`)}>
                                {taskCounts.closed_tasks}
                            </span>
                        </div>
                        <div className="body-item">
                            <span onClick={() => navigate(`/tasks/?tracker=${taskCounts.tracker_id}`)}>
                                {taskCounts.total_tasks}
                            </span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default SimpleTable