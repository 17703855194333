import React, {useEffect, useState} from "react";
import {fetchSprintEdit, updateSprint} from "../../utils/axios";
import {CircularProgress} from "@mui/material";
import {Link, useNavigate} from "react-router-dom";
import Calendar from "../Calendar";
import { ISprint } from "../../common/types/tasks";

const SprintEdit = ({sprintNumber} : {sprintNumber: number}) => {
    const navigate = useNavigate();
    const [fetched, setFetched] = useState<boolean>();
    
    const [sprint, setSprint] = useState<ISprint>();
    const [number, setNumber] = useState<number>();
    const [title, setTitle] = useState<string>('');
    const [desc, setDesc] = useState<string>('');
    const [startAt, setStartAt] = useState<Date | null>(null);
    const [endAt, setEndAt] = useState<Date | null>(null);

    const [errors, setErrors] = useState<string[]>([]);

    useEffect(() => {
        const getSprint = async () => {
            try {
                const fetchedSprint = await fetchSprintEdit(sprintNumber);

                if (fetchedSprint.sprint?.closed === true) {
                    navigate(`/sprints/${sprintNumber}/`);
                    return
                }

                setSprint(fetchedSprint.sprint);
                setNumber(fetchedSprint.sprint.number);
                setTitle(fetchedSprint.sprint.title);
                setDesc(fetchedSprint.sprint.desc);
                setStartAt(new Date(fetchedSprint.sprint.start_at));
                setEndAt(new Date(fetchedSprint.sprint.end_at));
            } catch (error) {
                console.error(error);
            }
            setFetched(true);
        };
        getSprint();
    }, [])

    const handleUpdateSprint = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        (async () => {
            setErrors([]);
            try {
                if (!title) {
                    setErrors(["Название не может быть пустыми"])   
                    return;
                }

                if (sprintNumber != null) {
                    const updatedSprint = await updateSprint(sprintNumber, {
                        title: title,
                        desc: desc,
                        start_at: startAt ? startAt.toISOString().slice(0, 10) : null,
                        end_at: endAt ? endAt.toISOString().slice(0, 10) : null
                    })
                    if (updatedSprint.sprint) {
                        navigate(`/sprints/${updatedSprint.sprint.number}/board`)
                    } else if (updatedSprint.errors) {
                        setErrors(updatedSprint.errors)
                    }
                }
            } catch (e) {
                console.error(e);
            }
        })();
    }

    return(
        <div className={'main-container task-new'}>
            <div className={'form-head'}>
                <p className={'title-form'}>
                    # {number}
                </p>
                <button className={'back-button'} onClick={() => navigate(-1)}>
                    <span>&lt;</span>
                </button>
            </div>

            {errors.length > 0 && <ul className="form-task-error">{errors.map((error)=>( <li>{error}</li> ))}</ul>}

            {fetched ?
                <>     
                    <form className={'form-main'} onSubmit={handleUpdateSprint}>
                        <div className={'form-task-col-left'}>
                            <div className={'form-task-group'}>
                                <label className={'form-task-label'} htmlFor={'title'}>Название</label>
                                <input className={'form-task-input'} name={'title'} placeholder={'Название'}
                                    value={title} onChange={(e) => setTitle(e.target.value)} />
                            </div>
                            <div className={'form-task-group'}>
                                <label className={'form-task-label'} htmlFor={'text'}>Цели спринта</label>
                                <textarea className={'form-sprint-textarea'} name={'text'} placeholder={'Цели спринта'}
                                        value={desc} onChange={(e) => (setDesc(e.target.value))}/>
                            </div>
                        </div>

                        <div className={'form-task-col-right'}>
                            <div className={'form-task-group'}>
                                <label className={'form-task-label'} htmlFor={'start_at'}>Дата начала</label>
                                <Calendar dateAt={startAt} setDateAt={setStartAt} minDate={null} maxDate={endAt} />
                            </div>

                            <div className={'form-task-group'}>
                                <label className={'form-task-label'} htmlFor={'end_at'}>Дата завершения</label>
                                <Calendar dateAt={endAt} setDateAt={setEndAt} minDate={startAt} maxDate={null} />
                            </div>
                        </div>

                        <div className={'form-task-col-center'}>
                            <button className={'save-task-btn'} type={'submit'}>
                                Обновить спринт
                            </button>
                        </div>

                    </form>

                    {sprint && 
                        <div className={'form-main'}>
                            <div className={'form-task-col-left'}>
                                <div className={'task-item'}>
                                    <strong>Автор: </strong>
                                    {sprint.author}
                                </div>
                                <div className={'task-item'}>
                                    <strong>Трудозатраты: </strong>
                                    {sprint.time_spent_to_tasks} ч
                                </div>
                                <div className={'task-item'}>
                                    <p className={'sprint-progress'}><strong>Прогресс: </strong>
                                        <span className={'progress-block'}>
                                            <div className={'progress-bar'}>
                                                <div className={'progress'} style={{'width': `${sprint.readiness}%`}}></div>
                                            </div>
                                            {sprint.readiness}%
                                        </span>
                                    </p>
                                </div>
                                <div className={'task-item'}>
                                    <strong>Количество задач: </strong>
                                    {sprint.tasks_count}
                                    &emsp; 
                                    <Link to={sprint.tasks_list_link} className={'to-btn'}>
                                        Перейти к списку задач
                                    </Link>
                                </div>
                            </div>
                        </div> 
                    }                    
                </>

                :
                <CircularProgress/>
            }
        </div>
    )
}

export default SprintEdit
