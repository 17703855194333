import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {IUser, IRole, IUserCreateUpdate} from "../../common/types/tasks";
import { createUser, deleteUser, fetchUserEdit, updateUser, createInviteLinkUser, sendInvitedLinkUser, fetchUserNew } from "../../utils/axios";
import { CircularProgress } from "@mui/material";
import ModalWindow from "../ModalWindow";

type userFormType = 'edit' | 'new' | 'create' | 'invite';
// type newFormType = ;

const LINK_SENDED_SUCCESSFUL = 'Ссылка отправлена';

const UserForm = ({typePage, id} : {typePage: userFormType, id?:number}) => {
    const navigate = useNavigate();
    const [fetched, setFetched] = useState<boolean>(false);

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [position, setPosition] = useState('');

    const [roles, setRoles] = useState<IRole[]>([]);
    const [role, setRole] = useState<number | null>(null);

    const [password, setPassword] = useState('');
    const [username, setUsername] = useState('');
    const [user, setUser] = useState<IUser>();
    const [errors, setErrors] = useState<string[]>([]);

    const [profileNotifications, setProfileNotifications] = useState<string>('');
    const [showModal, setShowModal] = useState(false);

    const [inviteLink, setInviteLink] = useState('');
    const [copyLinkText, setCopyLinkText] = useState('');
    const [sendLinkText, setSendLinkText] = useState('');

    const handleConfirm = async () => {
        if (!id) return;

        setShowModal(false);

        const deletedUser = await deleteUser(id);

        if (deletedUser.errors) {
            setErrors(deletedUser.errors);
        } else {
            navigate('/settings/users');
        }       
    }

    const clearNotifications = () => {
        setInviteLink('');
        setErrors([]);
        setCopyLinkText('');
        setSendLinkText('');
        setProfileNotifications('');
    }

    const handleCancel = () => {
        setShowModal(false);
    }    
    const handleCreateUser = async () => {
        clearNotifications();
        try {
            const createdUser = await createUser({first_name: firstName, last_name: lastName, email: email, position: position, role_id: role});
            if (createdUser.user) {
                setFirstName('');
                setLastName('');
                setEmail('');
                setPosition('');
                setRole(null);
                setUser(createdUser.user);
            } else if(createdUser.errors) {
                setErrors(createdUser.errors);
            }
        } catch (e) {
            console.error(e);
        }
    }

    const handleInviteUser = async () => {
        clearNotifications();
        try {
            const createdLink = await createInviteLinkUser({email: email});
            if (createdLink.link) {
                setInviteLink(createdLink.link);
            } else if(createdLink.errors) {
                setErrors(createdLink.errors);
            }
        } catch (e) {
            console.error(e);
        }
    }

    const copyInviteUser = async () => {
        try {
            await navigator.clipboard.writeText(inviteLink);
            setCopyLinkText('Ссылка скопирована');
        } catch (err) {
            console.error('Failed to copy: ', err);
            setCopyLinkText('Не удалось скопировать ссылку.');
        }
    }

    const sendInviteUser = async () => {
        if (!email || !inviteLink) return;

        setSendLinkText('');
        try {
            const sendedLink = await sendInvitedLinkUser(email, inviteLink);
            if (sendedLink.errors) {
                setErrors(sendedLink.errors);
            } else {
                setSendLinkText(LINK_SENDED_SUCCESSFUL);
            }  
        } catch (err) {
            console.error('Failed to send: ', err);
            setSendLinkText('Не удалось отправить ссылку.');
        }
    }

    const handleUpdateUser = async () => {
        if (!id) return;

        clearNotifications();
        try {
            let userNewData: IUserCreateUpdate = {
                first_name: firstName,
                last_name: lastName,
                email: email,
                role_id: role,
                position: position
            }

            if (password !== '') {
                userNewData = {
                  ...userNewData,
                  password: password,
                };
              }

            const updatedUser = await updateUser(id, userNewData);
            if (updatedUser.user) {
                setProfileNotifications('Данные успешно обновлены');
            } else if(updatedUser.errors) {
                setErrors(updatedUser.errors);
            }
        } catch (e) {
            console.error(e);
        }
    }    

    useEffect(() => {
        const getUserEdit = async () => {
            if (!id) return;

            try {
                const fetchedUser = await fetchUserEdit(id);
                if (fetchedUser.user) {
                    setUsername(fetchedUser.user.username);
                    setFirstName(fetchedUser.user.first_name);
                    setLastName(fetchedUser.user.last_name);
                    setRole(fetchedUser.user.role);
                    setRoles(fetchedUser.roles);
                    setPosition(fetchedUser.user.position);

                    setEmail(fetchedUser.user.email);
                }
            } catch (e) {
                console.error(e);
            }
            setFetched(true);
        }

        const getUserNew = async () => {
            try {
                const fetchedUser = await fetchUserNew();
                if (fetchedUser.roles) {
                    setRoles(fetchedUser.roles);
                    const editorRole = fetchedUser.roles.find(role => role.title === 'Редактор');
                    if (editorRole) {
                        setRole(editorRole.id);
                    }
                }
            } catch (e) {
                console.error(e);
            }
            setFetched(true);
        }

        if (!fetched && typePage === 'edit') {
            getUserEdit();
        } else if (!fetched && typePage === 'create') {
            getUserNew();
        }

    }, [fetched])

    return(
        <div className={'main-container task-list'}>
            <div className={'form-head'}>
                <p className={'title-form'}>
                    {(typePage === 'new' || typePage === 'create' || typePage === 'invite') && 'Новый пользователь'}
                    {typePage === 'edit' && username}
                </p>
                <button className={'back-button'} onClick={() => (navigate(-1))}>
                    <span>&lt;</span>
                </button>
            </div>

            {typePage === 'new' &&
                <div className={'form-main'}>
                    <div className={'form-task-col-left'}>
                        <div className={'form-task-group'}>
                            <h3>Ввести вручную данные пользователя, сохранить и получить пароль для входа</h3>
                            <button className={'setting-list-btn'} onClick={() => navigate('/settings/users/new/create')}>
                                Создать пользователя
                            </button>
                        </div>
                        <div className={'form-task-group'}>
                            <h3>Создать уникальную ссылку-приглашение для самостоятельной регистрации пользователя</h3>
                            <button className={'setting-list-btn'} onClick={() => navigate('/settings/users/new/invite')}>
                                Создать пригласительную ссылку
                            </button>
                        </div>
                    </div>
                </div>
            }

            {typePage === 'invite' &&
                <div className={'form-main'}>
                    {errors &&
                        <div className="form-task-error">
                            {errors.map((error) => (
                                <p>{error}</p>
                            ))}
                        </div>
                    }

                    <div className={'form-task-col-center'}>
                        <div className={'form-task-group'}>
                            <label className={'form-task-label'} htmlFor={'email'}>Электронная почта*</label>
                            <input className={'form-task-input'} name={'email'} placeholder={'Электронная почта'} type={'email'}
                                value={email} onChange={(e) => setEmail(e.target.value)} />  
                        </div>
                        <button type={"button"} className={'add-task-btn'} onClick={handleInviteUser}>Создать ссылку</button>
                        {inviteLink !== '' && 
                            <>
                                <h3 style={{color: 'green'}}>
                                    <p>Ссылка-приглашение для пользователя:</p>
                                    <p>{copyLinkText !== '' && copyLinkText}</p>
                                    <p>{sendLinkText !== '' && sendLinkText}</p>
                                </h3>
                                <p style={{'whiteSpace': 'pre-wrap', width: '100%'}}>{inviteLink}</p>
                                <button type={"button"} className={'to-btn'} onClick={copyInviteUser}>Скопировать ссылку</button>
                                &emsp;
                                {sendLinkText !== LINK_SENDED_SUCCESSFUL &&
                                    <button type={"button"} className={'to-btn'} onClick={sendInviteUser}>Отправить ссылку на электронную почту</button>
                                }
                                <p style={{'whiteSpace': 'pre-wrap', width: '100%'}}>Ссылка активна в течение одного месяца. Регистрация по данной ссылке возможна только один раз.</p>
                            </>
                        }
                    </div>  
                </div>
            }

            {typePage === 'create' &&
                <form className={'form-main'}>
                    {errors &&
                        <div className="form-task-error">
                            {errors.map((error) => (
                                <p>{error}</p>
                            ))}
                        </div>
                    }

                    <div className={'form-task-col-left'}>                     
                        <div className={'form-task-group'}>
                            <label className={'form-task-label'} htmlFor={'title'}>Имя*</label>
                            <input className={'form-task-input'} name={'first_name'} placeholder={'Имя'} type={'text'}
                                value={firstName} onChange={(e) => setFirstName(e.target.value)} />                
                        </div>

                        <div className={'form-task-group'}>
                            <label className={'form-task-label'} htmlFor={'title'}>Фамилия*</label>
                            <input className={'form-task-input'} name={'last_name'} placeholder={'Фамилия'} type={'text'}
                                value={lastName} onChange={(e) => setLastName(e.target.value)} />
                        </div>

                        <div className={'form-task-group'}>
                            <label className={'form-task-label'} htmlFor={'email'}>Электронная почта*</label>
                            <input className={'form-task-input'} name={'email'} placeholder={'Электронная почта'} type={'email'}
                                value={email} onChange={(e) => setEmail(e.target.value)} />  
                        </div>

                        <div className={'form-task-group'}>
                            <label className={'form-task-label'} htmlFor={'role'}>Роль</label>

                            <select
                                className='form-task-select'
                                name='readiness'
                                placeholder='Прогресс'
                                value={role !== null ? role.toString() : ''}
                                onChange={(e) => setRole(parseInt(e.target.value))}
                            >
                                {roles.map((role) => (
                                    <option key={role.id} value={role.id}>{role.title}</option>
                                ))}
                            </select>
                        </div>

                        <div className={'form-task-group'}>
                            <label className={'form-task-label'} htmlFor={'title'}>Позиция</label>
                            <input className={'form-task-input'} name={'position'} placeholder={'Позиция'} type={'text'}
                                value={position} onChange={(e) => setPosition(e.target.value)} />  
                        </div>

                        <button type={"button"} className={'add-task-btn'} onClick={handleCreateUser}>Сохранить</button>
                    </div>

                    <div className={'form-task-col-right'}>
                        {user && 
                            <>
                                <h3 style={{whiteSpace: 'pre-wrap', color: 'green'}}>
                                    <p>Пользователь сохранен.</p> 
                                    Пароль больше не будет виден! 
                                    (в дальнейшем пользователь сможет его изменить в Профиле)
                                </h3>

                                <div className={'form-task-group'}>
                                    <label className={'form-task-label'} htmlFor={'email'}>Полное имя</label>
                                    <input className={'form-task-input'} value={user.username} />
                                </div>
                                
                                <h3 className={'form-task-label'}>Данные для входа:</h3>

                                <div className={'form-task-group'}>
                                    <label className={'form-task-label'} htmlFor={'email'}>Рабочее окружение</label>
                                    <input className={'form-task-input'} value={user.workspace} />
                                </div>

                                <div className={'form-task-group'}>
                                    <label className={'form-task-label'} htmlFor={'email'}>Электронная почта</label>
                                    <input className={'form-task-input'} value={user.email} />
                                </div>
                                
                                <div className={'form-task-group'}>
                                    <label className={'form-task-label'} htmlFor={'title'}>Пароль</label>
                                    <input className={'form-task-input'} value={user.password} />
                                </div>     
                            </>             
                        }
                    </div>
                </form>
            }

            {typePage === 'edit' &&
                <>
                    {fetched ?
                        <form className={'form-main'}>
                            {errors &&
                                <div className="form-task-error">
                                    {errors.map((error) => (
                                        <p>{error}</p>
                                    ))}
                                </div>
                            }

                            { profileNotifications &&
                                <h3 style={{'whiteSpace': 'pre-wrap', 'width': '90%', 'color': '#008000'}}>{profileNotifications}</h3> }   

                            <div className={'form-task-col-left'}>                     
                                <div className={'form-task-group'}>
                                    <label className={'form-task-label'} htmlFor={'title'}>Имя</label>
                                    <input className={'form-task-input'} name={'first_name'} placeholder={'Имя'} type={'text'}
                                        value={firstName} onChange={(e) => setFirstName(e.target.value)} />                
                                </div>

                                <div className={'form-task-group'}>
                                    <label className={'form-task-label'} htmlFor={'title'}>Фамилия</label>
                                    <input className={'form-task-input'} name={'last_name'} placeholder={'Фамилия'} type={'text'}
                                        value={lastName} onChange={(e) => setLastName(e.target.value)} />
                                </div>

                                <div className={'form-task-group'}>
                                    <label className={'form-task-label'} htmlFor={'email'}>Электронная почта</label>
                                    <input className={'form-task-input'} name={'email'} placeholder={'Электронная почта'} type={'email'}
                                        value={email} onChange={(e) => setEmail(e.target.value)} />  
                                </div>

                                <div className={'form-task-group'}>
                                    <label className={'form-task-label'} htmlFor={'position'}>Позиция</label>
                                    <input className={'form-task-input'} name={'position'} placeholder={'Позиция'} type={'text'}
                                        value={position} onChange={(e) => setPosition(e.target.value)} />  
                                </div>

                                <div className={'form-task-group'}>
                                    <label className={'form-task-label'} htmlFor={'role'}>Роль</label>
                                    <select
                                        className='form-task-select'
                                        name='role'
                                        placeholder='Роль'
                                        value={role !== null ? role.toString() : ''}
                                        onChange={(e) => setRole(parseInt(e.target.value))}
                                    >
                                        {roles.map((role) => (
                                            <option key={role.id} value={role.id}>{role.title}</option>
                                        ))}
                                    </select>
                                </div>

                                <div className={'form-task-group'}>
                                    <label className={'form-task-label'} htmlFor={'title'}>Новый пароль</label>
                                    <input className={'form-task-input'} name={'password'} placeholder={'Пароль'} type={'text'}
                                        value={password} onChange={(e) => setPassword(e.target.value)} />
                                </div>  

                                <div style={{width: '100%', display: 'flex'}}>
                                    <button type={"button"} className={'add-task-btn'} onClick={handleUpdateUser}>Сохранить</button>
                                    &emsp;
                                    <button type={"button"} className={'del-task-btn'} onClick={() => (setShowModal(true))}>Удалить</button>
                                </div>                    
                            </div>

                            {showModal && <ModalWindow title="Вы уверены, что хотите удалить пользователя? Это действие нельзя отменить!"
                                                    onConfirm={handleConfirm}
                                                    onCancel={handleCancel} />}

                        </form>
                        :
                        <CircularProgress />
                    }
                </>
            }
        </div>
    )
}

export default UserForm
