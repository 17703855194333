

const SubscriptionBuy = () => {
  return(
    <div className="main-container">
      
    </div>
  )
}

export default SubscriptionBuy
