import {createSlice} from "@reduxjs/toolkit";
import {IAuthState} from "../../../common/types/auth";

const initialState: IAuthState = {
    user: {
        id: 0,
        username: '',
        email: '',
        workspace: '',
    },
    isAuthenticated: false
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login(state, action) {
            state.user = action.payload
            state.isAuthenticated = true
        },
        logout(state) {
            state.user = initialState.user;
            state.isAuthenticated = false;
        }
    }
})

export const {login, logout} = authSlice.actions
export default authSlice.reducer