// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.open-sidebar-btn {
  cursor: pointer;
  font-size: 24px;
}

.new-task-small {
  font-weight: normal;
  font-size: 18px;
  position: relative;
  display: inline-block;
}

.new-task-small.form {
  width: 100%;
}

.dropdown-toggle-small {
  height: 2rem;
  width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #273B64;
  color: #273B64;
  cursor: pointer;
}

.dropdown-toggle-small.active {
  background-color: #273B64;
  color: white;
}

.form-task-group-small {
  margin-bottom: 1rem;
}

.form-task-input-small, .form-task-select-small, .form-task-textarea-small {
  width: 100%;
  height: 2.5rem;
  margin-top: 0.5rem;
  background: white;
  font-size: 18px;
  padding-left: 0.5rem;
  border: 1px solid #273B64;
  border-radius: 10px;
  box-shadow: 0 3px 0 0 rgba(0, 0, 0, 0.02);
  color: #273B64;
}

.form-task-input-small {
  width: calc(100% - 0.5rem - 4px);
}

.form-task-textarea-small {
  width: calc(100% - 0.5rem - 4px);
  min-height: 7rem;
  resize: vertical;
}

.form-task-textarea-small.large {
  min-height: 13rem;
}

.save-task-btn.small-form {
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/TaskSidebar/TaskSidebar.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,mBAAmB;EACnB,eAAe;EACf,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,mBAAmB;EACnB,yBAAyB;EACzB,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,cAAc;EACd,kBAAkB;EAClB,iBAAiB;EACjB,eAAe;EACf,oBAAoB;EACpB,yBAAyB;EACzB,mBAAmB;EACnB,yCAAyC;EACzC,cAAc;AAChB;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,gCAAgC;EAChC,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,WAAW;AACb","sourcesContent":[".open-sidebar-btn {\n  cursor: pointer;\n  font-size: 24px;\n}\n\n.new-task-small {\n  font-weight: normal;\n  font-size: 18px;\n  position: relative;\n  display: inline-block;\n}\n\n.new-task-small.form {\n  width: 100%;\n}\n\n.dropdown-toggle-small {\n  height: 2rem;\n  width: 2rem;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border-radius: 10px;\n  border: 1px solid #273B64;\n  color: #273B64;\n  cursor: pointer;\n}\n\n.dropdown-toggle-small.active {\n  background-color: #273B64;\n  color: white;\n}\n\n.form-task-group-small {\n  margin-bottom: 1rem;\n}\n\n.form-task-input-small, .form-task-select-small, .form-task-textarea-small {\n  width: 100%;\n  height: 2.5rem;\n  margin-top: 0.5rem;\n  background: white;\n  font-size: 18px;\n  padding-left: 0.5rem;\n  border: 1px solid #273B64;\n  border-radius: 10px;\n  box-shadow: 0 3px 0 0 rgba(0, 0, 0, 0.02);\n  color: #273B64;\n}\n\n.form-task-input-small {\n  width: calc(100% - 0.5rem - 4px);\n}\n\n.form-task-textarea-small {\n  width: calc(100% - 0.5rem - 4px);\n  min-height: 7rem;\n  resize: vertical;\n}\n\n.form-task-textarea-small.large {\n  min-height: 13rem;\n}\n\n.save-task-btn.small-form {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
