// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown-toggle-small-relation {
    width: 12rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 1px solid #273B64;
    color: #273B64;
    cursor: pointer;
}

.dropdown-toggle-small-relation.active {
    background-color: #273B64;
    color: white;
}

.dropdown-menu-small-relation {
    width: 42rem;
    min-height: 23.5rem;
    transition: opacity 0.3s ease, transform 0.3s ease;
    opacity: 0;
    transform: translateY(-10px);
    position: absolute;
    top: 100%;
    /*right: 0;*/
    /*left: auto;*/
    z-index: -1;
    padding: 1rem 1rem;
    margin-top: 0.5rem;
    max-height: 20rem;
    overflow-y: auto;
    box-shadow: 4px 12px 12px 16px rgba(0,0,0,0.2);
    border: none;
    border-radius: 10px;
    background-color: #fff;
}

.dropdown-menu-small-relation.active {
    opacity: 1;
    transform: translateY(0);
    z-index: 10;
}`, "",{"version":3,"sources":["webpack://./src/components/RelatedTaskAdd/RelatedTaskAdd.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,mBAAmB;IACnB,yBAAyB;IACzB,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,yBAAyB;IACzB,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,mBAAmB;IACnB,kDAAkD;IAClD,UAAU;IACV,4BAA4B;IAC5B,kBAAkB;IAClB,SAAS;IACT,YAAY;IACZ,cAAc;IACd,WAAW;IACX,kBAAkB;IAClB,kBAAkB;IAClB,iBAAiB;IACjB,gBAAgB;IAChB,8CAA8C;IAC9C,YAAY;IACZ,mBAAmB;IACnB,sBAAsB;AAC1B;;AAEA;IACI,UAAU;IACV,wBAAwB;IACxB,WAAW;AACf","sourcesContent":[".dropdown-toggle-small-relation {\n    width: 12rem;\n    height: 2rem;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    border-radius: 10px;\n    border: 1px solid #273B64;\n    color: #273B64;\n    cursor: pointer;\n}\n\n.dropdown-toggle-small-relation.active {\n    background-color: #273B64;\n    color: white;\n}\n\n.dropdown-menu-small-relation {\n    width: 42rem;\n    min-height: 23.5rem;\n    transition: opacity 0.3s ease, transform 0.3s ease;\n    opacity: 0;\n    transform: translateY(-10px);\n    position: absolute;\n    top: 100%;\n    /*right: 0;*/\n    /*left: auto;*/\n    z-index: -1;\n    padding: 1rem 1rem;\n    margin-top: 0.5rem;\n    max-height: 20rem;\n    overflow-y: auto;\n    box-shadow: 4px 12px 12px 16px rgba(0,0,0,0.2);\n    border: none;\n    border-radius: 10px;\n    background-color: #fff;\n}\n\n.dropdown-menu-small-relation.active {\n    opacity: 1;\n    transform: translateY(0);\n    z-index: 10;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
