import React, { useEffect, useState } from "react";
import { IDeveloper, IPriority, IProject, IStatus, ITracker } from "../../common/types/tasks";
import { createTaskNew, fetchTaskNew } from "../../utils/axios";

interface TaskNewSidebarProps {
  onSuccess: (fetched: boolean) => void;
  onClose: () => void;
}

const TaskNewSidebar: React.FC<TaskNewSidebarProps> = ({ onSuccess, onClose }) => {
	const [propsFetched, setPropsFetched] = useState<boolean>(false);

	const [projects, setProjects] = useState<IProject[]>([]);
	const [developers, setDevelopers] = useState<IDeveloper[]>([]);
	const [availableDevelopers, setAvailableDevelopers] = useState<IDeveloper[]>([]);
	const [trackers, setTrackers] = useState<ITracker[]>([]);
	const [priorities, setPriorities] = useState<IPriority[]>([]);
    // const [statuses, setStatuses] = useState<IStatus[]>([]);

	const [project, setProject] = useState<number | null>(null);
	const [priority, setPriority] = useState<number | null>(null);
	const [tracker, setTracker] = useState<number | null>(null);
	const [developer, setDeveloper] = useState<number | null>(null);
    // const [status, setStatus] = useState<number | null>(null);
	const [title, setTitle] = useState('');
	const [text, setText] = useState('');

	const [errors, setErrors] = useState<string[]>([]);

	useEffect(() => {
		const getTasksProps = async () => {
		try {
			const fetchedTaskNew = await fetchTaskNew();
			setProjects(fetchedTaskNew.projects);
			setPriorities(fetchedTaskNew.priorities);
			setTrackers(fetchedTaskNew.trackers);
			setDevelopers(fetchedTaskNew.developers);
			setAvailableDevelopers(fetchedTaskNew.developers);
            // setStatuses(fetchedTaskNew.statuses);

			if (fetchedTaskNew.priorities && fetchedTaskNew.priorities.length > 0)
			    setPriority(fetchedTaskNew.priorities[0].id);
			if (fetchedTaskNew.trackers && fetchedTaskNew.trackers.length > 0)
			    setTracker(fetchedTaskNew.trackers[0].id);
			if (fetchedTaskNew.projects && fetchedTaskNew.projects.length > 0)
			    setProject(fetchedTaskNew.projects[0].id);
            // if (fetchedTaskNew.statuses && fetchedTaskNew.statuses.length > 0)
            //     setStatus(fetchedTaskNew.statuses[0].id);

			setPropsFetched(true);
		} catch (error) {
			console.error(error);
		}
		};

		if (!propsFetched) {
		getTasksProps();
		}
	}, []);

	const handleCreateTask = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		try {
		const task_data = {
			title: title,
			text: text,
			project_id: project,
			priority_id: priority,
			developer_id: developer,
			tracker_id: tracker,
			status_id: null,
			time_expected: null,
			readiness: 0,
			start_at: null,
			end_at: null,
			complexity_id: null,
			complexity_units: null,
			time_expected_by_params: ''
		};

		const task = await createTaskNew(task_data);
		if (task.errors) {
			setErrors(task.errors);
		} else {
			onSuccess(false);
			onClose();
		}
		} catch (error) {
		if (error instanceof Error) {
			setErrors([error.message]);
		} else {
			setErrors(["Произошла неизвестная ошибка"]);
		}
		console.error(error);
		}
	};

	useEffect(() => {
		if (project === 0 || project === null) return;

		const newAvailableDevelopers = developers.filter(dev => dev.project_ids.includes(project));
		setAvailableDevelopers(newAvailableDevelopers);
	}, [project]);

	return (
		<form onSubmit={handleCreateTask}>
            <h2 style={{marginTop: "0"}}>Добавить задачу</h2>
            {errors &&
                <div className="form-task-error">
                {errors.map((error, index) => (
                    <p key={index}>{error}</p>
                ))}
                </div>
            }

            <div className={'form-task-group-small'}>
                <label className={'form-task-label'} htmlFor={'title'}>Название</label>
                <input className={'form-task-input-small'} name={'title'} placeholder={'Название'}
                    value={title} onChange={(e) => setTitle(e.target.value)} />
            </div>

            <div className={'form-task-group-small'}>
                <label className={'form-task-label'} htmlFor={'project'}>Проект</label>
                <select className={'form-task-select-small'}
                    name={'project'}
                    placeholder={'Проект'}
                    value={project !== null ? project.toString() : ''}
                    onChange={(e) => setProject(parseInt(e.target.value) || null)}
                >
                {projects.map((project) => (
                    <option key={project.id} value={project.id}>{project.title}</option>
                ))}
                </select>
            </div>

            <div className={'form-task-group-small'}>
                <label className={'form-task-label'} htmlFor={'tracker'}>Тип</label>
                <select className={'form-task-select-small'}
                    name={'tracker'}
                    placeholder={'Тип'}
                    value={tracker !== null ? tracker.toString() : ''}
                    onChange={(e) => setTracker(parseInt(e.target.value) || null)}
                >
                {trackers.map((tracker) => (
                    <option key={tracker.id} value={tracker.id}>{tracker.title}</option>
                ))}
                </select>
            </div>

            <div className={'form-task-group-small'}>
                <label className={'form-task-label'} htmlFor={'priority'}>Приоритет</label>
                <select className={'form-task-select-small'}
                    name={'priority'}
                    placeholder={'Приоритет'}
                    value={priority !== null ? priority.toString() : ''}
                    onChange={(e) => setPriority(parseInt(e.target.value))}
                >
                {priorities.map((priority) => (
                    <option key={priority.id} value={priority.id}>{priority.title}</option>
                ))}
                </select>
            </div>
{/* 
            <div className={'form-task-group-small'}>
                <label className={'form-task-label'} htmlFor={'status'}>Статус</label>
                <select className={'form-task-select-small'}
                    name={'status'}
                    placeholder={'Статус'}
                    value={status !== null ? status.toString() : ''}
                    onChange={(e) => setStatus(parseInt(e.target.value))}
                >
                {statuses.map((status) => (
                    <option key={status.id} value={status.id}>{status.title}</option>
                ))}
                </select>
            </div> */}

            <div className={'form-task-group-small'}>
                <label className={'form-task-label'} htmlFor={'developer'}>Назначена</label>
                <select className={'form-task-select-small'}
                    name={'developer'}
                    placeholder={'Назначена'}
                    value={developer ? developer.toString() : ''}
                    onChange={(e) => setDeveloper(parseInt(e.target.value))}
                >
                <option key={'0'}></option>
                {availableDevelopers.map((developer) => (
                    <option key={developer.id} value={developer.id}>{developer.username}</option>
                ))}
                </select>
            </div>

            <div className={'form-task-group-small'}>
                <label className={'form-task-label'} htmlFor={'text'} >Описание</label>
                <textarea className={'form-task-textarea-small large'} name={'text'} placeholder={'Описание'}
                    value={text} onChange={(e) => setText(e.target.value)} />
            </div>

            <button className={'save-task-btn small-form'} type={'submit'}>
                Добавить задачу
            </button>
		</form>
	);
};

export default TaskNewSidebar;
