import Layout from "../../components/layout";
import {useEffect, useState} from "react";
import {updatePassword, updateProfile, fetchProfile, signOut} from "../../utils/axios";
import {IUser} from "../../common/types/tasks";
import {useNavigate} from "react-router-dom";
import {useActionIsAllowed, useAppDispatch} from "../../utils/hook";
import {logout} from "../../store/slice/auth";

const Profile = () => {

    const editAllowed = useActionIsAllowed('user_profile');
    const [profile, setProfile] = useState<IUser>();
    const [profileErrors, setProfileErrors] = useState<string[]>([]);
    const [profileNotifications, setProfileNotifications] = useState<string>('');

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [currentPassword, setCurrentPassword] = useState<string>('');
    const [newPassword, setNewPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [passwordErrors, setPasswordErrors] = useState<string[]>([]);
    const [passwordNotifications, setPasswordNotifications] = useState<string>('');

    const logoutProfile = async () => {
        const signOutRequest = await signOut();

        if (signOutRequest === 200) {
            dispatch(logout());
            navigate('/login');
        } else {
            console.log('signOutRequest:', signOutRequest);
        }
    }

    useEffect(() => {
        const getProfile = async () => {
            const fetchedProfile = await fetchProfile();
            setProfile(fetchedProfile.user);
        }
        getProfile();
    }, []);

    const handleUpdateProfile = async () => {
        setProfileErrors([]);
        setProfileNotifications('');
        if (!profile) return

        const updatedProfile = await updateProfile(profile);           
        if (updatedProfile.user) {
            setProfileNotifications('Данные успешно обновлены');
            setProfile(updatedProfile.user);
        } else if (updatedProfile.errors) {
            setProfileErrors(updatedProfile.errors);
        }
        
    }

    const handleUpdatePassword = async () => {
        setPasswordErrors([]);
        setPasswordNotifications('');

        if (!currentPassword || !newPassword || !confirmPassword) return

        if (newPassword !== confirmPassword) {
            setPasswordErrors(['Пароли не совпадают']);
            return
        }
        
        const updatedPassword = await updatePassword(currentPassword, newPassword, confirmPassword);           
        if (updatedPassword.status === 'ok') {
            setCurrentPassword('');
            setConfirmPassword('');
            setNewPassword('');
            setPasswordNotifications('Пароль успешно изменен!')
        } else if (updatedPassword.errors) {
            setPasswordErrors(updatedPassword.errors);
        }
    }

    return(
        <Layout title="Профиль">
            {profile &&
                <div className={'main-container task-new'}>
                    <div className={'form-main'}>
                        <div style={{'width': '100%', 'display': 'flex', 'justifyContent': 'space-between', 'alignItems': 'center', 'height': 'min-content'}}>
                            <h2 style={{'marginTop': '0'}}>{profile.username}</h2>  
                            <span className={'add-task-btn'} onClick={() => (logoutProfile())}>Выйти</span>
                        </div>

                        {
                            profileErrors.length > 0 &&
                                <ul className="form-task-error">
                                    {profileErrors.map((error: string)=>(
                                        <li><p style={{'whiteSpace': 'pre-wrap', 'width': '90%'}}>{error}</p></li>
                                    ))}
                                </ul>
                        }

                        { profileNotifications &&
                            <h3 style={{'whiteSpace': 'pre-wrap', 'width': '90%', 'color': '#008000'}}>{profileNotifications}</h3> }       

                        <div className={'form-task-col-left'}>                     
                            <div className={'form-task-group'}>
                                <label className={'form-task-label'} htmlFor={'title'}>Имя</label>
                                {editAllowed ?
                                    <input className={'form-task-input'} name={'first_name'} placeholder={'Имя'} type={'text'}
                                        value={profile.first_name} onChange={(e) => setProfile({...profile, first_name: e.target.value})} />
                                    :
                                    <input className={'form-task-input'} name={'first_name'} placeholder={'Имя'} type={'text'}
                                        value={profile.first_name} onChange={(e) => setProfile({...profile, first_name: e.target.value})} readOnly />
                                }
                            </div>

                            <div className={'form-task-group'}>
                                <label className={'form-task-label'} htmlFor={'title'}>Фамилия</label>
                                {editAllowed ?
                                    <input className={'form-task-input'} name={'last_name'} placeholder={'Фамилия'} type={'text'}
                                        value={profile.last_name} onChange={(e) => setProfile({...profile, last_name: e.target.value})} />        
                                    :
                                    <input className={'form-task-input'} name={'last_name'} placeholder={'Фамилия'} type={'text'}
                                        value={profile.last_name} onChange={(e) => setProfile({...profile, last_name: e.target.value})} readOnly />
                                } 
                            </div>

                            <div className={'form-task-group'}>
                                <label className={'form-task-label'} htmlFor={'email'}>Электронная почта</label>
                                {editAllowed ?
                                    <input className={'form-task-input'} name={'email'} placeholder={'Электронная почта'} type={'email'}
                                        value={profile.email} onChange={(e) => setProfile({...profile, email: e.target.value})} />           
                                    :
                                     <input className={'form-task-input'} name={'email'} placeholder={'Электронная почта'} type={'email'}
                                        value={profile.email} onChange={(e) => setProfile({...profile, email: e.target.value})} readOnly /> 
                                }
                            </div>

                            <div className={'form-task-group'}>
                                <label className={'form-task-label'} htmlFor={'title'}>Позиция</label>
                                {editAllowed ?
                                    <input className={'form-task-input'} name={'position'} placeholder={'Позиция'} type={'text'}
                                        value={profile.position} onChange={(e) => setProfile({...profile, position: e.target.value})} />   
                                    :
                                    <input className={'form-task-input'} name={'position'} placeholder={'Позиция'} type={'text'}
                                            value={profile.position} onChange={(e) => setProfile({...profile, position: e.target.value})} readOnly />
                                }
                            </div>
                            {editAllowed &&
                                <button type={"button"} className={'add-task-btn'} onClick={handleUpdateProfile}>Сохранить</button>}
                        </div>

                        <div className={'form-task-col-center'}><br></br><br></br><br></br></div>

                        {editAllowed && 
                            <div className={'form-task-col-left'}>  
                                <h3 style={{'marginTop': '0'}}>Изменить пароль</h3> 
                                {passwordErrors.length > 0 &&
                                        <ul className="form-task-error">
                                            {passwordErrors.map((error: string)=>(
                                                <li><p style={{'whiteSpace': 'pre-wrap', 'width': '90%'}}>{error}</p></li>
                                            ))}
                                        </ul>}
                                {passwordNotifications &&
                                    <h3 style={{'whiteSpace': 'pre-wrap', 'width': '90%', 'color': '#008000'}}>{passwordNotifications}</h3> }

                                <div className={'form-task-group'}>
                                    <label className={'form-task-label'} htmlFor={'current_password'}>Текущий пароль</label>
                                    <input className={'form-task-input'} name={'current_password'} placeholder={'Текущий пароль'}
                                        value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} type={'password'} autoComplete={'new-password'} />                
                                </div>

                                <div className={'form-task-group'}>
                                    <label className={'form-task-label'} htmlFor={'new_password'}>Новый пароль</label>
                                    <input className={'form-task-input'} name={'new_password'} placeholder={'Новый пароль'}
                                        value={newPassword} onChange={(e) => setNewPassword(e.target.value)} type={'password'} autoComplete={'new-password'} />        
                                </div>

                                <div className={'form-task-group'}>
                                    <label className={'form-task-label'} htmlFor={'confirm_password'}>Подтвердите новый пароль</label>
                                    <input className={'form-task-input'} name={'confirm_password'} placeholder={'Подтвердите новый пароль'}
                                        value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} type={'password'} autoComplete={'new-password'} />
                                </div>

                                <button type={"button"} className={'add-task-btn'} onClick={handleUpdatePassword}>Изменить пароль</button>
                            </div>}

                        <div className={'form-task-col-center'}><br></br><br></br></div>
                    </div>
                </div>
            }

        </Layout>
    )
}

export default Profile
