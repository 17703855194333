import { useEffect, useState } from 'react';
import { fetchGanttChart, updateTaskGantt } from '../../utils/axios';
import { CircularProgress } from "@mui/material";
import { GanttData } from '../../common/types/tasks';
import { Gantt, Task, ViewMode } from 'gantt-task-react';
import GanttTaskListHeader from '../GanttTaskListHeader';
import GanttTaskListTable from '../GanttTaskListTable';

import "gantt-task-react/dist/index.css";
import './ProjectGantt.css'

const ProjectGantt = ({ projectNumber }: {projectNumber: number}) => {
    const [tasks, setTasks] = useState<Task[]>([]);
    const [tasksWithoutDatesLink, settasksWithoutDatesLink] = useState<string>('');
    const [tasksWithoutDatesCount, settasksWithoutDatesCount] = useState<number>();
    const [fetched, setFetched] = useState<boolean>(false);

    let isUpdating = false;

    const NoTooltip = () => null;
    const getDefaultViewMode = (): ViewMode => {
        const storedViewMode = localStorage.getItem('GanttViewMode');
        return storedViewMode && ViewMode[storedViewMode as keyof typeof ViewMode] ? ViewMode[storedViewMode as keyof typeof ViewMode] : ViewMode.Week;
    }
    const [currentViewMode, setCurrentViewMode] = useState(getDefaultViewMode());
    const setAndStoreViewMode = (newViewMode: ViewMode) => {
        localStorage.setItem('GanttViewMode', newViewMode);
        setCurrentViewMode(newViewMode);
    }

    let columnWidth = 65;
    if (currentViewMode === ViewMode.Year) {
      columnWidth = 300;
    } else if (currentViewMode === ViewMode.Month) {
      columnWidth = 250;
    } else if (currentViewMode === ViewMode.Week) {
      columnWidth = 200;
    }

    const setAndStoreDisplayTasksList = () => {
        localStorage.setItem('displayTasksList', (!displayTasksList).toString());
        setDisplayTasksList(!displayTasksList);
    }
    const getStoredDisplayTasksList = () => {
        const storedDispalyValue = localStorage.getItem('displayTasksList');
        if (storedDispalyValue === null || storedDispalyValue === undefined) return true;
        else return localStorage.getItem('displayTasksList') === 'true'
    }
    const [displayTasksList, setDisplayTasksList] = useState<boolean>(getStoredDisplayTasksList());

    useEffect(() => {
        const getGanttChart = async () => {
          try {
            const fetchedChart = await fetchGanttChart(projectNumber);
            setTasks(fetchedChart.tasks.map((task: GanttData) => {
              let start = new Date(), end = new Date();
              try {
                start = new Date(task.start);
                end = new Date(task.end);
              } catch (error) {
                console.error('Error parsing date:', error);
              }
              return {...task, start, end} as Task;
            }));
            settasksWithoutDatesLink(fetchedChart.tasks_without_dates_link);
            settasksWithoutDatesCount(fetchedChart.tasks_without_dates_count);
            setFetched(true);
          } catch (error) {
            console.error(error);
            setFetched(false);
          }
        }
        getGanttChart();
    }, []);

    const handleProgressChange = async (task: Task) => {
        try {
            const roundedProgress = Math.round(task.progress / 5) * 5;
            const updatedTask = await updateTaskGantt(task.id, {readiness: roundedProgress});

            const taskToChart = {
                ...updatedTask.task, 
                start: new Date(updatedTask.task.start),
                end: new Date(updatedTask.task.end),
                progress: updatedTask.task.progress
            } as Task
            setTasks(tasks.map(t => (t.id === task.id ? taskToChart : t)));

            if (updatedTask.errors) {
                console.error(updatedTask.errors);
            }
        } catch (error){
            console.error(error);
        }
    };

    const handleDateChange = async (task: Task) => {
        if (isUpdating) return;
        isUpdating = true;

        try {
            const updatedTask = await updateTaskGantt(task.id, {start_at: task.start, end_at: task.end});
            const taskToChart = {
                ...updatedTask.task,
                start: new Date(updatedTask.task.start),
                end: new Date(updatedTask.task.end),
                progress: updatedTask.task.progress
            } as Task
            setTasks(tasks.map(t => (t.id === task.id ? taskToChart : t)));

            if (updatedTask.errors) {
                console.error(updatedTask.errors);
            }
        } catch (error){
            console.error(error);
        } finally {
            isUpdating = false;
        }
    };

    const handleDblClick = (task: Task) => {
        window.open('/tasks/' + task.id, '_blank', 'noopener,noreferrer');
    };

    return (
        <div className={'main-container task-list'}>
            {fetched ?
                <>
                    {tasks.length > 0 && 
                        <>
                            <div className={'tr-task-duration chart-mode-buttons'}>
                                <div className={'chart-view-mode-buttons'}>
                                    <div className="Switch" style={{marginRight: '1rem'}}>
                                        <label className="Switch_Toggle">
                                        <input
                                            type="checkbox"
                                            defaultChecked={displayTasksList}
                                            onClick={() => setAndStoreDisplayTasksList()}
                                        />
                                        <span className="Slider" />
                                        </label>
                                        Показывать список задач
                                    </div>
                                    <button className={'chart-mode-button ' + (currentViewMode === 'Day' ? 'selected': '')} type={'button'} onClick={()=>(setAndStoreViewMode(ViewMode.Day))}>День</button>
                                    <button className={'chart-mode-button ' + (currentViewMode === 'Week' ? 'selected': '')} type={'button'} onClick={()=>(setAndStoreViewMode(ViewMode.Week))}>Неделя</button>
                                    <button className={'chart-mode-button ' + (currentViewMode === 'Month' ? 'selected': '')} type={'button'} onClick={()=>(setAndStoreViewMode(ViewMode.Month))}>Месяц</button>
                                    <button className={'chart-mode-button ' + (currentViewMode === 'Year' ? 'selected': '')} type={'button'} onClick={()=>(setAndStoreViewMode(ViewMode.Year))}>Год</button>
                                </div>
                            </div>
                            <Gantt
                                tasks={tasks}
                                locale={'ru'}
                                barProgressColor={'#273b64'}
                                barBackgroundColor={'#D9D9D9'}
                                barProgressSelectedColor={'#273b64'}
                                barBackgroundSelectedColor={'#D9D9D9'}
                                viewMode={currentViewMode}
                                onProgressChange={handleProgressChange}
                                onDoubleClick={handleDblClick}
                                onDateChange={handleDateChange}
                                listCellWidth={displayTasksList ? "9.5rem" : ""}
                                columnWidth={columnWidth}
                                TaskListHeader={GanttTaskListHeader}
                                TaskListTable={GanttTaskListTable}
                                TooltipContent={NoTooltip}
                            />
                        </>
                    }

                    {tasksWithoutDatesLink !== '' &&
                        <div>
                            <br></br>
                            &emsp;
                            <a className={'to-btn'} href={tasksWithoutDatesLink} target="_blank">
                                Перейти к задачам без дат начала и завершения
                            </a>
                        </div>
                    }

                    {(tasksWithoutDatesCount === 0 && tasks.length === 0) && <h3>&emsp;&emsp;В проекте нет открытых задач</h3>}
                    
                </>
                :
                <CircularProgress />
            }
      </div>
    );
};

export default ProjectGantt
