import {useEffect, useState} from "react";
import {CircularProgress} from "@mui/material";
import {Link, useNavigate, useParams} from "react-router-dom";
import {deleteRelatedTask, deleteSubTask, fetchTask} from "../../utils/axios";
import {ITask, TimeParams} from "../../common/types/tasks";
import { useActionIsAllowed } from "../../utils/hook";
import {numbersToText} from "../../utils/utils";
import Comments from "../CommentsList";
import TimeEntries from "../TimeEntries";
import RelatedTaskAdd from "../RelatedTaskAdd";
import TaskMeets from "../TaskMeets";
import remarkGfm from 'remark-gfm';
import rehypeSlug from 'rehype-slug';
import rehypeRaw from 'rehype-raw'
import rehypeAutolinkHeadings from 'rehype-autolink-headings';
import Markdown from 'react-markdown';

import './TaskShow.css'

type windowType = 'comments' | 'time_entries' | 'meets';
type relSubType = '' | 'subtasks' | 'related';

const TaskShow = () => {
    const navigate = useNavigate();
    const [fetched, setFetched] = useState<boolean>(false);
    const { task_number } = useParams();
    const [task, setTask] = useState<ITask>();
    const editAllowed = useActionIsAllowed('task_edit');
    const [isOpenTimeParams, setIsOpenTimeParams] = useState<boolean>(false);
    const [windowEditType, setWindowEditType] = useState<windowType>('comments');
    
    const [isOpenRelated, setIsOpenRelated] = useState<boolean>(false);
    const [isOpenSub, setIsOpenSub] = useState<boolean>(false);

    useEffect(() => {
        const getTask = async () => {
            try {
                if (task_number) {
                    const fetchedTask = await fetchTask(parseInt(task_number));
                    setTask(fetchedTask.task);
                    setFetched(true);
                } else
                    navigate('/home/')

            } catch (error) {
                console.error(error);
            }
        };
        getTask();
    }, [task_number, fetched]);

    const handleEditBtn = () => {
        navigate(`/tasks/${task_number}/edit`);
    };

    const handleDeleteRelatedTask = async (task_id: number, related_task_id: number) => {
        await deleteRelatedTask(task_id, related_task_id);
        setFetched(false);
    }

    const handleDeleteSubTask = async (task_id: number, sub_task_id: number) => {
        await deleteSubTask(task_id, sub_task_id);
        setFetched(false);
    }

    return(
        <div className={'main-container task-show'}>
            <div className={'form-head'}>
                <p className={'title-form'}>
                    #{task_number}
                </p>
                <button className={'back-button'} onClick={() => navigate(-1)}>
                    <span>&lt;</span>
                </button>
            </div>
            {task ? (
                <div className={'show-main'}>
                    <div className={'show-task-col-center'}>
                        <div className={'task-item-title'}>
                            {task.parent_task ?
                                <>
                                    <div className={'parent-task-title'}>
                                        <Link to={'/tasks/' + task.parent_task.number} className={'parent-task'}>{task.parent_task.title}</Link>
                                        <br></br>
                                        <strong>&emsp;&ensp;{task.title}</strong>
                                    </div>
                                    {/* <strong>&emsp;&ensp;{task.title}</strong> */}
                                </>
                                :
                                <strong>{task.title}</strong>}
                            
                            {editAllowed &&
                                <div className={'task-btn-block'}>
                                    <img className={'task-btn'} src={'/app/edit.svg'} onClick={handleEditBtn}/>
                                </div>}
                            
                        </div>
                    </div>

                    <div className={'show-task-col-left'}>
                        <div className={'task-item'}>
                            <strong>Автор: </strong>
                            {task.author}
                        </div>
                        <div className={'task-item'}>
                            <strong>Проект: </strong>
                            <Link to={'/projects/' + task.project_number}>{task.project}</Link>
                        </div>
                        <div className={'task-item'}>
                            <strong>Тип: </strong>
                            {task.tracker}
                        </div>
                        <div className={'task-item'}>
                            <strong>Назначена: </strong>
                            {task.developer}
                        </div>

                        {task.sprint_number &&
                            <div className={'task-item'}>
                                <strong>Спринт: </strong>
                                <Link to={'/sprints/' + task.sprint_number}>{task.sprint}</Link>
                            </div>
                        }

                        {task.version_number &&
                            <div className={'task-item'}>
                                <strong>Спринт: </strong>
                                <Link to={'/versions/' + task.version_number}>{task.version}</Link>
                            </div>
                        }

                        {task.start_at ?
                            <div className={'task-item'}>
                                <strong>Дата начала: </strong>
                                {task.start_at.toString().split('-').at(2) + '-' +
                                    task.start_at.toString().split('-').at(1) + '-' +
                                    task.start_at.toString().split('-').at(0)}
                            </div>
                            :
                            <></>
                        }
                        {task.end_at ?
                            <div className={'task-item'}>
                                <strong>Дата завершения: </strong>
                                {task.end_at.toString().split('-').at(2) + '-' +
                                    task.end_at.toString().split('-').at(1) + '-' +
                                    task.end_at.toString().split('-').at(0)}
                            </div>
                            :
                            <></>
                        }
                    </div>

                    <div className={'show-task-col-right'}>
                        <div className={'task-item'}>
                            <strong>Статус: </strong>
                            {task.status}
                        </div>
                        {task.complexity && task.complexity_units && 
                            <div className={'task-item'}>
                                <strong>Оценка сложности: </strong>
                                {task.complexity}:&ensp;{task.complexity_units}
                            </div>}
                        <div className={'task-item'}>
                            <strong>Приоритет: </strong>
                            {task.priority}
                        </div>
                        <div className={'task-item-progress'}>
                            <strong>Прогресс: </strong>

                            <span className={'progress-block'}>
                                <div className={'progress-bar'}>
                                    <div className={'progress'} style={{'width': `${task.readiness}%`}}></div>
                                </div>
                                {task.readiness}%
                            </span>

                        </div>
                        {task.time_expected !== null && 
                            <div className={'task-item'}>
                                <strong>Оценка трудозатрат: </strong>
                                {task.time_expected} ч
                                
                                {task.time_expected_by_params !== '' && task.time_expected_by_params !== null &&
                                    <>
                                        &emsp;
                                        <span className={'time-params-show'} onClick={() => (setIsOpenTimeParams(!isOpenTimeParams))}>
                                            {isOpenTimeParams ? 'Свернуть' : 'Развернуть'} параметры
                                        </span>
                                        <div className={'task-time_expected_by_params ' + (isOpenTimeParams && 'open-params')}>
                                            {JSON.parse(task.time_expected_by_params)?.map((timeParam: TimeParams)=>(
                                                <div className={'task-time-item'}>
                                                    <span className={'time_param'}>{timeParam.time_param}: </span>
                                                    <span className={'time_expected'}>{timeParam.time_expected} ч</span>
                                                </div>
                                            ))}
                                        </div>
                                    </>
                                }
                            </div>
                        }
                        {task.time_spent !== null &&
                            <div className={'task-item'}>
                                <strong>Tрудозатраты: </strong>
                                {task.time_spent} ч
                            </div>
                        }
                    </div>

                    <div className={'show-task-col-left'}>
                        {task.custom_attributes.map((attr) => (
                            <div className={'task-item'}>
                                <strong>{attr.title}: </strong>
                                <span>{attr.value}</span>
                            </div>
                        ))}
                    </div>

                    <div className={'show-task-col-center'}>
                        <div className={'task-item'}>
                            <p className={'task-text'}>
                                <Markdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeSlug, rehypeAutolinkHeadings, rehypeRaw]}>
                                    {task.text}
                                </Markdown>  
                            </p>
                        </div>
                    </div>

                    <div className={'show-task-col-center bottom-margin'}>
                        <div className={'task-item bottom-margin '}>
                            <div className={'task-relation ' +
                                (isOpenRelated ? `active ${task.related_tasks.length <= 4 ? numbersToText(task.related_tasks.length) : 'five-or-more'}` : '' ) }>
                                
                                <div className={'list-head-board pointer'} onClick={() => setIsOpenRelated(!isOpenRelated)}>
                                    <span className={'title-sprint'}>
                                        <h3>Связанные задачи</h3>
                                        <span>
                                            {isOpenRelated ? <span className={'list-button small'}>&and;</span> : <span className={'list-button small'}>&or;</span>}
                                        </span>
                                    </span>
                                </div>

                                <div className={'task-relation-info ' + (isOpenRelated ? 'active' : '')}>
                                    {task.related_tasks.length > 0 ? task.related_tasks.map((related_task)=> (
                                        <div className={'task-relation-item'}>
                                            <Link to={'/tasks/' + related_task.number} target={'_blank'} className={'go-to-related-task'}>
                                                &emsp;#{related_task.number}&ensp;{related_task.title}
                                            </Link>
                                            <button className={'del-related-task'}
                                                    onClick={() => handleDeleteRelatedTask(task.id, related_task.id)}>
                                                Удалить
                                            </button>&emsp;
                                        </div>
                                    ))
                                    :
                                        <span>&emsp;нет задач</span>
                                    }
                                </div>
                            </div>
                            {editAllowed &&
                                <div className={'add-relation-btn ' + (isOpenRelated ? 'active' : '')}>
                                    <RelatedTaskAdd setFetched={setFetched} parentTaskId={task.id} projectId={task.project_id} isSubTask={false} />
                                </div>}
                        </div>

                        <div className={'task-item bottom-margin '}>
                            <div className={'task-relation ' +
                                (isOpenSub ? `active ${task.subtasks.length <= 4 ? numbersToText(task.subtasks.length) : 'five-or-more'}` : '' ) }>
                                
                                <div className={'list-head-board pointer'} onClick={() => setIsOpenSub(!isOpenSub)}>
                                    <span className={'title-sprint'}>
                                        <h3>Подзадачи</h3>
                                        <span>
                                            {isOpenSub ? <span className={'list-button small'}>&and;</span> : <span className={'list-button small'}>&or;</span>}
                                        </span>
                                    </span>
                                </div>

                                <div className={'task-relation-info ' + (isOpenSub ? 'active' : '')}>
                                    {task.subtasks?.length > 0 ? task.subtasks.map((subtask)=> (
                                        <div className={'task-relation-item'}>
                                            <Link to={'/tasks/' + subtask.number} target={'_blank'} className={'go-to-related-task'}>
                                                &emsp;#{subtask.number}&ensp;{subtask.title}
                                            </Link>
                                            <button className={'del-related-task'}
                                                    onClick={() => handleDeleteSubTask(task.id, subtask.id)}>
                                                Удалить
                                            </button>&emsp;
                                        </div>
                                    ))
                                    :
                                        <span>&emsp;нет подзадач</span>
                                    }
                                </div>
                            </div>
                            {editAllowed &&
                                <div className={'add-relation-btn ' + (isOpenSub ? 'active' : '')}>
                                    <RelatedTaskAdd setFetched={setFetched} parentTaskId={task.id} projectId={task.project_id} isSubTask={true} />
                                </div>}
                        </div>
                    </div>

                    <strong className={'window-type-btn ' + (windowEditType === 'comments' ? 'active' : '')}
                            onClick={() => setWindowEditType('comments')}>История</strong>
                    <strong className={'window-type-btn ' + (windowEditType === 'time_entries' ? 'active' : '')}
                            onClick={() => setWindowEditType('time_entries')}>Трудозатраты</strong>
                    <strong className={'window-type-btn ' + (windowEditType === 'meets' ? 'active' : '')}
                            onClick={() => setWindowEditType('meets')}>Видеоконференции</strong>
                    {windowEditType === 'comments' &&
                        <Comments comments={task.comments}
                                  taskId={task.id}
                                  setFetched={setFetched}
                                  canEdit={editAllowed} />}
                    {windowEditType === 'time_entries' &&
                        <TimeEntries time_entries={task.time_entries}
                                     taskId={task.id}
                                     setFetched={setFetched} 
                                     canEdit={editAllowed} />}
                    {windowEditType === 'meets' &&
                        <TaskMeets task_meets={task.meets}
                                   taskId={task.id}
                                   projectId={task.project_id}
                                   setFetched={setFetched}
                                   canEdit={editAllowed} />}                 
                </div>
            ) : (
                <>
                    <CircularProgress/>
                </>
            )}
        </div>
    )
}

export default TaskShow
