// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navigation {
    margin-top: 1rem;
    text-overflow: ellipsis;
    overflow: hidden;
}

.itemNavigation {
    display: flex;
    align-items: center;
    text-align: left;
    width: 100%;
    height: 4rem;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    color: white;
    text-decoration: none;
    transition: width 0.3s ease;
}

.itemNavigationContent {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 3rem;
}

.itemNavigationContent.closed {
    padding-left: 0;
    margin: auto;
}

.navigation-item-title {
    margin-left: 1rem;
}

.itemNavigation.active {
    background: #fff;
    color: #273b64;
}
`, "",{"version":3,"sources":["webpack://./src/components/navigation/navigation.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,uBAAuB;IACvB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,gBAAgB;IAChB,WAAW;IACX,YAAY;IACZ,6BAA6B;IAC7B,gCAAgC;IAChC,YAAY;IACZ,qBAAqB;IACrB,2BAA2B;AAC/B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;IAChB,cAAc;AAClB","sourcesContent":[".navigation {\n    margin-top: 1rem;\n    text-overflow: ellipsis;\n    overflow: hidden;\n}\n\n.itemNavigation {\n    display: flex;\n    align-items: center;\n    text-align: left;\n    width: 100%;\n    height: 4rem;\n    border-top-right-radius: 25px;\n    border-bottom-right-radius: 25px;\n    color: white;\n    text-decoration: none;\n    transition: width 0.3s ease;\n}\n\n.itemNavigationContent {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    padding-left: 3rem;\n}\n\n.itemNavigationContent.closed {\n    padding-left: 0;\n    margin: auto;\n}\n\n.navigation-item-title {\n    margin-left: 1rem;\n}\n\n.itemNavigation.active {\n    background: #fff;\n    color: #273b64;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
