// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-container.task-list {
    min-height: 80vh;
    margin-bottom: 10px;
    height: auto;
}

.list-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    font-size: medium;
    width: 100%;
    height: 4rem;
    border: none;
    background-color: #fff;
    color: #273B64;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    z-index: 100;
    box-shadow: 0 8px 12px 0 rgba(0,0,0,0.2);
    padding: 0 1rem;
}

.list-button {
    font-size: larger;
    width: 2.5rem;
    height: 2.5rem;
    background-color: #273B64;
    color: #fff;
    border: none;
    outline: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease;
    border-radius: 100%;
}

.list-button.back {
    margin-right: 2.5rem;
}

.head-with-add-btn {
    margin-left: 5rem;
    margin-bottom: 1rem;
    width: 90%;
    display: flex;
    align-items: end;
    justify-content: space-between;
}
`, "",{"version":3,"sources":["webpack://./src/components/TasksListWithFilters/TasksListWithFilters.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,iBAAiB;IACjB,iBAAiB;IACjB,WAAW;IACX,YAAY;IACZ,YAAY;IACZ,sBAAsB;IACtB,cAAc;IACd,4BAA4B;IAC5B,6BAA6B;IAC7B,YAAY;IACZ,wCAAwC;IACxC,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,aAAa;IACb,cAAc;IACd,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,aAAa;IACb,eAAe;IACf,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,+BAA+B;IAC/B,mBAAmB;AACvB;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,iBAAiB;IACjB,mBAAmB;IACnB,UAAU;IACV,aAAa;IACb,gBAAgB;IAChB,8BAA8B;AAClC","sourcesContent":[".main-container.task-list {\n    min-height: 80vh;\n    margin-bottom: 10px;\n    height: auto;\n}\n\n.list-head {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    font-weight: bold;\n    font-size: medium;\n    width: 100%;\n    height: 4rem;\n    border: none;\n    background-color: #fff;\n    color: #273B64;\n    border-top-left-radius: 20px;\n    border-top-right-radius: 20px;\n    z-index: 100;\n    box-shadow: 0 8px 12px 0 rgba(0,0,0,0.2);\n    padding: 0 1rem;\n}\n\n.list-button {\n    font-size: larger;\n    width: 2.5rem;\n    height: 2.5rem;\n    background-color: #273B64;\n    color: #fff;\n    border: none;\n    outline: none;\n    cursor: pointer;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    transition: transform 0.3s ease;\n    border-radius: 100%;\n}\n\n.list-button.back {\n    margin-right: 2.5rem;\n}\n\n.head-with-add-btn {\n    margin-left: 5rem;\n    margin-bottom: 1rem;\n    width: 90%;\n    display: flex;\n    align-items: end;\n    justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
