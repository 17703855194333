import React from 'react';
import './ModalWindowEdit.css'

interface ModalProps {
    inputLabel: string;
    inputValue: string;
    setInputValue: (value: string) => void;
    onConfirm: () => void;
    onCancel: () => void;
}

const ModalWindowEdit: React.FC<ModalProps> = ({ inputValue, setInputValue, inputLabel, onConfirm, onCancel }) => {
    return(
        <div className={'modal-window'} onClick={onCancel}>
            <div className={'modal-content'} onClick={e => e.stopPropagation()}>
                
                <label className={'form-task-label'} htmlFor={'time_expected'}>{inputLabel}</label>
                <input className={'form-task-input inputs-edit'} name={'title-tracker'} placeholder={''}
                        value={inputValue} onChange={(e) => setInputValue(e.target.value)}/>


                <div className={'modal-btns'}>
                    <button className={'confirm-button-save'} onClick={onConfirm}>Сохранить</button>
                    <button className={'cancel-button-save'} onClick={onCancel}>Отмена</button>
                </div>
            </div>
        </div>
    )
}

export default ModalWindowEdit;