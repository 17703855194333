import React, {useEffect, useRef, useState} from "react";
import TaskSidebar from "../TaskSidebar";
import './NewTaskSmall.css'

interface NewTaskSmallProps {
    onSuccessCreatedTask: (fetched: boolean) => void;
}

const NewTaskSmall: React.FC<NewTaskSmallProps> = ({onSuccessCreatedTask}) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const handleOutsideClick = (event: MouseEvent) => {
        const target = event.target as HTMLElement;
        if (dropdownRef.current && !dropdownRef.current.contains(target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);


    const handleSidebarToggle = () => {
        setIsOpen(!isOpen);
    };

    return(
        <div className="new-task-small" ref={dropdownRef}>
            <span className="dropdown-toggle-small" onClick={handleSidebarToggle}>
                +
            </span>

            <TaskSidebar 
                isOpen={isOpen}
                sidebarType="new"
                onClose={() => setIsOpen(false)} 
                onSuccess={onSuccessCreatedTask} 
            />
        </div>
    )
}

export default NewTaskSmall