import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IPayment } from "../../common/types/tasks";
import { fetchSubscriptionStatus } from "../../utils/axios";
import { CircularProgress } from "@mui/material";

const SubscriptionStatus = () => {
    const navigate = useNavigate();
    const [fetched, setFetched] = useState<boolean>(false);
    const [isActive, setIsActive] = useState<boolean>();
    const [lastPayment, setLastPayment] = useState<IPayment>();
    const [paymentsHistory, setPaymentsHistory] = useState<IPayment[]>();
    const [errors, setErrors] = useState<string[]>([]);

    useEffect(() => {
        const getSubscription = async () => {
            const fetchedSubscription = await fetchSubscriptionStatus();
            if (fetchedSubscription) {
                setIsActive(fetchedSubscription.is_active)
                setLastPayment(fetchedSubscription.last_payment);
                setPaymentsHistory(fetchedSubscription.payments_history);
            }

            setFetched(true);
        }

        if (!fetched) {
            getSubscription();
        }
    }, [fetched]);

    return (
        <div className="main-container task-list">
            <div className={'form-head'}>
                <p></p>
                <button className={'back-button'} onClick={() => navigate(-1)}>
                    <span>&lt;</span>
                </button>
            </div>

            { fetched ?
                <div className={"show-settings-main"}>
                    <div className={"show-task-col-left"}>
                        { lastPayment &&
                            <>
                                <h2>Тариф</h2>

                                { !isActive && <h3 className={"red-text"}>Действие тарифа завершилось</h3> }

                                <div className={"tariff-card"}>
                                    <div className={"task-item"}>
                                        <h3 style={{"marginTop": 0}}>{lastPayment.tariff.name}</h3>
                                    </div> 

                                    <div className={"task-item"}>
                                        <strong>Начало: </strong>
                                        {lastPayment.start_at_formatted}
                                    </div>

                                    <div className={"task-item"}>
                                        <strong>Завершение: </strong>
                                        {lastPayment.end_at_formatted}
                                    </div>

                                    <div className={"task-item"}>
                                        <strong>Стоимость: </strong>
                                        {lastPayment.tariff.price}
                                    </div>

                                    <div className={"task-item"}>
                                        <strong>Количество пользователей: </strong>
                                        {lastPayment.tariff.max_users}{/* Сюда добавить бы еще кол во юзеров зареганных */}
                                    </div>
                                </div>
                            </>
                        }

                        <div>
                            <a className={"add-task-btn"} href="#">Оформить подписку</a>
                        </div>
                    </div>
                    
                    <div className={"show-task-col-right"}></div>
                    <br></br>

                    <div className={"show-task-col-center"}>
                        <h2>История подписок</h2>

                        <div className="tasks-small-table">
                            <div className="tasks-small-header">
                                <div className={"header-item"}>Тариф</div>
                                <div className={"header-item"}>Стоимость</div>
                                <div className={"header-item"}>Начало</div>
                                <div className={"header-item"}>Завершение</div>
                            </div>
                            <div className="tasks-small-body">
                                {paymentsHistory?.map((subscription) => (
                                        <div className="table-row">
                                            <div className={"body-item"}>{subscription.tariff.name}</div>
                                            <div className={"body-item"}>{subscription.tariff.price}</div>
                                            <div className={"body-item"}>{subscription.start_at_formatted}</div>
                                            <div className={"body-item"}>{subscription.end_at_formatted}</div>
                                        </div>
                                    ))
                                }                                
                            </div>
                        </div>
                    </div>
                </div>
                :
                <CircularProgress />
            }
        </div>
    )  
}

export default SubscriptionStatus
