import TasksTable from "../../components/TasksTable";
import {FC, useEffect, useState} from "react";
import {IPaginatedResponse} from "../../common/types/tasks";
import {fetchHomeTasks, fetchTasks} from "../../utils/axios";
import {CircularProgress} from "@mui/material";
import Pagination from "../../components/Pagination";
import { convertSearchToParam, getInitialSearchParams, isPresent, searchEntries } from "../../utils/utils";

type HomeTasksListProps = {
    endpoint: string;
    data: IPaginatedResponse;
};

const HomeTasksList: FC<HomeTasksListProps> = ({ endpoint, data }) => {
    const [fetched, setFetched] = useState<boolean>(true);
    const [tasksData, setTasksData] = useState<IPaginatedResponse>(data);
    const [localParams, setLocalParams] = useState("");
    const [paginateData, setPaginateData] = useState<{ page: string; page_size: string; }>({
        page: getInitialSearchParams("page"),
        page_size: getInitialSearchParams("page_size")
    });

    useEffect(() => {
        if (isPresent(paginateData.page) || isPresent(paginateData.page_size))
        handleFilterApply();
    }, [paginateData]);

    useEffect(() => {
        const getTasks = async () => {
            try {
                const fetchedTasks = await fetchHomeTasks(endpoint, localParams);
                setTasksData(fetchedTasks);
            } catch (error) {
                console.error(error);
            }
            setFetched(true);
        };

        if (!data) {
            setFetched(false);
        } else {
            setFetched(true);
        }

        if (!fetched) {
            getTasks();
        }
    }, [fetched]);

    const handlePrevNextBtn = (paginate_params: string) => async () => {
        try {
            const url_params = new URLSearchParams(paginate_params);   
            setPaginateData({
                page: url_params.get("page") || "",
                page_size: url_params.get("page_size") || ""
            });
        } catch (error) {
            console.error(error);
        }
    }

    const handleFilterApply = async () => {
        try {
            const paginate_params = Object.entries(paginateData).reduce((acc, [filter, value]) => acc + convertSearchToParam(value, searchEntries[filter].param_name), "");
            setLocalParams(paginate_params);
            setFetched(false);
        } catch (error) {
            console.error(error);
        }
    };

    return(
        <>
            {fetched ?
                <>
                    <TasksTable tasks={tasksData.objects} scrollable={false} />
                    <Pagination data={tasksData} onPrevNext={handlePrevNextBtn} />
                </>
                :
                <CircularProgress/>
            }
        </>
    )

}

export default HomeTasksList