import React, {useEffect, useRef, useState} from "react";
import TaskFindSmall from "../TaskFindSmall";
import TaskFormSmall from "../TaskFormSmall";
import {BoardColumn, BoardTaskItem} from "../../common/types/boards";

type FindOrCreateType = "find" | "create";

interface TaskFindOrCreateProps {
    onSuccessCreatedColumnTask: (column_index: string, task_id: number, column: BoardColumn) => void;
    columnId: number;
    columnStatus: string;
    boardId: number;
    projectId: number;
}

const TaskFindOrCreate: React.FC<TaskFindOrCreateProps> = (
    {onSuccessCreatedColumnTask, columnId, columnStatus, boardId, projectId}
) => {
    const [formType, setFormType] = useState<FindOrCreateType>("create");
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const handleDropdownToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleOutsideClick = (event: MouseEvent) => {
        const target = event.target as HTMLElement;
        if (dropdownRef.current && !dropdownRef.current.contains(target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    return (
        <div className="new-task-small" ref={dropdownRef}>
            <span className={'add-task-btn full-width ' + (isOpen ? 'active' : '')} onClick={handleDropdownToggle}>
                Добавить задачу
            </span>

            <div className={'dropdown-menu-small without-padding ' + (isOpen ? 'active' : '')}>
                <div className="form-type-dropdown-menu">
                    <button
                        className={"form-type-dropdown-button " + (formType === "find" ? "active" : "")}
                        onClick={() => setFormType("create")}
                    >
                      Новая
                    </button>
                    <button
                        className={"form-type-dropdown-button " + (formType === "create" ? "active" : "")}
                        onClick={() => setFormType("find")}
                    >
                      Существующая
                    </button>
                </div>
                <div className={'form-small-padding'}>
                    {formType === "find" && <TaskFindSmall onSuccessCreatedColumnTask={onSuccessCreatedColumnTask}
                                                           columnId={columnId} columnStatus={columnStatus}
                                                           boardId={boardId} projectId={projectId} />}
                    {formType === "create" && <TaskFormSmall onSuccessCreatedColumnTask={onSuccessCreatedColumnTask}
                                                             columnId={columnId} columnStatus={columnStatus}
                                                             boardId={boardId} projectId={projectId} />}
                </div>
            </div>
        </div>
    );
}

export default TaskFindOrCreate
