import Layout from "../../components/layout";
import ProjectsTable from "../../components/ProjectsTable";
import {useEffect, useState} from "react";
import {fetchProjects} from "../../utils/axios";
import {IPaginatedResponse} from "../../common/types/tasks";
import {CircularProgress} from "@mui/material";
import Pagination from "../../components/Pagination";
import {convertSearchToParam, getInitialSearchParams, isPresent, searchEntries, setParams} from "../../utils/utils";


const ProjectsList = ({ganttAllowed} : {ganttAllowed: boolean}) => {
    const [fetched, setFetched] = useState<boolean>();
    const [projectsData, setProjectsData] = useState<IPaginatedResponse>({ objects: [], previous: '', next: '', current_page: 0, count_objects: 0, count_pages: 0 });

    // const [searchData, setSearchData] = useState<{ title: string; }>({ title: getInitialSearchParams('title') });

    const [paginateData, setPaginateData] = useState<{ page: string; page_size: string; }>({
        page: getInitialSearchParams('page'),
        page_size: getInitialSearchParams('page_size')
    });

    useEffect(() => {
        const getSprints = async () => {
            try {
                const fetchedProjects = await fetchProjects();
                setProjectsData(fetchedProjects);
            } catch (error) {
                console.error(error);
            }
            setFetched(true);
        };
        if (!fetched)
            getSprints();
    }, [fetched]);

    const handlePrevNextBtn = (paginate_params: string) => async () => {
        try {
            const url_params = new URLSearchParams(paginate_params);   
            setPaginateData({
                page: url_params.get('page') || '',
                page_size: url_params.get('page_size') || ''
            });
        } catch (error) {
            console.error(error);
        }
    }

    const handleFilterApply = async () => {
        try {
            const params = getProjectsParams();
            setParams(params);
            setFetched(false);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (isPresent(paginateData.page) || isPresent(paginateData.page_size))
        handleFilterApply();
    }, [paginateData]);

    const getProjectsParams = () => {
        return(
            // Object.entries(searchData).reduce((acc, [filter, value]) => acc + convertSearchToParam(value, searchEntries[filter].param_name), '') +
            Object.entries(paginateData).reduce((acc, [filter, value]) => acc + convertSearchToParam(value, searchEntries[filter].param_name), '')
        )
    }

    return(
        <div className={'main-container task-list'}>
            {fetched ?
                <>
                    <ProjectsTable projects={projectsData.objects} ganttAllowed={ganttAllowed} />
                    <Pagination data={projectsData} onPrevNext={handlePrevNextBtn} />
                </>
            :
                <CircularProgress/>
            }
        </div>
    )
}

export default ProjectsList
