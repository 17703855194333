import React from "react";
import RightSidebar from "../RightSidebar";
import TaskNewSidebar from "../TaskNewSidebar";
import TaskEditSidebar from "../TaskEditSidebar";
import { ITask } from "../../common/types/tasks";
import "./TaskSidebar.css"

type sidebarType = "edit" | "new";

interface TaskSidebarProps {
  isOpen: boolean;
  sidebarType: sidebarType;
  taskCurrentProps?: ITask | null;
  onUpdateTaskProps?: (task: ITask) => void;
  onClose: () => void;
  onSuccess: (fetched: boolean) => void;
}

const TaskSidebar: React.FC<TaskSidebarProps> = ({
    isOpen,
    sidebarType,
    taskCurrentProps,
    onClose,
    onSuccess,
    onUpdateTaskProps,
}) => {
    return (
    <RightSidebar isOpen={isOpen} onClose={onClose}>
		{sidebarType === "new" && <TaskNewSidebar onSuccess={onSuccess} onClose={onClose} />}
		{sidebarType === "edit" && taskCurrentProps && onUpdateTaskProps && (
            <TaskEditSidebar
                onSuccess={onSuccess}
                onClose={onClose}
                taskCurrentProps={taskCurrentProps}
                onUpdateTaskProps={onUpdateTaskProps}
            />
        )}
    </RightSidebar>
  );
};

export default TaskSidebar;
