import BoardShow from "../../components/BoardShow";
import BoardsList from "../../components/BoardsList";
import BoardNew from "../../components/BoardNew";
import Layout from "../../components/layout";
import { useParams } from "react-router-dom";
import { useActionIsAllowed } from "../../utils/hook";

type BoardPageType = 'list' | 'show' | 'new';

const Boards = ({typePage} : {typePage: BoardPageType}) => {
    const {board_number} = useParams();

    const boardShowAllowed = useActionIsAllowed('board_show');
    const boardCreateAllowed = useActionIsAllowed('board_create');

    return(
        <Layout title="Доски" addButton={typePage === 'list' && boardCreateAllowed ? 'addBoard' : ''}>
            {typePage === 'list' && boardShowAllowed && <BoardsList />}
            {typePage === 'show' && boardShowAllowed && board_number && <BoardShow boardNumber={board_number} />}
            {typePage === 'new' && boardCreateAllowed && <BoardNew />}
        </Layout>
    )
}

export default Boards
