import { FC } from "react";

interface SearchMultiHeadProps {
    searchData: Record<string, string>;
    onSearchFilled: (filter: string, value: string) => void;
    onApply: () => void;
}

const SearchMultiHead: FC<SearchMultiHeadProps> = ({ searchData, onSearchFilled, onApply }) => {
    const searchFilters = ['title', 'name'];

    const handleChange = (filter: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        onSearchFilled(filter, event.target.value);
    };

    return (
        <div className={'search-field-with-btn'}>
            {Object.entries(searchData)
                .filter(([key]) => searchFilters.includes(key))
                .map(([filter, value]) => (
                    <input
                        key={filter}
                        className={'search-filter'}
                        placeholder={'Поиск'}
                        value={value}
                        onChange={handleChange(filter)}
                    />
                ))
            }
            <button type={'button'} className={'list-find-btn-with-icon'} onClick={onApply}>
                <img src={'/search_icon.png'} alt="Search Icon" />
            </button>
        </div>
    );
};

export default SearchMultiHead;
