import {Draggable, Droppable} from "react-beautiful-dnd";
import {Link} from "react-router-dom";
import {ITask} from "../../common/types/tasks";

interface TasksListDroppableProps {
    tasks: ITask[];
    droppableId: string;
    prev?: string;
    next?: string;
}

const TasksListDroppable: React.FC<TasksListDroppableProps> = ({tasks, droppableId, prev, next}) => {
    return(
        <Droppable droppableId={droppableId}>
            {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef} className={'droppable-sprints' + (droppableId == 'droppable1' ? ' full-height' : '')}>
                    {tasks.map(({ number, title }, index) => (
                        <Draggable key={number} draggableId={number.toString()} index={index}>
                            {(provided) => (
                                <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef} className={'sprint-task'}>
                                    <p key={index}>
                                        <Link to={'/tasks/'+number}
                                              target={'_blank'}
                                              // title={'Открыть в новой вкладке'}
                                              className={'link-to'}>
                                            #{number} {title}
                                        </Link>
                                    </p>
                                </div>
                            )}
                        </Draggable>
                    ))}
                    {provided.placeholder}
                </div>
            )}
        </Droppable>
    )
}

export default TasksListDroppable