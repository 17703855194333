// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.settings-container {
  margin: 2rem;
}

.setting-list-btn {
  text-align: left;
  border-radius: 10px;
  height: 3rem;
  width: 90%;
  border: 1px solid #273b64;
  background-color: #fff;
  color: #273b64;
  font-size: large;
  cursor: pointer;
  transition: background-color 0.2s 0.1ms, color 0.1s 0.1ms;
}

.setting-list-btn:hover{
  transition: background-color 0.2s 0.1ms, color 0.1s 0.1ms;
  color: #fff;
  background-color: #273b64;
}

.del-task-btn{
  text-align: center;
  font-size: 16px;
  font-weight: normal;
  border-radius: 10px;
  border: none;
  background-color: #992c30;
  color: #ffffff;
  width: 12rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
}

.red-text {
  color: #992c30;
}

.show-settings-main {
  display: flex;
  padding: 1rem 4rem;
  flex-wrap: wrap;
}`, "",{"version":3,"sources":["webpack://./src/pages/WorkspaceSetting/WorkspaceSettings.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,YAAY;EACZ,UAAU;EACV,yBAAyB;EACzB,sBAAsB;EACtB,cAAc;EACd,gBAAgB;EAChB,eAAe;EACf,yDAAyD;AAC3D;;AAEA;EACE,yDAAyD;EACzD,WAAW;EACX,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,mBAAmB;EACnB,mBAAmB;EACnB,YAAY;EACZ,yBAAyB;EACzB,cAAc;EACd,YAAY;EACZ,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;EACf,qBAAqB;AACvB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,eAAe;AACjB","sourcesContent":[".settings-container {\n  margin: 2rem;\n}\n\n.setting-list-btn {\n  text-align: left;\n  border-radius: 10px;\n  height: 3rem;\n  width: 90%;\n  border: 1px solid #273b64;\n  background-color: #fff;\n  color: #273b64;\n  font-size: large;\n  cursor: pointer;\n  transition: background-color 0.2s 0.1ms, color 0.1s 0.1ms;\n}\n\n.setting-list-btn:hover{\n  transition: background-color 0.2s 0.1ms, color 0.1s 0.1ms;\n  color: #fff;\n  background-color: #273b64;\n}\n\n.del-task-btn{\n  text-align: center;\n  font-size: 16px;\n  font-weight: normal;\n  border-radius: 10px;\n  border: none;\n  background-color: #992c30;\n  color: #ffffff;\n  width: 12rem;\n  height: 2rem;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n  text-decoration: none;\n}\n\n.red-text {\n  color: #992c30;\n}\n\n.show-settings-main {\n  display: flex;\n  padding: 1rem 4rem;\n  flex-wrap: wrap;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
