import axios from "axios";
import {
    BoardColumn,
    BoardData,
    ICloseSprintStat,
    IMoveOptions,
    IBoard,
    ISprintBoard,
    ITaskColumnsUpdated,
    ITaskColumnUpdated, ITaskDeletedColumnUpdated,
    IBoardList
} from "../../common/types/boards";
import {
    IComment,
    ICreateComment, ICreateProject,
    ICreateSprint,
    IPaginatedResponse, IProfile, IProject,
    IProjectShowResponse,
    ISprint,
    IStatus,
    ITask,
    ITaskCreate,
    ITaskNew,
    ITaskShowResponse,
    ITasksToSprintResponse, ITimeEntries, ITimeEntriesCreate,
    IUpdateSprint, IUser,
    IHomePageResponse, IHomePageUpdate, IHomePageProps, GanttData,
    ITaskUpdateGantt, IWikiPage, IWikiPageCreateUpdate,
    IMeetCreate,
    IUserCreateUpdate,
    InvitedUserCreate,
    InvitedUser,
    ICreateBoard,
    IGanttReponse,
    IRole,
    ITaskListResponse,
    IProjectNewResponse,
    IProjectEditResponse,
    ITracker,
    IComplexity,
    ICalendarEvent,
    IUpdateEvent,
    IPayment,
    IPaymentStatus,
    ITaskUpdate
} from "../../common/types/tasks";
import { IPublicUser, IWorkspaceAndUser } from "../../common/types/auth";
import { logout } from "../../store/slice/auth";
import store from '../../store';
import { setPermissionsData } from '../../store/slice/permissions';
import { IMeet, IMeetCreateUpdate } from "../../common/types/meets";

export const instanceWithoutPermissions = axios.create({
    baseURL: `https://${process.env.REACT_APP_API_URL}/api/v1`,
    timeout: 1000,
    headers: {
        "Accept": "application/json",
        "Auth-Key": process.env.REACT_APP_API_KEY
    }
});

export const instance = axios.create({
    baseURL: `https://${process.env.REACT_APP_API_URL}/api/v1`,
    timeout: 1000,
    headers: {
        "Accept": "application/json",
        "Auth-Key": process.env.REACT_APP_API_KEY
    }
});

instanceWithoutPermissions.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (error.response) {
            switch (error.response.status) {
                case 401:
                    store.dispatch(logout());
                    break;
                // case 402:
                //     window.location.href = '/app/settings/subscription/';
                //     break;
                // case 403:
                //     window.location.href = '/app/error/403/';
                //     break;
                // case 404:
                //     window.location.href = '/app/error/404/';
                //     break;
                // case 422:
                //     return error.response;
                // case 500:
                //     window.location.href = '/app/error/500/';
                //     break;
                default:
                    break;
            }
        }
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    async function (response) {
        const localVersion = store.getState()?.permissions?.version;
        const serverVersion = response.headers['x-data-version'];
        if (localVersion !== serverVersion) {
            const permissionsResponse = await fetchPermissions();
            store.dispatch(setPermissionsData({
                role: permissionsResponse.role,
                permissions: permissionsResponse.permissions,
                version: permissionsResponse.version,
            }));
        }

        return response;
    },
    function (error) {
        if (error.response) {
            switch (error.response.status) {
                case 401:
                    store.dispatch(logout());
                    break;
                case 402:
                    window.location.href = '/app/settings/subscription/';
                    break;
                case 403:
                    window.location.href = '/app/error/403/';
                    break;
                // case 404:
                //     window.location.href = '/app/error/404/';
                //     break;
                case 422:
                    return error.response;
                    break;
                // case 500:
                //     window.location.href = '/app/error/500/';
                //     break;
                default:
                    break;
            }
        }
        return Promise.reject(error);
    }
);

export const fetchPermissions = async (): Promise<{ role: string, permissions: string[], version: string }> => {
    try {
        const response = await instanceWithoutPermissions.get<{ role: string, permissions: string[], version: string }>(`users/permissions/`);
        return response.data;
    } catch (error) {
        console.error(error)
        throw new Error('Failed to fetch permissions');
    }
}

export const fetchTasks = async (
    { projects, trackers, statuses, priorities, sprints, params }:
        {
            projects?: number[],
            trackers?: number[],
            statuses?: number[],
            priorities?: number[],
            sprints?: number[],
            params?: string
        } = {}): Promise<ITaskListResponse> => {
    try {
        const response =
            await instance.get<ITaskListResponse>('/tasks/?' + (params ? params : ''), {
                params: {
                    project: projects ? projects.join(',') : undefined,
                    tracker: trackers ? trackers.join(',') : undefined,
                    status: statuses ? statuses.join(',') : undefined,
                    priority: priorities ? priorities.join(',') : undefined,
                    sprint: sprints ? sprints.join(',') : undefined
                }
            });

        return response.data;
    } catch (error) {
        console.error(error)
        throw new Error('Failed to fetch tasks');
    }
};

export const fetchHomeTasks = async (endpoint: string, params?: string): Promise<IPaginatedResponse> => {
    try {
        const response =
            await instance.get<IPaginatedResponse>(`/${endpoint}/?` + (params ? params : ''));

        return response.data;
    } catch (error) {
        console.error(error)
        throw new Error('Failed to fetch tasks');
    }
};

export const fetchTask = async (id: number): Promise<ITaskShowResponse> => {
    try {
        const response = await instance.get<ITaskShowResponse>(`/tasks/${id}/`);
        return response.data;
    } catch (error) {
        console.error(error)
        throw new Error('Failed to fetch tasks');
    }
};

export const fetchTaskEdit = async (number: number): Promise<{ task: ITask, task_props: ITaskNew }> => {
    try {
        const response = await instance.get<{ task: ITask, task_props: ITaskNew }>(`/tasks/${number}/edit`);
        return response.data;
    } catch (error) {
        console.error(error)
        throw new Error('Failed to fetch tasks');
    }
};

export const fetchTaskNew = async (): Promise<ITaskNew> => {
    try {
        const response = await instance.get<ITaskNew>('/tasks/new/');
        return response.data;
    } catch (error) {
        console.error(error)
        throw new Error('Failed to fetch tasks init data');
    }
};

export const createTaskNew = async (data: ITaskCreate): Promise<{ task?: ITask, errors?: string[] }> => {
    const response = await instance.post<{ errors?: string[] }>('/tasks/', data);
    if (response.status === 201) {
        return response.data;
    } else {
        throw new Error(JSON.stringify(response.data));
    }
};

export const updateTask = async (id: number, data: ITaskCreate | ITaskUpdate): Promise<{ task?: ITask, errors?: string[] }> => {
    const response = await instance.patch<{ task?: ITask, errors?: string[] }>(`/tasks/${id}/`, data);
    if (response.status === 200) {
        return response.data;
    } else {
        throw new Error(JSON.stringify(response.data));
    }
};

export const updateTaskGantt = async (id: string, data: ITaskUpdateGantt): Promise<{ task: GanttData, errors?: string[] }> => {
    const response = await instance.patch<{ task: GanttData, errors?: string[] }>(`/tasks/${id}/update_gantt`, data);
    if (response) {
        return response.data;
    } else {
        throw new Error(JSON.stringify(response));
    }
};

export const createTaskMeet = async (taskId: number, data: IMeetCreate): Promise<{ errors?: string[] }> => {
    const response = await instance.post<{ errors?: string[] }>(`/tasks/${taskId}/meets`, data);
    if (response.status === 201) {
        return response.data;
    } else {
        throw new Error(JSON.stringify(response.data));
    }
};

export const createComment = async (taskId: number, data: ICreateComment): Promise<{ comment: IComment }> => {
    const response = await instance.post<{ comment: IComment }>(`/tasks/${taskId}/comments/`, data);
    if (response.status === 201) {
        return response.data;
    } else {
        throw new Error(JSON.stringify(response.data));
    }
};

export const editComment = async (taskId: number, commentId: number, data: ICreateComment): Promise<{}> => {
    const response = await instance.patch<{ comment: IComment }>(`/tasks/${taskId}/comments/${commentId}/edit`, data);
    if (response.status === 200) {
        return response;
    } else {
        throw new Error(JSON.stringify(response));
    }
};

export const createTimeEntries = async (taskId: number, data: ITimeEntriesCreate): Promise<{ time_entry: ITimeEntries }> => {
    const response = await instance.post<{ time_entry: ITimeEntries }>(`/tasks/${taskId}/time_entries/`, data);
    if (response.status === 201) {
        return response.data;
    } else {
        throw new Error(JSON.stringify(response.data));
    }
};

export const editTimeEntries = async (taskId: number, timeEntriesId: number, data: ITimeEntriesCreate): Promise<{}> => {
    const response = await instance.patch<{ time_entry: ITimeEntries }>(`/tasks/${taskId}/time_entries/${timeEntriesId}/edit`, data);
    if (response.status === 200) {
        return response;
    } else {
        throw new Error(JSON.stringify(response));
    }
};

export const createRelatedTask = async (taskId: number, taskRelatedId: number): Promise<{}> => {
    const response = await instance.post<{}>(`/tasks/${taskId}/add_related_task/?related_task_id=${taskRelatedId}`);
    if (response.status === 201) {
        return response.data;
    } else {
        throw new Error(JSON.stringify(response.data));
    }
};

export const deleteRelatedTask = async (taskId: number, taskRelationId: number): Promise<{}> => {
    const response = await instance.delete<{}>(`/tasks/${taskId}/delete_related_task/?task_relation_id=${taskRelationId}`);
    if (response.status === 200) {
        return response.data;
    } else {
        throw new Error(JSON.stringify(response.data));
    }
};

export const createSubTask = async (taskId: number, taskRelatedId: number): Promise<{}> => {
    const response = await instance.post<{}>(`/tasks/${taskId}/add_sub_task/?sub_task_id=${taskRelatedId}`);
    if (response.status === 201) {
        return response.data;
    } else {
        throw new Error(JSON.stringify(response.data));
    }
};

export const deleteSubTask = async (taskId: number, subTaskId: number): Promise<{}> => {
    const response = await instance.delete<{}>(`/tasks/${taskId}/delete_sub_task/?sub_task_id=${subTaskId}`);
    if (response.status === 201) {
        return response.data;
    } else {
        throw new Error(JSON.stringify(response.data));
    }
};


export const fetchBoardTasks = async (): Promise<BoardData> => {
    try {
        const response = await instance.get<BoardData>('/tasks/?board=true');
        return response.data;
    } catch (error) {
        console.error(error)
        throw new Error('Failed to fetch board');
    }
};

export const fetchSprints = async ({ params }: { params?: string }): Promise<{ sprints: IPaginatedResponse, filter_data: { projects: IProject[] } }> => {
    try {
        const response = await instance.get<{ sprints: IPaginatedResponse, filter_data: { projects: IProject[] } }>(`/sprints/?` + (params ? params : ''));
        return response.data;
    } catch (error) {
        console.error(error)
        throw new Error('Failed to fetch sprints');
    }
}

export const fetchSprint = async (id: number): Promise<{ sprint: ISprint }> => {
    try {
        const response = await instance.get<{ sprint: ISprint }>(`/sprints/${id}/`);
        return response.data;
    } catch (error) {
        console.error(error)
        throw new Error('Failed to fetch sprints');
    }
}

export const fetchSprintEdit = async (id: number): Promise<{ sprint: ISprint }> => {
    try {
        const response = await instance.get<{ sprint: ISprint }>(`/sprints/${id}/edit`);
        return response.data;
    } catch (error) {
        console.error(error)
        throw new Error('Failed to fetch sprints');
    }
}

export const createSprint = async (data: ICreateSprint): Promise<{sprint?: ISprint, errors: string[] }> => {
    try {
        const response = await instance.post<{sprint?: ISprint, errors: string[] }>(`/sprints/`, data);
        return response.data;
    } catch (error) {
        console.error(error)
        throw new Error('Failed to create sprint');
    }
}

export const updateSprint = async (id: number, data: IUpdateSprint): Promise<{sprint?: ISprint, errors: string[] }> => {
    try {
        const response = await instance.patch<{sprint?: ISprint, errors: string[] }>(`/sprints/${id}/`, data);
        return response.data;
    } catch (error) {
        console.error(error)
        throw new Error('Failed to fetch sprints');
    }
}

export const fetchSprintStatClose = async (id: number): Promise<{ move_options: IMoveOptions[], tasks_data: ICloseSprintStat }> => {
    try {
        const response = await instance.get<{ move_options: IMoveOptions[], tasks_data: ICloseSprintStat }>(`/sprints/${id}/close_sprint_stat`);
        return response.data;
    } catch (error) {
        console.error(error)
        throw new Error('Failed to fetch sprints');
    }
}

export const closeUpdateSprint = async (id: number, moveOption?: string): Promise<{sprint?: ISprint, errors: string[] }> => {
    try {
        const response = await instance.post<{sprint?: ISprint, errors: string[] }>(`/sprints/${id}/close_sprint`, { move_option: moveOption });
        return response.data;
    } catch (error) {
        console.error(error)
        throw new Error('Failed to fetch sprints');
    }
}

export const fetchSprintBoard = async (id: number): Promise<ISprintBoard> => {
    try {
        const response = await instance.get<ISprintBoard>(`/sprints/${id}/board/`);
        return response.data;
    } catch (error) {
        console.error(error)
        throw new Error('Failed to fetch sprints board');
    }
}

export const fetchBoard = async (id: string): Promise<IBoard> => {
    try {
        const response = await instance.get<IBoard>(`/boards/${id}/`);
        return response.data;
    } catch (error) {
        console.error(error)
        throw new Error('Failed to fetch sprints board');
    }
}

export const createTaskColumnNew = async (board_id: number, data: ITaskCreate): Promise<ITaskColumnUpdated> => {
    try {
        const response = await instance.patch(`boards/${board_id}/add_task/`, data);
        return response.data;
    } catch (error) {
        console.error(error)
        throw new Error('Failed to create new task in column');
    }
};

export const createTaskColumnExists = async (board_id: number, task_id: number): Promise<ITaskColumnUpdated> => {
    try {
        const response = await instance.patch(`boards/${board_id}/add_task/?task_id=${task_id}`);
        return response.data;
    } catch (error) {
        console.error(error)
        throw new Error('Failed to create new task in column');
    }
};

export const updateTaskColumn = async (old_column_id: number, task_id: number, data: {
    id: number, column_id: number, task_id: number, order_number: number
}): Promise<ITaskColumnsUpdated> => {
    try {
        const response = await instance.patch(`boards/${old_column_id}/${task_id}/update_columns/`, data);
        return response.data;
    } catch (error) {
        console.error(error)
        throw new Error('Failed to updateTaskColumn');
    }
}

export const deleteTaskColumn = async (board_id: number, column_task_id: number): Promise<ITaskDeletedColumnUpdated> => {
    try {
        const response = await instance.delete<ITaskDeletedColumnUpdated>(`boards/column_tasks/${column_task_id}/?id=${board_id}`);
        return response.data;
    } catch (error) {
        console.error(error)
        throw new Error('Failed to deleteTaskColumn');
    }
}

export const updateTaskColumnOrder = async (column_id: number, task_id: number, data: {
    id: number, new_order_number: number, order_dir: string, order_end: number
}): Promise<ITaskColumnUpdated> => {
    try {
        const response = await instance.patch(`boards/${column_id}/${task_id}/update_order/`, data);
        return response.data;
    } catch (error) {
        console.error(error)
        throw new Error('Failed to updateTaskColumnOrder');
    }
}

export const createColumnInBoard = async (board_id: number, data: {
    status_id_to_create: number
}): Promise<{ column: BoardColumn, errors?: string[] }> => {
    try {
        const response = await instance.post<{ column: BoardColumn, errors?: string[] }>(`boards/${board_id}/columns/column_create`, data);
        return response.data;
    } catch (error) {
        console.error(error)
        throw new Error('Failed to createColumnInBoard');
    }
}

export const deleteColumnInBoard = async (board_id: number, column_id: number): Promise<{ deleted_status: IStatus, default_column: BoardColumn, errors?: string[] }> => {
    try {
        const response = await instance.delete(`boards/columns/${column_id}/column_delete?id=${board_id}`);
        return response.data;
    } catch (error) {
        console.error(error)
        throw new Error('Failed to deleteColumnInBoard');
    }
}

export const hideColumnInBoard = async (method: string, board_id: number, column_id: number): Promise<{ column: BoardColumn, errors?: string[] }> => {
    try {
        const response = await instance.post<{ column: BoardColumn, errors?: string[] }>(`boards/columns/${column_id}/${method}?id=${board_id}`);
        return response.data;
    } catch (error) {
        console.error(error)
        throw new Error('Failed to hideColumnInBoard');
    }    
}

export const fetchTasksToSprint = async (
    { params, excluded }: { params?: string, excluded?: number[] } = {}): Promise<ITasksToSprintResponse> => {
    try {
        const response = await instance.get<ITasksToSprintResponse>('/tasks/backlog' + (params ? params : '')
            + (excluded ? `&excluded=${excluded.join(',')}` : '')
        );
        return response.data;
    } catch (error) {
        console.error(error)
        throw new Error('Failed to fetch tasks to sprint');
    }
};

export const fetchTasksForRelation = async (
    { params }: { params?: string } = {}): Promise<ITaskListResponse> => {
    try {
        const response = await instance.get<ITaskListResponse>('/tasks/available_for_relation' + (params ? params : ''));
        return response.data;
    } catch (error) {
        console.error(error)
        throw new Error('Failed to fetchTasksForRelation');
    }
};

export const fetchProjects = async (
    params?: string
): Promise<IPaginatedResponse> => {
    try {
        const response = await instance.get<IPaginatedResponse>('/projects/?' + (params ? params : ''));
        return response.data;
    } catch (error) {
        console.error(error)
        throw new Error('Failed to fetch projects');
    }
}

export const fetchProject = async (id: number): Promise<{project: IProjectShowResponse}> => {
    try {
        const response = await instance.get<{project: IProjectShowResponse}>(`/projects/${id}/?`);
        return response.data;
    } catch (error) {
        console.error(error)
        throw new Error('Failed to fetch project');
    }
}

export const fetchProjectEdit = async (id: number): Promise<IProjectEditResponse> => {
    try {
        const response = await instance.get<IProjectEditResponse>(`/projects/${id}/edit?`);
        return response.data;
    } catch (error) {
        console.error(error)
        throw new Error('Failed to fetch project');
    }
}

export const fetchProjectNew = async (): Promise<IProjectNewResponse> => {
    try {
        const response = await instance.get<IProjectNewResponse>(`/projects/new`);
        return response.data;
    } catch (error) {
        console.error(error)
        throw new Error('Failed to fetch project');
    }
}

export const fetchProfile = async (): Promise<IProfile> => {
    try {
        const response = await instance.get<IProfile>(`/users/profile/?`);
        return response.data;
    } catch (error) {
        console.error(error)
        throw new Error('Failed to fetch profile');
    }
}

export const updateProfile = async (profile: IUser): Promise<IProfile> => {
    try {
        const response = await instance.patch<IProfile>(`/users/current_user_update/?`, { user: profile });
        return response.data;
    } catch (error) {
        console.error(error)
        throw new Error('Failed to fetch profile');
    }
}

export const signIn = async (userData: { workspace: string, email: string, password: string }): Promise<IPublicUser> => {
    try {
        const response = await instanceWithoutPermissions.post<IPublicUser>('/users/sign_in', { user: userData });
        return response.data;
    } catch (error) {
        console.error(error)
        throw new Error('Failed to sign in');
    }
}


export const createInvitedUser = async (userData: InvitedUserCreate): Promise<InvitedUser> => {
    try {
        const response = await instance.post<InvitedUser>(`/users/invite/create`, { ...userData });
        return response.data;
    } catch (error) {
        console.error(error)
        throw new Error('Failed to createInvitedUser');
    }
}

export const createUserAndWorkspace = async (workspaceData: { title: string }, userData: { first_name: string, last_name: string, email: string, password: string }): Promise<IWorkspaceAndUser> => {
    try {
        const response = await instanceWithoutPermissions.post<IWorkspaceAndUser>('/workspaces/', { workspace: workspaceData, user: userData });
        return response.data;
    } catch (error) {
        console.error(error)
        throw new Error('Failed to create ws');
    }
}

export const signOut = async (): Promise<number> => {
    try {
        const response = await instanceWithoutPermissions.delete<number>('/users/sign_out');
        return response.status;
    } catch (error) {
        console.error(error)
        throw new Error('Failed to sign out');
    }
}

export const updatePassword = async (current_password: string, password: string, password_confirmation: string): Promise<{ errors?: string[], status?: string }> => {
    try {
        const response = await instance.post<{ errors?: string[], status?: string }>('/users/update_password', {
            user: {
                current_password,
                password,
                password_confirmation
            }
        });
        return response.data;
    } catch (error) {
        console.error(error)
        throw new Error('Failed to updatePassword');
    }
}

export const createProject = async (data: ICreateProject): Promise<{ project?: IProject, errors?: string[] }> => {
    try {
        const response = await instance.post<{ project?: IProject, errors?: string[] }>('/projects/', { project: data });
        return response.data;
    } catch (error) {
        console.error(error)
        throw new Error('Failed to fetch tasks to sprint');
    }
};

export const updateProject = async (id: number, data: ICreateProject): Promise<{ project?: IProject, errors?: string[] }> => {
    try {
        const response = await instance.patch<{ project?: IProject, errors?: string[] }>(`/projects/${id}`, { project: data });
        return response.data;
    } catch (error) {
        console.error(error)
        throw new Error('Failed to fetch tasks to sprint');
    }
};

export const fetchHomePage = async (): Promise<{page: IHomePageResponse}> => {
    try {
        const response = await instance.get<{page: IHomePageResponse}>('/tasks/home');
        return response.data;
    } catch (error) {
        console.error(error)
        throw new Error('Failed to fetch tasks to sprint');
    }
}

export const fetchHomePageProps = async (): Promise<IHomePageProps> => {
    try {
        const response = await instance.get<IHomePageProps>('/users/home_page_props');
        return response.data;
    } catch (error) {
        console.error(error)
        throw new Error('Failed to fetch tasks to sprint');
    }
}

export const updateHomeProps = async (new_home_props: IHomePageUpdate): Promise<IProfile> => {
    try {
        const response = await instance.patch<IProfile>(`/users/current_user_update/?`, { user: { home_props: JSON.stringify(new_home_props) } });
        return response.data;
    } catch (error) {
        console.error(error)
        throw new Error('Failed to fetch profile');
    }
}

export const fetchGanttChart = async (id: number): Promise<IGanttReponse> => {
    try {
        const response = await instance.get<IGanttReponse>(`/projects/${id}/gantt_chart/`);
        return response.data;
    } catch (error) {
        console.error(error)
        throw new Error('Failed to fetch Gantt Chart');
    }
};

export const fetcheWikiPagesList = async (params?: string): Promise<{ wiki_pages: IPaginatedResponse, filter_data: { projects: IProject[] } }> => {
    try {
        const response = await instance.get<{ wiki_pages: IPaginatedResponse, filter_data: { projects: IProject[] } }>(`/wiki?${params}`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to fetch wiki pages list');
    }
}

export const fetcheWikiPageMain = async (paramTypePage: string, projectId?: string): Promise<{ wiki_page: IWikiPage }> => {
    try {
        const response = await instance.get<{ wiki_page: IWikiPage }>(`/wiki/main/?type=${paramTypePage}&project_id=${projectId}`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to fetch wiki page main');
    }
}

export const fetcheWikiPage = async (slug: string): Promise<{ wiki_page: IWikiPage }> => {
    try {
        const response = await instance.get<{ wiki_page: IWikiPage }>(`/wiki/pages/${slug}/`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to fetch wiki page');
    }
}

export const updateWikiPage = async (slug: string, data: IWikiPageCreateUpdate): Promise<{ wiki_page: IWikiPage, errors?: string[] }> => {
    try {
        const response = await instance.patch<{ wiki_page: IWikiPage, errors?: string[] }>(`/wiki/pages/${slug}`, data);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to fetch wiki page main');
    }
}

export const createWikiPage = async (data: IWikiPageCreateUpdate): Promise<{ wiki_page: IWikiPage, errors?: string[] }> => {
    try {
        const response = await instance.post<{ wiki_page: IWikiPage, errors?: string[] }>(`/wiki/pages/`, data);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to fetch wiki page main');
    }
}

export const fetcheWikiPageNew = async (): Promise<{ projects: IProject[] }> => {
    try {
        const response = await instance.get<{ projects: IProject[] }>(`/wiki/new`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to fetch wiki page main');
    }
}

export const fetcheWikiPageEdit = async (slug: string): Promise<{ wiki_page: IWikiPage, projects: IProject[] }> => {
    try {
        const response = await instance.get<{ wiki_page: IWikiPage, projects: IProject[] }>(`/wiki/pages/${slug}/edit`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to fetch wiki page main');
    }
}

export const fetchBoards = async (
    { params }: { params?: string }
): Promise<{ boards: IPaginatedResponse, filter_data: { projects: IProject[] } }> => {
    try {
        const response = await instance.get<{ boards: IPaginatedResponse, filter_data: { projects: IProject[] } }>(`/boards/?${params ? params : ''}`);
        return response.data;
    } catch (error) {
        console.error(error)
        throw new Error('Failed to fetchBoards');
    }
}

export const createBoard = async (data: ICreateBoard): Promise<{ board?: IBoardList, errors?: string[] }> => {
    try {
        const response = await instance.post<{ board_data: IBoardList, errors?: string[] }>(`/boards/`, data);
        return response.data;
    } catch (error) {
        console.error(error)
        throw new Error('Failed to fetchBoards');
    }
}

export const fetchMeets = async (params?: string): Promise<{ meets: IPaginatedResponse, filter_data: { projects: IProject[] } }> => {
    try {
        const response = await instance.get<{ meets: IPaginatedResponse, filter_data: { projects: IProject[] } }>('/meets/?' + (params ? params : ''));
        return response.data;
    } catch (error) {
        console.error(error)
        throw new Error('Failed to fetch meets');
    }
}

export const fetchMeet = async (id: number): Promise<{ meet: IMeet }> => {
    try {
        const response = await instance.get<{ meet: IMeet }>(`/meets/${id}/`);
        return response.data;
    } catch (error) {
        console.error(error)
        throw new Error('Failed to fetch meet');
    }
}

export const createMeet = async (data: IMeetCreateUpdate): Promise<{ meet?: IMeet, errors?: string[] }> => {
    try {
        const response = await instance.post<{ meet?: IMeet, errors?: string[] }>(`/meets/`, data);
        return response.data;
    } catch (error) {
        console.error(error)
        throw new Error('Failed to create meet');
    }
}

export const updateMeet = async (id: number, data: IMeetCreateUpdate): Promise<{ meet?: IMeet, errors?: string[] }> => {
    try {
        const response = await instance.patch<{ meet?: IMeet, errors?: string[] }>(`/meets/${id}/`, data);
        return response.data;
    } catch (error) {
        console.error(error)
        throw new Error('Failed to update meet');
    }
}

export const fetchMeetNew = async (): Promise<{ projects: IProject[], access_types: { [key: string]: string; }, errors?: string[] }> => {
    try {
        const response = await instance.get<{ projects: IProject[], access_types: {}, errors?: string[] }>(`/meets/new/`);
        return response.data;
    } catch (error) {
        console.error(error)
        throw new Error('Failed to fetch meet new');
    }
}

export const fetchMeetEdit = async (number: number): Promise<{  meet: IMeet, projects: IProject[], access_types: { [key: string]: string; }, errors?: string[] }> => {
    try {
        const response = await instance.get<{ meet: IMeet, projects: IProject[], access_types: {}, errors?: string[] }>(`/meets/${number}/edit/`);
        return response.data;
    } catch (error) {
        console.error(error)
        throw new Error('Failed to fetch meet edit');
    }
}

// Workspace Settings

export const fetchUsers = async (
    { params }: { params?: string } = {}): Promise<IPaginatedResponse> => {
    try {
        const response = await instance.get<IPaginatedResponse>('/workspace_settings/users/?' + (params ? params : ''));
        return response.data;
    } catch (error) {
        console.error(error)
        throw new Error('Failed to fetchUsers');
    }
};

export const createUser = async (userData: IUserCreateUpdate): Promise<{ user: IUser, errors?: string[] }> => {
    try {
        const response = await instance.post<{ user: IUser, errors?: string[] }>('/workspace_settings/users/', { user: userData });
        return response.data;
    } catch (error) {
        console.error(error)
        throw new Error('Failed to createUser');
    }
}

export const fetchUserEdit = async (id: number): Promise<{ user: IUser, roles: IRole[] }> => {
    try {
        const response = await instance.get<{ user: IUser, roles: IRole[] }>(`/workspace_settings/users/${id}/edit/`);
        return response.data;
    } catch (error) {
        console.error(error)
        throw new Error('Failed to fetchUserEdit');
    }
}

export const fetchUserNew = async (): Promise<{ roles: IRole[] }> => {
    try {
        const response = await instance.get<{ roles: IRole[] }>(`/workspace_settings/users/new/`);
        return response.data;
    } catch (error) {
        console.error(error)
        throw new Error('Failed to fetchUserNew');
    }
}

export const updateUser = async (id: number, userData: IUserCreateUpdate): Promise<{ user: IUser, errors?: string[] }> => {
    try {
        const response = await instance.patch<{ user: IUser, errors?: string[] }>(`/workspace_settings/users/${id}/`, { user: userData });
        return response.data;
    } catch (error) {
        console.error(error)
        throw new Error('Failed to createUser');
    }
}

export const deleteUser = async (id: number): Promise<{ errors?: string[] }> => {
    try {
        const response = await instance.delete<{ errors?: string[] }>(`/workspace_settings/users/${id}/`);
        return response.data;
    } catch (error) {
        console.error(error)
        throw new Error('Failed to deleteUser');
    }
}

export const createInviteLinkUser = async ({ email }: { email: string }): Promise<{ link: string, errors?: string[] }> => {
    try {
        const response = await instance.post<{ link: string, errors?: string[] }>(`/workspace_settings/users/invite/`, { email: email });
        return response.data;
    } catch (error) {
        console.error(error)
        throw new Error('Failed to createInviteLinkUser');
    }
}

export const sendInvitedLinkUser = async (email: string, link: string): Promise<{ errors?: string[] }> => {
    try {
        const response = await instance.post<{ errors?: string[] }>(`/workspace_settings/users/invite/send_email`, { email: email, link: link });
        return response.data;
    } catch (error) {
        console.error(error)
        throw new Error('Failed to sendInvitedLinkUser');
    }
}

// Типы задач

export const fetchTrackers = async (): Promise<{ trackers: ITracker[] }> => {
    try {
        const response = await instance.get<{ trackers: ITracker[] }>(`/workspace_settings/trackers/`);
        return response.data;
    } catch (error) {
        console.error(error)
        throw new Error('Failed to fetchTrackers');
    }
}

export const updateTrackersNumber = async (trackers: ITracker[]): Promise<{ errors?: string[] }> => {
    try {
        const response = await instance.patch<{ errors?: string[] }>(`/workspace_settings/trackers/update_number`, { trackers: trackers });
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to update trackers order');
    }
}

export const createTracker = async (title: string): Promise<{ trackers?: ITracker[], errors?: string[] }> => {
    try {
        const response = await instance.post<{ trackers?: ITracker[], errors?: string[] }>(`/workspace_settings/trackers/`, { tracker: {title: title} });
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to craete tracker');
    }
}

export const updateTracker = async (id: number, title: string): Promise<{ trackers?: ITracker[], errors?: string[] }> => {
    try {
        const response = await instance.patch<{ trackers?: ITracker[], errors?: string[] }>(`/workspace_settings/trackers/${id}/`, { tracker: {title: title} });
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to update tracker');
    }
}

export const destroyTracker = async (id: number): Promise<{ trackers?: ITracker[], errors?: string[] }> => {
    try {
        const response = await instance.delete<{ trackers?: ITracker[], errors?: string[] }>(`/workspace_settings/trackers/${id}/`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to craete tracker');
    }
}

// статусы задачи

export const fetchStatuses = async (): Promise<{ statuses: IStatus[] }> => {
    try {
        const response = await instance.get<{ statuses: IStatus[] }>(`/workspace_settings/statuses/`);
        return response.data;
    } catch (error) {
        console.error(error)
        throw new Error('Failed to fetchStatuses');
    }
}

export const updateStatusNumber = async (statuses: IStatus[]): Promise<{ errors?: string[] }> => {
    try {
        const response = await instance.patch<{ errors?: string[] }>(`/workspace_settings/statuses/update_number`, { statuses: statuses });
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to updateStatusNumber');
    }
}

export const createStatus = async (title: string): Promise<{ statuses?: IStatus[], errors?: string[] }> => {
    try {
        const response = await instance.post<{ statuses?: IStatus[], errors?: string[] }>(`/workspace_settings/statuses/`, { status: {title: title} });
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to craete status');
    }
}

export const updateStatus = async (id: number, title: string): Promise<{ statuses?: IStatus[], errors?: string[] }> => {
    try {
        const response = await instance.patch<{ statuses?: IStatus[], errors?: string[] }>(`/workspace_settings/statuses/${id}/`, { status: {title: title} });
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to update status');
    }
}

// оценка сложности

export const fetchComplexities = async (): Promise<{ complexities: IComplexity[] }> => {
    try {
        const response = await instance.get<{ complexities: IComplexity[] }>(`/workspace_settings/complexities/`);
        return response.data;
    } catch (error) {
        console.error(error)
        throw new Error('Failed to fetchStatuses');
    }
}

export const updateComplexityNumber = async (complexities: IComplexity[]): Promise<{ errors?: string[] }> => {
    try {
        const response = await instance.patch<{ errors?: string[] }>(`/workspace_settings/complexities/update_number`, { complexities: complexities });
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to updateStatusNumber');
    }
}

export const createComplexity = async (title: string): Promise<{ complexities?: IComplexity[], errors?: string[] }> => {
    try {
        const response = await instance.post<{ complexities?: IComplexity[], errors?: string[] }>(`/workspace_settings/complexities/`, { complexity: {title: title} });
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to craete status');
    }
}

export const updateComplexity = async (id: number, title: string): Promise<{ complexities?: IComplexity[], errors?: string[] }> => {
    try {
        const response = await instance.patch<{ complexities?: IComplexity[], errors?: string[] }>(`/workspace_settings/complexities/${id}/`, { complexity: {title: title} });
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to update status');
    }
}

export const destroyComplexity = async (id: number): Promise<{ complexities?: IComplexity[], errors?: string[] }> => {
    try {
        const response = await instance.delete<{ complexities?: IComplexity[], errors?: string[] }>(`/workspace_settings/complexities/${id}/`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to update status');
    }
}

// Calendar

export const fetchCalendarEvents = async (params?: string): Promise<{events: ICalendarEvent[]}> => {
    try {
        const response = await instance.get<{events: ICalendarEvent[]}>(`/calendar/events/?${params}`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to fetchCalendarEvents');
    }
}

export const createEvent = async (eventData: Partial<ICalendarEvent>): Promise<{event?: ICalendarEvent, errors?: string[]}> => {
    try {
        const response = await instance.post<{event: ICalendarEvent}>('/calendar/events', { event: eventData });
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to create event');
    }
};

export const updateEvent = async (eventData: Partial<IUpdateEvent>): Promise<{event?: ICalendarEvent, errors?: string[]}> => {
    try {
        const response = await instance.patch<{event: ICalendarEvent}>(`/calendar/events/${eventData.id}`, { event: eventData });
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to update event');
    }
};

export const deleteEvent = async (eventId: number): Promise<{errors?: string[]}> => {
    try {
        const response = await instance.delete<{errors?: string[]}>(`/calendar/events/${eventId}`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to delete event');
    }
};

export const fetchTaskForEventInfo = async (taskNumber: string): Promise<{task?: any; errors?: string[]}> => {
    try {
        const response = await instance.get<{task?: any; errors?: string[]}>(`/calendar/events/task_to_event?task_number=${taskNumber}`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to fetchTaskForEventInfo');
    }
};

// Подписка и оплата

export const fetchSubscriptionStatus = async (): Promise<IPaymentStatus> => {
    try {
        const response = await instance.get<{is_active: boolean, last_payment: IPayment, payments_history: IPayment[]}>(`/subscriptions/status`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to fetchSubscriptionStatus');
    }
};
