// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.simple-list-table {
    width: 95%;
    border-radius: 15px;
    padding-left: 5%;
}

.simple-list-table th, td {
    padding: 10px;
    min-width: 10rem;
    height: 2rem;
    border: 1px solid #EDEDED;
}

.simple-list-table-tr {
    padding: 10px;
    height: 2rem;
    border: 1px solid #EDEDED;
}

.left-align {
    float: left;
}

.right-align {
    float: right;
}

.td-center, .th-center {
    text-align: center;
}

.simple-list-table span {
    cursor: pointer;
}

.with-bottom-border {
    padding-bottom: 0.5rem;
}`, "",{"version":3,"sources":["webpack://./src/components/SimpleList/SimpleList.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,gBAAgB;IAChB,YAAY;IACZ,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,yBAAyB;AAC7B;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,sBAAsB;AAC1B","sourcesContent":[".simple-list-table {\n    width: 95%;\n    border-radius: 15px;\n    padding-left: 5%;\n}\n\n.simple-list-table th, td {\n    padding: 10px;\n    min-width: 10rem;\n    height: 2rem;\n    border: 1px solid #EDEDED;\n}\n\n.simple-list-table-tr {\n    padding: 10px;\n    height: 2rem;\n    border: 1px solid #EDEDED;\n}\n\n.left-align {\n    float: left;\n}\n\n.right-align {\n    float: right;\n}\n\n.td-center, .th-center {\n    text-align: center;\n}\n\n.simple-list-table span {\n    cursor: pointer;\n}\n\n.with-bottom-border {\n    padding-bottom: 0.5rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
