import {IProject} from '../../common/types/tasks';
import {Link} from "react-router-dom";
import React from "react";

interface ProjectsTableProps {
    projects: IProject[];
    ganttAllowed: boolean;
}

const ProjectsTable: React.FC<ProjectsTableProps> = ({ projects, ganttAllowed }) => {
    return (
        <div className={'list-table'} style={{minHeight: '80vh'}}>
            <table className={'table-main'}>
                <thead className={'table-head'}>
                <tr>
                    <th className={'th-id'}>#</th>
                    <th className={'th-title project'}>Название</th>
                    <th className={'th-readiness'}>Количество задач</th>
                    {ganttAllowed && <th className={'th-readiness'}>Диаграмма Ганта</th>}
                    <th className={'th-readiness'}>Прогрес</th>
                    <th className={'th-sprint-author'}>Автор</th>
                </tr>
                </thead>
                <tbody className={'table-body'}>
                {projects.map((project) => (
                    <tr key={project.id} className={'task-row'}>
                        <td className={'td-id'}>{project.number}</td>
                        <td className={'td-title project'}>
                            <Link to={'/projects/' + project.number}>{project.title}</Link>
                        </td>
                        <td className={'td-readiness'}>
                            <div className={'tasks-count-with-arrow'}>
                                <span>{project.tasks_count}</span>
                                <Link to={'/tasks/?project=' + project.id}
                                      className={'to-list-task-btn'}
                                      title={'Перейти к списку задач'}>
                                    &rarr;
                                </Link>
                            </div>
                        </td>
                        {ganttAllowed &&
                            <td className={'td-readiness'}>
                                <Link to={`/projects/${project.number}/gantt`} className={'to-btn'}>Открыть</Link>
                            </td>
                        }
                        <td className={'td-readiness'}>
                            <span className={'progress-block'}>
                                <div className={'progress-bar'}>
                                    <div className={'progress'} style={{'width': `${project.readiness}%`}}></div>
                                </div>
                                {project.readiness}%
                            </span>
                        </td>
                        <td className={'td-sprint-author'}>{project.author}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default ProjectsTable;