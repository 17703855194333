import { useEffect, useState } from "react";
import Layout from "../../components/layout";
import { IComplexity } from "../../common/types/tasks";
import { fetchComplexities, updateComplexityNumber, createComplexity, updateComplexity, destroyComplexity } from "../../utils/axios";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { CircularProgress } from "@mui/material";
import ModalWindowEdit from "../../components/ModalWindowEdit";
import { useNavigate } from "react-router-dom";
import ModalWindow from "../../components/ModalWindow";

const SettingsComplexities = () => {
    const navigate = useNavigate();
    const [fetched, setFetched] = useState<boolean>(false);
    const [complexities, setComplexities] = useState<IComplexity[]>([]);
    const [errors, setErrors] = useState<string[]>([]);
    const [title, setTitle] = useState('');
    const [titleEdit, setTitleEdit] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [complexityIdEdit, setComplexityIdEdit] = useState<number | null>();
    const [complexityIdDelete, setComplexityIdDelete] = useState<number | null>();

    useEffect(() => {
        const getcomplexities = async () => {
            const fetchedcomplexities = await fetchComplexities();
            if (fetchedcomplexities.complexities) {
                setComplexities(fetchedcomplexities.complexities.sort((a, b) => a.complexity_number - b.complexity_number));
            }
            setFetched(true);
        }

        if (!fetched) {
            getcomplexities();
        }    
    }, [fetched]);

    const handleDragEnd = async (result: any) => {
        if (!result.destination) return;
    
        const complexityItems = Array.from(complexities);
        const [reorderedItem] = complexityItems.splice(result.source.index, 1);
        complexityItems.splice(result.destination.index, 0, reorderedItem);
    
        const updatedcomplexities = complexityItems.map((complexity, index) => ({
            ...complexity,
            complexity_number: index + 1
        }));
    
        setComplexities(updatedcomplexities);
    
        const req = await updateComplexityNumber(updatedcomplexities);
        if (req.errors) {
            setErrors(req.errors);
        }
    };
 
    const addNewComplexity = async () => {
        setErrors([]);
        if (title === '') return

        const createdComplexity = await createComplexity(title)
        if (createdComplexity.complexities) {
            setTitle('');
            setComplexities(createdComplexity.complexities);
        } else if (createdComplexity.errors) {
            setErrors(createdComplexity.errors);
        }
    };

    const editComplexity = async (complexity_id: number) => {
        setErrors([]);
        const editedComplexity = await updateComplexity(complexity_id, titleEdit)
        if (editedComplexity.complexities) {
            setComplexities(editedComplexity.complexities);
        } else if (editedComplexity.errors) {
            setErrors(editedComplexity.errors);
        }
    };   
    
    const removeComplexity = async (tracker_id: number) => {
        setErrors([]);
        const destroyedComplexity = await destroyComplexity(tracker_id)
        if (destroyedComplexity.complexities) {
            setComplexities(destroyedComplexity.complexities);
        } else if (destroyedComplexity.errors) {
            setErrors(destroyedComplexity.errors);
        }
    }; 

    const showModalEditComplexity = (title: string, complexity_id: number) => {       
        setTitleEdit(title);
        setComplexityIdEdit(complexity_id);
        setShowModal(true);
    }

    const showModalDeleteComplexity = (complexity_id: number) => {
        setTitleEdit('');
        setComplexityIdEdit(null);

        setComplexityIdDelete(complexity_id);
        setShowModal(true);
    }

    const handleConfirm = () => {
        setShowModal(false);
        if (complexityIdEdit) {
            editComplexity(complexityIdEdit)
        }
        if (complexityIdDelete) {
            removeComplexity(complexityIdDelete)
        }
    }

    const handleCancel = () => {
        setComplexityIdEdit(null);
        setShowModal(false);
    }

    return (
        <Layout title={'Настройки. Оценка сложности'}>
            <div className={'main-container task-list'}>

                <div className={'form-head'}>
                    <p></p>
                    <button className={'back-button'} onClick={() => navigate(-1)}>
                        <span>&lt;</span>
                    </button>
                </div>

                {errors.length > 0 &&
                    <div>
                        <ul>
                            {errors.map((error)=>(
                                <li>{error}</li>
                                ))}
                        </ul>
                    </div>}

                {fetched ? 
                    <div className={'widgets-container'}>
                        <div className={'widgets-order-index'}>
                            <div className={'w-index'}>#</div>
                            {complexities.map((complexity, i) => (
                                <div className={'w-index'}>{i+1}</div>
                            ))}   
                        </div>

                        <DragDropContext onDragEnd={handleDragEnd}>
                            <div className={'widgets-table'}>
                                <div className={'w-head'}>Оценка сложности</div>

                                <Droppable droppableId="droppable">
                                    {(provided) => (
                                        <div className={'w-name'} {...provided.droppableProps} ref={provided.innerRef}>
                                            {complexities.map((complexity, index) => (
                                                <Draggable key={index} draggableId={index.toString()} index={index}>
                                                    {(provided) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        className='widgets-row'
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={{ ...provided.draggableProps.style,}}
                                                    >
                                                        <span>&emsp;&equiv;&ensp;{complexity.title}</span>
                                                        <div>
                                                            <span className="add-widget" onClick={() => showModalEditComplexity(complexity.title, complexity.id)}>&ensp;редактировать&ensp;</span>    
                                                            &ensp;
                                                            <span className="remove-widget" onClick={() => showModalDeleteComplexity(complexity.id)}>&ensp;удалить&ensp;</span>
                                                        </div>
                                                        
                                                    </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>

                                <div className={'form-task-group'}>
                                    <br></br>
                                    <label className={'form-task-label'} htmlFor={'complexity'}>Новая оценка сложности</label>
                                    <div className={'form-task-complexity'}>
                                        <input className={'form-task-input two-third-width'} name={'title-complexity'} placeholder={'Название оценки'}
                                                value={title} onChange={(e) => setTitle(e.target.value)}/>
                                        <button type={'button'} className="time-roles-btn add" onClick={() => addNewComplexity()}>
                                            Добавить
                                        </button>
                                    </div>
                                </div>
                                
                            </div>
                        </DragDropContext>
                        
                        {showModal && complexityIdDelete && <ModalWindow title="Вы уверены, что хотите удалить оценку? У всех задач данная оценка будет очищена"
                                                                         onConfirm={handleConfirm}
                                                                         onCancel={handleCancel}/>}
                                                
                        {showModal && complexityIdEdit && <ModalWindowEdit inputValue={titleEdit}
                                                                           setInputValue={setTitleEdit}
                                                                           inputLabel={"Название"}
                                                                           onConfirm={handleConfirm}
                                                                           onCancel={handleCancel}/>} 

                    </div>
                    :
                    <CircularProgress/>
                }
            </div>
        </Layout>
    );
}

export default SettingsComplexities;
