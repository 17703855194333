// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar {
    position: fixed;
    width: 15%;
    height: 100%;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    background-color: #273b64;
    transition: width 0.3s ease;
}

.sidebar.closed {
    width: 50px;
}

.collapse-button {
    width: 35px;
    height: 35px;
    right: 2.5rem;
    bottom: 4rem;
    background-color: #fff;
    color: #273b64;
    border: none;
    outline: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease;
    border-radius: 100%;
    position: absolute;
}

.closed + .mainLayout {
    width: calc(100% - 50px);
}

.profile-sidebar {
    padding-left: 3rem;
    margin-top: 3rem;
    text-align: left;
    color: white;
    height: 4rem;
}

.profile-info.closed {
    display: none;
}

.collapse-button.closed {
    right: 8px;
}`, "",{"version":3,"sources":["webpack://./src/components/sidebar/sidebar.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,UAAU;IACV,YAAY;IACZ,6BAA6B;IAC7B,gCAAgC;IAChC,yBAAyB;IACzB,2BAA2B;AAC/B;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,YAAY;IACZ,sBAAsB;IACtB,cAAc;IACd,YAAY;IACZ,aAAa;IACb,eAAe;IACf,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,+BAA+B;IAC/B,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,gBAAgB;IAChB,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,UAAU;AACd","sourcesContent":[".sidebar {\n    position: fixed;\n    width: 15%;\n    height: 100%;\n    border-top-right-radius: 25px;\n    border-bottom-right-radius: 25px;\n    background-color: #273b64;\n    transition: width 0.3s ease;\n}\n\n.sidebar.closed {\n    width: 50px;\n}\n\n.collapse-button {\n    width: 35px;\n    height: 35px;\n    right: 2.5rem;\n    bottom: 4rem;\n    background-color: #fff;\n    color: #273b64;\n    border: none;\n    outline: none;\n    cursor: pointer;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    transition: transform 0.3s ease;\n    border-radius: 100%;\n    position: absolute;\n}\n\n.closed + .mainLayout {\n    width: calc(100% - 50px);\n}\n\n.profile-sidebar {\n    padding-left: 3rem;\n    margin-top: 3rem;\n    text-align: left;\n    color: white;\n    height: 4rem;\n}\n\n.profile-info.closed {\n    display: none;\n}\n\n.collapse-button.closed {\n    right: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
