import { Link, useNavigate } from "react-router-dom";
import { fetchSprint } from "../../utils/axios";
import { useEffect, useState } from "react";
import { ISprint } from "../../common/types/tasks";
import {CircularProgress} from "@mui/material";


const SprintShowClosed = ({sprintNumber} : {sprintNumber: number}) => {

    const navigate = useNavigate();
    const [fetched, setFetched] = useState<boolean>(false);

    const [sprint, setSprint] = useState<ISprint>();

    useEffect(() => {
        const getFecth = async () => {
            try {
                const fetchedSprint = await fetchSprint(sprintNumber);
                if (fetchedSprint.sprint?.closed !== true) {
                    navigate(`/sprints/${sprintNumber}/board`)
                }
                setSprint(fetchedSprint.sprint);
            } catch (error) {
                console.error(error);
            }
            setFetched(true);
        };
        if (!fetched)
            getFecth();

    }, [fetched])

    return(
        <div className={'main-container task-show'}>
            <div className={'form-head'}>
                <p className={'title-form'}>
                    #{sprintNumber}
                </p>
                <button className={'back-button'} onClick={() => navigate('/sprints/')}>
                    <span>&lt;</span>
                </button>
            </div>
            {fetched && sprint ? 
                <div className={'show-main'}>
                    <div className={'form-task-col-left'}>
                        <div className={'task-item'}>
                            <strong>Название: </strong>
                            {sprint.title}
                        </div>
                        <div className={'task-item'}>
                            <strong>Цели спринта: </strong>
                            <textarea className={'form-sprint-textarea'} placeholder={'Цели спринта'}
                                      value={sprint.desc} readOnly />
                        </div>
                        
                        <div className={'task-item'}>
                            <strong>Автор: </strong>
                            {sprint.author}
                        </div>

                        <div className={'task-item'}>
                            <strong>Трудозатраты: </strong>
                            {sprint.time_spent_to_tasks} ч
                        </div>
                        <div className={'task-item'}>
                            <strong>Количество задач: </strong>
                            {sprint.tasks_count}
                            &emsp; 
                            <Link to={sprint.tasks_list_link} className={'to-btn'}>
                                Перейти к списку задач
                            </Link>
                        </div>
                        {sprint.project && 
                            (<div className={'task-item'}>
                                <strong>Проект: </strong>
                                {sprint.project}
                            </div>)
                        }
                    </div>
        
                    <div className={'show-task-col-right'}>
                        <div className={'task-item'}>
                            <strong>Дата начала: </strong>
                            {sprint.start_at_formatted}
                        </div>
                        <div className={'task-item'}>
                            <strong>Дата завершения: </strong>
                            {sprint.end_at_formatted}
                        </div>
                    </div>
                
                </div>
                :
                <CircularProgress />
            }
        </div>
    )
}

export default SprintShowClosed