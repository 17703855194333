import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {IUserProject} from "../../common/types/tasks";
import {createProject, fetchProjectEdit, fetchProjectNew, updateProject} from "../../utils/axios";
import ProjectUsersTables from "../ProjectUsersTables";
import { CircularProgress } from "@mui/material";

const ProjectForm = ({ typePage, projectNumber }: {typePage: string, projectNumber?: number}) => {
    const navigate = useNavigate();
    const [fetched, setFetched] = useState<boolean>();

    const [title, setTitle] = useState('');
    const [errors, setErrors] = useState<String[]>([]);
    const [description, setDescription] = useState('');

    const [usersToProject, setUsersToProject] = useState<IUserProject[]>([]);
    const [usersAvailable, setUsersAvailable] = useState<IUserProject[]>([]);

    useEffect(() => {
        const getProjectEdit = async () => {
            try {
                if (!projectNumber) return;
                const fetchedProject = await fetchProjectEdit(projectNumber);

                setTitle(fetchedProject.project.title);
                setDescription(fetchedProject.project.description);
                setUsersToProject(fetchedProject.project.users_project);
                setUsersAvailable(fetchedProject.available_users);
                setFetched(true);
            } catch (error) {
                console.error(error);
            }
        };

        const getProjectNew = async () => {
            try {
                const fetchedProjectNew = await fetchProjectNew();

                setUsersAvailable(fetchedProjectNew.available_users);
                setFetched(true);
            } catch (error) {
                console.error(error);
            }
        };

        if (projectNumber && typePage === 'edit') {
            getProjectEdit();
        } else  if (typePage === 'new') {
            getProjectNew();
        }

    }, []);

    const handleCreateUpdateProject = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const projectData = {
            title: title,
            description: description,
            users: usersToProject.map((user) => (user.id))
        };

        (async () => {
            try {
                if (typePage === 'new') {
                    const createdProject = await createProject(projectData)
                    if (createdProject.project) {
                        navigate(`/projects/${createdProject.project.number}/`);  
                    } else if (createdProject.errors) {
                        setErrors(createdProject.errors);
                    }
                }

                if (typePage === 'edit') {
                    if (!projectNumber) return;

                    const updatedProject = await updateProject(projectNumber, {
                        title: title,
                        description: description,
                        users: usersToProject.map((user) => (user.id))
                    });

                    if (updatedProject.project) {
                        navigate(`/projects/${updatedProject.project.number}/`);  
                    } else if (updatedProject.errors) {
                        setErrors(updatedProject.errors);
                    }
                }                 
            } catch (e) {
                console.error(e);
            }
        })();
    }

    return(
        <div className={'main-container task-show'}>
            <div className={'form-head'}>
                <p className={'title-form'}>
                    {typePage === 'new' && 'Новый проект'}
                    {typePage === 'edit' && 'Редактировать проект'}
                </p>
                <button className={'back-button'} onClick={() => navigate(-1)}>
                    <span>&lt;</span>
                </button>
            </div>

            {fetched ? 
                <form className={'form-main'} onSubmit={handleCreateUpdateProject}>
                    <div className={'form-task-col-left'}>
                        <div className={'form-task-group'}>
                            <label className={'form-task-label'} htmlFor={'title'}>Название</label>
                            <input className={'form-task-input'} name={'title'} placeholder={'Название'}
                                value={title} onChange={(e) => setTitle(e.target.value)} />
                            {errors &&
                                <div className="form-task-error">
                                    {errors.map((error)=>(
                                        <p>{error}</p>
                                    ))}
                                </div>
                            }
                        </div>

                        <div className={'form-task-group'}>
                            <label className={'form-task-label'} htmlFor={'text'}>Описание</label>
                            <textarea className={'form-sprint-textarea'} name={'text'} placeholder={'Описание'}
                                    value={description} onChange={(e) => (setDescription(e.target.value))}/>
                        </div>
                    </div>
                    <div className={'form-task-col-right'}></div>

                    {(usersAvailable.length > 0 || usersToProject.length > 0) &&
                        <div className={'form-task-col-left'}>
                            <div className={'form-task-group'}>
                                <label className={'form-task-label'} htmlFor={'users'}>Пользователи</label>
                                <ProjectUsersTables usersToProject={usersToProject} setUsersToProject={setUsersToProject}
                                                    usersAvailable={usersAvailable} />
                            </div>
                        </div>
                    }

                    <div className={'form-task-col-center'}>
                        <br></br>
                        <button className={'save-task-btn'} type={'submit'}>
                            {typePage === 'new' && 'Добавить проект'}
                            {typePage === 'edit' && 'Сохранить'}
                        </button>
                    </div>
                </form>
                :
                <CircularProgress/>
            }
        </div>
    )
}

export default ProjectForm
