import React from 'react';
import './ModalWindow.css'

interface ModalProps {
    title: string;
    onConfirm: () => void;
    onCancel: () => void;
}

const ModalWindow: React.FC<ModalProps> = ({ title, onConfirm, onCancel }) => {
    return(
        <div className={'modal-window'} onClick={onCancel}>
            <div className={'modal-content'} onClick={e => e.stopPropagation()}>
                <h3 style={{whiteSpace: 'pre-wrap'}}>{title}</h3>
                <div className={'modal-btns'}>
                    <button className={'confirm-button'} onClick={onConfirm}>Да</button>
                    <button className={'cancel-button'} onClick={onCancel}>Отмена</button>
                </div>
            </div>
        </div>
    )
}

export default ModalWindow;