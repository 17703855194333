import {IUserInProject, IUsersByRoles} from "../../common/types/tasks";
import './SimpleList.css'

const SimpleList = ({users} : {users: IUsersByRoles}) => {
    return(
        <div className={'simple-list-table'}>
            {Object.entries(users).map(([userRole, users], index) =>
                <div className={'with-bottom-border'} key={index}>
                    <h4>{userRole}</h4>
                    {users.map((user: IUserInProject) =>
                        <div key={user.id} className={'simple-list-table-tr'}>
                            <span>
                                <div className={'left-align'}>
                                    {user.username}
                                </div>
                                <div className={'right-align'}>
                                    {user.user_role}
                                </div>                                
                            </span>

                        </div>

                    )}
                </div>
            )}
        </div>
    )
}

export default SimpleList