import {AppDispatch, RootState} from "../../store";
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export const useAuth = () => {
    const {isAuthenticated} = useAppSelector((state) => state.auth)
    return isAuthenticated
}

export const useUserId = () => {
    const {user} = useAppSelector((state) => state.auth)
    return user.id
}

export const useUserName = () => {
    const {user} = useAppSelector((state) => state.auth)
    return user.username
}

export const useUserEmail = () => {
    const {user} = useAppSelector((state) => state.auth)
    return user.email
}

export const useUserWorkspace = () => {
    const {user} = useAppSelector((state) => state.auth)
    return user.workspace
}

export const useIsAdmin = () => {
    const {role} = useAppSelector((state) => state.permissions)
    return role === 'admin'
}

export const usePermissions = () => {
    const {permissions} = useAppSelector((state) => state.permissions)
    return permissions
}

export const useActionIsAllowed = (action: string) => {
    const {permissions} = useAppSelector((state) => state.permissions);
    return permissions.includes(action)
}