import SearchHead from "../SearchHead";
import { useState } from "react";
import { createRelatedTask, createSubTask, createTaskColumnExists, fetchTasks, fetchTasksForRelation, fetchTasksToSprint } from "../../utils/axios";
import { ITask } from "../../common/types/tasks";
import { BoardColumn, BoardTaskItem } from "../../common/types/boards";

interface TaskFindSmallProps {
    columnId?: number;
    columnStatus?: string;
    parentTaskId?: number;
    isSubTask?: boolean;
    boardId?: number;
    findClosed?: boolean;
    projectId: number;
    onSuccessCreatedColumnTask?: (column_index: string, task_id: number, column: BoardColumn) => void;
    setFetched?: (fetched: boolean) => void;
}

const TaskFindSmall: React.FC<TaskFindSmallProps> = (
    { columnId, boardId, columnStatus, parentTaskId, isSubTask, findClosed, projectId, onSuccessCreatedColumnTask, setFetched }
) => {

    const [searchData, setSearchData] = useState<string>('');
    const [searchedTasks, setSearchedTasks] = useState<ITask[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [countPages, setCountPages] = useState<number>(0);
    const pageSize = 15;

    const handleFilledSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchData(event.target.value);
    }

    const handleSearchApply = async () => {
        try {
            let params = `page_size=${pageSize}&page=${1}`;
            params += `&project=${projectId}`
            params += `&title=${searchData}`;
            params += `&parent_task_id=${parentTaskId}`;
            params += `&closed=${findClosed}`

            const fetchedTasks = await makeRequest(params);

            if (fetchedTasks === null) return;

            setSearchedTasks(fetchedTasks.tasks.objects);
            setCountPages(fetchedTasks.tasks.count_pages);
            setCurrentPage(fetchedTasks.tasks.current_page);
        } catch (error) {
            console.error(error);
        }
    }

    const handleLoadMore = async () => {
        try {
            let params = `page_size=${pageSize}&page=${currentPage + 1}`;
            params += `&project=${projectId}`
            params += `&title=${searchData}`;
            params += `&parent_task_id=${parentTaskId}`;
            params += `&closed=${findClosed}`

            const fetchedTasks = await makeRequest(params);

            if (fetchedTasks === null) return;

            setSearchedTasks([...searchedTasks, ...fetchedTasks.tasks.objects]);
            setCountPages(fetchedTasks.tasks.count_pages);
            setCurrentPage(fetchedTasks.tasks.current_page);
        } catch (error) {
            console.error(error);
        }
    };

    const makeRequest = async (params: string) => {
        try {
            let fetchedTasks;
            if (onSuccessCreatedColumnTask && columnId && columnStatus) {
                fetchedTasks = await fetchTasksToSprint({ params: `?${params}` });
            } else if (setFetched && parentTaskId) {
                fetchedTasks = await fetchTasksForRelation({ params: `?${params}` });
            } else {
                fetchedTasks = await fetchTasks({ params: `?${params}` });
            }
            return fetchedTasks
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    const taskClicked = async (taskId: number) => {
        // добавить задачу на доске
        if (onSuccessCreatedColumnTask && boardId && columnStatus) {
            const updatedColumn = await createTaskColumnExists(boardId, taskId);
            if (updatedColumn) {
                onSuccessCreatedColumnTask(updatedColumn.column_status, updatedColumn.task_id, updatedColumn.source_column);
            }
            await handleSearchApply()
        }
        // добавить связанную задачу или подзадачу
        if (setFetched && parentTaskId) {
            let createdTask;
            if (isSubTask) {
                createdTask = await createSubTask(parentTaskId, taskId);
            } else {
                createdTask = await createRelatedTask(parentTaskId, taskId);
            }

            if (createdTask) {
                setFetched(false);
            }
            await handleSearchApply()
        }
    }

    return (
        <div className={'form-type-dropdown-menu'}>
            <SearchHead
                searchData={searchData}
                onSearchFilled={handleFilledSearch}
                onApply={handleSearchApply}
            />
            {searchedTasks.map(({ id, number, title }, index) => (
                <div className={'sprint-task'} onClick={() => taskClicked(id)} style={{cursor: 'pointer'}}>
                    #{number} {title}
                </div>
            ))}
            {currentPage < countPages && (
                <div className={'center-div'}>
                    <button className={'to-btn'} onClick={handleLoadMore}>Загрузить еще</button>
                </div>
            )}
        </div>

    )
}

export default TaskFindSmall