import {IComment} from "../../common/types/tasks";
import React, {useState} from "react";
import {createComment, editComment} from "../../utils/axios";
import { Tooltip } from '@material-ui/core';
import {useStyles} from "../../utils/utils";
import { useUserId } from "../../utils/hook";
import remarkGfm from 'remark-gfm';
import rehypeSlug from 'rehype-slug';
import rehypeRaw from 'rehype-raw'
import rehypeAutolinkHeadings from 'rehype-autolink-headings';
import Markdown from 'react-markdown';
import './Comments.css'

type commentType = 'show' | 'edit';

const Comments = ({comments, taskId, setFetched, canEdit} :
                      {comments: IComment[], taskId: number, setFetched: (fetched: false) => void, canEdit: boolean}) => {
    
    const current_user_id = useUserId();
    const [commentText, setCommentText] = useState('');
    const [commentTextEdit, setCommentTextEdit] = useState('');
    const [commentIdEdit, setCommentIdEdit] = useState<number>();
    const [commentType, setCommentType] = useState<commentType>('show');
    const classes = useStyles();

    const handleAddComment = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (commentText === '') return
        await createComment(taskId, {text: commentText});
        setFetched(false);
        setCommentText('');
    }

    const handleEditComment = async (commentsId: number) => {
        const editedComment = await editComment(taskId, commentsId, {text: commentTextEdit});
        if (editedComment) {
            setCommentType('show');
            setFetched(false);
            setCommentTextEdit('');
        }
    }

    return(
        <div className={'comments-container'}>
            <div className={'comments-list'}>
                {comments.map((comment) => (
                    <div key={comment.id} className={'comment-item'}>
                        <div className={'comment-number'}>#{comment.sequence_number}</div>
                        <div className={'comment-text'}>
                            <div className={'comment-author_created_at'}>
                                <p className={'comment-author'}>{comment.author_name}</p>
                                <Tooltip title={comment.created_at_formatted} placement="top" classes={{ tooltip: classes.tooltip }}>
                                    <p className={'comment-created_at'}>{comment.created_at_days}</p>
                                </Tooltip>
                            </div>
                            { commentType === 'edit' && commentIdEdit === comment.id ?
                                (
                                    <div className={'comment-text-edit'}>
                                        <textarea className={'comment-edit-textarea'}
                                                  value={commentTextEdit}
                                                  onChange={(e) => (setCommentTextEdit(e.target.value))}
                                        />
                                        <div className={'flex-with-spase comment-btns'}>
                                            <button className={'add-task-btn comment-save'}
                                                    onClick={() => setCommentType('show')}>
                                                Отменить
                                            </button>
                                            <button className={'add-task-btn comment-save'}
                                                    onClick={() => handleEditComment(comment.id)}>
                                                Сохранить
                                            </button>
                                        </div>
                                    </div>
                                ) : (
                                    <div className={'comment-text-edit show'}>
                                        <div className={'comment-text-main'}>
                                            <Markdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeSlug, rehypeAutolinkHeadings, rehypeRaw]}>
                                                {comment.text}
                                            </Markdown>
                                        </div>
                                        {current_user_id === comment.author && !comment.history && canEdit &&
                                            <img className={'task-board-btn'} src={'/app/edit.svg'}
                                                 onClick={() => {
                                                     setCommentType('edit');
                                                     setCommentIdEdit(comment.id);
                                                     setCommentTextEdit(comment.text)
                                                 }}
                                            />
                                        }
                                    </div>
                                )
                            }
                        </div>
                    </div>
                ))}
            </div>

            {canEdit && 
                <form className={'comments-form'} onSubmit={handleAddComment}>
                    <textarea className={'comment-form-textarea'} name={'text'} placeholder={'Оставить примечания'}
                            value={commentText} onChange={(e) => (setCommentText(e.target.value))}/>
                    <button className={'add-task-btn comment-save'} type={'submit'}>
                        Сохранить
                    </button>
                </form>
            }
        </div>
    )
}

export default Comments