import {ChangeEvent, useState} from "react";
import {isEmpty} from "../../utils/utils";
import {filterEntries} from "../../utils/utils";
import Multiselect from "../Multiselect";
import Calendar from "../Calendar";
import './FiltersHeadMeets.css'


const FiltersHeadMeets = ({ searchData, filterData, onSearchFilled, selectedFilters, onSelectedChange, onApply }: any) => {
    const [startAt, setStartAt] = useState<Date | null>(
        !isEmpty(searchData['start_at']) ? new Date(searchData['start_at']) : new Date()
    );
    const [endAt, setEndAt] = useState<Date | null>(
        !isEmpty(searchData['end_at']) ? new Date(searchData['end_at']) : new Date()
    );

    const onApplySearch = () => {
        if (startAt === null) {
            setStartAt(new Date());
        }
        if (endAt === null) {
            setEndAt(new Date());
        }
        onApply()
    }

    return (
        <div className={'searches-meet'}>
            <div className={'search-field-meets'}>
                <label className={'form-task-label'} htmlFor={'title'}>Название</label>
                <input className={'search-filter-meets'}
                       placeholder={'Поиск'}
                       value={searchData['title']}
                       onChange={(e: ChangeEvent<HTMLInputElement>) => onSearchFilled('title', e.target.value)}
                />
            </div>

            <div className={'search-field-meets'}>
                <label className={'form-task-label'} htmlFor={'start_at'}>Дата начала, с</label>
                <Calendar dateAt={startAt} setDateAt={setStartAt} minDate={null} maxDate={endAt}
                          onSearchFill={onSearchFilled} title_name={'start_at'}/>
            </div>

            <div className={'search-field-meets'}>
                <label className={'form-task-label'} htmlFor={'start_at'}>Дата начала, по</label>
                <Calendar dateAt={endAt} setDateAt={setEndAt} minDate={startAt} maxDate={null}
                          onSearchFill={onSearchFilled} title_name={'end_at'}/>
            </div>

            <div className={'search-field-meets'}>
                <label className={'form-task-label'} htmlFor={'start_at'}>&emsp;</label>
                {Object.entries(filterData).map(([filter, options]) =>
                    <Multiselect
                        key={filter}
                        name={filterEntries[filter].title}
                        preselectedOptions={selectedFilters[filter]}
                        // @ts-ignore
                        options={options}
                        onSelectedOptionsChange={onSelectedChange(filter)}
                        classNameCustom="projects-filter-meet"
                    />
                )}
            </div>

            <div className={'search-field'}>
                <label className={'form-task-label'} htmlFor={'start_at'}>&emsp;</label>
                <button className={'list-find-btn-meets'} onClick={onApplySearch}>Применить</button>
            </div>
        </div>
    )
}

export default FiltersHeadMeets