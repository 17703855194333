import { Link, useNavigate, useParams } from 'react-router-dom';
import Layout from '../../components/layout';

type ErrorCode = '403' | '404' | '500';
type ErrorMessages = {
  [key in ErrorCode]: string;
};

const errorMessages: ErrorMessages = {
  '403': 'Доступ запрещен',
  '404': 'Объект не найден',
  '500': 'Ошибка сервера. Попробуйте позже'
};

const ErrorPage: React.FC = () => {
    const navigate = useNavigate();
    const { err_code } = useParams<{ err_code?: ErrorCode }>();

    return (
        <Layout title="">
            <div className="main-container task-list">
                <div className="form-head">
                    <p className="title-form">
                        Произошла ошибка!
                    </p>
                    <button className="back-button" onClick={() => navigate(-2)}>
                        <span>&lt;</span>
                    </button>
                </div>

                <div style={{height: '75vh'}}>
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '2rem', marginBottom: '2rem'}}>
                        {err_code && <h1 style={{'fontSize': 'xxx-large'}}>{err_code}</h1>}
                    </div>

                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '2rem', marginBottom: '4rem'}}>
                        { err_code && errorMessages[err_code] ? (
                            <h2>{errorMessages[err_code]}</h2>
                        ) : (
                            <h2>Неизвестная ошибка</h2>
                        )}
                    </div>
                    
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Link to={'/app/home'} className={'to-conference'}>
                            Домашняя страница
                        </Link>
                        &emsp;
                        <button className={'to-conference'} onClick={() => navigate(-2)}>
                            Вернуться назад
                        </button>
                    </div>

                </div>
            </div>
        </Layout>
    );
};

export default ErrorPage;
