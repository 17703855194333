import {IMeet} from "../../common/types/meets";
import React from "react";
import {Link} from "react-router-dom";
import './MeetsTable.css'

interface MeetsTableProps {
    meets: IMeet[];
}

const MeetsTable: React.FC<MeetsTableProps> = ({meets}) => {

    return (
        <div className={'list-table'}>
            <table className={'table-main'}>
                <thead className={'table-head'}>
                    <tr>
                        <th className={'th-id'}>#</th>
                        <th className={'th-meet-title'}>Название</th>
                        <th className={'th-meet-start_at'}>Начало</th>
                        <th className={'th-meet-end_at'}>Завершение</th>
                        <th className={'th-meet-author'}>Ссылка</th>
                    </tr>
                </thead>
                <tbody className={'table-body'}>
                    {meets.map((meet) => (
                        <tr key={meet.id} className={'task-row'}>
                            <td className={'td-meet-id'}>
                                {meet.number}
                            </td>
                            <td className={'td-meet-title'}>
                                <Link to={`/meets/${meet.number}/`}>
                                    {meet.title}
                                </Link>
                            </td>
                            <td className={'td-meet-start_at'}>{meet.start_at_formatted}</td>
                            <td className={'td-meet-end_at'}>{meet.end_at_formatted}</td>

                            <td className={'td-meet-author'}>
                                <Link to={`https://${process.env.REACT_APP_API_URL}/meets/conference/${meet.uuid}`}
                                    className={'to-conference'}
                                    target={'_blank'}>
                                    Открыть
                                </Link>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default MeetsTable