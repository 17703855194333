import React from "react";
import "./RightSidebar.css"

interface RightSidebarProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const RightSidebar: React.FC<RightSidebarProps> = ({ isOpen, onClose, children }) => {
	return (
		<>
			<div className={`right-sidebar ${isOpen ? "open" : ""}`}>
				<div className="sidebar-content">
					{children}
				</div>
			</div>
			
			{isOpen && <div className="sidebar-overlay" onClick={onClose}></div>}
		</>
	);
};

export default RightSidebar;
