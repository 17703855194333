import {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {IMeet} from "../../common/types/meets";
import {fetchMeet} from "../../utils/axios";
import { useActionIsAllowed } from "../../utils/hook";
import {CircularProgress, Tooltip} from "@mui/material";
import {useStyles} from "../../utils/utils";
import { downloadFile } from "../../utils/utils";
import "./MeetShow.css"

const MeetShow = ({meetNumber, editAllowed} : {meetNumber: number, editAllowed: boolean}) => {
    const canJoinConference = useActionIsAllowed("meet_joins");

    const navigate = useNavigate();
    const [fetched, setFetched] = useState<boolean>(false);
    const [meet, setMeet] = useState<IMeet>();

    const classes = useStyles();

    const initialText = "Скопировать приглашение";
    const copiedText = "Приглашение скопировано ✔";

    const initialLinkText = "Скопировать ссылку";
    const copiedLinkText = "Ссылка скопирована ✔";
    
    const [buttonText, setButtonText] = useState(initialText);
    const [buttonLinkText, setButtonLinkText] = useState(initialLinkText);

    useEffect(() => {
        const getMeet = async () => {
            try {
                const fetchedMeet = await fetchMeet(meetNumber);
                setMeet(fetchedMeet.meet);
                setFetched(true);
            } catch (error) {
                console.error(error);
            }
        };

        if (meetNumber) {
            getMeet();
        } else {
            navigate("/meets/")
        }
    }, [meetNumber, fetched]);

    const handleCopyInfo = async (isLink: boolean) => {
        if (!meet) return;

        let meetInfo = ""
        if (isLink) {
            meetInfo = `https://${process.env.REACT_APP_API_URL}/meets/conference/${meet.uuid}`
        } else {
            meetInfo = `Название: ${meet.title}\nДата начала: ${meet.start_at_formatted}\nДата завершения: ${meet.end_at_formatted}\nСсылка: https://${process.env.REACT_APP_API_URL}/meets/conference/${meet.uuid}`;
        }
       
        try {
            await navigator.clipboard.writeText(meetInfo);
            if (isLink) {
                setButtonLinkText(copiedLinkText);
            } else {
                setButtonText(copiedText);
            }
        } catch (err) {
            console.error("Ошибка копирования: ", err);
        }
    };

    const handleMouseEnter = () => {
        if (buttonText === copiedText) {
            setButtonText(initialText);
        }
    };  

    const handleMouseEnterLink = () => {
        if (buttonLinkText === copiedLinkText) {
            setButtonLinkText(initialLinkText);
        }
    };


    return (
        <div className={"main-container meet-show"}>
            <div className={"form-head"}>
                <p className={"title-form"}>
                    #{meetNumber}
                </p>
                <button className={"back-button"} onClick={() => navigate(-1)}>
                    <span>&lt;</span>
                </button>
            </div>
            {fetched && meet ?
                <div className={"show-main"}>
                    <div className={"show-meet-col-center"}>
                        <div className={"meet-item-title"}>
                            <strong>{meet.title}</strong>

                            {
                                !meet.finished && editAllowed &&
                                (
                                    <div className={"meet-btn-block"}>
                                        <img className={"meet-btn"} src={"/app/edit.svg"} onClick={
                                            () => navigate(`/meets/${meetNumber}/edit`)}
                                        />
                                        {/*<img className={"meet-btn"} src={"/app/delete.svg"} onClick={handleDeleteBtn} />*/}
                                    </div>
                                )
                            }

                        </div>

                    </div>

                    <div className={"show-meet-col-left"}>
                        {meet.start_at_formatted ?
                            <div className={"meet-item"}>
                                <strong>Дата начала: </strong>
                                {meet.start_at_formatted}
                            </div>
                            :
                            <></>
                        }
                        {meet.end_at_formatted ?
                            <div className={"meet-item"}>
                                <strong>Дата завершения: </strong>
                                {meet.end_at_formatted}
                            </div>
                            :
                            <></>
                        }

                        <div className={"meet-item"} style={{display: "flex", alignItems: "end"}}>
                            <strong>Вести запись&nbsp;</strong>
                            <input
                                name={"waiting_room"}
                                type="checkbox"
                                checked={meet.auto_record}
                                disabled={true}
                                style={{"boxSizing": "unset"}}
                            />
                        </div>

                        { meet.project && 
                            <div className={"meet-item"}>
                                <strong>Проект: </strong>
                                <a href={`/app/projects/${meet.project_id}`} target={"_blank"} className={"meet-project"}>{meet.project}</a>
                            </div>
                        }

                        <div className={"meet-item"}>
                            <strong>Автор: </strong>
                            {meet.author}
                        </div>
                        
                        {meet.task_number &&
                            <div className={"meet-item"} style={{width: "min-content"}}>
                                <strong>Связанная задача: </strong>
                                <a href={`/app/tasks/${meet.task_number}`} target={"_blank"}>{meet.task_title}</a>
                            </div>
                        }

                        {meet.record_details &&
                            <div className={"meet-item"} style={{width: "min-content"}}>
                                <strong>Запись встречи: </strong>
                                {meet.record_details.url &&
                                    <>
                                        &ensp;
                                        <a className={"btn-hrefs"} target="_blank" href={meet.record_details.url}>Открыть</a>
                                        &emsp;
                                        <a className={"btn-hrefs"} onClick={() => downloadFile(meet.record_details.url, `${meet?.title}.mp4`)}>Скачать</a>
                                        &emsp;
                                    </>
                                }
                                {meet.record_details.error && <p>Произошла ошибка: {meet.record_details.error}</p>}
                            </div>
                        }
                                                
                        {canJoinConference && !meet.finished &&
                            <>
                                <div className={"meet-item"}>
                                    <br></br>
                                    <Link to={`https://${process.env.REACT_APP_API_URL}/meets/conference/${meet.uuid}`}
                                        className={"to-conference"}
                                        target={"_blank"}>
                                        Войти в конференцию
                                    </Link>
                                </div>

                                <div className={"meet-item"}>
                                    <br></br>
                                    <button 
                                        className={"to-conference"} 
                                        onClick={() => handleCopyInfo(false)}
                                        onMouseEnter={handleMouseEnter}
                                    >
                                        {buttonText}
                                    </button>
                                    &emsp;
                                    <button 
                                        className={"to-conference"} 
                                        onClick={() => handleCopyInfo(true)}
                                        onMouseEnter={handleMouseEnterLink}
                                    >
                                        {buttonLinkText}
                                    </button>
                                </div>
                            </>
                        }
                    </div>

                    <div className={'form-task-col-right'}>
                        <div className={"meet-item"} style={{width: "min-content"}}>
                            <strong>Доступ к конференции: </strong>
                            {meet.access_type}
                            {meet.access_type === "Для всех пользователей" && 
                                <>
                                    &nbsp;
                                    <Tooltip title={"Доступ к конференции будет даже у незарегистрированных пользователей, у которых есть ссылка"} placement="bottom" classes={{ tooltip: classes.tooltip }}>
                                        <span className={'question-tooltip'}>?</span>
                                    </Tooltip>
                                </>
                            }
                        </div>
                    </div>

                    <div className={"show-meet-col-center"}>
                    </div>
                </div>         
            :
                <CircularProgress/>
            }

        </div>
    )
}

export default MeetShow
