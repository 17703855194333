import { DragDropContext } from 'react-beautiful-dnd';
import DroppableTable from "../DroppableTable";
import {BoardColumn, BoardData, ITaskColumnUpdated} from "../../common/types/boards";
import {useState} from "react";
import {hideColumnInBoard, updateTaskColumn, updateTaskColumnOrder} from "../../utils/axios";
import {IStatus} from "../../common/types/tasks";
import ModalWindow from "../ModalWindow";
import { useActionIsAllowed } from '../../utils/hook';
import './KanbanBoard.css'

interface KanbanBoardProps {
    columns: BoardData;
    statuses?: IStatus[];
    setColumns: (columns: BoardData) => void;
    addNewColumn: () => void;
    deleteColumn: (column_id: number) => void;
    setNewColumn: (newColumn: number | undefined) => void;
    setErrors?: (errors: string[]) => void;
    newColumn: number | undefined;
    boardId: number;
    projectId: number;
    setFetched: (fetched: boolean) => void;
}

const KanbanBoard: React.FC<KanbanBoardProps> = ({columns, statuses, setColumns, addNewColumn, deleteColumn, setNewColumn, setErrors, setFetched, newColumn, boardId, projectId}) => {

    const editAllowed = useActionIsAllowed('board_edit');

    const [dragged, setDragged] = useState('');

    const onDragEnd = async (result: any, columns: any, setColumns: any) => {
        if (setErrors) setErrors([]);
        if (!result.destination) return;

        const { source, destination } = result;

        if (source.droppableId !== destination.droppableId) {
            const sourceColumn = columns[source.droppableId];
            const destColumn = columns[destination.droppableId];
            const sourceItems = [...sourceColumn.tasks];
            const destItems = [...destColumn.tasks];
            const [removed] = sourceItems.splice(source.index, 1);
            destItems.splice(destination.index, 0, removed);
            setColumns({
                ...columns,
                [source.droppableId]: {
                    ...sourceColumn,
                    tasks: sourceItems
                },
                [destination.droppableId]: {
                    ...destColumn,
                    tasks: destItems
                }
            });

            const updateTask = await updateTaskColumn(sourceColumn.id, removed.id,
                {id: boardId, 'column_id': destColumn.id, 'task_id': removed.id, 'order_number': destination.index})

            if (updateTask.need_update_board) {
                setFetched(false);
            }

            if (updateTask) {

                setColumns({
                    ...columns,
                    [source.droppableId]: {
                        ...sourceColumn,
                        tasks: updateTask.source_column
                    },
                    [destination.droppableId]: {
                        ...destColumn,
                        tasks: updateTask.dest_column
                    }
                });

                setDragged(removed.id);
                setTimeout(() => setDragged(''), 300);
            } else {
                setColumns({
                    ...columns,
                    [source.droppableId]: {
                        ...sourceColumn,
                        tasks: sourceColumn.tasks
                    },
                    [destination.droppableId]: {
                        ...destColumn,
                        tasks: destColumn.tasks
                    }
                });
            }

        } else {
            const column = columns[source.droppableId];
            const copiedItems = [...column.tasks];
            const [removed] = copiedItems.splice(source.index, 1);

            copiedItems.splice(destination.index, 0, removed);

            if (destination.index === source.index) return

            setColumns({
                ...columns,
                [source.droppableId]: {
                    ...column,
                    tasks: copiedItems
                }
            });

            const order_dir = destination.index > source.index ? 'lte' : 'gte';

            const updateTask: ITaskColumnUpdated = await updateTaskColumnOrder(column.id, removed.id,
                {'id': boardId, 'new_order_number': destination.index, 'order_dir': order_dir, 'order_end': source.index})

            if (updateTask.need_update_board) {
                setFetched(false);
            }

            if (updateTask) {
                setColumns({
                    ...columns,
                    [source.droppableId]: {
                        ...column,
                        tasks: updateTask.source_column
                    }
                });
                setDragged(removed.id);
                setTimeout(() => setDragged(''), 300);
            } else {
                setColumns({
                    ...columns,
                    [source.droppableId]: {
                        ...column,
                        tasks: column.tasks
                    }
                });
            }
        }
    };

    const setUpdatedColumn = (column_index: string, task_id: number, column: BoardColumn) => {
        setColumns({
            ...columns,
            [column_index]:column
        });

        setDragged(task_id.toString());
        setTimeout(() => setDragged(''), 500);
    }

    const [showModal, setShowModal] = useState(false);
    const [columnDeleteId, setColumnDeleteId] = useState<number | null>(null);

    const handleConfirm = () => {
        setShowModal(false);
        if (columnDeleteId) {
            deleteColumn(columnDeleteId);
        }       
    }

    const handleCancel = () => {
        setShowModal(false);
    }

    return (
        <>
            {columns &&
                <div className={'kanban-container ' + (Object.entries(columns).length > 3 ? 'wide' : '')}>
                    <DragDropContext onDragEnd={result => onDragEnd(result, columns, setColumns)}>
                        {Object.entries(columns).sort(([,a], [,b]) => a.order_number - b.order_number).map(([id, column]) => {
                            return (
                                <DroppableTable key={column.order_number}
                                                id={id}
                                                column={column}
                                                dragged={dragged}
                                                boardId={boardId}
                                                projectId={projectId}
                                                canEdit={editAllowed}
                                                setShowModal={setShowModal}
                                                setColumnDeleteId={setColumnDeleteId}
                                                setUpdatedColumn={setUpdatedColumn}
                                />
                                );
                            })
                        }
                    </DragDropContext>

                    {editAllowed && statuses && statuses?.length > 0 &&
                        <div className={'addNewColumn'}>
                            <select className={'form-task-select add-column'}
                                    name={'status'}
                                    placeholder={'Статус'}
                                    value={newColumn ? newColumn : ''}
                                    onChange={(e) => setNewColumn(parseInt(e.target.value))}
                            >
                                {statuses.map((status) => (
                                    <option key={status.id} value={status.id}>{status.title}</option>
                                ))}
                            </select>
                            <button className={'list-button'} onClick={addNewColumn}>
                                <span>∧</span>
                            </button>
                        </div>
                    }

                    {showModal ? <ModalWindow title="Вы уверены, что хотите удалить колонку? Все задачи будут перенесены в начальную колонку"
                                              onConfirm={handleConfirm}
                                              onCancel={handleCancel}/> : null}

                </div>
            }
        </>
    );
};

export default KanbanBoard;
