import React, { useEffect, useState } from "react";
import { IDeveloper, IPriority, IProject, IStatus, ITask, ITaskUpdate, ITracker } from "../../common/types/tasks";
import { fetchTaskNew, updateTask } from "../../utils/axios";

interface TaskNewSidebarProps {
	taskCurrentProps: ITask;
    onUpdateTaskProps: (task: ITask) => void;
	onSuccess: (fetched: boolean) => void;
	onClose: () => void;
}

const TaskEditSidebar: React.FC<TaskNewSidebarProps> = ({ taskCurrentProps, onUpdateTaskProps, onClose }) => {
	const [propsFetched, setPropsFetched] = useState<boolean>(false);

	const [projects, setProjects] = useState<IProject[]>([]);
	const [developers, setDevelopers] = useState<IDeveloper[]>([]);
	const [availableDevelopers, setAvailableDevelopers] = useState<IDeveloper[]>([]);
	const [trackers, setTrackers] = useState<ITracker[]>([]);
	const [priorities, setPriorities] = useState<IPriority[]>([]);
    const [statuses, setStatuses] = useState<IStatus[]>([]);

    const [project, setProject] = useState<number | null>(taskCurrentProps.project_id);
    const [priority, setPriority] = useState<number | null>(taskCurrentProps.priority_id);
    const [tracker, setTracker] = useState<number | null>(taskCurrentProps.tracker_id);
    const [developer, setDeveloper] = useState<number | null>(taskCurrentProps.developer_id);
    const [status, setStatus] = useState<number | null>(taskCurrentProps.status_id);
    const [title, setTitle] = useState(taskCurrentProps.title);
    const [text, setText] = useState(taskCurrentProps.text);

	const [errors, setErrors] = useState<string[]>([]);

	useEffect(() => {
		const getTasksProps = async () => {
			try {
				const fetchedTaskNew = await fetchTaskNew();
				setProjects(fetchedTaskNew.projects);
				setPriorities(fetchedTaskNew.priorities);
				setTrackers(fetchedTaskNew.trackers);
				setDevelopers(fetchedTaskNew.developers);
				setAvailableDevelopers(fetchedTaskNew.developers);
                setStatuses(fetchedTaskNew.statuses);

				setPropsFetched(true);
			} catch (error) {
				console.error(error);
			}
		};

		if (!propsFetched) {
			getTasksProps();
		}
	}, []);

    const handleUpdateTask = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        try {
            const updatedTaskData: ITaskUpdate = {
                title,
                text,
                project_id: project,
                priority_id: priority,
                developer_id: developer,
                tracker_id: tracker,
                status_id: status
            };
        
            const updatedTask = await updateTask(taskCurrentProps.number, updatedTaskData);
            if (updatedTask.errors) {
                setErrors(updatedTask.errors);
            } else if (updatedTask.task) {
                onUpdateTaskProps(updatedTask.task);
                onClose();
            }
        } catch (error) {
            if (error instanceof Error) {
                setErrors([error.message]);
            } else {
                setErrors(["Произошла неизвестная ошибка"]);
            }
            console.error(error);
        }
    };

	useEffect(() => {
		if (project === 0 || project === null) return;

		const newAvailableDevelopers = developers.filter(dev => dev.project_ids.includes(project));
		setAvailableDevelopers(newAvailableDevelopers);
	}, [project]);

	return (
		<form onSubmit={handleUpdateTask}>
			<h2 style={{marginTop: "0"}}>Редактировать задачу</h2>
			{errors &&
				<div className="form-task-error">
				{errors.map((error, index) => (
					<p key={index}>{error}</p>
				))}
				</div>
			}

			<div className={'form-task-group-small'}>
				<label className={'form-task-label'} htmlFor={'title'}>Название</label>
				<input className={'form-task-input-small'} name={'title'} placeholder={'Название'}
					   value={title} onChange={(e) => setTitle(e.target.value)} />
			</div>

			<div className={'form-task-group-small'}>
                <label className={'form-task-label'} htmlFor={'project'}>Проект</label>
                <select className={'form-task-select-small'}
                        name={'project'}
                        placeholder={'Проект'}
                        value={project !== null ? project.toString() : ''}
                        onChange={(e) => setProject(parseInt(e.target.value) || null)}
                >
                {projects.map((project) => (
                    <option key={project.id} value={project.id}>{project.title}</option>
                ))}
                </select>
            </div>

            <div className={'form-task-group-small'}>
                <label className={'form-task-label'} htmlFor={'tracker'}>Тип</label>
                <select className={'form-task-select-small'}
                        name={'tracker'}
                        placeholder={'Тип'}
                        value={tracker !== null ? tracker.toString() : ''}
                        onChange={(e) => setTracker(parseInt(e.target.value) || null)}
                >
                {trackers.map((tracker) => (
                    <option key={tracker.id} value={tracker.id}>{tracker.title}</option>
                ))}
                </select>
            </div>

            <div className={'form-task-group-small'}>
                <label className={'form-task-label'} htmlFor={'priority'}>Приоритет</label>
                <select className={'form-task-select-small'}
                        name={'priority'}
                        placeholder={'Приоритет'}
                        value={priority !== null ? priority.toString() : ''}
                        onChange={(e) => setPriority(parseInt(e.target.value))}
                >
                {priorities.map((priority) => (
                    <option key={priority.id} value={priority.id}>{priority.title}</option>
                ))}
                </select>
            </div>

            <div className={'form-task-group-small'}>
                <label className={'form-task-label'} htmlFor={'status'}>Статус</label>
                <select className={'form-task-select-small'}
                    name={'status'}
                    placeholder={'Статус'}
                    value={status !== null ? status.toString() : ''}
                    onChange={(e) => setStatus(parseInt(e.target.value))}
                >
                {statuses.map((status) => (
                    <option key={status.id} value={status.id}>{status.title}</option>
                ))}
                </select>
            </div>

            <div className={'form-task-group-small'}>
                <label className={'form-task-label'} htmlFor={'developer'}>Назначена</label>
                <select className={'form-task-select-small'}
                        name={'developer'}
                        placeholder={'Назначена'}
                        value={developer ? developer.toString() : ''}
                        onChange={(e) => setDeveloper(parseInt(e.target.value))}
                >
                <option key={'0'}></option>
                {availableDevelopers.map((developer) => (
                    <option key={developer.id} value={developer.id}>{developer.username}</option>
                ))}
                </select>
            </div>

            <div className={'form-task-group-small'}>
                <label className={'form-task-label'} htmlFor={'text'} >Описание</label>
                <textarea className={'form-task-textarea-small large'} name={'text'} placeholder={'Описание'}
                    value={text} onChange={(e) => setText(e.target.value)} />
            </div>

            <button className={'save-task-btn small-form'} type={'submit'}>
                Сохранить изменения
            </button>
        </form>
    );
};

export default TaskEditSidebar;
