import { useState } from 'react';
import {useNavigate} from "react-router-dom";
import {CircularProgress} from "@mui/material";
import {useAppDispatch} from "../../../utils/hook";
import {storeUserData, getUserData, removeUserData} from "../../../utils/utils";
import {createUserAndWorkspace} from "../../../utils/axios";
import {login} from "../../../store/slice/auth";
import {setPermissionsData} from '../../../store/slice/permissions';


const SignupForm = () => {
    const navigate = useNavigate()

    const storedUserData = getUserData();

    const [title, setTitle] = useState(storedUserData.workspace?.title || '');

    const [firstName, setFirstName] = useState(storedUserData.user?.firstName || '');
    const [lastName, setLastName] = useState(storedUserData.user?.lastName || '');
    const [email, setEmail] = useState(storedUserData.user?.email || '');
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");

    const [errors, setErrors] = useState<string[]>([]);

    const [fetched, setFetched] = useState<boolean>(true);

    const dispatch = useAppDispatch();

    const handleSubmitWorkspace = async () => {
        try {
            // Trim whitespace from all input fields
            const trimmedTitle = title.trim();
            const trimmedFirstName = firstName.trim();
            const trimmedLastName = lastName.trim();
            const trimmedEmail = email.trim();

            let newErrors = [];

            if (trimmedTitle === '') newErrors.push('Название не может быть пустым');
            if (trimmedFirstName === '') newErrors.push('Имя не может быть пустым');
            if (trimmedLastName === '') newErrors.push('Фамилия не может быть пустой');
            if (trimmedEmail === '') newErrors.push('Электронная почта не может быть пустой');
            if (password === '') newErrors.push('Пароль не может быть пустым');
            if (password && !passwordConfirm) newErrors.push('Подтвердите пароль');
            if (password && passwordConfirm && password !== passwordConfirm) newErrors.push('Пароли не совпадают');

            const wsData = {
                user: { firstName: trimmedFirstName, lastName: trimmedLastName, email: trimmedEmail, password: password },
                workspace: { title: trimmedTitle }
            };
            storeUserData(wsData);

            if (newErrors.length > 0) {
                setErrors(newErrors);
                setFetched(true);
                return;
            }

            const res = await createUserAndWorkspace(
                { title: trimmedTitle },
                { first_name: trimmedFirstName, last_name: trimmedLastName, email: trimmedEmail, password: password }
            );
    
            if (res.user && res.workspace.title && res.permissions) {
                removeUserData();
                dispatch(login(res.user));
                dispatch(setPermissionsData({
                    role: res.permissions.role,
                    permissions: res.permissions.permissions,
                    version: res.permissions.version,
                }));

                navigate('/home');
            } else if (res.errors.length > 0) {
                setErrors(res.errors);
                setFetched(true);
            }

        } catch (e) {
            return e
        }
    }

    return(
        <form className="form">
            {
                errors.length > 0 &&
                    <ul className="form-task-error">
                        {errors.map((error: string)=>(
                            <li><p style={{'whiteSpace': 'pre-wrap', 'width': '90%'}}>{error}</p></li>
                        ))}
                    </ul>
            }

            <>
                <h3 style={{marginTop: '0'}}>Рабочее окружение</h3>
                <div className='form-group'>
                    <label className='form-label'>Название</label>
                    <input 
                        type="text" 
                        className="form-input" 
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        />
                </div>

                <h3>Пользователь</h3>
                <div className='form-group'>
                    <label className='form-label'>Имя</label>
                    <input 
                        type="text" 
                        className="form-input" 
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        />
                </div>
                
                <div className='form-group'>
                    <label className='form-label'>Фамилия</label>
                    <input
                        type="text" 
                        className="form-input" 
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)} 
                    />
                </div>

                <div className='form-group'>
                    <label className='form-label'>Электронная почта</label>
                    <input 
                        type="email"
                        className="form-input"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
            
                <div className='form-group'>
                    <label className='form-label'>Пароль</label>
                    <input 
                        type="password" 
                        className="form-input"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>

                <div className='form-group'>
                    <label className='form-label'>Подтверждение пароля</label>
                    <input 
                        type="password" 
                        className="form-input"
                        value={passwordConfirm}
                        onChange={(e) => setPasswordConfirm(e.target.value)}
                    />
                </div>
                
                <div className='form-group'>
                    {fetched ? 
                        <button className="form-submit" onClick={handleSubmitWorkspace} type={'button'}>Сохранить</button>
                        :
                        <CircularProgress/>
                    }
                   
                </div>  
            </>
        </form>
    )
}

export default SignupForm;