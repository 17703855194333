import {IUserProject} from "../../common/types/tasks";
import React from "react";

interface ProjectUsersTablesProps {
    usersToProject: IUserProject[];
    setUsersToProject: (users: IUserProject[]) => void;
    usersAvailable: IUserProject[];
}

const ProjectUsersTables:React.FC<ProjectUsersTablesProps> = (
    {usersToProject, setUsersToProject, usersAvailable}) => {

    const handleOptionToggle = (optionUser: IUserProject) => {
        let newSelectedOptions;
        if (usersToProject.some((up) => up.id === optionUser.id)) {
            newSelectedOptions = usersToProject.filter((up) => up.id !== optionUser.id);
        } else {
            newSelectedOptions = [...usersToProject, optionUser];
        }
        setUsersToProject(newSelectedOptions);
    };

    return(
        <>
            <div className={'users-list-to-project'}>
                {usersAvailable?.map((user) => (
                        <div className={'user-in-table'} key={user.id} onClick={() => (handleOptionToggle(user))}>
                            <input
                                type="checkbox"
                                value={user.id}
                                checked={usersToProject.some((up) => up.id === user.id)}
                                onChange={() => (handleOptionToggle(user))}
                            />
                            &emsp;
                            <div style={{height: '100%', width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                <span>{user.username}</span>
                                <span>{user.user_role}</span>
                            </div>
                            
                        </div>
                    )
                )}
            </div>
        </>

    )
}

export default ProjectUsersTables