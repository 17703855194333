// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-head {
    padding-left: 1rem;
    padding-right: 1rem;
}

.search-field-with-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.5rem;
    margin-bottom: 1rem;
}

.list-find-btn-with-icon {
    background-color: #273b64;
    border: none;
    border-radius: 100%;
    width: 2.3rem;
    height: 2.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0.5rem;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/SearchHead/SearchHead.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,cAAc;IACd,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,mBAAmB;IACnB,aAAa;IACb,cAAc;IACd,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,mBAAmB;IACnB,eAAe;AACnB","sourcesContent":[".search-head {\n    padding-left: 1rem;\n    padding-right: 1rem;\n}\n\n.search-field-with-btn {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    height: 2.5rem;\n    margin-bottom: 1rem;\n}\n\n.list-find-btn-with-icon {\n    background-color: #273b64;\n    border: none;\n    border-radius: 100%;\n    width: 2.3rem;\n    height: 2.3rem;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    margin-left: 0.5rem;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
