import {useEffect, useState} from "react";
import {fetchMeets} from "../../utils/axios";
import {IPaginatedResponse, IProject} from "../../common/types/tasks";
import {CircularProgress} from "@mui/material";
import Pagination from "../../components/Pagination";
import MeetsTable from "../../components/MeetsTable";
import {convertFiltersToParam, convertSearchToParam, filterEntries, getInitialFilterParams, getInitialSearchParams, isPresent, searchEntries, setParams} from "../../utils/utils";
import FiltersHeadMeets from "../FiltersHeadMeets";
import { Link } from "react-router-dom";
import { v4 as uuid } from 'uuid'

const MeetsList = () => {
    const [fetched, setFetched] = useState<boolean>();
    const [meetsData, setMeetsData] = useState<IPaginatedResponse>({ objects: [], previous: '', next: '', current_page: 0, count_objects: 0, count_pages: 0 });

    const [searchData, setSearchData] = useState<{
        title: string;
        start_at: string;
        end_at: string;
        page: string;
        page_size: string
    }>({
        title: getInitialSearchParams('title'),
        start_at: getInitialSearchParams('start_at'),
        end_at: getInitialSearchParams('end_at'),
        page: getInitialSearchParams('page'),
        page_size: getInitialSearchParams('page_size')
    });
    const [filterData, setFilterData] = useState<{ projects: IProject[] }>({ projects: [] });
    const [selectedFilters, setSelectedFilters] = useState<{ projects: number[] }>({ projects: getInitialFilterParams('project')});
    const handleSelectedChange = (filter: string) => (newSelectedOptions: number[]) => {
        setSelectedFilters(prev => ({ ...prev, [filter]: newSelectedOptions }));
    };

    const getMeetsParams = () => {
        return(
            Object.entries(searchData).reduce((acc, [filter, value]) => acc + convertSearchToParam(value, searchEntries[filter].param_name), '') + 
            Object.entries(selectedFilters).reduce((acc, [filter, values]) => acc + convertFiltersToParam(values, filterEntries[filter].param_name), '')
        )
    }

    const handleFilledSearch = (filter: string, value: string) => {
        setSearchData(prev => ({ ...prev, [filter]:value }));
    }

    const handleFilterApply = async () => {
        try {
            const params = getMeetsParams();
            setParams(params);
            setFetched(false);
        } catch (error) {
            console.error(error);
        }
    };

    const handlePrevNextBtn = (paginate_params: string) => async () => {
        try {
            const url_params = new URLSearchParams(paginate_params);
            handleFilledSearch('page', url_params.get('page') || '');
            handleFilledSearch('page_size', url_params.get('page_size') || '');

            setFetched(false);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        const getMeets = async () => {
            try {
                const curr_params = getMeetsParams();
                setParams(curr_params);

                const fetchedMeets = await fetchMeets(curr_params);
                setMeetsData(fetchedMeets.meets);
                setFilterData(fetchedMeets.filter_data);
                setFetched(true);
            } catch (error) {
                console.error(error);
            }
        };
        getMeets();

    }, [fetched]);

    const randomUUID = uuid();

    return(
        <>
            <div className={'head-with-add-btn'} style={{"marginBottom": "0"}}>
                <FiltersHeadMeets
                    searchData={searchData}
                    onSearchFilled={handleFilledSearch}
                    filterData={filterData}
                    selectedFilters={selectedFilters}
                    onSelectedChange={handleSelectedChange}
                    onApply={handleFilterApply}
                />
            </div>

            <div className={'head-with-add-btn'}>
                <div className={'searches-meet'}>
                    <Link to={`https://${process.env.REACT_APP_API_URL}/meets/q/${randomUUID}`}
                        className={"to-conference"}
                        target={'_blank'}>
                        Начать быструю встречу
                    </Link>                    
                </div>
            </div>

            <div className={'main-container task-list'}>
                {fetched ?
                    <>
                        <MeetsTable meets={meetsData.objects}/>
                        <Pagination data={meetsData} onPrevNext={handlePrevNextBtn} />
                    </>
                    :
                    <CircularProgress/>
                }

            </div>
        </>
    )
}

export default MeetsList
