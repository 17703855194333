import {Link} from "react-router-dom";
import NewTaskSmall from "../NewTaskSmall";
import React from "react";

interface AddTasksButtonsProps {
    onSuccessCreatedTask: (fetched: boolean) => void;
}

const AddTasksButtons: React.FC<AddTasksButtonsProps> = ({onSuccessCreatedTask}) => {
    return(
        <div className={'flex-with-spase'}>
            <Link to={'/tasks/new'} className={'add-task-btn'}>Добавить задачу &rarr;</Link>
            <NewTaskSmall onSuccessCreatedTask={onSuccessCreatedTask} />
        </div>
    )
}

export default AddTasksButtons