// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kanban-container {
    display: flex;
    margin-bottom: 1rem;
    white-space: pre-line;
    color: #273B64;
    min-height: calc(85vh - 4rem);
    width: auto;
    height: auto;
    border-top: 1px solid #EDEDED;
    overflow-x: auto;
    padding-left: 1rem;
    padding-right: 1rem;
}

.kanban-container.wide {
    justify-content: left;
}

.addNewColumn {
    display: flex;
    height: 2.5rem;
    margin: 22px 1rem 0 3rem;
    font-weight: normal;
    color: #ffffff;
    text-decoration: none;
}

.add-select {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 22px 1rem 0 3rem;
    padding: 0.5rem 1.5rem;
}

.form-task-select.add-column {
    /*min-width: 12rem;*/
    width: auto;
    margin-top: 0;
    margin-right: 0.5rem;
}`, "",{"version":3,"sources":["webpack://./src/components/KanbanBoard/KanbanBoard.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,qBAAqB;IACrB,cAAc;IACd,6BAA6B;IAC7B,WAAW;IACX,YAAY;IACZ,6BAA6B;IAC7B,gBAAgB;IAChB,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,aAAa;IACb,cAAc;IACd,wBAAwB;IACxB,mBAAmB;IACnB,cAAc;IACd,qBAAqB;AACzB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,wBAAwB;IACxB,sBAAsB;AAC1B;;AAEA;IACI,oBAAoB;IACpB,WAAW;IACX,aAAa;IACb,oBAAoB;AACxB","sourcesContent":[".kanban-container {\n    display: flex;\n    margin-bottom: 1rem;\n    white-space: pre-line;\n    color: #273B64;\n    min-height: calc(85vh - 4rem);\n    width: auto;\n    height: auto;\n    border-top: 1px solid #EDEDED;\n    overflow-x: auto;\n    padding-left: 1rem;\n    padding-right: 1rem;\n}\n\n.kanban-container.wide {\n    justify-content: left;\n}\n\n.addNewColumn {\n    display: flex;\n    height: 2.5rem;\n    margin: 22px 1rem 0 3rem;\n    font-weight: normal;\n    color: #ffffff;\n    text-decoration: none;\n}\n\n.add-select {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    margin: 22px 1rem 0 3rem;\n    padding: 0.5rem 1.5rem;\n}\n\n.form-task-select.add-column {\n    /*min-width: 12rem;*/\n    width: auto;\n    margin-top: 0;\n    margin-right: 0.5rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
