// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.right-sidebar {
    position: fixed;
    top: 0;
    right: -35%;
    width: 35%;
    height: 100%;
    background-color: white;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
    transition: right 0.3s ease, opacity 0.3s ease;
    opacity: 0;
    z-index: 1000;
    overflow-y: auto;
}

.right-sidebar.open {
    right: 0;
    opacity: 1;
}

.sidebar-content {
    padding: 20px;
}

.sidebar-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    transition: opacity 0.3s ease;
}

.sidebar-overlay.open {
    opacity: 1;
}

.open-sidebar-btn {
    cursor: pointer;
    font-size: 24px;
}
`, "",{"version":3,"sources":["webpack://./src/components/RightSidebar/RightSidebar.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,WAAW;IACX,UAAU;IACV,YAAY;IACZ,uBAAuB;IACvB,yCAAyC;IACzC,8CAA8C;IAC9C,UAAU;IACV,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,QAAQ;IACR,UAAU;AACd;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,oCAAoC;IACpC,YAAY;IACZ,6BAA6B;AACjC;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,eAAe;IACf,eAAe;AACnB","sourcesContent":[".right-sidebar {\n    position: fixed;\n    top: 0;\n    right: -35%;\n    width: 35%;\n    height: 100%;\n    background-color: white;\n    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);\n    transition: right 0.3s ease, opacity 0.3s ease;\n    opacity: 0;\n    z-index: 1000;\n    overflow-y: auto;\n}\n\n.right-sidebar.open {\n    right: 0;\n    opacity: 1;\n}\n\n.sidebar-content {\n    padding: 20px;\n}\n\n.sidebar-overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: rgba(0, 0, 0, 0.5);\n    z-index: 999;\n    transition: opacity 0.3s ease;\n}\n\n.sidebar-overlay.open {\n    opacity: 1;\n}\n\n.open-sidebar-btn {\n    cursor: pointer;\n    font-size: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
