import Layout from "../../components/layout"
import {useNavigate} from "react-router-dom";
import { useActionIsAllowed } from "../../utils/hook";
import './WorkspaceSettings.css'


const WorkspaceSettings = () => {
    const navigate = useNavigate();

    const userSettingsAllowed = useActionIsAllowed('user_show');
    const trackerSettingsAllowed = useActionIsAllowed('tracker_show') || true;
    const statusSettingsAllowed = useActionIsAllowed('status_show') || true;
    const complexitySettingsAllowed = useActionIsAllowed('complexity_show') || true;
    const subscriptionSettingsAllowed = useActionIsAllowed('subscription_show') || true;

    return(
        <Layout title="Настройки">
            <div className={'main-container task-list'}>
                <div className={'settings-container'}>
                    <div className={'form-task-col-left'}>
                        <div className={'form-task-group'}>
                            {userSettingsAllowed && 
                                <button className={'setting-list-btn'} onClick={() => (navigate('/settings/users'))}>&emsp;Пользователи</button>
                            }
                        </div>
                        <div className={'form-task-group'}>
                            {trackerSettingsAllowed && 
                                <button className={'setting-list-btn'} onClick={() => (navigate('/settings/trackers'))}>&emsp;Типы задач</button>
                            }   
                        </div>
                        <div className={'form-task-group'}>
                            {statusSettingsAllowed && 
                                <button className={'setting-list-btn'} onClick={() => (navigate('/settings/statuses'))}>&emsp;Статусы</button>
                            }   
                        </div>
                        <div className={'form-task-group'}>
                            {complexitySettingsAllowed && 
                                <button className={'setting-list-btn'} onClick={() => (navigate('/settings/complexities'))}>&emsp;Оценка сложности</button>
                            }   
                        </div>
                        <div className={'form-task-group'}>
                            {subscriptionSettingsAllowed &&
                                <button className={'setting-list-btn'} onClick={() => (navigate('/settings/subscription'))}>&emsp;Подписка и оплата</button>
                            }   
                        </div>
                    </div>
                </div> 
            </div>
        </Layout>
    )
}

export default WorkspaceSettings