import { useEffect, useState } from "react";
import Layout from "../../components/layout";
import { IStatus } from "../../common/types/tasks";
import { fetchStatuses, updateStatusNumber, createStatus, updateStatus } from "../../utils/axios";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { CircularProgress } from "@mui/material";
import ModalWindowEdit from "../../components/ModalWindowEdit";
import { useNavigate } from "react-router-dom";

const SettingsStatuses = () => {
    const navigate = useNavigate();
    const [fetched, setFetched] = useState<boolean>(false);
    const [statuses, setStatuses] = useState<IStatus[]>([]);
    const [errors, setErrors] = useState<string[]>([]);
    const [title, setTitle] = useState('');
    const [titleEdit, setTitleEdit] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [statusIdEdit, setStatusIdEdit] = useState<number | null>();

    useEffect(() => {
        const getStatuses = async () => {
            const fetchedStatuses = await fetchStatuses();
            if (fetchedStatuses.statuses) {
                setStatuses(fetchedStatuses.statuses.sort((a, b) => a.status_number - b.status_number));
            }
            setFetched(true);
        }

        if (!fetched) {
            getStatuses();
        }    
    }, [fetched]);

    const handleDragEnd = async (result: any) => {
        if (!result.destination) return;
    
        const statusItems = Array.from(statuses);
        const [reorderedItem] = statusItems.splice(result.source.index, 1);
        statusItems.splice(result.destination.index, 0, reorderedItem);
    
        const updatedStatuses = statusItems.map((status, index) => ({
            ...status,
            status_number: index + 1
        }));
    
        setStatuses(updatedStatuses);
    
        const req = await updateStatusNumber(updatedStatuses);
        if (req.errors) {
            setErrors(req.errors);
        }
    };
 
    const addNewStatus = async () => {
        setErrors([]);
        if (title === '') return

        const createdStatus = await createStatus(title)
        if (createdStatus.statuses) {
            setTitle('');
            setStatuses(createdStatus.statuses);
        } else if (createdStatus.errors) {
            setErrors(createdStatus.errors);
        }
    };

    const editStatus = async (status_id: number) => {
        setErrors([]);
        const editedStatus = await updateStatus(status_id, titleEdit)
        if (editedStatus.statuses) {
            setStatuses(editedStatus.statuses);
        } else if (editedStatus.errors) {
            setErrors(editedStatus.errors);
        }
    };    

    const showModalEditStatus = (title: string, status_id: number) => {       
        setTitleEdit(title);
        setStatusIdEdit(status_id);
        setShowModal(true);
    }

    const handleConfirm = () => {
        setShowModal(false);
        if (statusIdEdit) {
            editStatus(statusIdEdit)
        }     
    }

    const handleCancel = () => {
        setStatusIdEdit(null);
        setShowModal(false);
    }

    return (
        <Layout title={'Настройки. Статусы'}>
            <div className={'main-container task-list'}>

                <div className={'form-head'}>
                    <p></p>
                    <button className={'back-button'} onClick={() => navigate(-1)}>
                        <span>&lt;</span>
                    </button>
                </div>

                {errors.length > 0 &&
                    <div>
                        <ul>
                            {errors.map((error)=>(
                                <li>{error}</li>
                                ))}
                        </ul>
                    </div>}

                {fetched ? 
                    <div className={'widgets-container'}>
                        <div className={'widgets-order-index'}>
                            <div className={'w-index'}>#</div>
                            {statuses.map((status, i) => (
                                <div className={'w-index'}>{i+1}</div>
                            ))}   
                        </div>

                        <DragDropContext onDragEnd={handleDragEnd}>
                            <div className={'widgets-table'}>
                                <div className={'w-head'}>Статус</div>

                                <Droppable droppableId="droppable">
                                    {(provided) => (
                                        <div className={'w-name'} {...provided.droppableProps} ref={provided.innerRef}>
                                            {statuses.map((status, index) => (
                                                <Draggable key={index} draggableId={index.toString()} index={index}>
                                                    {(provided) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        className='widgets-row'
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={{ ...provided.draggableProps.style,}}
                                                    >
                                                        <span>&emsp;&equiv;&ensp;{status.title}</span>
                                                        <span className="add-widget" onClick={() => showModalEditStatus(status.title, status.id)}>&ensp;редактировать&ensp;</span>
                                                    </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>

                                <div className={'form-task-group'}>
                                    <br></br>
                                    <label className={'form-task-label'} htmlFor={'time_expected'}>Новый статус</label>
                                    <div className={'form-task-complexity'}>
                                        <input className={'form-task-input two-third-width'} name={'title-status'} placeholder={'Название статуса'}
                                                value={title} onChange={(e) => setTitle(e.target.value)}/>
                                        <button type={'button'} className="time-roles-btn add" onClick={() => addNewStatus()}>
                                            Добавить
                                        </button>
                                    </div>
                                </div>
                                
                            </div>
                        </DragDropContext>
                                                
                        {showModal && statusIdEdit && <ModalWindowEdit inputValue={titleEdit}
                                                                        setInputValue={setTitleEdit}
                                                                        inputLabel={"Название"}
                                                                        onConfirm={handleConfirm}
                                                                        onCancel={handleCancel}/>} 

                    </div>
                    :
                    <CircularProgress/>
                }
            </div>
        </Layout>
    );
}

export default SettingsStatuses;
