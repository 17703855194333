import {useEffect, useState} from "react";
import {DragDropContext} from 'react-beautiful-dnd';
import TasksListDroppable from "../TasksListDroppable";
import {IProject, ITask} from "../../common/types/tasks";
import {fetchTasks, fetchTasksToSprint} from "../../utils/axios";
import {CircularProgress} from "@mui/material";
import PaginationDroppable from "../PaginationDroppable";
import SearchHead from "../SearchHead";
import './TasksTables.css'

interface TasksTablesProps {
    tasksToSprint: ITask[];
    setTasksToSprint: (tasks: ITask[]) => void;
    project: number | null;
    setProject: (project: number | null) => void;
}

const TasksTables: React.FC<TasksTablesProps> = ({tasksToSprint, setTasksToSprint, project, setProject}) => {
    const [fetched, setFetched] = useState<boolean>(false);

    const [tasksBacklog, setTasksBacklog] = useState<ITask[]>([]);
    const [prevLink, setPrevLink] = useState<string | undefined>('');
    const [nextLink, setNextLink] = useState<string | undefined>('');
    const [countPages, setCountPages] = useState(0);
    const [countTasks, setCountTasks] = useState(0);
    const [currPage, setCurrPage] = useState(0);

    const [searchData, setSearchData] = useState<string>('');

    const [projects, setProjects] = useState<IProject[]>([]);

    useEffect(() => {
        const getTasksSprint = async () => {
            try {
                const fetchedTasks = await fetchTasksToSprint();
                setTasksBacklog(fetchedTasks.tasks.objects);
                setNextLink(fetchedTasks.tasks.next);
                setPrevLink(fetchedTasks.tasks.prev);
                setCountPages(fetchedTasks.tasks.count_pages);
                setCountTasks(fetchedTasks.tasks.count_objects);
                setCurrPage(fetchedTasks.tasks.current_page);

                setProjects(fetchedTasks.projects);
                setProject(fetchedTasks.projects[0].id);

            } catch (error) {
                console.error(error);
            }
            setFetched(true);
        };

        if (!fetched) {
            getTasksSprint();
        }
    }, [fetched]);

    const handleOnDragEnd = (result: any) => {
        if (!result.destination) return;
        const { source, destination } = result;

        if (source.droppableId !== destination.droppableId) {
            const sourceList = source.droppableId === 'droppable1' ? tasksToSprint : tasksBacklog;
            const destList = destination.droppableId === 'droppable1' ? tasksToSprint : tasksBacklog;
            const [removed] = sourceList.splice(source.index, 1);
            destList.splice(destination.index, 0, removed);

            if (source.droppableId === 'droppable1') {
                setTasksToSprint([...sourceList]);
                setTasksBacklog([...destList]);
            } else {
                setTasksToSprint([...destList]);
                setTasksBacklog([...sourceList]);
            }
        } else {
            const [removed] = tasksToSprint.splice(source.index, 1);
            tasksToSprint.splice(destination.index, 0, removed);
            setTasksToSprint([...tasksToSprint]);
        }
    }

    const handlePrevNextBtn = (url: string) => async () => {
        try {
            const fetchedTasks = await fetchTasksToSprint(
                { params: url, excluded: tasksToSprint.map((task)=>(task.id))}
            );
            setTasksBacklog(fetchedTasks.tasks.objects);
            setNextLink(fetchedTasks.tasks.next);
            setPrevLink(fetchedTasks.tasks.prev);
            setCountPages(fetchedTasks.tasks.count_pages);
            setCountTasks(fetchedTasks.tasks.count_objects);
            setCurrPage(fetchedTasks.tasks.current_page);
        } catch (error) {
            console.error(error);
        }
    }

    const handleFilledSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchData(event.target.value);
    }

    const handleFilterApply = async () => {
        try {
            const fetchedTasks = await fetchTasksToSprint({ params: `?title=${searchData}&project=${project || ''}` });
            setTasksBacklog(fetchedTasks.tasks.objects);
            setNextLink(fetchedTasks.tasks.next);
            setPrevLink(fetchedTasks.tasks.prev);
            setCountPages(fetchedTasks.tasks.count_pages);
            setCountTasks(fetchedTasks.tasks.count_objects);
            setCurrPage(fetchedTasks.tasks.current_page);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        handleFilterApply();
    }, [project]);

    return(
        <>
            {fetched && tasksBacklog && projects ?
                <>
                    <div className={'form-task-col-left'}>
                        <div className={'form-task-group'}>
                            <label className={'form-task-label'} htmlFor={'project'}>Проект</label>
                            <select className={'form-task-select'}
                                    name={'project'}
                                    placeholder={'Проект'}
                                    value={project !== null ? project.toString() : ''}
                                    onChange={(e) => setProject(parseInt(e.target.value) || null)}
                            >
                                {projects.map((project) => (
                                    <option key={project.id} value={project.id}>{project.title}</option>
                                ))}
                            </select>
                            <p>После выбора проекта "Бэклог" и "Задачи" будут обновлены</p>
                        </div>
                    </div>
                    <div className={'form-task-col-right'}></div>
                    <div className={'form-task-col-center'}>
                        <div className={'form-task-group'}>
                            <DragDropContext onDragEnd={handleOnDragEnd}>
                                <div className={'form-task-col-left'}>
                                    <label className={'form-task-label'} htmlFor={'start_at'}>Бэклог</label>
                                    <div className={'tasks-list-to-sprint-backlog'}>
                                        {fetched ?
                                            <>
                                                <SearchHead
                                                    searchData={searchData}
                                                    onSearchFilled={handleFilledSearch}
                                                    onApply={handleFilterApply}
                                                />
                                                <TasksListDroppable tasks={tasksBacklog} droppableId={'droppable2'} />
                                                <PaginationDroppable prev={prevLink}
                                                                    next={nextLink}
                                                                    count_pages={countPages}
                                                                    count_objects={countTasks}
                                                                    current_page={currPage}
                                                                    onPrevNext={handlePrevNextBtn} />
                                            </>
                                            :
                                            <CircularProgress/>
                                        }
                                    </div>
                                </div>
                                <div className={'form-task-col-right'}>
                                    <label className={'form-task-label'} htmlFor={'start_at'}>Задачи</label>
                                    <div className={'tasks-list-to-sprint'}>
                                        <TasksListDroppable tasks={tasksToSprint} droppableId={'droppable1'} />
                                    </div>
                                </div>
                            </DragDropContext>
                        </div>
                    </div>
                </>
                :
                <CircularProgress />
            }
        </>

    )
}

export default TasksTables