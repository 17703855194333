import { FC } from "react";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";
import "react-datepicker/dist/react-datepicker.css";
import "./Calendar.css"

interface CalendarProps {
    dateAt: Date | null,
    minDate: Date | null,
    maxDate: Date | null,
    setDateAt: (date: Date | null) => void,
    disabled?: boolean,
    onSearchFill?: (title: string, date: String | null) => void | null,
    title_name?: string
}

const Calendar: FC<CalendarProps> = (
    { dateAt, minDate, maxDate, setDateAt, disabled, onSearchFill, title_name }) => {
    registerLocale("ru", ru);

    const handleCalendarChange = (date: Date) => {
        setDateAt(date);
        if (typeof onSearchFill !== "undefined" && typeof title_name !== "undefined") {
            onSearchFill(title_name, date ? date.toISOString() : new Date().toISOString());
        }
    }

    return (
        <>
            <DatePicker selected={dateAt}
                        locale={"ru"}
                        dateFormat="dd-MM-yyyy"
                        minDate={minDate}
                        maxDate={maxDate}
                        onChange={(date: Date) => handleCalendarChange(date)}
                        className={disabled ? "form-task-select disabled" : "form-task-select"}
                        disabled={disabled}
                        placeholderText={"дд-мм-гггг"}
            />
        </>
    );
}

export default Calendar