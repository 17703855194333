import { useEffect, useState } from "react";
import Layout from "../../components/layout";
import { ITracker } from "../../common/types/tasks";
import { fetchTrackers, updateTrackersNumber, createTracker, destroyTracker, updateTracker } from "../../utils/axios";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { CircularProgress } from "@mui/material";
import ModalWindow from "../../components/ModalWindow";
import ModalWindowEdit from "../../components/ModalWindowEdit";
import { useNavigate } from "react-router-dom";
import "./SettingsTrackers.css"

const SettingsTrackers = () => {
    const navigate = useNavigate();
    const [fetched, setFetched] = useState<boolean>(false);
    const [trackers, setTrackers] = useState<ITracker[]>([]);
    const [errors, setErrors] = useState<string[]>([]);
    const [title, setTitle] = useState('');
    const [titleEdit, setTitleEdit] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [trackerIdEdit, setTrackerIdEdit] = useState<number | null>();
    const [trackerIdDelete, setTrackerIdDelete] = useState<number | null>();

    useEffect(() => {
        const getTrackers = async () => {
            const fetchedTrackers = await fetchTrackers();
            if (fetchedTrackers.trackers) {
                setTrackers(fetchedTrackers.trackers.sort((a, b) => a.tracker_number - b.tracker_number));
            }
            setFetched(true);
        }

        if (!fetched) {
            getTrackers();
        }    
    }, [fetched]);

    const handleDragEnd = async (result: any) => {
        setErrors([]);
        if (!result.destination) return;
    
        const trackerItems = Array.from(trackers);
        const [reorderedItem] = trackerItems.splice(result.source.index, 1);
        trackerItems.splice(result.destination.index, 0, reorderedItem);
    
        const updatedTrackers = trackerItems.map((tracker, index) => ({
            ...tracker,
            order_number: index + 1
        }));
    
        setTrackers(updatedTrackers);
    
        const req = await updateTrackersNumber(updatedTrackers);
        if (req.errors) {
            setErrors(req.errors);
        }
    };
 
    const addNewTracker = async () => {
        setErrors([]);
        if (title === '') return

        const createdTracker = await createTracker(title)
        if (createdTracker.trackers) {
            setTitle('');
            setTrackers(createdTracker.trackers);
        } else if (createdTracker.errors) {
            setErrors(createdTracker.errors);
        }
    };

    const editTracker = async (tracker_id: number) => {
        setErrors([]);
        const editedTracker = await updateTracker(tracker_id, titleEdit)
        if (editedTracker.trackers) {
            setTrackers(editedTracker.trackers);
        } else if (editedTracker.errors) {
            setErrors(editedTracker.errors);
        }
    };    
 
    const removeTracker = async (tracker_id: number) => {
        setErrors([]);
        const destroyedTracker = await destroyTracker(tracker_id)
        if (destroyedTracker.trackers) {
            setTrackers(destroyedTracker.trackers);
        } else if (destroyedTracker.errors) {
            setErrors(destroyedTracker.errors);
        }
    };   

    const showModalEditTracker = (title: string, tracker_id: number) => {
        setTrackerIdDelete(null);
        
        setTitleEdit(title);
        setTrackerIdEdit(tracker_id);
        setShowModal(true);
    }

    const showModalDeleteTracker = (tracker_id: number) => {
        setTitleEdit('');
        setTrackerIdEdit(null);

        setTrackerIdDelete(tracker_id);
        setShowModal(true);
    }

    const handleConfirm = () => {
        setShowModal(false);
        if (trackerIdDelete) {
            removeTracker(trackerIdDelete); 
        }
        if (trackerIdEdit) {
            editTracker(trackerIdEdit)
        }     
    }

    const handleCancel = () => {
        setTrackerIdDelete(null);
        setTrackerIdEdit(null);
        setShowModal(false);
    }

    return (
        <Layout title={'Настройки. Типы задач'}>
            <div className={'main-container task-list'}>

                <div className={'form-head'}>
                    <p></p>
                    <button className={'back-button'} onClick={() => navigate(-1)}>
                        <span>&lt;</span>
                    </button>
                </div>

                {errors.length > 0 &&
                    <div>
                        <ul>
                            {errors.map((error)=>(
                                <li>{error}</li>
                                ))}
                        </ul>
                    </div>}

                {fetched ? 
                    <div className={'widgets-container'}>
                        <div className={'widgets-order-index'}>
                            <div className={'w-index'}>#</div>
                        
                            {trackers.map((tracker, i) => (
                                <div className={'w-index'}>{i+1}</div>
                            ))}
                            
                        </div>

                        <DragDropContext onDragEnd={handleDragEnd}>
                            <div className={'widgets-table'}>
                                <div className={'w-head'}>Тип</div>

                                <Droppable droppableId="droppable">
                                    {(provided) => (
                                        <div className={'w-name'} {...provided.droppableProps} ref={provided.innerRef}>
                                            {trackers.map((tracker, index) => (
                                                <Draggable key={index} draggableId={index.toString()} index={index}>
                                                    {(provided) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        className='widgets-row'
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={{ ...provided.draggableProps.style,}}
                                                    >
                                                        <span>&emsp;&equiv;&ensp;{tracker.title}</span>
                                                        <div>
                                                            <span className="add-widget" onClick={() => showModalEditTracker(tracker.title, tracker.id)}>&ensp;редактировать&ensp;</span>
                                                            &ensp;
                                                            <span className="remove-widget" onClick={() => showModalDeleteTracker(tracker.id)}>&ensp;удалить&ensp;</span>
                                                        </div>
                                                    </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>

                                <div className={'form-task-group'}>
                                    <br></br>

                                    <label className={'form-task-label'} htmlFor={'time_expected'}>Новый Тип</label>
                                    <div className={'form-task-complexity'}>
                                        <input className={'form-task-input two-third-width'} name={'title-tracker'} placeholder={'Название типа'}
                                                value={title} onChange={(e) => setTitle(e.target.value)}/>
                                        <button type={'button'} className="time-roles-btn add" onClick={() => addNewTracker()}>
                                            Добавить
                                        </button>
                                    </div>
                                </div>
                                
                            </div>
                        </DragDropContext>

                        {showModal && trackerIdDelete && <ModalWindow title="Вы уверены, что хотите удалить тип? Все задачи будут перенесены в стандартный тип"
                                                                      onConfirm={handleConfirm}
                                                                      onCancel={handleCancel}/>}
                                                
                        {showModal && trackerIdEdit && <ModalWindowEdit inputValue={titleEdit}
                                                                        setInputValue={setTitleEdit}
                                                                        inputLabel={"Название"}
                                                                        onConfirm={handleConfirm}
                                                                        onCancel={handleCancel}/>} 

                    </div>
                    :
                    <CircularProgress/>
                }
            </div>
        </Layout>
    );
}

export default SettingsTrackers;
