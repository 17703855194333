import {configureStore} from "@reduxjs/toolkit";
import authReducer from "./slice/auth";
import permissionsReducer from "./slice/permissions"
import { loadState } from "./browser-storage";

const store = configureStore({
    reducer: {
        auth: authReducer,
        permissions: permissionsReducer,
    },
    preloadedState: loadState()
});

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>

export default store;
