import { FC } from 'react';
import { Link } from "react-router-dom";
import { IBoardList } from '../../common/types/boards';

interface BoardsTableProps {
    boards: IBoardList[];
}
const BoardsTable: FC<BoardsTableProps> = ({ boards }) => {
    return (
        <div className={'list-table'}>
            <table className={'table-main'}>
                <thead className={'table-head'}>
                <tr>
                    <th className={'th-sprint-title'}>Название</th>
                    <th className={'th-sprint-readiness'}>Прогресс</th>
                    <th className={'th-sprint-project'}>Проект</th>
                    <th className={'th-sprint-author'}>Автор</th>
                </tr>
                </thead>
                <tbody className={'table-body'}>
                    {boards.map((board) => (
                        <tr key={board.number} className={'task-row'}>
                            <td className={'td-sprint-title'}><Link to={`/boards/${board.number}`} >{board.title}</Link></td>
                            <td className={'td-sprint-readiness'}>
                                <span className={'progress-block'}>
                                    <div className={'progress-bar'}>
                                        <div className={'progress'} style={{'width': `${board.readiness}%`}}></div>
                                    </div>
                                    {board.readiness}%
                                </span>
                            </td>
                            <td className={'td-sprint-project'}>
                                <Link to={`/projects/${board.project_number}/`}>
                                    {board.project}
                                </Link>
                            </td>
                            <td className={'td-sprint-author'}>{board.author}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default BoardsTable;