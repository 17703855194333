import {CircularProgress} from "@mui/material";
import {BoardData, IBoardInfo} from "../../common/types/boards";
import {useEffect, useState} from "react";
import {createColumnInBoard, deleteColumnInBoard, fetchBoard } from "../../utils/axios";
import {IStatus} from "../../common/types/tasks";
import KanbanBoard from '../KanbanBoard';
import { Link, useNavigate } from "react-router-dom";


const BoardShow = ({boardNumber}: {boardNumber: string}) => {

    const [errors, setErrors] = useState<string[]>([]);
    const [fetched, setFetched] = useState<boolean>();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [boardInfo, setBoardInfo] = useState<IBoardInfo>();
    const [columns, setColumns] = useState<BoardData>();
    const [statuses, setStatuses] = useState<IStatus[]>([]);
    const [newColumn, setNewColumn] = useState<number>();
    const navigate = useNavigate();

    const addNewColumn = async () => {
        setErrors([]);
        const statusToAdd = statuses.find(status => status.id === newColumn);
        if (!statusToAdd) return;

        const createNewColumn = await createColumnInBoard(parseInt(boardNumber), {status_id_to_create: statusToAdd.id})
        if (!createNewColumn.errors) {
            setColumns(prevColumns => ({
                ...prevColumns,
                [createNewColumn.column.title]: createNewColumn.column,
            }))
            const updatedStatuses = statuses.filter(status => status.id !== statusToAdd.id);
            setStatuses(updatedStatuses);
        } else {
            setErrors(createNewColumn.errors);
        }
    }

    const deleteColumn = async (column_id: number) => {
        setErrors([]);
        const deleteColumnResponse = await deleteColumnInBoard(parseInt(boardNumber), column_id);

        if (!deleteColumnResponse.errors) {
            setColumns(prevColumns => {
                const newColumns = {...prevColumns};
                const columnKeyToDelete = Object.keys(newColumns).find(key => newColumns[key].id === column_id);
                if (columnKeyToDelete) {
                    delete newColumns[columnKeyToDelete];
                }
                newColumns[deleteColumnResponse.default_column.title] = deleteColumnResponse.default_column;
                return newColumns;
            });

            if (deleteColumnResponse.deleted_status) {
                const deletedStatus: IStatus = deleteColumnResponse.deleted_status
                setStatuses(prevStatuses => [...prevStatuses, deletedStatus])
            }
        } else {
            setErrors(deleteColumnResponse.errors);
        }
    }

    useEffect(() => {
        const getBoard = async () => {
            try {
                const fetchedBoard = await fetchBoard(boardNumber);
                setColumns(fetchedBoard.board);
                setBoardInfo(fetchedBoard.board_info);
                if (fetchedBoard.statuses)
                    setStatuses(fetchedBoard.statuses);
            } catch (error) {
                console.error(error);
            }
            setFetched(true);
        };
        if (!fetched)
            getBoard();
    }, [fetched])

    useEffect(() => {
        if (statuses.length > 0)
            setNewColumn(statuses[0].id)
    }, [statuses]);

    return(
        <>
            <div className={'main-container task-list '}>
                {fetched && boardInfo && columns ?
                    <>
                    <div className={'head-board ' + (isOpen ? 'active kanban ' : '') + ((Number.isInteger(boardInfo.project_id) && isOpen) ? 'with-project-kanban' : '')}>
                        <div className={'list-head-board'}>
                            <span className={'title-sprint'}>
                                <h3>{boardInfo.title}</h3>
                                <button className={'list-button small'} onClick={() => setIsOpen(!isOpen)}>
                                    {isOpen ? <span>&and;</span> : <span>&or;</span>}
                                </button>
                            </span>

                            <button className={'list-button back'} onClick={() => navigate(-1)}><span>&lt;</span></button>

                        </div>
                        <div className={'sprint-info ' + (isOpen ? 'active' : '')}>
                            <p className={'sprint-progress'}><strong>Прогресс: </strong>
                                <span className={'progress-block'}>
                                    <div className={'progress-bar'}>
                                        <div className={'progress'} style={{'width': `${boardInfo.readiness}%`}}></div>
                                    </div>
                                    {boardInfo.readiness}%
                                </span>
                            </p>
                            <p><strong>Трудозатраты: </strong>{boardInfo.time_spent_to_tasks} ч</p>
                            <p>
                                <strong>Количество задач: </strong>{boardInfo.tasks_count}
                                <Link to={'/tasks/?board=' + boardInfo.id} className={'to-list-task-btn'}>Перейти к списку задач</Link>
                            </p>
                            {boardInfo.project && <p><strong>Проект: </strong>{boardInfo.project}</p>}
                            <p><strong>Автор: </strong>{boardInfo.author}</p>

                            <div className={'flex-with-spase'}>
                                {/* <img className={'task-board-btn'} src={'/app/edit.svg'}
                                     onClick={() => navigate(`/boards/${boardNumber}/edit`)}/> */}
                                {/* <button className={'add-task-btn'} onClick={() => (onClickCloseSprint())}>Завершить спринт</button> */}
                            </div>
                        </div>
                    </div>

                        {errors.length > 0 &&  
                            <ul>
                                {errors.map((error)=>(
                                    <li>{error}</li>
                                ))}
                            </ul>
                        }

                        <KanbanBoard columns={columns}
                                     setColumns={setColumns}
                                     addNewColumn={addNewColumn}
                                     deleteColumn={deleteColumn}
                                     statuses={statuses}
                                     setNewColumn={setNewColumn}
                                     newColumn={newColumn}
                                     boardId={parseInt(boardNumber)}
                                     projectId={boardInfo.project_id}
                                     setErrors={setErrors}
                                     setFetched={setFetched}
                        />
                    </>
                :
                    <CircularProgress/>
                }
            </div>
        </>
    )
}

export default BoardShow