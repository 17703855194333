import { useEffect, useRef, useState } from "react";

interface SingleSelectProps {
    key: string;
    options: { id: number; title: string; }[];
    preselectedOption: number;
    onSelectedOptionChange: (selectedOption: number) => void;
}

const SingleSelect: React.FC<SingleSelectProps> = ({
    key,
    options,
    preselectedOption,
    onSelectedOptionChange,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState<number | undefined>(preselectedOption);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const handleOptionSelect = (optionId: number) => {
        setSelectedOption(optionId);
        onSelectedOptionChange(optionId);
        setIsOpen(false);
    };

    const handleDropdownToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleOutsideClick = (event: MouseEvent) => {
        const target = event.target as HTMLElement;
        if (dropdownRef.current && !dropdownRef.current.contains(target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    return (
        <div className="multi-select" ref={dropdownRef}>
            <span className={'dropdown-toggle sprint-type ' + (isOpen ? 'active' : '')} onClick={handleDropdownToggle}>
                {options.find(option => option.id === selectedOption)?.title}
            </span>

            <div className={'dropdown-menu ' + (isOpen ? 'active' : '')}>
                {options.map(option => (
                    <label key={option.id}>
                        <input
                            type="radio"
                            value={option.id}
                            checked={selectedOption === option.id}
                            onChange={() => handleOptionSelect(option.id)}
                        />
                        {option.title}
                    </label>
                ))}
            </div>
            {/* <input type="hidden" value={selectedOption} /> */}
        </div>
    );
}

export default SingleSelect;
