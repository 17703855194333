import React from 'react';
import Sidebar from '../sidebar';
import {Link} from "react-router-dom";
import './layout.css';
import '../sidebar/sidebar.css'

interface LayoutProps {
    children: React.ReactNode;
}

const Layout: React.FC<LayoutProps & { title: string, addButton?: string, homeBtnClick?: () => void, img_src?: string }> = ({ children, title, addButton, homeBtnClick, img_src }) => {
    return (
        <div className="layout">
            <Sidebar title={title} />
            <div className={'mainLayout'}>
                <div className={'title-layout-and-btn'}>
                    <div className={'title-layout'}>
                        <h1 className={'title'}>{title}</h1>
                        { (title === 'Моя страница' && homeBtnClick) && <img className={'task-btn'} src={img_src} onClick={homeBtnClick} /> }
                    </div>
                    { addButton === 'addTask' &&
                        <Link to={'/tasks/new'} className={'add-task-btn'}>Добавить задачу &rarr;</Link>
                    }
                    { addButton === 'addSprint' &&
                        <Link to={'/sprints/new'} className={'add-task-btn'}>Добавить спринт +</Link>
                    }
                    { addButton === 'addProject' &&
                        <Link to={'/projects/new'} className={'add-task-btn'}>Добавить проект +</Link>
                    }
                    { addButton === 'addMeet' &&
                        <Link to={'/meets/new'} className={'add-task-btn conference'}>Добавить видеоконференцию +</Link>
                    }
                    { addButton === 'addUser' &&
                        <Link to={'/settings/users/new'} className={'add-task-btn conference'}>Добавить пользователя +</Link>
                    }
                    { addButton === 'addBoard' &&
                        <Link to={'/boards/new'} className={'add-task-btn'}>Добавить доску +</Link>
                    }
                </div>

                {children}
            </div>
        </div>
    );
};

export default Layout;