import './Pagination.css'

const Pagination = ({ data, onPrevNext }: any) => (
    <div className={'list-bottom'}>
        <button className={`list-button prev ${data.previous === null ? 'unactive' : ''}`} onClick={onPrevNext(data.previous)} disabled={data.previous === null}>
            <span>&lt;</span>
        </button>

        {Array.from({ length: data.count_pages }, (_, i) => i + 1)
            .map((page_number) => (
                    <span key={page_number} className={'paginate-number ' + (page_number === data.current_page ? 'active' : '')}>
                    {page_number}
                </span>
                )
            )}

        <button className={`list-button next ${data.next === null ? 'unactive' : ''}`} onClick={onPrevNext(data.next)} disabled={data.next === null}>
            <span>&gt;</span>
        </button>
        
        <div className={'paginate-total'}>
            <div>
                <span>На странице: </span>
                <span className={'page-size'} onClick={onPrevNext('page_size=15')}>15</span>
                <span className={'page-size'} onClick={onPrevNext('page_size=50')}>50</span>
                <span className={'page-size'} onClick={onPrevNext('page_size=100')}>100</span>
            </div>
            <span>Найдено: {data.count_objects} элементов </span>
        </div>

    </div>
)

export default Pagination