// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tasks-small-table {
    display: flex;
    flex-direction: column;
    width: 95%;
    overflow-x: auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.tasks-small-header, .table-row {
    display: flex;
    justify-content: start;
}

.header-item, .body-item {
    overflow: hidden;
    flex: 1 1;
    padding: 10px;
    width: 6rem;
    height: 2rem;
    border: 1px solid #EDEDED;
    word-break: keep-all;
    margin: 0.1rem;
}

.header-item {
    font-weight: bolder;
}

.fixed-width {
    max-width: 10rem;
    height: 2rem;
}`, "",{"version":3,"sources":["webpack://./src/components/SimpleTable/SimpleTable.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,UAAU;IACV,gBAAgB;IAChB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,gBAAgB;IAChB,SAAO;IACP,aAAa;IACb,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,oBAAoB;IACpB,cAAc;AAClB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,YAAY;AAChB","sourcesContent":[".tasks-small-table {\n    display: flex;\n    flex-direction: column;\n    width: 95%;\n    overflow-x: auto;\n    margin-top: 1rem;\n    margin-bottom: 1rem;\n}\n\n.tasks-small-header, .table-row {\n    display: flex;\n    justify-content: start;\n}\n\n.header-item, .body-item {\n    overflow: hidden;\n    flex: 1;\n    padding: 10px;\n    width: 6rem;\n    height: 2rem;\n    border: 1px solid #EDEDED;\n    word-break: keep-all;\n    margin: 0.1rem;\n}\n\n.header-item {\n    font-weight: bolder;\n}\n\n.fixed-width {\n    max-width: 10rem;\n    height: 2rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
