// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tracker-item {
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 10px;
    border: 1px solid #EDEDED;
}
`, "",{"version":3,"sources":["webpack://./src/pages/SettingsTrackers/SettingsTrackers.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,uBAAuB;IACvB,aAAa;IACb,yBAAyB;AAC7B","sourcesContent":[".tracker-item {\n    overflow: hidden;\n    text-overflow: ellipsis;\n    padding: 10px;\n    border: 1px solid #EDEDED;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
