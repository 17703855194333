import { useEffect, useState } from 'react';
import { fetcheWikiPage, fetcheWikiPageMain } from '../../utils/axios';
import { IWikiPage } from '../../common/types/tasks';
import { CircularProgress } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import remarkGfm from 'remark-gfm';
import rehypeSlug from 'rehype-slug';
import rehypeRaw from 'rehype-raw'
import rehypeAutolinkHeadings from 'rehype-autolink-headings';
import Markdown from 'react-markdown';

type WikiPageDisplayType = 'mainWS' | 'mainProject' | 'show';


const WikiPageShow = ({slug, pageType, canEdit, canCreate} :
                         {slug?: string, pageType: WikiPageDisplayType, canEdit: boolean, canCreate: boolean}) => {

    const {project_id} = useParams();

    const [fetched, setFetched] = useState<boolean>(false);
    const [page, setPage] = useState<IWikiPage>();
   
    useEffect(() => {
        const getWikiPageShow = async () => {
            if (!slug) return;
            
            const fetchedWikiPage = await fetcheWikiPage(slug);
            if (fetchedWikiPage) {
                setPage(fetchedWikiPage.wiki_page);
                setFetched(true);
            }
        }

        const getWikiPageMain = async () => {
            if (pageType === 'mainProject' && project_id) {
                const fetchedWikiPage = await fetcheWikiPageMain(pageType, project_id);
                if (fetchedWikiPage) {
                    setPage(fetchedWikiPage.wiki_page);
                    setFetched(true);
                }
            }
            if (pageType === 'mainWS') {
                const fetchedWikiPage = await fetcheWikiPageMain(pageType);
                if (fetchedWikiPage) {
                    setPage(fetchedWikiPage.wiki_page);
                    setFetched(true);
                }
            }
        }

        if (pageType === 'mainWS' || pageType === 'mainProject') {
            getWikiPageMain();
        } else if (pageType === 'show') {
            getWikiPageShow();
        }     
    }, []);

    return (
        <>
            <div className={'header-wiki'}>
                <div className={'right-links'}>
                    {(pageType === 'mainWS' || pageType === 'mainProject') && 
                        <>
                            {canCreate &&
                                <Link to={'/wiki/new/'}>Новая страница</Link>}
                            <Link to={`/wiki/pages/`}>Список страниц</Link>
                            {canEdit && 
                                <Link to={`/wiki/pages/${page?.slug}/edit?project_id=` + (page?.project_id ? page?.project_id : '' )}>
                                    Редактировать
                                </Link>}
                        </>
                    }

                    {pageType === 'show' && 
                        <>
                            {canCreate &&
                                <Link to={'/wiki/new/'}>Новая страница</Link>}
                            {page && page.project_id ? (
                                <Link to={`/wiki/project/${page.project_number}`}>Главная страница</Link>
                            ):(
                                <Link to={`/wiki/`}>Главная страница</Link>
                            )}
                            <Link to={`/wiki/pages/`}>Список страниц</Link>
                            {canEdit && 
                                <Link to={`/wiki/pages/${page?.slug}/edit?project_id=` + (page?.project_id ? page?.project_id : '' )}>
                                    Редактировать
                                </Link>}
                        </>
                    }
                </div>
            </div>
            
            <div className={'main-container wiki'}>
                {fetched ?
                    <>
                        <div className={'wiki-container'}>
                            {page &&
                                <Markdown
                                   remarkPlugins={[remarkGfm]}
                                   rehypePlugins={[rehypeSlug, rehypeAutolinkHeadings, rehypeRaw]}>
                                  {page.content}
                                </Markdown>
                            }                        
                        </div>
                    </>
                    :
                    <CircularProgress />
                }
            </div>

            <div className={'header-wiki'}>
                {page && 
                <>
                    {page.project_id ? 
                        (<p><span>Проект: </span><Link to={`/projects/${page.project_id}`}>{page.project}</Link></p>)
                            : 
                        ''
                    }
                    <p><span>Создана: </span>{page.created_at_formatted}</p>
                </>
                    
                }
            </div>
            

        </>
    );
};

export default WikiPageShow;
