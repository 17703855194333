import { useState, useEffect, useRef, useCallback } from "react";
import { createEvent, deleteEvent, fetchCalendarEvents, updateEvent } from "../../utils/axios";
import { ICalendarEvent, ITaskEvent } from "../../common/types/tasks";
import { CircularProgress } from "@mui/material";
import CalendarEvents from "../CalendarEvents";
import EventModal from "../EventModal";
import FullCalendar from "@fullcalendar/react";

const HomeCalendar = () => {
    const [fetched, setFetched] = useState<boolean>(false);
    const [events, setEvents] = useState<ICalendarEvent[]>([]);
    const [errors, setErrors] = useState<string[]>([]);
    const [modalPosition, setModalPosition] = useState<{ top: number; left: number } | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalType, setModalType] = useState<"new" | "edit">("new");
    const [eventData, setEventData] = useState<{id?: number, title?: string; start: Date, end: Date, jsEvent?: any, task?: ITaskEvent} | null>(null);
    const [currentView, setCurrentView] = useState(() => {
        return localStorage.getItem("calendarView") || "dayGridMonth";
    });
    const calendarRef = useRef<FullCalendar>(null);
    const [dateRange, setDateRange] = useState<{ start: string; end: string } | null>(null);

    useEffect(() => {   
        const getEvents = async () => {
            try {
                const params = `start_at=${dateRange?.start || ''}&end_at=${dateRange?.end || ''}`;
                const fetchedEvents = await fetchCalendarEvents(params);
                setEvents(fetchedEvents.events);
            } catch (error) {
                console.error(error);
                setErrors(["Не удалось загрузить события"]);
            }
        };

        if (dateRange) {
            getEvents();
        }
    }, [dateRange]);

    const handleSelect = (selectInfo: any) => {
        setErrors([]);
        if (selectInfo.end.getDate() !== selectInfo.start.getDate()) { return }
    
        setEventData(
            {
                start: selectInfo.start,
                end: selectInfo.end,
                jsEvent: selectInfo.jsEvent
            }
        )

        setModalType("new");
        setIsModalOpen(true);
    };

    const handleEventClick = async (clickInfo: any) => {
        setErrors([]);
        if (clickInfo.event.end.getDate() !== clickInfo.event.start.getDate()) { return }

        const curr_event = events.find(event => event.id.toString() === clickInfo.event.id.toString());
        setEventData(
            {
                id: clickInfo.event.id,
                title: clickInfo.event.title,
                start: clickInfo.event.start,
                end: clickInfo.event.end,
                jsEvent: clickInfo.jsEvent,
                task: curr_event?.task
            }
        )
        setModalType("edit");
        setIsModalOpen(true);
    }

    const clearSelection = () => {
        if (calendarRef.current) {
            calendarRef.current.getApi().unselect();
        }
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
        setEventData(null);
        setModalPosition(null);
        clearSelection();
    }; 

    const handleEventCreate = async (eventData: { title: string; start_at: Date; end_at: Date, task_id?: number }) => {
        setErrors([]);
        try {
            const createdEvent = await createEvent(eventData);
            if (createdEvent.event) {
                setEvents([...events, createdEvent.event]);
            } else if (createdEvent.errors) {
                setErrors(createdEvent.errors);
            }          
            handleModalClose();
        } catch (error) {
            console.error(error);
            setErrors(["Не удалось создать событие"]);
        }
    };

    const handleEventUpdate = async (eventData: { id: number, title: string; start_at: Date; end_at: Date, task_id?: number }) => {
        setErrors([]);
        try {
            const updatedEvent = await updateEvent(eventData);
            if (updatedEvent.event) {
                setEvents((prevEvents) =>
                    prevEvents.map((event) =>
                        event.id === updatedEvent.event?.id ? { ...event, ...updatedEvent.event } : event
                    )
                );
            } else if (updatedEvent.errors) {
                setErrors(updatedEvent.errors);
            }    
            handleModalClose();
        } catch (error) {
            console.error(error);
            setErrors(["Не удалось обновить событие"]);
        }
    };
    
    const handleEventChange = async (changeInfo: any) => {
        setErrors([]);
        try {
            const editEvent = {
                id: changeInfo.event.id,
                start_at: changeInfo.event.start,
                end_at: changeInfo.event.end,
            };
            const updatedEvent = await updateEvent(editEvent);
            if (updatedEvent.event) {
                setEvents((prevEvents) =>
                    prevEvents.map((event) =>
                        event.id === updatedEvent.event?.id ? { ...event, ...updatedEvent.event } : event
                    )
                );
            } else if (updatedEvent.errors) {
                setErrors(updatedEvent.errors);
            }
        } catch (error) {
            console.error(error);
            setErrors(["Не удалось обновить событие"]);
        }
    };

    const handleEventDelete = async (eventId: number) => {
        setErrors([]);
        try {
            const deletedEvent = await deleteEvent(eventId);
            if (deletedEvent.errors) {
                setErrors(deletedEvent.errors);
            } else {
                setEvents((prevEvents) => prevEvents.filter((event) => event.id.toString() !== eventId.toString()));
            }
        } catch (error) {
            console.error(error);
            setErrors(["Не удалось удалить событие"]);
        }
    }

    const handleDatesSet = useCallback((dateInfo: any) => {
        const { start, end } = dateInfo.view.getCurrentData().dateProfile.activeRange;
        const newDateRange = {
            start: start.toISOString(),
            end: end.toISOString()
        };

        if (JSON.stringify(newDateRange) !== JSON.stringify(dateRange)) {
            setDateRange(newDateRange);
            setCurrentView(dateInfo.view.type);
            localStorage.setItem("calendarView", dateInfo.view.type);
        }
    }, [dateRange]);

    return(
        <div className={"list-table"}>
                {errors.length > 0 &&  
                    <ul>
                        {errors.map((error)=>(
                            <li>{error}</li>
                        ))}
                    </ul>
                }

                {events ?
                    <>
                        <CalendarEvents 
                            calendarRef={calendarRef}
                            events={events}
                            initialView={currentView}
                            handleSelect={handleSelect} 
                            handleEventClick={handleEventClick}
                            handleEventChange={handleEventChange}
                            onDatesSet={handleDatesSet}
                        />

                        {eventData && (
                            <EventModal
                                isOpen={isModalOpen}
                                eventData={eventData}
                                currentView={currentView}
                                modalType={modalType}
                                modalPosition={modalPosition}
                                setModalPosition={setModalPosition}
                                onClose={handleModalClose}
                                onSave={handleEventCreate}
                                onUpdate={handleEventUpdate}
                                onRemove={handleEventDelete}
                            />
                        )}
                    </>
                    :
                    <CircularProgress />
                }
            
        </div>
    )
}

export default HomeCalendar
