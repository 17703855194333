// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainLayout {
    width: 85%;
    float: right;
    transition: width 0.3s ease-in-out;
    text-align: left;
}

.title-layout-and-btn {
    margin-left: 5rem;
    width: 90%;
    margin-top: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title-layout {
    width: min-content;
    display: flex;
    justify-content: center;
    align-items: center;
}

.title {
    float: left;
    left: 0;
}

.add-task-btn {
    text-align: center;
    font-size: 16px;
    font-weight: normal;
    border-radius: 10px;
    border: none;
    background-color: #273B64;
    color: #ffffff;
    width: 12rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
}

.add-task-btn.conference {
    width: 18rem;
}

.full-width {
    width: 21rem;
    border: 1px solid #273B64;
}

.flex-with-spase {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 15rem;
    margin-right: 0.5rem;
}`, "",{"version":3,"sources":["webpack://./src/components/layout/layout.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,YAAY;IACZ,kCAAkC;IAClC,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;IACjB,UAAU;IACV,kBAAkB;IAClB,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,OAAO;AACX;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,mBAAmB;IACnB,mBAAmB;IACnB,YAAY;IACZ,yBAAyB;IACzB,cAAc;IACd,YAAY;IACZ,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;IACf,qBAAqB;AACzB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,YAAY;IACZ,oBAAoB;AACxB","sourcesContent":[".mainLayout {\n    width: 85%;\n    float: right;\n    transition: width 0.3s ease-in-out;\n    text-align: left;\n}\n\n.title-layout-and-btn {\n    margin-left: 5rem;\n    width: 90%;\n    margin-top: 0.5rem;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.title-layout {\n    width: min-content;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.title {\n    float: left;\n    left: 0;\n}\n\n.add-task-btn {\n    text-align: center;\n    font-size: 16px;\n    font-weight: normal;\n    border-radius: 10px;\n    border: none;\n    background-color: #273B64;\n    color: #ffffff;\n    width: 12rem;\n    height: 2rem;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    cursor: pointer;\n    text-decoration: none;\n}\n\n.add-task-btn.conference {\n    width: 18rem;\n}\n\n.full-width {\n    width: 21rem;\n    border: 1px solid #273B64;\n}\n\n.flex-with-spase {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    width: 15rem;\n    margin-right: 0.5rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
