import {useEffect, useState} from "react";
import { convertFiltersToParam, convertSearchToParam, filterEntries, getInitialFilterParams, getInitialSearchParams, isPresent, searchEntries, setParams } from "../../utils/utils";
import {IPaginatedResponse, IProject} from "../../common/types/tasks";
import SprintsTable from "../../components/SprintsTable";
import {CircularProgress} from "@mui/material";
import {fetchSprints} from "../../utils/axios";
import Layout from "../../components/layout";
import Pagination from "../../components/Pagination";
import FiltersHead from "../../components/FiltersHead";
import { useActionIsAllowed } from "../../utils/hook";

const SprintsList = () => {

    const createAllowed = useActionIsAllowed('sprint_create');

    const [fetched, setFetched] = useState<boolean>(false);
    const [sprintsData, setSprintsData] = useState<IPaginatedResponse>({ objects: [], previous: '', next: '', current_page: 0, count_objects: 0, count_pages: 0 });

    const [filterData, setFilterData] = useState<{ projects: IProject[] }>({ projects: [] });
    const [singleFilterData, setSingleFilterData] = useState<{ closed: {}[] }>({ closed: [] });
    const [searchData, setSearchData] = useState<{ title: string }>({ title: getInitialSearchParams('title') });
    const [paginateData, setPaginateData] = useState<{ page: string; page_size: string; }>({
        page: getInitialSearchParams('page'),
        page_size: getInitialSearchParams('page_size')
    });

    const [selectedFilters, setSelectedFilters] = useState<{ projects: number[] }>({ projects: getInitialFilterParams('project') });
    const [singleSelectedFilters, setSingleSelectedFilters] = useState<{ closed: number }>(
        { closed: parseInt(getInitialSearchParams('closed')) || 0 }
    );
 
    const isClosed = parseInt(getInitialSearchParams('closed')) === 1

    const handleSelectedChange = (filter: string) => (newSelectedOptions: number[]) => {
        setSelectedFilters(prev => ({ ...prev, [filter]: newSelectedOptions }));
    };

    const handleSingleSelectedChange = (filter: string) => (newSelectedOption: number) => {
        setSingleSelectedFilters(prev => ({ ...prev, [filter]: newSelectedOption }));
    };

    const handleFilledSearch = (filter: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchData(prev => ({ ...prev, [filter]: event.target.value }));
    }

    const handleFilterApply = async () => {
        try {
            const params = getSprintsParams();
            setParams(params);
            setFetched(false);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (isPresent(paginateData.page) || isPresent(paginateData.page_size))
        handleFilterApply();
    }, [paginateData]);

    useEffect(() => {
        const getSprints = async () => {
            try {
                let curr_params = getSprintsParams();

                const fetchedSprints = await fetchSprints({params: curr_params});
                setSprintsData(fetchedSprints.sprints);
                setFilterData(fetchedSprints.filter_data);
                setSingleFilterData({closed: [
                    { id: 0, title: 'Текущие' },
                    { id: 1, title: 'Закрытые' }
                ]})
                setFetched(true);
            } catch (error) {
                console.error(error);
            }
        };
        if (!fetched) {
            getSprints();
        }
        
    }, [fetched]);

    const handlePrevNextBtn = (paginate_params: string) => async () => {
        try {
            const url_params = new URLSearchParams(paginate_params);   
            setPaginateData({
                page: url_params.get('page') || '',
                page_size: url_params.get('page_size') || ''
            });
        } catch (error) {
            console.error(error);
        }
    }

    const getSprintsParams = () => {
        return(
            Object.entries(searchData).reduce((acc, [filter, value]) => acc + convertSearchToParam(value, searchEntries[filter].param_name), '') +
            Object.entries(selectedFilters).reduce((acc, [filter, values]) => acc + convertFiltersToParam(values, filterEntries[filter].param_name), '') +
            Object.entries(singleSelectedFilters).reduce((acc, [filter, value]) => acc + convertSearchToParam(value.toString(), filterEntries[filter].param_name), '') +
            Object.entries(paginateData).reduce((acc, [filter, value]) => acc + convertSearchToParam(value, searchEntries[filter].param_name), '')
        )
    }

    return(
        <Layout title={"Спринты" + (isClosed ? ". Завершенные" : "")} addButton={createAllowed ? 'addSprint' : undefined}> 
            <div className={'head-with-add-btn'}>
                <FiltersHead
                    searchData={searchData}
                    filterData={filterData}
                    singleFilterData={singleFilterData}
                    selectedFilters={selectedFilters}
                    selectedSingleOption={singleSelectedFilters}
                    onSingleSelectedChange={handleSingleSelectedChange}
                    onSelectedChange={handleSelectedChange}
                    onSearchFilled={handleFilledSearch}
                    onApply={handleFilterApply}
                />
            </div>

            <div className={'main-container task-list'}>
                {fetched ?
                    <>
                        <SprintsTable sprints={sprintsData.objects} />
                        <Pagination data={sprintsData} onPrevNext={handlePrevNextBtn} />
                    </>
                :
                    <CircularProgress/>
                }
            </div>
        </Layout>
    )
}

export default SprintsList
