import { Link } from "react-router-dom";
import { IUser } from "../../common/types/tasks";


interface UsersTableProps {
    users: IUser[];
    canEdit: boolean;
}

const UsersTable: React.FC<UsersTableProps> = ({users, canEdit}) => {
    return (
        <div className={'list-table'}>
            <table className={'table-main'}>
                <thead className={'table-head'}>
                <tr>
                    <th className={'th-title project'}>Имя</th>
                    <th className={'th-readiness'}>Email</th>
                    <th className={'th-readiness'}>Позиция</th>
                    <th className={'th-readiness'}>Роль</th>
                    <th className={'th-readiness'}>Дата создания</th>
                </tr>
                </thead>
                <tbody className={'table-body'}>
                    {users.map((user: IUser) => (
                        <tr key={user.id} className={'task-row'}>
                            <td className={'td-title project'}>
                                {canEdit ?
                                    <Link to={`/settings/users/${user.id}/edit`}>{user.username}</Link> 
                                    :
                                    <span>{user.username}</span>
                                }
                            </td>
                            <td className={'td-readiness'}>{user.email}</td>
                            <td className={'td-readiness'}>{user.position}</td>
                            <td className={'td-readiness'}>{user.role_title}</td>
                            <td className={'td-readiness'}>{user.created_at_formatted}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default UsersTable