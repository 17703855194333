// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Свойства, чтобы работала прокрутка календаря. */

.fc {
  height: 100%;
  padding-bottom: 1rem;
}

.fc .fc-view-harness {
  height: 100%;
}

.fc .fc-scrollgrid {
  height: 100%;
}

.fc .fc-timegrid {
  height: 100%;
  overflow-y: auto;
}

/* ---------- */

/* Уменьшаем размер ячеек */
.fc-daygrid-day {
  height: 80px !important;
}

/* Сдвигаем кнопки от краев */
.fc .fc-toolbar {
  padding: 0 10px;
}

.fc .fc-toolbar-title {
  font-size: 1.5em;
}

/* Уменьшаем размер текста в ячейках */
.fc .fc-daygrid-day-number {
  font-size: 0.9em;
}

/* Уменьшаем отступы в заголовке */
.fc .fc-toolbar.fc-header-toolbar {
  padding-top: 0.6rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-bottom: 0.6rem;
}

/* Стили для кнопок */
.fc .fc-button {
  padding: 0.3em 0.6em;
  font-size: 0.9em;
}

/* Стили для контейнера индикатора текущего времени */
.fc .fc-timegrid-now-indicator-container {
  position: absolute;
  z-index: -100;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: visible !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/CalendarEvents/CalendarEvents.css"],"names":[],"mappings":"AAAA,kDAAkD;;AAElD;EACE,YAAY;EACZ,oBAAoB;AACtB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,gBAAgB;AAClB;;AAEA,eAAe;;AAEf,2BAA2B;AAC3B;EACE,uBAAuB;AACzB;;AAEA,6BAA6B;AAC7B;EACE,eAAe;AACjB;;AAEA;EACE,gBAAgB;AAClB;;AAEA,sCAAsC;AACtC;EACE,gBAAgB;AAClB;;AAEA,kCAAkC;AAClC;EACE,mBAAmB;EACnB,kBAAkB;EAClB,mBAAmB;EACnB,qBAAqB;AACvB;;AAEA,qBAAqB;AACrB;EACE,oBAAoB;EACpB,gBAAgB;AAClB;;AAEA,qDAAqD;AACrD;EACE,kBAAkB;EAClB,aAAa;EACb,MAAM;EACN,SAAS;EACT,OAAO;EACP,QAAQ;EACR,4BAA4B;AAC9B","sourcesContent":["/* Свойства, чтобы работала прокрутка календаря. */\n\n.fc {\n  height: 100%;\n  padding-bottom: 1rem;\n}\n\n.fc .fc-view-harness {\n  height: 100%;\n}\n\n.fc .fc-scrollgrid {\n  height: 100%;\n}\n\n.fc .fc-timegrid {\n  height: 100%;\n  overflow-y: auto;\n}\n\n/* ---------- */\n\n/* Уменьшаем размер ячеек */\n.fc-daygrid-day {\n  height: 80px !important;\n}\n\n/* Сдвигаем кнопки от краев */\n.fc .fc-toolbar {\n  padding: 0 10px;\n}\n\n.fc .fc-toolbar-title {\n  font-size: 1.5em;\n}\n\n/* Уменьшаем размер текста в ячейках */\n.fc .fc-daygrid-day-number {\n  font-size: 0.9em;\n}\n\n/* Уменьшаем отступы в заголовке */\n.fc .fc-toolbar.fc-header-toolbar {\n  padding-top: 0.6rem;\n  padding-left: 1rem;\n  padding-right: 1rem;\n  margin-bottom: 0.6rem;\n}\n\n/* Стили для кнопок */\n.fc .fc-button {\n  padding: 0.3em 0.6em;\n  font-size: 0.9em;\n}\n\n/* Стили для контейнера индикатора текущего времени */\n.fc .fc-timegrid-now-indicator-container {\n  position: absolute;\n  z-index: -100;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  overflow: visible !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
