import {useEffect, useState} from "react";
import {fetchProject} from "../../utils/axios";
import {Link, useNavigate} from "react-router-dom";
import {IProjectShowResponse, ISprint} from "../../common/types/tasks";
import SimpleTable from "../SimpleTable";
import SimpleList from "../SimpleList";
import { useActionIsAllowed } from "../../utils/hook";

const ProjectShow = ({projectNumber} : {projectNumber: number}) => {

    const editAllowed = useActionIsAllowed('project_edit');
    const ganttAllowed = useActionIsAllowed('gantt_edit');

    const navigate = useNavigate();
    const [project, setProject] = useState<IProjectShowResponse>();

    useEffect(() => {
        const getTask = async () => {
            try {
                if (projectNumber) {
                    const fetchedProject = await fetchProject(projectNumber);
                    setProject(fetchedProject.project);

                } else
                    navigate('/projects/')

            } catch (error) {
                console.error(error);
            }
        };
        getTask();
    }, [projectNumber]);

    return(
        <div className={'main-container task-show'}>                          {/* ADD PERMISSIONS TO ALL */}

            <div className={'form-head'}>
                <p className={'title-form'}>
                    {project?.title}
                </p>
                <button className={'back-button'} onClick={() => navigate(-1)}>
                    <span>&lt;</span>
                </button>
            </div>

            {!project ? (
                <></>
            ) : (
                <div className={'show-main'}>
                    <div className={'show-task-col-left'}>
                        <div className={'project-item'} style={{'marginBottom': '1rem'}}>
                            <h3 style={{'marginTop': '0'}}>Описание</h3>
                            <p>{project.description}</p>
                        </div>
                    </div>

                    <div className={'show-task-col-right'}>
                        {editAllowed &&
                            <div className={'project-item'} style={{'float': 'right', 'marginBottom': '2rem'}}>
                                <Link to={`/projects/${projectNumber}/edit/`} className={'to-btn'}>Редактировать</Link>
                            </div>
                        }
                    </div>

                    <div className={'show-task-col-left'}>
                        <div className={'project-item'}>
                            <h3 style={{'marginTop': '0'}}>Задачи</h3>

                            <span>
                                <strong>Трудозатраты: </strong>
                                <span>{project.time_spent_to_tasks} ч</span>
                            </span>

                            <SimpleTable task_counts={project.task_by_tracker}/>
                            <Link to={'/tasks/?project=' + projectNumber} className={'to-btn'}>Перейти к списку задач</Link>
                            &emsp;
                            {ganttAllowed &&
                                <Link to={`/projects/${projectNumber}/gantt`} className={'to-btn'}>Открыть Диаграмму Ганта</Link>}
                            
                            <span className={'item-end'}></span>
                        </div>

                        <div className={'project-item'}>
                            <div className={'flex-start'}>
                                <span style={{fontWeight: 'bold', 'fontSize': '1.5rem'}}>Спринты&ensp;</span>
                            </div>
                            <br></br>

                            <div className="tasks-small-table">
                                <div className="tasks-small-header">
                                    <div className={'header-item fixed-width'}>Открыто:</div>
                                    <div className={'header-item fixed-width'}>Завершено:</div>
                                </div>
                                <div className="tasks-small-body">
                                    <div className="table-row">
                                        <div className={"body-item fixed-width"}>{project.sprints_data.current_count}</div>
                                        <div className={"body-item fixed-width"}>{project.sprints_data.closed_count}</div>
                                    </div>
                                </div>
                            </div>

                            {project.sprints_data?.sprints?.length > 0 &&
                                <ul>
                                    {project.sprints_data?.sprints?.map((sprint: ISprint) =>
                                        <li className="sprints-list-project">
                                            <Link to={`/sprints/${sprint.number}`}>{sprint.title}</Link>
                                        </li>
                                    )} 
                                </ul>
                            }
                            

                            <Link to={'/sprints/?project=' + projectNumber} className={'to-btn'}>Перейти к спринтам</Link>

                            <span className={'item-end'}></span>
                        </div> 

                        <div className={'project-item'}>
                            <div className={'flex-start'}>
                                <span style={{fontWeight: 'bold', 'fontSize': '1.5rem'}}>Wiki&ensp;</span>
                            </div>

                            <br></br>

                            <div className="tasks-small-table">
                                <div className="tasks-small-header">
                                    <div className={'header-item fixed-width'}>Страницы:</div>
                                </div>
                                <div className="tasks-small-body">
                                    <div className="table-row">
                                        <div className={"body-item fixed-width"}>{project.wiki_counts}</div>
                                    </div>
                                </div>
                            </div>

                            {/* <br></br> */}

                            <Link to={`/wiki/project/${projectNumber}`} className={'to-btn'}>Перейти к WIKI</Link>

                            <span className={'item-end'}></span>
                        </div>

                        <div className={'project-item'}>
                            <div className={'flex-start'}>
                                <span style={{fontWeight: 'bold', 'fontSize': '1.5rem'}}>Участники</span>
                            </div>
                            <SimpleList users={project.users_by_roles}/>
                            <span className={'item-end'}></span>
                        </div>

                        <div className={'project-item'}>
                            <h4 style={{'marginTop': '0', 'marginBottom': '10px'}}>Создан</h4>
                            <span>{project.created_at_formatted}</span>
                        </div>
                    </div>
                </div>
            )}

        </div>
    )
}

export default ProjectShow
