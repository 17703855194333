import {ITask} from '../../common/types/tasks';
import {Link} from "react-router-dom";
import React, { useState } from "react";
import EditTaskSmall from '../EditTaskSmall';
import './TasksTable.css'

interface TasksTableProps {
    tasks: ITask[];
    scrollable?: boolean;
}
const TasksTable: React.FC<TasksTableProps> = ({ tasks, scrollable }) => {
    const [taskList, setTaskList] = useState<ITask[]>(tasks);
    const [selectedTask, setSelectedTask] = useState<ITask | null>(null);

    const updateTaskProps = (updatedTask: ITask) => {
        setTaskList(prevTasks =>
            prevTasks.map(task =>
                task.id === updatedTask.id ? updatedTask : task
            )
        );
    };

    const handleTaskEdit = (task: ITask) => {
        setSelectedTask(task);
    };

    const [sidebarIsOpen, setSidebarIsOpen] = useState(false);

    const handleSidebarOpen = (task: ITask) => {
        handleTaskEdit(task)
        setSidebarIsOpen(true);
    };

    const handleSidebarClose = () => {
        setSelectedTask(null)
        setSidebarIsOpen(false);
    };

     return (
        <div className={scrollable ? 'list-table-scrollable' : 'list-table'}>
            <table className={'table-main'}>
                <thead className={'table-head'}>
                    <tr>
                        <th className={'th-id'}>#</th>
                        <th className={'th-project'}>Проект</th>
                        <th className={'th-status'}>Статус</th>
                        <th className={'th-readiness'}>Прогрес</th>
                        <th className={'th-priority'}>Приоритет</th>
                        <th className={'th-title'}>Название</th>
                    </tr>
                </thead>
                <tbody className={scrollable ? 'table-body-scrollable' : 'table-body'}>
                    {taskList?.map((task) => (
                        <tr key={task.number} className={'task-row'}>
                            <td className={'td-id'}>{task.number}</td>
                            <td className={'td-project'} title={task.project}>
                                <Link to={'/projects/' + task.project_number}>{task.project}</Link>
                            </td>
                            <td className={'td-status'} title={task.status}>{task.status}</td>
                            <td className={'td-readiness'}>
                            <span className={'progress-block'}>
                                <div className={'progress-bar'}>
                                    <div className={'progress'} style={{'width': `${task.readiness}%`}}></div>
                                </div>
                                {task.readiness}%
                            </span>
                            </td>
                            <td className={'td-priority'} title={task.priority}>{task.priority}</td>
                            <td className={"td-title"}>
                                <div style={{display: "flex", justifyContent: "space-between", }}>
                                    <Link to={'/tasks/' + task.number}>{task.title}</Link>
                                    <img className={'small-edit'} src={'/app/edit.svg'} onClick={() => handleSidebarOpen(task)} />
                                </div>
                           </td>
                        </tr>
                    ))}
                </tbody>
            </table>

        
            <EditTaskSmall 
                isOpen={sidebarIsOpen}
                taskCurrentProps={selectedTask}
                setIsOpen={setSidebarIsOpen}
                onUpdateTaskProps={updateTaskProps}
                onClose={handleSidebarClose}
                onSuccess={handleSidebarClose}
            />
        </div>
    );
};

export default TasksTable;