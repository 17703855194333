import React, { useEffect, useRef, useState } from "react";
import TaskFindSmall from "../TaskFindSmall";
import './RelatedTaskAdd.css'

interface RelatedTaskAddProps {
    setFetched?: (fetched: boolean) => void;
    parentTaskId?: number;
    projectId: number;
    isSubTask: boolean;
}

const RelatedTaskAdd: React.FC<RelatedTaskAddProps> = ({ setFetched, parentTaskId, projectId, isSubTask }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const handleDropdownToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleOutsideClick = (event: MouseEvent) => {
        const target = event.target as HTMLElement;
        if (dropdownRef.current && !dropdownRef.current.contains(target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    return (
        <div className="new-task-small" ref={dropdownRef}>
            <span className={'dropdown-toggle-small-relation ' + (isOpen ? 'active' : '')} onClick={handleDropdownToggle}>
                Добавить задачу
            </span>
            <div className={'dropdown-menu-small-relation ' + (isOpen ? 'active' : '')}>
                <TaskFindSmall setFetched={setFetched} parentTaskId={parentTaskId} isSubTask={isSubTask} findClosed={true} projectId={projectId} />
            </div>
        </div>
    )
}

export default RelatedTaskAdd