import Layout from "../../components/layout";
import {useParams} from "react-router-dom";
import ProjectShow from "../../components/ProjectShow";
import ProjectForm from "../../components/ProjectForm";
import ProjectGantt from "../../components/ProjectGantt";
import ProjectsList from "../../components/ProjectsList";
import { useActionIsAllowed } from "../../utils/hook";

type ProjectPageType = 'list' | 'show' | 'new' | 'edit' | 'gantt';

const Project = ({typePage} : {typePage: ProjectPageType}) => {
    const {project_number} = useParams();
    const title = `Проекты${typePage === 'gantt' ? '. Диаграмма Ганта' : ''}`

    const showAllowed = useActionIsAllowed('project_show');
    const editAllowed = useActionIsAllowed('project_edit');
    const createAllowed = useActionIsAllowed('project_create');
    const ganttAllowed = useActionIsAllowed('gantt_edit');

    return(
        <Layout title={title} addButton={typePage === 'list' && createAllowed ? 'addProject' : undefined}>
            {typePage === 'list' && showAllowed && <ProjectsList ganttAllowed={ganttAllowed} />}
            {typePage === 'show' && showAllowed && project_number && <ProjectShow projectNumber={parseInt(project_number)} />}
            {typePage === 'edit' && editAllowed && project_number && <ProjectForm typePage={typePage} projectNumber={parseInt(project_number)} />}
            {typePage === 'new' && createAllowed && <ProjectForm typePage={typePage} />}
            {typePage === 'gantt' && ganttAllowed && project_number && <ProjectGantt projectNumber={parseInt(project_number)} />}
        </Layout>
    )
}

export default Project
