import { useState, useEffect } from 'react';
import {useNavigate} from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {CircularProgress} from "@mui/material";
import {fetchHomePageProps, updateHomeProps} from "../../utils/axios";
import { IHomePageUpdate, Widget } from '../../common/types/tasks';
import './HomePageEdit.css'

const HomePageEdit = () => {

    const navigate = useNavigate();
    const [fetched, setFetched] = useState<boolean>();
    const [widgets, setWidgets] = useState<Widget[]>([]);
    const [availableWidgets, setAvailableWidgets] = useState<Widget[]>([]);
    const [newWidget, setNewWidget] = useState<string>('');

    const handleDragEnd = (result: any) => {
        if (!result.destination) return;

        const { source, destination } = result;
        const newWidgets = Array.from(widgets);
        const [reorderedItem] = newWidgets.splice(source.index, 1);
        newWidgets.splice(destination.index, 0, reorderedItem);
    
        setWidgets(newWidgets);
    };

    useEffect(() => {
        const getHomeProps = async () => {
            try {
                const fetchedHomePageProps = await fetchHomePageProps();

                const sortedUsedWidgets = Object.entries(fetchedHomePageProps.used_widgets)
                .map(([id, widget]) => ({ 
                    id, 
                    title: widget.title, 
                    title_ru: widget.title_ru, 
                    index: widget.index 
                }))
                .sort((a, b) => a.index - b.index);
                setWidgets(sortedUsedWidgets);

                const sortedAvailableWidgets = Object.entries(fetchedHomePageProps.available_widgets)
                    .map(([id, widget]) => ({ 
                        id, 
                        title: widget.title, 
                        title_ru: widget.title_ru, 
                        index: widget.index 
                    }))
                    .sort((a, b) => a.index - b.index);
                
                setAvailableWidgets(sortedAvailableWidgets);
            } catch (error) {
                console.error(error);
            }
            setFetched(true);
        };
        if (!fetched)
            getHomeProps();

    }, []);

    const handleUpdateHomeProps = async () => {
        try {
            const homePropsHash = widgets.reduce<IHomePageUpdate>((acc, widget, index) => {
                acc[index.toString()] = widget.title;
                return acc;
            }, {});

            const updatedHomeProps = await updateHomeProps(homePropsHash);
            if (updatedHomeProps.user) {
                navigate('/home');
            } else {
                // setErrors(updatedHomeProps.errors); // TODO
            }

        } catch (error) {
            console.error(error);
        }
    }

    const handleRemoveWidget = async (widget_id: string) => {
        try {
            const widgetToRemove = widgets.find(widget => widget.id === widget_id);
            if (widgetToRemove) {
                setAvailableWidgets(prevAvailableWidgets => [...prevAvailableWidgets, widgetToRemove]);
            }

            const newWidgets = widgets.filter(widget => widget.id !== widget_id);
            setWidgets(newWidgets);
        } catch (error) {
            console.error(error);
        }
    }
    
    const addNewWidget = async () => {
        if (newWidget === '') return;

        try {
            const selectedWidget = availableWidgets.find(widget => widget.title === newWidget);
    
            if (selectedWidget) {
                setWidgets(prevWidgets => [...prevWidgets, selectedWidget]);
                const newAvailableWidgets = availableWidgets.filter(widget => widget.id !== selectedWidget.id);
                setAvailableWidgets(newAvailableWidgets);
    
                setNewWidget('');
            } else {
                console.error('Selected widget not found');
            }
        } catch (error) {
            console.error(error);
        }
    }   
       

    return(
        <>
            {fetched ?
                <div className={'main-container task-list'}>
                    <div className={'widgets-container'}>
                        
                        <div className={'widgets-order-index'}>
                            <div className={'w-index'}>#</div>
                        
                            {widgets.map((widget, i) => (
                                <div className={'w-index'}>{i+1}</div>
                            ))}
                            
                        </div>

                        <DragDropContext onDragEnd={handleDragEnd}>
                            <div className={'widgets-table'}>
                                <div className={'w-head'}>Виджет</div>

                                <Droppable droppableId="droppable">
                                    {(provided) => (
                                        <div className={'w-name'} {...provided.droppableProps} ref={provided.innerRef}>
                                            {widgets.map((widget, index) => (
                                                <Draggable key={`${widget.id}-${widgets.length}`} draggableId={index.toString()} index={index}>
                                                    {(provided) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        className='widgets-row'
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={{ ...provided.draggableProps.style,}}
                                                    >
                                                        <span>&emsp;&equiv;&ensp;{widget.title_ru}{widget.title_ru === "Мои задачи" && " (я - исполнитель)"}</span>
                                                        <span className="remove-widget" onClick={() =>handleRemoveWidget(widget.id)}>&ensp;удалить&ensp;</span>
                                                    </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>

                                <div className={'w-name'}>
                                    <div className='widgets-row add-widget-row'>
                                        &emsp;
                                        {availableWidgets && availableWidgets?.length > 0 &&
                                            <>
                                                <div className={'add-new-widget-select'}>
                                                    <select className={'form-task-select add-column'}
                                                            name={'widget'}
                                                            placeholder={'Виджет'}
                                                            value={newWidget ? newWidget : ''}
                                                            onChange={(e) => setNewWidget(e.target.value)}
                                                    >
                                                        <option key={''} value={''}>Выберите виджет</option>
                                                        {availableWidgets.map((widget) => (
                                                            <option key={widget.id} value={widget.title}>{widget.title_ru}</option>
                                                        ))}
                                                    </select>
                                                    &ensp;
                                                </div>
                                                <span className='add-widget' onClick={addNewWidget}>
                                                    &oplus;&ensp;Добавить виджет
                                                </span>
                                            </>
                                        }
                                    </div>
                                </div>
                                
                            </div>
                        </DragDropContext>
                        
                    </div>
                    <button type={"button"} 
                            style={{marginLeft: '3rem', marginBottom: '3rem'}}
                            className={'add-task-btn'}
                            onClick={handleUpdateHomeProps}>Сохранить</button>
                </div>
                :
                <CircularProgress/>
            }
        </>
    )
}

export default HomePageEdit