import {Routes, Route} from 'react-router-dom';
import AuthForm from './pages/Auth';
import Home from './pages/Home';
import Task from './pages/Task';
import Sprint from './pages/Sprint';
import Project from "./pages/Project";
import PrivateRoute from "./utils/routes/privateRoute";
import LoginRoute from "./utils/routes/loginRoute";
import PrivateAdminRoute from './utils/routes/privateAdminRoute';
import Profile from "./pages/Profile";
import Wiki from './pages/Wiki';
import ErrorPage from './pages/ErrorPage';
import Boards from './pages/Boards';
import Meet from './pages/Meet';
import SprintsList from './pages/SprintsList';
import WorkspaceSettings from './pages/WorkspaceSetting';
import SettingsTrackers from './pages/SettingsTrackers';
import SettingsStatuses from './pages/SettingsStatuses';
import SettingsComplexities from './pages/SettingsComplexities';
import SettingsSubscription from './pages/SettingsSubscription';
import SettingsUsers from './pages/SettingsUsers';
import './App.css';

function App() {
  return (
    <div className="App">   
      <Routes>
        <Route element={<PrivateRoute />}>
          <Route path='/' element={<Home typePage='show' />}></Route>
          <Route path='/home' element={<Home typePage='show' />}></Route>
          <Route path='/home/edit' element={<Home typePage='edit' />}></Route>

          <Route path='/tasks' element={<Task typePage={'list'} />}></Route>
          <Route path='/tasks/:task_number' element={<Task typePage={'show'} />}></Route>
          <Route path='/tasks/:task_number/edit' element={<Task typePage={'edit'} />}></Route>
          <Route path='/tasks/new' element={<Task typePage={'new'} />}></Route>

          <Route path='/sprints' element={<SprintsList />}></Route>
          <Route path='/sprints/:sprint_number/' element={<Sprint typePage={'show'} />}></Route>
          <Route path='/sprints/:sprint_number/edit' element={<Sprint typePage={'edit'} />}></Route>
          <Route path='/sprints/new' element={<Sprint typePage={'new'} />}></Route>
          <Route path='/sprints/:sprint_number/board' element={<Sprint typePage={'board'} />}></Route>

          <Route path='/boards' element={<Boards typePage={'list'} />}></Route>
          <Route path='/boards/new' element={<Boards typePage={'new'} />}></Route>
          <Route path='/boards/:board_number' element={<Boards typePage={'show'} />}></Route>

          <Route path='/projects' element={<Project typePage={'list'} />}></Route>
          <Route path='/projects/new' element={<Project typePage={'new'} />}></Route>
          <Route path='/projects/:project_number' element={<Project typePage={'show'} />}></Route>
          <Route path='/projects/:project_number/edit' element={<Project typePage={'edit'} />}></Route>
          <Route path='/projects/:project_number/gantt' element={<Project typePage={'gantt'} />}></Route>

          <Route path='/profile' element={<Profile />}></Route>
          
          <Route path='/wiki' element={<Wiki typePage={'mainWS'} />}></Route>
          <Route path='/wiki/project/:project_id/' element={<Wiki typePage={'mainProject'} />}></Route>
          <Route path='/wiki/new' element={<Wiki typePage={'new'} />}></Route>
          <Route path='/wiki/pages' element={<Wiki typePage={'list'} />}></Route>
          <Route path='/wiki/pages/:slug' element={<Wiki typePage={'show'} />}></Route>
          <Route path='/wiki/pages/:slug/edit' element={<Wiki typePage={'edit'} />}></Route>

          <Route path='/meets' element={<Meet typePage={'list'} />}></Route>
          <Route path='/meets/:meet_number' element={<Meet typePage={'show'} />}></Route>
          <Route path='/meets/:meet_number/edit' element={<Meet typePage={'edit'} />}></Route>
          <Route path='/meets/new' element={<Meet typePage={'new'} />}></Route>

          <Route element={<PrivateAdminRoute />}>
            <Route path='/settings' element={<WorkspaceSettings />}></Route>
            <Route path='/settings/users' element={<SettingsUsers typePage={'list'} />}></Route>
            
            <Route path='/settings/users/new' element={<SettingsUsers typePage={'new'} />}></Route>
            <Route path='/settings/users/new/create' element={<SettingsUsers typePage={'create'} />}></Route>
            <Route path='/settings/users/new/invite' element={<SettingsUsers typePage={'invite'} />}></Route>
            
            <Route path='/settings/users/:id/edit' element={<SettingsUsers typePage={'edit'} />}></Route>

            <Route path='/settings/trackers' element={<SettingsTrackers />}></Route>
            <Route path='/settings/statuses' element={<SettingsStatuses />}></Route>
            <Route path='/settings/complexities' element={<SettingsComplexities />}></Route>

            <Route path='/settings/subscription' element={<SettingsSubscription typePage={"status"} />}></Route>
          </Route>
          
          <Route path='/error/' element={<ErrorPage />}></Route>
          <Route path='/error/:err_code' element={<ErrorPage />}></Route>
        </Route>

        <Route element={<LoginRoute />}>
          <Route path='/login' element={<AuthForm />}></Route>
        </Route>
      </Routes>
    </div>
  );
}

export default App;
