import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PermissionsState } from "../../../common/types/auth";

const initialState: PermissionsState = {
    role: '',
    permissions: [],
    version: ''
};

export const permissionsSlice = createSlice({
    name: 'permissions',
    initialState,
    reducers: {
        setPermissionsData(state, action: PayloadAction<PermissionsState>) {
        state.role = action.payload.role;
        state.permissions = action.payload.permissions;
        state.version = action.payload.version;
        },
    }
});

export const { setPermissionsData } = permissionsSlice.actions;
export default permissionsSlice.reducer;
