import Layout from "../../components/layout";
import {useNavigate} from "react-router-dom";
import HomePageShow from "../../components/HomePageShow";
import HomePageEdit from "../../components/HomePageEdit";
import { useActionIsAllowed } from "../../utils/hook";

type HomePageType = 'edit' | 'show';


const Home = ({typePage} : {typePage: HomePageType}) => {
    const navigate = useNavigate();
    const img_src = typePage === 'show' ? '/app/edit.svg' : '/app/home_selected.svg';
    const navigate_path = typePage === 'show' ? '/home/edit' : '/home';

    const editAllowed = useActionIsAllowed('user_profile');
    const homePageAllowed = useActionIsAllowed('task_show');
    const createTaskAllowed = useActionIsAllowed('task_create');

    return(
        <Layout title={"Моя страница"}
                homeBtnClick={editAllowed ? (() => navigate(navigate_path)) : undefined}
                img_src={editAllowed ? img_src : undefined}
                addButton={createTaskAllowed ? 'addTask' : undefined}>

            {typePage === 'show' && homePageAllowed && <HomePageShow />}
            {typePage === 'edit' && editAllowed && <HomePageEdit />}

        </Layout>
    )
}

export default Home