import React, { useState } from 'react';
import { ICloseSprintStat, IMoveOptions } from '../../common/types/boards';
import './ModalCloseSprint.css'

interface ModalProps {
    tasksData: ICloseSprintStat;
    moveOptions: IMoveOptions[];
    onConfirm: (selectedMoveOption: string) => void;
    onCancel: () => void;
}

const ModalCloseSprint: React.FC<ModalProps> = ({ tasksData, moveOptions, onConfirm, onCancel }) => {
    const [selectedOption, setSelectedOption] = useState<string>('');
    const [dragged, setDragged] = useState<boolean>(false);

    const handleRadioChange = (value: string) => {
        setSelectedOption(value);
    };

    const onConfirmCloseSprint = (selectedOption: string) => {
        if (selectedOption === '' && tasksData.opened_tasks !== 0) {
            setDragged(true);
            setTimeout(() => setDragged(false), 300);
            return;
        }
        onConfirm(selectedOption);
    }

    return(
        <div className={'modal-window-close-sprint'} onClick={onCancel}>
            <div className={'modal-content-close-sprint'} onClick={e => e.stopPropagation()}>
                <h3 style={{whiteSpace: 'pre-wrap'}}>Вы уверены, что хотите завершить спринт?</h3>
                <br></br>
                <div style={{width: '90%'}}>
                    <div className={'tasks-number'}>Открытые задачи: <strong>{tasksData['opened_tasks']}</strong></div>
                    <div className={'tasks-number'}>Закрытые задачи: <strong>{tasksData['closed_tasks']}</strong></div>
                    <br></br>
                    {Number(tasksData['opened_tasks']) > 0 && (
                        <div>
                            <span className={'how-move ' + (dragged && 'dragged')}>Выберите куда перенести незакрытые задачи:</span>
                            {moveOptions.map((moveOption) => (
                                <div key={moveOption.value} className={'move-option-container'}>
                                    <label className={'move-option-label'}>
                                        <input
                                            type="radio"
                                            name="moveOption"
                                            value={moveOption.value}
                                            checked={selectedOption === moveOption.value}
                                            onChange={() => handleRadioChange(moveOption.value)}
                                        />
                                        {moveOption.title}
                                        <p className='move-option-desc'>{moveOption.desc}</p>
                                    </label>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                <br></br>
                <div className={'modal-btns'}>
                    <button className={'confirm-button'} onClick={() => onConfirmCloseSprint(selectedOption)}>Да</button>
                    <button className={'cancel-button'} onClick={onCancel}>Отмена</button>
                </div>
            </div>
        </div>
    )
}

export default ModalCloseSprint;
