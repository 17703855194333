const GanttTaskListHeader: React.FC<{
  headerHeight: number;
  rowWidth: string;
  fontFamily: string;
  fontSize: string;
}> = ({ headerHeight, rowWidth, fontFamily, fontSize }) => {
  return(
      <div
      className={'ganttTable'}
      style={{
          fontFamily: fontFamily,
          fontSize: fontSize,
      }}
      >
      <div className={'ganttTable_Header'} style={{height: headerHeight - 2,}}>
          <div className={'ganttTable_HeaderItem'} style={{minWidth: rowWidth}}>
              &nbsp;Название
          </div>
          <div className={'ganttTable_HeaderSeparator'} style={{height: headerHeight * 0.5, marginTop: headerHeight * 0.2,}}/>
          <div className={'ganttTable_HeaderItem'} style={{minWidth: rowWidth,}}>
              &nbsp;Дата начала
          </div>
          <div className={'ganttTable_HeaderSeparator'} style={{height: headerHeight * 0.5,marginTop: headerHeight * 0.25}}/>
          <div className={'ganttTable_HeaderItem'} style={{minWidth: rowWidth,}}>
              &nbsp;Дата завершения
          </div>
      </div>
  </div>
)};

export default GanttTaskListHeader;