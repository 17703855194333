import {useEffect, useState} from "react";
import Navigation from "../navigation";
import { useUserName, useUserEmail } from "../../utils/hook";
import '../../components/layout/layout.css'
import './sidebar.css'

const Sidebar = ({title} : {title: string}) => {
    const storedIsOpen = localStorage.getItem('isOpen') === 'true';
    const [isOpen, setIsOpen] = useState<boolean>(storedIsOpen);
    const username = useUserName();
    const user_email = useUserEmail();

    useEffect(() => {
        const storedIsOpen = localStorage.getItem('isOpen');
        if (storedIsOpen) {
            setIsOpen(JSON.parse(storedIsOpen));
        }
    }, []);

    const toggleSidebar = () => {
        const newIsOpen = !isOpen;
        setIsOpen(newIsOpen);
        localStorage.setItem('isOpen', JSON.stringify(newIsOpen));
    };

    return (
        <div className={"sidebar " + (isOpen ? 'open' : 'closed')}>
            <div className={'profile-sidebar'}>
                <div className={'profile-info' + (isOpen ? '' : ' closed')}>
                    <h3>{username}</h3>
                    <p>{user_email}</p>
                </div>
            </div>

            <Navigation isOpen={isOpen} title={title} />

            <button className={'collapse-button' + (isOpen ? '' : ' closed')} onClick={toggleSidebar}>
                {isOpen ? <span>&lt;</span> : <span>&gt;</span>}
            </button>
        </div>
    );
};

export default Sidebar;