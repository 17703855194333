import { Draggable, Droppable } from "react-beautiful-dnd";
import { BoardColumn } from "../../common/types/boards";
import TaskFindOrCreate from "../TaskFindOrCreate";
import { deleteTaskColumn, hideColumnInBoard } from "../../utils/axios";
import { FC } from "react";
import './DroppableTable.css'

interface DroppableTableProps {
    id: string;
    column: BoardColumn;
    dragged: string;
    boardId: number;
    projectId: number;
    canEdit: boolean;
    setUpdatedColumn: (column_index: string, task_id: number, column: BoardColumn) => void;
    setShowModal: (showModal: boolean) => void;
    setColumnDeleteId: (columnDeleteId: number) => void;
}

const DroppableTable: FC<DroppableTableProps> = ({ id, column, dragged, boardId, projectId, canEdit, setShowModal, setColumnDeleteId, setUpdatedColumn }) => {
    const showModalDeleteColumn = () => {
        setShowModal(true);
        setColumnDeleteId(column.id);
    }

    const handleDeleteTaskInColumn = async (column_task_id: number) => {
        const updatedColumn = await deleteTaskColumn(boardId, column_task_id);
        setUpdatedColumn(updatedColumn.column.title, 0, updatedColumn.column);
    }

    const handleHideColumn = async (method: string) => {
        const hiddenColumn = await hideColumnInBoard(method, boardId, column.id);
        setUpdatedColumn(hiddenColumn.column.title, 0, hiddenColumn.column);
    }

    return (
        <>
            {column.hidden ?
                <div key={id} className={'droppable-table'}>
                    <div className={'hiddenColumn'}>
                        <button className={'unhideColumn'} onClick={() => handleHideColumn('column_unhide')}>
                            <img src={"/app/unhide.svg"}></img>
                        </button>
                    </div>                            
                </div>
                :
                <div key={id} className={'droppable-table'}>
                    <div className={'columnHeader'}>
                        <h2>{column.title}</h2>

                        <button className={'hideColumn'} onClick={() => handleHideColumn('column_hide')}>
                            <img src={"/app/hide.svg"}></img>
                        </button>

                        {canEdit &&
                            <button className={'deleteColumn'} onClick={() => showModalDeleteColumn()}>&times;</button>}

                        {column.title === 'Открыто' && canEdit &&
                            <span className={"column-task-create"}>
                                <TaskFindOrCreate onSuccessCreatedColumnTask={setUpdatedColumn} columnId={column.id}
                                    columnStatus={column.title} boardId={boardId} projectId={projectId} />
                            </span>
                        }
                    </div>

                    <Droppable droppableId={id} isDropDisabled={!canEdit}>
                        {(provided, snapshot) => {
                            return (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    className={'droppable-main'}
                                >

                                    {column.tasks.map((task, index) => {
                                        return (
                                            <Draggable key={task.id} draggableId={task.id.toString()} index={index} isDragDisabled={!canEdit}>
                                                {(provided, snapshot) => {
                                                    return (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            className={'draggable-main ' + (parseInt(dragged) === task.id ? 'dragged' : '')}
                                                            style={{ ...provided.draggableProps.style }}
                                                        >
                                                            <h3>{task.title}</h3>
                                                            {task.developer && <span className={'dev-for'}><strong>Назначена: </strong>{task.developer}</span>}
                                                            <a className={'open-task'}
                                                                target="_blank"
                                                                href={'/app/tasks/' + task.number}
                                                            >
                                                                Перейти
                                                            </a>
                                                            {canEdit &&
                                                                <a className={'close-task'}
                                                                    onClick={() => handleDeleteTaskInColumn(task.column_task_id)}>
                                                                    Удалить
                                                                </a>}
                                                        </div>
                                                    );
                                                }}
                                            </Draggable>
                                        );
                                    })}
                                    {provided.placeholder}
                                </div>
                            );
                        }}
                    </Droppable>
                </div>
            }
        </>
    );
}

export default DroppableTable
