import Multiselect from "../Multiselect";
import {filterEntries} from "../../utils/utils";
import SingleSelect from "../SingleSelect";
import './FiltersHead.css'


const FiltersHead = ({ searchData, filterData, singleFilterData, selectedFilters, onSelectedChange, onSearchFilled,
                       selectedSingleOption, onSingleSelectedChange, onApply }: any) => (
    <div className={'searches'}>
        <div className={'search-field'}>
            <input className={'search-filter'}
                   placeholder={'Поиск'}
                   value={searchData['title']}
                   onChange={onSearchFilled('title')}
            />
        </div>

        <div className={'filters-head'}>
            {Object.entries(filterData).map(([filter, options]) =>
                <Multiselect
                    key={filter}
                    name={filterEntries[filter].title}
                    preselectedOptions={selectedFilters[filter]}
                    // @ts-ignore
                    options={options}
                    onSelectedOptionsChange={onSelectedChange(filter)}
                />
            )}
            {singleFilterData && Object.entries(singleFilterData).map(([filter, options]) =>
                <SingleSelect
                    key={filter}
                    // @ts-ignore
                    options={options}
                    preselectedOption={selectedSingleOption[filter]}
                    onSelectedOptionChange={onSingleSelectedChange(filter)}
                />
            )}
            <button className={'list-find-btn'} onClick={onApply}>Применить</button>
        </div>
    </div>
)

export default FiltersHead