import React, { useState, useRef, useEffect } from 'react';
import './Multiselect.css';

interface MultiSelectProps {
    name: string;
    options: any[];
    preselectedOptions?: number[];
    classNameCustom: string;
    onSelectedOptionsChange: (selectedOptions: number[]) => void;
}

const MultiSelect: React.FC<MultiSelectProps> = ({ name, options, classNameCustom, preselectedOptions, onSelectedOptionsChange }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState<number[]>(preselectedOptions || []);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const handleOptionToggle = (optionNumber: number) => {
        let newSelectedOptions;
        if (selectedOptions.includes(optionNumber)) {
            newSelectedOptions = selectedOptions.filter(number => number !== optionNumber);
        } else {
            newSelectedOptions = [...selectedOptions, optionNumber];
        }
        setSelectedOptions(newSelectedOptions);
        onSelectedOptionsChange(newSelectedOptions);
    };

    const handleDropdownToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleOutsideClick = (event: MouseEvent) => {
        const target = event.target as HTMLElement;
        if (dropdownRef.current && !dropdownRef.current.contains(target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    return (
        <div className={"multi-select " + (classNameCustom)} ref={dropdownRef}>
            <span className={'dropdown-toggle ' + (isOpen ? 'active' : '')} onClick={handleDropdownToggle}>
                {name}
                &ensp;
                {selectedOptions.length > 0 ?
                    <span className={'selected-count ' + (isOpen ? 'active' : '')}>{selectedOptions.length}</span>
                    : <>&#9660;</>}
            </span>
            <div className={'dropdown-menu ' + (isOpen ? 'active' : '')}>
                {options.map(option => (
                    <label key={option.id}>
                        <input
                            type="checkbox"
                            value={option.id}
                            checked={selectedOptions.includes(option.id)}
                            onChange={() => handleOptionToggle(option.id)}
                        />
                        {option.title || option.username}
                    </label>
                ))}
            </div>
            <input type="hidden" value={selectedOptions.join(',')} />
        </div>
    );
};

export default MultiSelect;