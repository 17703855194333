import { useEffect, useState } from "react"
import { IPaginatedResponse, IUser } from "../../common/types/tasks"
import { CircularProgress } from "@mui/material";
import { fetchUsers } from "../../utils/axios";
import UsersTable from "../../components/UsersTable";
import Pagination from "../../components/Pagination";
import {
  convertSearchToParam,
  searchEntries,
  getInitialSearchParams,
  setParams,
  isPresent,
} from "../../utils/utils";
import SearchMultiHead from "../SearchMultiHead";


const UsersList = ({canEdit} : {canEdit: boolean}) => {
    const [fetched, setFetched] = useState<boolean>(false);
    const [usersData, setUsersData] = useState<IPaginatedResponse>();
  
    const [searchData, setSearchData] = useState<{ name: string; }>({ name: getInitialSearchParams('name') });
    const [paginateData, setPaginateData] = useState<{ page: string; page_size: string; }>({
        page: getInitialSearchParams('page'),
        page_size: getInitialSearchParams('page_size')
    });
  
    useEffect(()=> {
        const getUsers = async () => {
            const fetchedUsers = await fetchUsers({ params: getUsersParams() });
            if (fetchedUsers) {
                setUsersData(fetchedUsers);
            }
            setFetched(true);
        }
        
        if (!fetched) {
            getUsers();
        }    
          
    }, [fetched])

    const handleFilledSearch = (filter: string, value: string) => {
        setSearchData(prev => ({ ...prev, [filter]:value }));
    }
  
    const handleFilterApply = async () => {
        try {
            const params = getUsersParams();
            setParams(params);
            setFetched(false);
        } catch (error) {
            console.error(error);
        }
    };
  
    const handlePrevNextBtn = (paginate_params: string) => async () => {
        try {
            const url_params = new URLSearchParams(paginate_params);   
            setPaginateData({
                page: url_params.get('page') || '',
                page_size: url_params.get('page_size') || ''
            });
        } catch (error) {
            console.error(error);
        }
    }
    
    useEffect(() => {
        if (isPresent(paginateData.page) || isPresent(paginateData.page_size))
        handleFilterApply();
    }, [paginateData]);

    const getUsersParams = () => {
        return(
            Object.entries(searchData).reduce((acc, [filter, value]) => acc + convertSearchToParam(value, searchEntries[filter].param_name), '') +
            Object.entries(paginateData).reduce((acc, [filter, value]) => acc + convertSearchToParam(value, searchEntries[filter].param_name), '')
        )
    }

    return(
        <>
          <div className={'head-with-add-btn'}>
              <SearchMultiHead
                  searchData={searchData}
                  onSearchFilled={handleFilledSearch}
                  onApply={handleFilterApply}
              />
          </div>
          <div className={'main-container task-list'}>
            {fetched && usersData ?
              <>
                <UsersTable users={usersData.objects} canEdit={canEdit} />
                <Pagination data={usersData} onPrevNext={handlePrevNextBtn} />
              </>
              :
              <CircularProgress />
            } 
          </div>
        </>
    )
}

export default UsersList