import {useState} from "react";
import {Link} from 'react-router-dom';
import { useStyles } from "../../utils/utils";
import { Tooltip } from '@material-ui/core';
import { useIsAdmin } from "../../utils/hook";
import './navigation.css'

const Navigation = ({isOpen, title} : {isOpen: boolean, title: string}) => {
    const handleLinkClick = (index: number) => {
        setSelectedItem(index);
    };
    const classes = useStyles();

    interface NavigationItem {
        title: string;
        imgSrc: string;
        imgSrcSel: string;
        link: string;
    }

    const isAdmin = useIsAdmin();

    let navigationItems: NavigationItem[] = [
        { title: 'Моя страница', imgSrc: '/app/home.svg', imgSrcSel: '/app/home_selected.svg', link: '/app/home' },
        { title: 'Задачи', imgSrc: '/app/tasks.svg', imgSrcSel: '/app/tasks_selected.svg', link: '/app/tasks' },
        { title: 'Проекты', imgSrc: '/app/projects.svg', imgSrcSel: '/app/projects_selected.svg', link: '/app/projects' },
        { title: 'Спринты', imgSrc: '/app/calendar.svg', imgSrcSel: '/app/calendar_selected.svg', link: '/app/sprints' },
        { title: 'Доски', imgSrc: '/app/tasks.svg', imgSrcSel: '/app/tasks_selected.svg', link: '/app/boards' },
        { title: 'Видеоконференции', imgSrc: '/app/videos.svg', imgSrcSel: '/app/videos_selected.svg', link: '/app/meets' },
        { title: 'Wiki', imgSrc: '/app/projects.svg', imgSrcSel: '/app/projects_selected.svg', link: '/app/wiki' },
        { title: 'Профиль', imgSrc: '/app/profile.svg', imgSrcSel: '/app/profile_selected.svg', link: '/app/profile' },
    ];

    if (isAdmin) {
        navigationItems.push({ title: 'Настройки', imgSrc: '/app/settings.svg', imgSrcSel: '/app/settings_selected.svg', link: '/app/settings' });
    }

    const item = navigationItems.findIndex((item) => title.includes(item.title));
    const [selectedItem, setSelectedItem] = useState<number | null>(item);

    return(
        <div className={'navigation' + (isOpen ? '': ' closed')}>
            {navigationItems.map((item, index) => (
                <div key={index}>
                    { isOpen ?
                        <Link to={`https://${process.env.REACT_APP_API_URL}${item.link}`}
                              className={'itemNavigation opened' + (selectedItem === index ? ' active' : '')}
                              onClick={() => handleLinkClick(index)}>
                            <div className={'itemNavigationContent'}>
                                {selectedItem === index ? <img src={item.imgSrcSel} /> : <img src={item.imgSrc} />}
                                <span className="navigation-item-title">{item.title}</span>
                            </div>
                        </Link>
                    :
                        <Tooltip title={item.title} placement="right" classes={{ tooltip: classes.tooltip }}>
                            <Link to={`https://${process.env.REACT_APP_API_URL}${item.link}`}
                                className={'itemNavigation' + (selectedItem === index ? ' active' : '')}
                                onClick={() => handleLinkClick(index)}>
                                <div className={'itemNavigationContent closed'}>
                                    {selectedItem === index ? <img src={item.imgSrcSel} /> : <img src={item.imgSrc} />}
                                </div>
                            </Link>
                        </Tooltip>
                    }   
                </div>
            ))}
        </div>
    )
}

export default Navigation

