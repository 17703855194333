import Layout from "../../components/layout";
import {useParams} from "react-router-dom";
import MeetForm from "../../components/MeetForm";
import MeetShow from "../../components/MeetShow";
import MeetsList from "../../components/MeetsList";
import { useActionIsAllowed } from "../../utils/hook";

type ProjectPageType = 'show' | 'new' | 'edit' | 'list';

const Meet = ({typePage} : {typePage: ProjectPageType}) => {
    const {meet_number} = useParams();

    const showAllowed = useActionIsAllowed('meet_show');
    const createAllowed = useActionIsAllowed('meet_create');
    const editAllowed = useActionIsAllowed('meet_edit');

    return(
        <Layout title="Видеоконференции" addButton={typePage === 'list' && createAllowed ? 'addMeet' : ''}>
            {typePage === 'list' && showAllowed && <MeetsList />}
            {typePage === 'show' && showAllowed && meet_number && <MeetShow meetNumber={parseInt(meet_number)} editAllowed={editAllowed} />}
            {typePage === 'edit' && editAllowed && meet_number && <MeetForm typePage={typePage} meetNumber={parseInt(meet_number)} />}
            {typePage === 'new' && createAllowed && <MeetForm typePage={typePage} />}
        </Layout>
    )
}

export default Meet
