import {makeStyles} from "@material-ui/core";

export function getCookie(name: string) {
    const cookieValue = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)');
    return cookieValue ? cookieValue.pop() : '';
}

export function storeUserData(userData: {user: {firstName: string, lastName: string, email: string, password: string }, workspace: { title: string }}) {
    localStorage.setItem('userData', JSON.stringify(userData));
}

export function getUserData() {
    const userData = localStorage.getItem('userData');
    return userData ? JSON.parse(userData) : {};
}

export function removeUserData() {
    localStorage.removeItem('userData');
}

export function convertFiltersToParam(selected: number[], name: string) {
    return `&${name}=${selected.join(',')}`;
}

export function convertSearchToParam(search: string, name: string) {
    return `&${name}=${search}`;
}

export function setParams(params: string) {
    const newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + params;
    window.history.pushState({path:newUrl}, '', newUrl);
}

export function getFormattedDate(date: Date) {
    return `${date.getFullYear()}-${date.getMonth()}-${date.getDay()}`
}

export const getInitialSearchParams = (param: string): string => {
    const params = new URLSearchParams(window.location.search);
    return params.get(param) || ''
}

export const getInitialFilterParams = (param: string): number[] => {
    const params = new URLSearchParams(window.location.search);
    const params_query = params.get(param);
    return params_query ? params_query.split(',').map(Number) : [];
};

export const filterEntries: {[key: string]: {title: string, param_name: string}} = {
    title: {title: 'Название', param_name: 'title'},
    closed: {title: 'Завершенные', param_name: 'closed'},
    projects: {title: 'Проект', param_name: 'project'},
    trackers: {title: 'Тип', param_name: 'tracker'},
    statuses: {title: 'Статус', param_name: 'status'},
    priorities: {title: 'Приоритет', param_name: 'priority'},
    developers: {title: 'Назначена', param_name: 'developer'},
    sprints: {title: 'Спринт', param_name: 'sprint'},
    boards: {title: 'Доска', param_name: 'board'},
}

export const searchEntries: {[key: string]: {title: string, param_name: string}} = {
    title: {title: 'Название', param_name: 'title'},
    start_at: {title: 'Начало', param_name: 'start_at'},
    end_at: {title: 'Завершение', param_name: 'end_at'},
    name: {title: 'Имя', param_name: 'name'},
    page: {title: 'Страница', param_name: 'page'},
    page_size: {title: 'Количество', param_name: 'page_size'},
}

export const useStyles = makeStyles((theme) => ({
    tooltip: {
        fontSize: 'large !important',
        backgroundColor: '#273B64 !important',
        color: '#FFF !important',
        borderRadius: '10px !important',
        padding: '0.1rem 0.3rem !important',
        textAlign: 'center'
    }
}));

export const numbersToText = (num: number) => {
    const numbersText = ["", "one", "two", "three", "four"];

    try {
        return numbersText[num];
    } catch (error) {
        return 'one'
    }
}

export const isPresent = (text: string): boolean => {
    return text !== '' && text !== undefined;
};

export const isEmpty = (text: string): boolean => {
    return !text;
};

export const downloadFile = async (url: string, filename: string) => {
    try {
        const response = await fetch(url);
        const blob = await response.blob();
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = filename
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
        console.error('Download record failed:', error);
    }
}