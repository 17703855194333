import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {createMeet, fetchMeetEdit, fetchMeetNew, updateMeet} from "../../utils/axios";
import { IProject } from "../../common/types/tasks";
import Calendar from "../Calendar";

const MeetForm = ({ typePage, meetNumber }: {typePage: string, meetNumber?: number}) => {
    const navigate = useNavigate();

    const [title, setTitle] = useState('');
    const [startAt, setStartAt] = useState<Date | null>(null);
    const [endAt, setEndAt] = useState<Date | null>(null);

    const [startAtTime, setStartAtTime] = useState<string>('');
    const [endAtTime, setEndAtTime] = useState<string>('');

    const [autoRecord, setAutoRecord] = useState<boolean>(false);

    const [accessType, setAccessType] = useState('');
    const [project, setProject] = useState<number | null>(null);

    const [projects, setProjects] = useState<IProject[]>([]);
    const [accessTypes, setAccessTypes] = useState<{[key: string]: string;}>({});

    const [errors, setErrors] = useState<String[]>([]);

    useEffect(() => {
        const getMeetEdit = async () => {
            try {
                if (!meetNumber) return;

                const fetchedMeet = await fetchMeetEdit(meetNumber);

                setTitle(fetchedMeet.meet.title);
                let start_at = new Date(fetchedMeet.meet.start_at);
                let end_at = new Date(fetchedMeet.meet.end_at)
                setStartAt(start_at);
                setEndAt(end_at);
                setStartAtTime(`${start_at.getHours().toString().padStart(2, '0')}:${start_at.getMinutes().toString().padStart(2, '0')}`);
                setEndAtTime(`${end_at.getHours().toString().padStart(2, '0')}:${end_at.getMinutes().toString().padStart(2, '0')}`);
        

                setAutoRecord(fetchedMeet.meet.auto_record);
                
                if (fetchedMeet.meet.project_id !== null) {
                    setProject(fetchedMeet.meet.project_id);
                    setAccessType("project");
                } else {
                    setProject(fetchedMeet.projects[0].id);
                    setAccessType("workspace");
                }

                setProjects(fetchedMeet.projects);
                setAccessTypes(fetchedMeet.access_types);

            } catch (error) {
                console.error(error);
            }
        };

        const getMeetNew = async () => {
            const fetchedMeetNew = await fetchMeetNew();;
            setProjects(fetchedMeetNew.projects);
            setAccessTypes(fetchedMeetNew.access_types);
            if (fetchedMeetNew.projects && fetchedMeetNew.projects.length > 0) {
                setProject(fetchedMeetNew.projects[0].id);
            }    
        }

        if (meetNumber && typePage === 'edit') {
            getMeetEdit();
        } else if (typePage === 'new') {
            getMeetNew();
        }
    }, []);

    const handleCreateOrUpdateMeet = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        (async () => {
            try {
                setErrors([]);

                if (title === "") {
                    setErrors(['Название обязательно']);
                    return
                }
                if (!startAt || !endAt) {
                    setErrors(['Время начала и завершения обязательны']);
                    return
                }
                if (startAt > endAt) {
                    setErrors(['Дата завершения не может быть меньше даты начала']);
                    return
                }
                if (parseInt(startAtTime) > parseInt(endAtTime)) {
                    setErrors(['Время завершения не может быть меньше времени начала']);
                    return
                }

                if (typePage === 'new') {
                    const createdMeet = await createMeet(buildMeetParams())
                    if (createdMeet.meet) {
                        navigate(`/meets/${createdMeet.meet.number}/`);
                    } else if (createdMeet.errors) {
                        setErrors(createdMeet.errors);
                    }
                }

                if (typePage === 'edit' && meetNumber) {
                    const updatedMeet = await updateMeet(meetNumber, buildMeetParams())
                    if (updatedMeet.meet) {
                        navigate(`/meets/${updatedMeet.meet.number}/`)
                    } else if (updatedMeet.errors) {
                        setErrors(updatedMeet.errors);
                    }   
                }
                
            } catch (e) {
                console.error(e);
            }
        })();
    }
    
    const buildMeetParams = () => {
        const meetData: { 
            title: string, 
            start_at: string, 
            end_at: string, 
            auto_record: boolean, 
            access_type: string, 
            project_id: number | null 
        } = {
            title: title,
            start_at: '',
            end_at: '',
            auto_record: autoRecord,
            access_type: accessType,
            project_id: null,
        }
    
        if (startAt && startAtTime) {
            const [hours, minutes] = startAtTime.split(':');
            startAt.setHours(parseInt(hours));
            startAt.setMinutes(parseInt(minutes));
            meetData.start_at = startAt.toISOString();
        }
    
        if (endAt && endAtTime) {
            const [hours, minutes] = endAtTime.split(':');
            endAt.setHours(parseInt(hours));
            endAt.setMinutes(parseInt(minutes));
            meetData.end_at = endAt.toISOString();
        }

        if (accessType === "project") {
            meetData.project_id = project;
        }
    
        return meetData;
    }    

    return(
        <div className={'main-container task-new'}>
            <div className={'form-head'}>
                <p className={'title-form'}>
                    {typePage === 'new' && 'Новая видеоконференция'}
                    {typePage === 'edit' && `#${meetNumber}`}
                </p>
                <button className={'back-button'} onClick={() => navigate(-1)}>
                    <span>&lt;</span>
                </button>
            </div>

            <form className={'form-main'} onSubmit={handleCreateOrUpdateMeet}>

                {errors &&
                    <div className="form-task-error">
                        {errors.map((error) => (
                            <p>{error}</p>
                        ))}
                    </div>
                }

                <div className={'form-task-col-left'}>
                    <div className={'form-task-group'}>
                        <label className={'form-task-label'} htmlFor={'title'}>Название</label>
                        <input className={'form-task-input'} name={'title'} placeholder={'Название'}
                               value={title} onChange={(e) => setTitle(e.target.value)}/>
                    </div>

                    <div className={'form-task-group'}>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start'}}>
                            <div>
                                <label className={'form-task-label'} htmlFor={'start_at'}>Дата начала</label>
                                <Calendar dateAt={startAt} setDateAt={setStartAt} minDate={new Date()} maxDate={endAt} />
                            </div>                     
                            <span>&emsp;</span>
                            <div>
                                <label className={'form-task-label'} htmlFor={'start_at'}>Время</label>
                                <input type="time"
                                    value={startAtTime}
                                    onChange={(e) => setStartAtTime(e.target.value)}
                                    className={'form-task-input'}
                                    style={{width: 'calc(100% - 0.4rem)'}}
                                    placeholder="время" name="time-input"
                                    required
                                />
                            </div>
                        </div>
                    </div>

                    <div className={'form-task-group'}>                       
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start'}}>
                            <div>
                            <label className={'form-task-label'} htmlFor={'end_at'}>Дата завершения</label>
                                <Calendar dateAt={endAt} setDateAt={setEndAt} minDate={startAt} maxDate={null} />
                            </div>                     
                            <span>&emsp;</span>
                            <div>
                                <label className={'form-task-label'} htmlFor={'end_at'}>Время</label>
                                <input type="time"
                                    value={endAtTime}
                                    onChange={(e) => setEndAtTime(e.target.value)}
                                    className={'form-task-input'}
                                    style={{width: 'calc(100% - 0.4rem)'}}
                                    placeholder="время" name="time-input"
                                    required
                                />
                            </div>
                        </div>
                    </div>

                    <div className={'form-task-group'} style={{'display': 'flex'}}>
                        <input
                            name={'waiting_room'}
                            type="checkbox"
                            checked={autoRecord}
                            onChange={() => setAutoRecord(!autoRecord)}
                        />
                        <label htmlFor={'waiting_room'}>Вести запись</label>
                    </div>
                </div>

                <div className={'form-task-col-right'}>
                    <div className={'form-task-group'}>
                        <label className={'form-task-label'} htmlFor={'access'}>Доступ к конференции</label>
                        <select className={'form-task-select'}
                                    name={'access'}
                                    placeholder={'Доступ'}
                                    value={accessType !== null ? accessType.toString() : ''}
                                    onChange={(e) => setAccessType(e.target.value)}
                        >
                            {Object.entries(accessTypes).map(([key, value]) => (
                                <option key={key} value={key}>{value}</option>
                            ))}
                        </select>
                    </div>

                    {accessType === 'project' && 
                        <div className={'form-task-group'}>
                            <label className={'form-task-label'} htmlFor={'project'}>Проект</label>
                            <select className={'form-task-select'}
                                    name={'project'}
                                    placeholder={'Проект'}
                                    value={project !== null ? project.toString() : ''}
                                    onChange={(e) => setProject(parseInt(e.target.value) || null)}
                            >
                                {projects.map((project) => (
                                    <option key={project.id} value={project.id}>{project.title}</option>
                                ))}
                            </select>
                        </div>
                    }     
                    
                </div>

                <div className={'form-task-col-center'}>
                    <br></br>
                    <button className={'save-task-btn'} type={'submit'}>
                        {typePage === 'new' && 'Добавить видеоконференцию'}
                        {typePage === 'edit' && 'Сохранить'}
                    </button>
                </div>
            </form>
        </div>
    )
}

export default MeetForm
