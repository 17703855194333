import { useEffect, useRef, useState } from 'react';
import { createWikiPage, fetcheWikiPageEdit, fetcheWikiPageNew, updateWikiPage } from '../../utils/axios';
import { IProject, IWikiPage } from '../../common/types/tasks';
import { Link, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';

type pageWikiFormType = 'edit' | 'new';

const WikiPageForm = ({slug, pageType} : {slug?: string, pageType: pageWikiFormType}) => {
    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    }
    const query = useQuery();
    const [fetched, setFetched] = useState<boolean>(false);
    const [page, setPage] = useState<IWikiPage>();
    const [editContent, setEditContent] = useState<string>('');
    const [errors, setErrors] = useState<string[]>([]);
    const [title, setTitle] = useState("");
    const [projects, setProjects] = useState<IProject[]>([]);
    const [projectId, setProjectId] = useState<number | null>(null);

    const navigate = useNavigate();

    useEffect(() => {
        const projectIdParam = query.get('project_id');
        if (projectIdParam) {
          setProjectId(Number(projectIdParam));
        }
      }, [query]);

    useEffect(() => {
        const getWikiPageEdit = async () => {
            if (!slug) return;
            const fetchedWikiPageEdit = await fetcheWikiPageEdit(slug);
            if (fetchedWikiPageEdit) {
                setPage(fetchedWikiPageEdit.wiki_page);
                setTitle(fetchedWikiPageEdit.wiki_page.title);
                setEditContent(fetchedWikiPageEdit.wiki_page.content);
                setProjects(fetchedWikiPageEdit.projects);
                setFetched(true);
            }
        };
        
        const getWikiPageNew = async () => {
            const fetchedWikiPageProps = await fetcheWikiPageNew();
            if (fetchedWikiPageProps) {
                setProjects(fetchedWikiPageProps.projects);
                setFetched(true);
            }
        };
        
        if (pageType === 'edit') {
            getWikiPageEdit();
        } else if (pageType === 'new') {
            getWikiPageNew();
        }
        
    }, [slug]);

    const handleContentChange = (value: string) => {
        setEditContent(value);
    };
    
    const textareaRef = useRef<HTMLTextAreaElement>(null);

    const adjustHeight = () => {
        if (textareaRef.current) {
            const scrollPosition = window.scrollY;

            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
            window.scrollTo(0, scrollPosition);
        }
    };

    useEffect(() => {
        adjustHeight();
    }, [editContent]);

    const handleUpdate = async (editContent: string) => {
        if (!slug) return;

        const updatedWiki = await updateWikiPage(slug,  {title: title, content: editContent});
        if (updatedWiki.wiki_page) {
            navigate(`/wiki/pages/${updatedWiki.wiki_page.slug}/`);
        } else if (updatedWiki.errors)  {
            setErrors(updatedWiki.errors);
        }
    };

    const handleCreate = async () => {
        const createdWiki = await createWikiPage({title: title, content: editContent, project_id: projectId});
        if (createdWiki.wiki_page) {
            navigate(`/wiki/pages/${createdWiki.wiki_page.slug}/`);
        } else if (createdWiki.errors)  {
            setErrors(createdWiki.errors);
        }
    };  

    return (
        <>
            <div className={'header-wiki'}>
                <div className={'right-links'}>
                    <Link to="/wiki/pages/">Список страниц</Link>
                    <span onClick={() => navigate(-1)} style={{textDecoration: 'underline', cursor: 'pointer'}}>Отмена</span>
                </div>
            </div>
            <div className={'main-container wiki'}>
                {errors.length > 0 &&
                    <div className="form-task-error">
                        {errors.map((error: string)=>(
                            <p>{error}</p>
                        ))}
                    </div>
                }

                {fetched ? (
                    <div className={'wiki-container'}>
                        <div className={'form-wiki-group'}>
                            <label className='form-label' style={{fontSize: 'medium'}}>Название документа</label>
                            <input
                                type="text"
                                className="form-input"
                                style={{'outline': 'none'}}
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                            />
                        </div>

                        {((!page?.project_main && !page?.ws_main) || pageType === 'new') &&
                        
                            <div className={'form-wiki-group'}>
                                <label className={'form-label'} style={{fontSize: 'medium'}} htmlFor={'project'}>Проект</label>
                                <select className={'form-wiki-select'}
                                        name={'project'}
                                        placeholder={'Проект'}
                                        value={projectId !== null ? projectId.toString() : ''}
                                        onChange={(e) => setProjectId(parseInt(e.target.value))}
                                >
                                    <option key={'0'}>Для всех проектов</option>
                                    {projects.map((project) => (
                                        <option key={project.id} value={project.id}>{project.title}</option>
                                    ))}
                                </select>
                            </div>
                        
                        }
                        
                        <textarea className={'wiki-form-textarea'} name={'text'} placeholder={'Содержание'} ref={textareaRef}
                                  value={editContent} onChange={(e) => handleContentChange(e.target.value)} />

                        {pageType === 'edit' && <button type="button" className={'add-task-btn-wiki'} onClick={() => (handleUpdate(editContent))}>Сохранить</button>}
                        {pageType === 'new' && <button type="button" className={'add-task-btn-wiki'} onClick={() => (handleCreate())}>Сохранить</button>}
                    </div>
                ) : (
                    <CircularProgress />
                )}
            </div>
        </>
    );
};

export default WikiPageForm;
