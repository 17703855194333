import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import store from './store';
import { Provider } from 'react-redux';
import {debounce} from "@mui/material";
import {saveState} from "./store/browser-storage";
import './index.css';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

store.subscribe(
  debounce(() => {
      saveState(store.getState());
  }, 800)
);

root.render(
  <React.Fragment>
    <Provider store={store} >
      <BrowserRouter basename="/app" >
        <App />
      </BrowserRouter>
    </Provider>
  </React.Fragment>
);

reportWebVitals();
