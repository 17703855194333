import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {createTaskNew, fetchTaskEdit, fetchTaskNew, updateTask} from "../../utils/axios";
import {CustomAttribute, IComplexity, IDeveloper, IPriority, IProject, IStatus, ITask, ITaskCreate, ITracker, TimeParams} from "../../common/types/tasks";
import {CircularProgress} from "@mui/material";
import Calendar from "../Calendar";
import remarkGfm from 'remark-gfm';
import rehypeSlug from 'rehype-slug';
import rehypeRaw from 'rehype-raw'
import rehypeAutolinkHeadings from 'rehype-autolink-headings';
import Markdown from 'react-markdown';
import 'react-datepicker/dist/react-datepicker.css';
import './TaskForm.css';

const TaskForm = ({ typePage }: {typePage: string}) => {
    const navigate = useNavigate();
    const { task_number } = useParams();
    const [task, setTask] = useState<ITask>()

    const [projects, setProjects] = useState<IProject[]>([]);
    const [developers, setDevelopers] = useState<IDeveloper[]>([]);
    const [availableDevelopers, setAvailableDevelopers] = useState<IDeveloper[]>([]);
    const [trackers, setTrackers] = useState<ITracker[]>([]);
    const [priorities, setPriorities] = useState<IPriority[]>([]);
    const [statuses, setStatuses] = useState<IStatus[]>([]);
    const [complexities, setComplexities] = useState<IComplexity[]>([]);

    const [title, setTitle] = useState('');
    const [text, setText] = useState('');
    const [author, setAuthor] = useState('');
    const [project, setProject] = useState<number | null>(null);
    const [priority, setPriority] = useState<number | null>(null);
    const [tracker, setTracker] = useState<number | null>(null);
    const [timeExpected, setTimeExpected] = useState<number | null>(null);
    const [developer, setDeveloper] = useState<number | null>(null);
    const [readiness, setReadiness] = useState<number | null>(null);
    const [status, setStatus] = useState<number | null>(null);
    const [complexity, setComplexity] = useState<number | null>(null);
    const [complexityUnits, setComplexityUnits] = useState<string>('');

    const [startAt, setStartAt] = useState<Date | null>(null);
    const [endAt, setEndAt] = useState<Date | null>(null);

    const [errors, setErrors] = useState<String[]>([]);

    const [fetched, setFetched] = useState<boolean>();

    const [timeParamsEnable, setTimeParamsEnable] = useState<boolean>(false);
    const [timeParams, setTimeParams] = useState<TimeParams[]>([]);
    const [timeExpectedParam, setTimeExpectedParam] = useState<string>('');
    const [timeExpectedTime, setTimeExpectedTime] = useState<number | null>(null);
    const [timeParamsOptions, setTimeParamsOptions] = useState<string[]>([]);

    const [customAttrs, setCustomAttrs] = useState<CustomAttribute[]>([]);
    const [customAttrTitle, setCustomAttrTitle] = useState<string>('');
    const [customAttrValue, setCustomAttrValue] = useState<string>('');

    const options = [];
    for (let i = 0; i <= 100; i += 5) {
        options.push(<option key={i} value={i}>{i}%</option>);
    }

    useEffect(() => {
        const getTaskNew = async () => {
            try {
                const fetchedTaskNew = await fetchTaskNew();
                setProjects(fetchedTaskNew.projects);
                setPriorities(fetchedTaskNew.priorities);
                setTrackers(fetchedTaskNew.trackers);
                setDevelopers(fetchedTaskNew.developers);
                setAvailableDevelopers(fetchedTaskNew.developers);
                setStatuses(fetchedTaskNew.statuses);
                setComplexities(fetchedTaskNew.complexities);

                if (fetchedTaskNew.priorities && fetchedTaskNew.priorities.length > 0)
                    setPriority(fetchedTaskNew.priorities[0].id);
                if (fetchedTaskNew.trackers && fetchedTaskNew.trackers.length > 0)
                    setTracker(fetchedTaskNew.trackers[0].id);
                if (fetchedTaskNew.statuses && fetchedTaskNew.statuses.length > 0)
                    setStatus(fetchedTaskNew.statuses[0].id);
                if (fetchedTaskNew.projects && fetchedTaskNew.projects.length > 0)
                    setProject(fetchedTaskNew.projects[0].id);
                
                setFetched(true);
            } catch (error) {
                console.error(error);
            }
        };

        const getTaskEdit = async () => {
            try {
                if (!task_number) return 

                const fetchedTask = await fetchTaskEdit(parseInt(task_number));
                setTask(fetchedTask.task);
                setProjects(fetchedTask.task_props.projects);
                setPriorities(fetchedTask.task_props.priorities);
                setTrackers(fetchedTask.task_props.trackers?.sort((a, b) => a.tracker_number - b.tracker_number));
                setDevelopers(fetchedTask.task_props.developers);
                setAvailableDevelopers(fetchedTask.task_props.developers);
                setStatuses(fetchedTask.task_props.statuses?.sort((a, b) => a.status_number - b.status_number));
                setComplexities(fetchedTask.task_props.complexities);

                setTitle(fetchedTask.task.title);
                setText(fetchedTask.task.text);
                setAuthor(fetchedTask.task.author);
                setTimeExpected(fetchedTask.task.time_expected);

                let fetchedTimeParamsRaw = fetchedTask.task.time_expected_by_params || '';
                let fetchedTimeParams: TimeParams[] = [];
                if (fetchedTimeParamsRaw && fetchedTimeParamsRaw.trim() !== "") {
                    try {
                        fetchedTimeParams = JSON.parse(fetchedTimeParamsRaw);
                    } catch (error) {
                        fetchedTimeParams = [];
                    }
                } else {
                    fetchedTimeParams = [];
                }
                setTimeParamsEnable(fetchedTimeParams.length > 0);

                setTimeParams(fetchedTimeParams);
                setTracker(fetchedTask.task.tracker_id);
                setProject(fetchedTask.task.project_id);
                setPriority(fetchedTask.task.priority_id);
                setReadiness(fetchedTask.task.readiness);
                setDeveloper(fetchedTask.task.developer_id);
                setStatus(fetchedTask.task.status_id);
                setComplexity(fetchedTask.task.complexity_id);
                setComplexityUnits(fetchedTask.task.complexity_units);
                if (fetchedTask.task.start_at)
                    setStartAt(new Date(fetchedTask.task.start_at));
                if (fetchedTask.task.end_at)
                    setEndAt(new Date(fetchedTask.task.end_at));
                
                setCustomAttrs(fetchedTask.task.custom_attributes);

                setFetched(true);
            } catch (error) {
                console.error(error);
            }
        };
        if (typePage === 'edit')
            getTaskEdit()
        else if (typePage === 'new')
            getTaskNew();

    }, []);

    const handleCreateTask = async () => {
        const createdTask = await createTaskNew(buildTaskData());
        if (createdTask.task) {
            navigate('/tasks/' + createdTask.task.number);
        } else if (createdTask.errors) {
            setErrors(createdTask.errors);
        }
    }

    const handleUpdateTask = async () => {
        if (task_number == null) return
        
        const updatedTask = await updateTask(parseInt(task_number), buildTaskData());
        if (updatedTask.task) {
            navigate('/tasks/' + updatedTask.task.number);
        } else if (updatedTask.errors) {
            setErrors(updatedTask.errors)
        }
        
    }

    useEffect(() => {
        if (project === 0 || project === null) {
            setAvailableDevelopers(developers);
        } else {
            const newAvailableDevelopers = developers.filter(dev => dev.project_ids.includes(project));
            setAvailableDevelopers(newAvailableDevelopers);
        }
    }, [project]);

    const addTimeParam = () => {
        if (timeExpectedParam === null || timeExpectedParam === undefined || timeExpectedParam === '') return;
        if (timeExpectedTime === null || timeExpectedTime === undefined|| timeExpectedTime === 0) return;

        setTimeParams([...timeParams, { time_param: timeExpectedParam, time_expected: timeExpectedTime }]);
        setTimeExpectedParam('');
        setTimeExpectedTime(null);
    }

    const removeTimeParam = (index: number) => {
        setTimeParams(currentRolesTime => currentRolesTime.filter((item, idx) => idx !== index));
    }

    // считаем оценку трудозатрат, если добавлены трудозатраты по параметрам.
    useEffect(()=> {
        if (!(timeParamsEnable && timeParams.length > 0)) return;

        if (timeParams.length === 0) {
            setTimeExpected(null);
        } else {
            const total = timeParams.reduce((acc, roleTime) => acc + roleTime.time_expected, 0);
            setTimeExpected(total);
        }
    }, [timeParams, timeParamsEnable])

    const handleTimeParamChange = (index: number, field: keyof TimeParams, value: string | number) => {
        setTimeParams(currentParams => 
            currentParams.map((param, idx) => {
                if (idx === index) {
                    return { ...param, [field]: value };
                }
                return param;
            })
        );
    };

    const handleCustomAttrChange = (index: number, key: string, value: string) => {
        const updatedAttrs = [...customAttrs];
        updatedAttrs[index].value = value;
        setCustomAttrs(updatedAttrs);
    };
      
    const removeCustomAttr = (index: number) => {
        const updatedAttrs = [...customAttrs];
        updatedAttrs.splice(index, 1);
        setCustomAttrs(updatedAttrs);
    };

    const addCustomAttr = () => {
        if (customAttrTitle === '' || customAttrValue === '') {
            return
        }

        const newAttr: CustomAttribute = {
            id: null,
            title: customAttrTitle,
            value: customAttrValue
        };
        setCustomAttrs([...customAttrs, newAttr]);

        setCustomAttrTitle('');
        setCustomAttrValue('');
    };

    const buildTaskData = (): ITaskCreate => {
        let taskData = {
            title: title,
            text: text,
            project_id: project,
            priority_id: priority,
            tracker_id: tracker,
            status_id: status,
            time_expected: timeExpected,
            time_expected_by_params: '',
            developer_id: developer,
            readiness: readiness || 0,
            start_at: startAt ? startAt.toString() : null,
            end_at: endAt ? endAt.toString() : null,
            complexity_id: complexity,
            complexity_units: complexityUnits,
            task_attrs: customAttrs
        }
        
        if (timeParamsEnable && timeParams.length > 0) {
            taskData['time_expected_by_params'] = JSON.stringify(timeParams)
        }

        return(taskData)
    }

    const [showPreview, setShowPreview] = useState(false);

    return (
        <div className={'main-container task-new'}>
            <div className={'form-head'}>
                <p className={'title-form'}>
                    { typePage === 'edit' && task ? `#${task.number}` : 'Новая задача' }
                </p>
                <button className={'back-button'} onClick={() => navigate(-1)}>
                    <span>&lt;</span>
                </button>
            </div>

            {fetched ?
                <form className={'form-main'} method={'POST'} onSubmit={typePage === 'new' ? handleCreateTask : handleUpdateTask}>
                    
                    {errors &&
                        <div className="form-task-error">
                            {errors.map((error)=>(
                                <p>{error}</p>
                            ))}
                        </div>
                    }

                    <div className={'form-task-col-center'}>
                        <div className={'form-task-group'}>
                            <label className={'form-task-label'} htmlFor={'title'}>Название</label>
                            <input className={'form-task-input'} name={'title'} placeholder={'Название'}
                                   value={title} onChange={(e) => setTitle(e.target.value)} />
                        </div>
                    </div>

                    <div className={'form-task-col-left'}>
                        <div className={'form-task-group'}>
                            <label className={'form-task-label'} htmlFor={'author'}>Автор</label>
                            <input className={'form-task-input'} style={{'background': '#EDEDED'}}
                                   placeholder={'Автор'}
                                   disabled={true}
                                   value={task ? task.author : author}
                            />
                        </div>
                        <div className={'form-task-group'}>
                            <label className={'form-task-label'} htmlFor={'project'}>Проект</label>
                            <select className={'form-task-select'}
                                    name={'project'}
                                    placeholder={'Проект'}
                                    value={project !== null ? project.toString() : ''}
                                    onChange={(e) => setProject(parseInt(e.target.value) || null)}
                            >
                                {projects.map((project) => (
                                    <option key={project.id} value={project.id}>{project.title}</option>
                                ))}
                            </select>
                        </div>

                        <div className={'form-task-group'}>
                            <label className={'form-task-label'} htmlFor={'tracker'}>Тип</label>
                            <select
                                className='form-task-select'
                                name='tracker'
                                placeholder='Тип'
                                value={tracker !== null ? tracker.toString() : ''}
                                onChange={(e) => setTracker(parseInt(e.target.value))}
                            >
                                {trackers.map((tracker) => (
                                    <option key={tracker.id} value={tracker.id}>{tracker.title}</option>
                                ))}
                            </select>
                        </div>

                        <div className={'form-task-group'}>
                            <label className={'form-task-label'} htmlFor={'developer'}>Назначена</label>
                            <select className={'form-task-select'}
                                    name={'developer'}
                                    placeholder={'Назначена'}
                                    value={developer ? developer.toString() : ''}
                                    onChange={(e) => setDeveloper(parseInt(e.target.value))}>
                                <option key={'0'}></option>
                                {availableDevelopers.map((developer) => (
                                    <option key={developer.id} value={developer.id}>{developer.username}</option>
                                ))}
                            </select>
                        </div>

                        <div className={'form-task-group'}>
                            <label className={'form-task-label'} htmlFor={'start_at'}>Дата начала</label>
                            <Calendar dateAt={startAt} setDateAt={setStartAt} minDate={null} maxDate={endAt} />
                        </div>

                        <div className={'form-task-group'}>
                            <label className={'form-task-label'} htmlFor={'end_at'}>Дата завершения</label>
                            <Calendar dateAt={endAt} setDateAt={setEndAt} minDate={startAt} maxDate={null} />
                        </div>
                    </div>

                    <div className={'form-task-col-right'}>
                        <div className={'form-task-group'}>
                            <label className={'form-task-label'} htmlFor={'developer'}>Статус</label>
                            <select className={'form-task-select'}
                                    name={'status'}
                                    placeholder={'Статус'}
                                    value={status ? status.toString() : ''}
                                    onChange={(e) => setStatus(parseInt(e.target.value))}
                            >
                                {statuses.map((status) => (
                                    <option key={status.id} value={status.id}>{status.title}</option>
                                ))}
                            </select>
                        </div>

                        <div className={'form-task-group'}>
                            <label className={'form-task-label'} htmlFor={'time_expected'}>Оценка трудозатрат (часов)</label>
                            <div className={'form-task-complexity'}>
                                <input className={'form-task-input two-third-width ' + (timeParams.length > 0 && timeParamsEnable && 'with-params')} name={'time_expected'}
                                    placeholder={'Оценка трудозатрат'} value={timeExpected !== null ? timeExpected.toString() : ''}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        if (value === '' || (value !== '' && Number.isInteger(parseInt(value)))) {
                                            setTimeExpected(value === '' ? null : parseInt(value));
                                        } else {
                                            setTimeExpected(null);
                                        }
                                    }}
                                />
                                <button type={'button'} className="time-roles-btn add" onClick={()=>(setTimeParamsEnable(!timeParamsEnable))}>
                                    {timeParamsEnable ?    
                                        <span>
                                            <div>Убрать</div>
                                            <div>параметры</div>
                                        </span>
                                        :
                                        <span>
                                            <div>Добавить</div>
                                            <div>параметры</div>
                                        </span>}
                                </button>
                            </div>
                            <div className={'roles-time-expected ' + (timeParamsEnable && 'opened')}>
                                {timeParams.map((timeParam, index)=>(
                                    <div className={'form-task-time_expected'}>
                                        <input
                                            className={'form-task-input time_expected-param'}
                                            value={timeParam.time_param}
                                            onChange={(e) => handleTimeParamChange(index, 'time_param', e.target.value)}
                                        />
                                        <input
                                            className={'form-task-input time_expected-param'}
                                            value={timeParam.time_expected.toString()}
                                            onChange={(e) => handleTimeParamChange(index, 'time_expected', parseInt(e.target.value))}
                                        />

                                        <div style={{width: '20%'}}>
                                            <button type={'button'} className="time-roles-btn delete" onClick={()=>(removeTimeParam(index))}>
                                                &times;
                                            </button>
                                        </div>
                                    </div>
                                ))}
                                
                                <div className={'form-task-time_expected'}>
                                    <input className={'form-task-input time_expected-param'} name={'time_expected_custom_param'}
                                        placeholder={'Параметр оценки'} value={timeExpectedParam}
                                        onChange={(e) => {setTimeExpectedParam(e.target.value)}} 
                                        list="time-params-options"
                                    />
                                    <datalist id="time-params-options">
                                        {timeParamsOptions.map((timeParamOption)=>(
                                            <option value={timeParamOption} />
                                        ))}
                                    </datalist>

                                    <input className={'form-task-input time_expected-param'} name={'time_expected'}
                                        placeholder={'Оценка трудозатрат'} value={timeExpectedTime !== null ? timeExpectedTime.toString() : ''}
                                        onChange={(e) => {
                                            let value = e.target.value;
                                            if (value === '' || (value !== '' && Number.isInteger(parseInt(value)))) {
                                                setTimeExpectedTime(value === '' ? null : parseInt(value));
                                            } else {
                                                setTimeExpectedTime(null);
                                            }
                                        }} 
                                    />

                                    <button className={'time-roles-btn add'} type={'button'} onClick={addTimeParam}>
                                        <span>
                                            <div>Добавить</div>
                                            <div>оценку</div>
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className={'form-task-group'}>
                            <label className={'form-task-label'} htmlFor={'time_expected'}>Оценка сложности</label>
                            <div className={'form-task-complexity'}>
                                <select className={'form-task-select half-width'}
                                    name={'complexity'}
                                    placeholder={'Параметр сложности'}
                                    value={complexity !== null ? complexity.toString() : ''}
                                    onChange={(e) => setComplexity(parseInt(e.target.value))}
                                    >
                                    {complexities.map((complexity) => (
                                        <option key={complexity.id} value={complexity.id}>{complexity.title}</option>
                                    ))}
                                </select>
                                <input className={'form-task-input half-width'} name={'time_expected'}
                                    placeholder={'Оценка сложности'} value={complexityUnits !== null ? complexityUnits.toString() : ''}
                                    onChange={(e) => setComplexityUnits(e.target.value)} />
                            </div>
                        </div>                        

                        <div className={'form-task-group'}>
                            <label className={'form-task-label'} htmlFor={'priority'}>Приоритет</label>
                            <select className={'form-task-select'}
                                    name={'priority'}
                                    placeholder={'Приоритет'}
                                    value={priority !== null ? priority.toString() : ''}
                                    onChange={(e) => setPriority(parseInt(e.target.value))}
                            >
                                {priorities.map((priority) => (
                                    <option key={priority.id} value={priority.id}>{priority.title}</option>
                                ))}
                            </select>
                        </div>

                        <div className={'form-task-group'}>
                            <label className={'form-task-label'} htmlFor={'readiness'}>Прогресс</label>
                            <select
                                className='form-task-select'
                                name='readiness'
                                placeholder='Прогресс'
                                value={readiness !== null ? readiness.toString() : ''}
                                onChange={(e) => setReadiness(parseInt(e.target.value))}
                            >
                                {options}
                            </select>
                        </div>
                    </div>
                    
                    <div className={'form-task-col-left'}>
                        <div className={'form-task-group'}>
                            <label className={'form-task-label'} htmlFor={'custom_attrs'}>Дополнительные параметры</label>
                            {customAttrs.map((attr, index) => (
                                <div key={index} className={'form-task-time_expected'}>
                                    <input
                                        className={'form-task-input time_expected-param'}
                                        value={attr.title}
                                        readOnly
                                    />
                                    <input
                                        className={'form-task-input time_expected-param'}
                                        value={attr.value}
                                        onChange={(e) => handleCustomAttrChange(index, attr.title, e.target.value)}
                                    />
                                    <div style={{width: '20%'}}>
                                        <button type={'button'} className="time-roles-btn delete" onClick={() => removeCustomAttr(index)}>
                                            &times;
                                        </button>
                                    </div>
                                </div>
                            ))}

                            <div className={'form-task-time_expected'}>
                                <input
                                    className={'form-task-input time_expected-param'}
                                    value={customAttrTitle}
                                    onChange={(e) => setCustomAttrTitle(e.target.value)}
                                />
                                <input
                                    className={'form-task-input time_expected-param'}
                                    value={customAttrValue}
                                    onChange={(e) => setCustomAttrValue(e.target.value)}
                                />
                                <div style={{width: '20%'}}>
                                    <button type={'button'} className="time-roles-btn plus" onClick={addCustomAttr}>
                                        +
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={'form-task-col-center'}>
                        <div className={'form-task-group'}>
                            <label className={'form-task-label'} htmlFor={'text'}>
                                Описание
                                &emsp;
                                <span className={'text-decoration'} onClick={() => (setShowPreview(!showPreview))}>Предпросмотр</span>
                            </label>
                            <textarea className={'form-task-textarea'} name={'text'} placeholder={'Описание'}
                                      value={text} onChange={(e) => (setText(e.target.value))}/>
                        </div>

                        {showPreview && (
                            <div className={'preview-section'}>
                                <div className={'preview-header'}>
                                    <h4>Предпросмотр:</h4>
                                </div>
                                <div className={'preview-content'}>
                                    <Markdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeSlug, rehypeAutolinkHeadings, rehypeRaw]}>
                                        {text}
                                    </Markdown>
                                </div>
                            </div>
                        )}
                        
                        <button className={'save-task-btn'} type={'button'} onClick={typePage === 'new' ? handleCreateTask : handleUpdateTask}>
                            {typePage === 'new' && 'Добавить задачу'}
                            {typePage === 'edit' && 'Обновить задачу'}
                        </button>
                    </div>
                </form>
            :
                <CircularProgress/>
            }
        </div>
    )
}

export default TaskForm