// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.th-sprint-title, .th-sprint-start_at, .th-sprint-end_at, .th-sprint-readiness, .th-sprint-project, .th-sprint-author,
.td-sprint-title, .td-sprint-start_at, .td-sprint-end_at, .td-sprint-readiness, .td-sprint-project, .td-sprint-author {
    padding: 10px;
    border: 1px solid #EDEDED;
}

.th-sprint-title, .th-sprint-start_at, .th-sprint-end_at, .th-sprint-project, .th-sprint-readiness, .th-sprint-author {
    font-weight: bold;
    width: 15%;
}

.td-sprint-title, .td-sprint-start_at, .td-sprint-end_at, .td-sprint-readiness, .td-sprint-project, .td-sprint-author {
    width: 15%;
}

.td-sprint-title a, .td-sprint-project a {
    text-decoration: none;
    color: #273B64;
}

`, "",{"version":3,"sources":["webpack://./src/components/SprintsTable/SprintsTable.css"],"names":[],"mappings":"AAAA;;IAEI,aAAa;IACb,yBAAyB;AAC7B;;AAEA;IACI,iBAAiB;IACjB,UAAU;AACd;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,qBAAqB;IACrB,cAAc;AAClB","sourcesContent":[".th-sprint-title, .th-sprint-start_at, .th-sprint-end_at, .th-sprint-readiness, .th-sprint-project, .th-sprint-author,\n.td-sprint-title, .td-sprint-start_at, .td-sprint-end_at, .td-sprint-readiness, .td-sprint-project, .td-sprint-author {\n    padding: 10px;\n    border: 1px solid #EDEDED;\n}\n\n.th-sprint-title, .th-sprint-start_at, .th-sprint-end_at, .th-sprint-project, .th-sprint-readiness, .th-sprint-author {\n    font-weight: bold;\n    width: 15%;\n}\n\n.td-sprint-title, .td-sprint-start_at, .td-sprint-end_at, .td-sprint-readiness, .td-sprint-project, .td-sprint-author {\n    width: 15%;\n}\n\n.td-sprint-title a, .td-sprint-project a {\n    text-decoration: none;\n    color: #273B64;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
