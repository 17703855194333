import Layout from "../../components/layout";
import SubscriptionStatus from "../../components/SubscriptionStatus";
import SubscriptionBuy from "../../components/SubscriptionBuy";

type SprintPageType =  "status" | "buy";

const SettingsSubscription = ({typePage} : {typePage: SprintPageType}) => {
    return(
        <Layout title="Настройки. Подписка">
            {typePage === "status" && <SubscriptionStatus />}
            {typePage === "buy" && <SubscriptionBuy />}
        </Layout>
    )
}

export default SettingsSubscription
