import Layout from "../../components/layout";
import TaskForm from "../../components/TaskForm";
import TaskShow from "../../components/TaskShow";
import TasksListWithFilters from "../../components/TasksListWithFilters";
import { useActionIsAllowed } from "../../utils/hook";

type TaskPageType = 'new' | 'show' | 'edit' | 'list';

const Task = ({typePage} : {typePage: TaskPageType}) => {

    const showAllowed = useActionIsAllowed('task_show');
    const createAllowed = useActionIsAllowed('sprint_create');
    const editAllowed = useActionIsAllowed('sprint_edit');

    return(
        <Layout title="Задачи">
            {typePage === "list" && showAllowed && <TasksListWithFilters />}
            {typePage === "show" && showAllowed && <TaskShow />}
            {typePage === "new" && createAllowed && <TaskForm typePage={typePage} />}
            {typePage === "edit" && editAllowed && <TaskForm typePage={typePage} />}
        </Layout>
    )
}

export default Task
