import Layout from "../../components/layout";
import {useParams} from "react-router-dom";
import SprintBoard from "../../components/SprintBoard";
import SprintEdit from "../../components/SprintEdit";
import SprintNew from "../../components/SprintNew";
import SprintShowClosed from "../../components/SprintShowClosed";
import { useActionIsAllowed } from "../../utils/hook";

type SprintPageType =  'list' | 'edit' | 'board' | 'new' | 'show';

const Sprint = ({typePage} : {typePage: SprintPageType}) => {
    const {sprint_number} = useParams();

    const showAllowed = useActionIsAllowed('sprint_show');
    const createAllowed = useActionIsAllowed('sprint_create');
    const editAllowed = useActionIsAllowed('sprint_edit');

    return(
        <Layout title="Спринты">
            {typePage === 'board' && showAllowed && sprint_number && <SprintBoard sprintNumber={parseInt(sprint_number)} />}
            {typePage === 'show' && showAllowed && sprint_number && <SprintShowClosed sprintNumber={parseInt(sprint_number)} />}
            {typePage === 'edit' && editAllowed && sprint_number && <SprintEdit sprintNumber={parseInt(sprint_number)} />}
            {typePage === 'new' && createAllowed && <SprintNew />}
        </Layout>
    )
}

export default Sprint
