import { useState } from 'react';
import LoginForm from "../../components/auth/login"
import SignForm from "../../components/auth/register";
import { useSearchParams } from 'react-router-dom';
import LoginInvitedUser from '../../components/LoginInvitedUser';
import './auth.css';

type FormType = "login" | "signin";

const Auth = () => {

  const [formType, setFormType] = useState<FormType>("login");

  const [searchParams] = useSearchParams(); // Use useSearchParams hook

  const wsId = searchParams.get('ws_id');
  const wsTitle = searchParams.get('ws_title');
  const email = searchParams.get('email');
  const sign = searchParams.get('sign');

  if (wsId && wsTitle && email && sign) {
    return <LoginInvitedUser wsId={wsId} wsTitle={wsTitle} email={email} sign={sign} />
  }

  return (
      <div className="form-container">
        <h3 className="form-title">Mngr.online</h3>
        <div className="form-wrapper">

          <div className="form-type">
            <button
                className={"form-type-button " + (formType === "signin" ? "active" : "")}
                onClick={() => setFormType("login")}
            >
              Вход
            </button>
            <button
                className={"form-type-button " + (formType === "login" ? "active" : "")}
                onClick={() => setFormType("signin")}
            >
              Регистрация
           </button>
          </div>

          {formType === "login" ? (
              <LoginForm />
          ) : (
              <SignForm />
          )}
        </div>
      </div>
  );
}

export default Auth;