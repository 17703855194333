import React, {useEffect, useRef} from "react";
import TaskSidebar from "../TaskSidebar";
import { ITask } from "../../common/types/tasks";
import '../NewTaskSmall/NewTaskSmall.css'

interface EditTaskSmallProps {
    isOpen: boolean;
    taskCurrentProps: ITask | null;
    onUpdateTaskProps: (task: ITask) => void;
    onSuccess: () => void;
    onClose: () => void;
    setIsOpen: (isOpen: boolean) => void;
}

const EditTaskSmall: React.FC<EditTaskSmallProps> = ({isOpen, taskCurrentProps, setIsOpen, onClose, onSuccess, onUpdateTaskProps}) => {
    const dropdownRef = useRef<HTMLDivElement>(null);

    const handleOutsideClick = (event: MouseEvent) => {
        const target = event.target as HTMLElement;
        if (dropdownRef.current && !dropdownRef.current.contains(target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    return(
        <TaskSidebar 
            isOpen={isOpen}
            sidebarType="edit"
            taskCurrentProps={taskCurrentProps}
            onUpdateTaskProps={onUpdateTaskProps}
            onClose={onClose}
            onSuccess={onSuccess}
        />
    )

}

export default EditTaskSmall
