import {useEffect, useState} from 'react';
import {BoardData, ICloseSprintStat, IMoveOptions} from "../../common/types/boards";
import {closeUpdateSprint, createColumnInBoard, deleteColumnInBoard, fetchSprintBoard,fetchSprintStatClose,  updateSprint} from "../../utils/axios";
import KanbanBoard from "../KanbanBoard";
import {CircularProgress} from "@mui/material";
import {Link, useNavigate} from "react-router-dom";
import {ISprint, IStatus} from "../../common/types/tasks";
import ModalCloseSprint from '../ModalCloseSprint';
import { useActionIsAllowed } from '../../utils/hook';
import './SprintBoard.css'

const SprintBoard = ({sprintNumber} : {sprintNumber: number}) => {

    const editAllowed = useActionIsAllowed('sprint_edit');

    const navigate = useNavigate();

    const [errors, setErrors] = useState<string[]>([]);
    const [fetched, setFetched] = useState<boolean>();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [showModal, setShowModal] = useState(false);
    
    const [moveOptions, setMoveOptions] = useState<IMoveOptions[]>([]);
    const [tasksData, setTasksData] = useState<ICloseSprintStat>();

    const [columns, setColumns] = useState<BoardData>();
    const [sprint, setSprint] = useState<ISprint>();
    const [statuses, setStatuses] = useState<IStatus[]>([]);
    const [newColumn, setNewColumn] = useState<number>();

    const addNewColumn = async () => {
        setErrors([]);
        const statusToAdd = statuses.find(status => status.id === newColumn);
        if (!statusToAdd) return;
        if (sprint === undefined) return;

        const createNewColumn = await createColumnInBoard(sprint.board_number, {status_id_to_create: statusToAdd.id})
        if (!createNewColumn.errors && createNewColumn.column) {
            setColumns(prevColumns => ({
                ...prevColumns,
                [createNewColumn.column.title]: createNewColumn.column,
            }))
            const updatedStatuses = statuses.filter(status => status.id !== statusToAdd.id);
            setStatuses(updatedStatuses);
        } else if (createNewColumn.errors) {
            setErrors(createNewColumn.errors);
        }
    }

    const deleteColumn = async (column_id: number) => {
        if (sprint === undefined) return;
        setErrors([]);
        const deleteColumnResponse = await deleteColumnInBoard(sprint.board_number, column_id);

        if (!deleteColumnResponse.errors) {
            setColumns(prevColumns => {
                const newColumns = {...prevColumns};
                const columnKeyToDelete = Object.keys(newColumns).find(key => newColumns[key].id === column_id);
                if (columnKeyToDelete) {
                    delete newColumns[columnKeyToDelete];
                }
                newColumns[deleteColumnResponse.default_column.title] = deleteColumnResponse.default_column;
                return newColumns;
            });

            if (deleteColumnResponse.deleted_status) {
                const deletedStatus: IStatus = deleteColumnResponse.deleted_status
                setStatuses(prevStatuses => [...prevStatuses, deletedStatus])
            }

        } else {
            setErrors(deleteColumnResponse.errors);
        }
    }

    useEffect(() => {
        const getBoard = async () => {
            try {
                const fetchedBoard = await fetchSprintBoard(sprintNumber);
                if (fetchedBoard.sprint?.closed === true) {
                    navigate(`/sprints/${sprintNumber}/`);
                    return;
                }
                setColumns(fetchedBoard.board);
                setSprint(fetchedBoard.sprint);
                if (fetchedBoard.statuses)
                    setStatuses(fetchedBoard.statuses);
            } catch (error) {
                console.error(error);
            }
            setFetched(true);
        };
        if (!fetched)
            getBoard();
    }, [fetched])

    useEffect(() => {
        if (statuses.length > 0)
            setNewColumn(statuses[0].id)
    }, [statuses]);

    const closeSprint = async (moveOption?: string) => {
        const finishSprint = async () => {
            if (!sprint) return;
            
            try {
                const finishedSprint = await closeUpdateSprint(sprint.number, moveOption);
                setSprint(finishedSprint.sprint);
            } catch (error) {
                console.error(error);
            } 
        };
        await finishSprint();
        setShowModal(false);
        setFetched(false);
    };

    const onClickCloseSprint = async () => {
        try {
            const fetchedStat = await fetchSprintStatClose(sprintNumber);
            if (fetchedStat) {
                setMoveOptions(fetchedStat.move_options);
                setTasksData(fetchedStat.tasks_data);
            } 
        } catch (error) {
            console.error(error);
        }
        
        setIsOpen(false);
        setShowModal(true);
    };

    return(
        <div className={'main-container task-list'}>
            {fetched && columns && sprint ?
                <>
                    <div className={'head-board ' + (isOpen ? 'active ' : '') + ((Number.isInteger(sprint.project_id) && isOpen) ? 'with-project' : '')}>
                        <div className={'list-head-board'}>
                            <span className={'title-sprint'}>
                                <h3>{sprint.title}</h3>
                                <button className={'list-button small'} onClick={() => setIsOpen(!isOpen)}>
                                    {isOpen ? <span>&and;</span> : <span>&or;</span>}
                                </button>
                            </span>

                            <button className={'list-button back'} onClick={() => navigate(-1)}><span>&lt;</span></button>

                        </div>
                        <div className={'sprint-info ' + (isOpen ? 'active' : '')}>
                            <span>{sprint.desc}</span>
                            <p><strong>Начало: </strong>{sprint.start_at_formatted}</p>
                            <p><strong>Завершение: </strong>{sprint.end_at_formatted}</p>
                            <p className={'sprint-progress'}><strong>Прогресс: </strong>
                                <span className={'progress-block'}>
                                    <div className={'progress-bar'}>
                                        <div className={'progress'} style={{'width': `${sprint.readiness}%`}}></div>
                                    </div>
                                    {sprint.readiness}%
                                </span>
                            </p>
                            <p><strong>Трудозатраты: </strong>{sprint.time_spent_to_tasks} ч</p>
                            <p>
                                <strong>Количество задач: </strong>{sprint.tasks_count}
                                <Link to={'/tasks/?sprint=' + sprint.id} className={'to-list-task-btn'}>Перейти к списку задач</Link>
                            </p>
                            {sprint.project && <p><strong>Проект: </strong>{sprint.project}</p>}
                            <p><strong>Автор: </strong>{sprint.author}</p>

                            {editAllowed &&
                                <div className={'flex-with-spase'}>
                                    <img className={'task-board-btn'} src={'/app/edit.svg'}
                                        onClick={() => navigate(`/sprints/${sprintNumber}/edit`)}/>
                                    <button className={'add-task-btn'} onClick={() => (onClickCloseSprint())}>Завершить спринт</button>
                                </div>}
                        </div>
                    </div>

                    {errors.length > 0 &&
                        <ul>
                            {errors.map((error)=>(
                                <li>{error}</li>
                            ))}
                        </ul>
                    }

                    <KanbanBoard columns={columns}
                                 setColumns={setColumns}
                                 addNewColumn={addNewColumn}
                                 deleteColumn={deleteColumn}
                                 statuses={statuses}
                                 setNewColumn={setNewColumn}
                                 newColumn={newColumn}
                                 boardId={sprint.board_number}
                                 projectId={sprint.project_id}
                                 setErrors={setErrors}
                                 setFetched={setFetched}
                    />

                    {showModal && tasksData ? <ModalCloseSprint tasksData={tasksData}
                                                                moveOptions={moveOptions}
                                                                onConfirm={closeSprint}
                                                                onCancel={() => (setShowModal(false))}/> : null}
                </>
                :
                <CircularProgress/>
            }
        </div>
    )
}

export default SprintBoard
